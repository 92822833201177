import i18next from 'i18next';
import enTranslations from './en.json';
import frTranslations from './fr.json';
import 'moment/locale/fr';
import moment from 'moment';
i18next.init({
//   lng: 'en', // if you're using a language detector, do not define the lng option
//   debug: true,
  resources: {
    en: {
      translation: enTranslations
    },
    fr: {
      translation: frTranslations
    }
  },
  fallbackLng: 'en'
});

// set browser locale
//split for getting the primary language and not Code
i18next.changeLanguage(navigator.language?.split('-')?.[0]);
moment.locale(i18next.language)
const i18n = i18next;
export const locale=i18next.language;
export default i18n;