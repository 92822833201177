import axios from 'axios'
import config from './../config';

const login = async (payload) => {
    try {
        const { username, password } = payload;
        let response;
        try {
            response = await axios.post( config.SERVER_BASE_URL + '/login', { username, password });
        } catch (error) {
            console.log('Catch Response error', error);
            return error.response.data;
        }
        console.log('Response', response);
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    login
}