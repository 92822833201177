import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getAbmList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/abms' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getAbmDetail = async (airportId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/abms/' + airportId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addAbm = async (payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post(config.SERVER_BASE_URL + '/abms', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editAbmDetail = async (airportId, payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.put( config.SERVER_BASE_URL + '/abms/' + airportId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteAbm = async (awhmId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.SERVER_BASE_URL + '/abms/' +  awhmId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export { getAbmList , getAbmDetail , addAbm, editAbmDetail , deleteAbm};
