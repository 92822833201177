import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Menu,
  Chip,
  Link,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  lighten,
  darken,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import { getOptionData } from "../utils/commonUtils";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { NestedMenuItem } from "mui-nested-menu";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class CoursesTable extends React.Component {
  constructor(props) {
    super(props);
    this.airportId = props?.airportId;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.onAction = props?.onAction;
    this.tableHeaders = props?.tableHeaders;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.hideAirportDetails = props?.hideAirportDetails;
    this.respOptions = config?.respOptions;
    this.confirmOptions = config?.confirmOptions;
    this.state = {
      ...props.data,
      showRespDropDown: false,
      showExecutedDropDown: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    this.enableEditOption = config?.enableEditOptionRenewal;
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps?.data });
  }

  handleClick = (event, data, rowIndex, key) => {
    this.setState({
      ...this.state,
      [key]: event.currentTarget,
      courseData: data,
      rowIndex,
    });
  };
  handleClose = (key) => {
    this.setState({
      ...this.state,
      [key]: null,
    });
  };

  render() {
    return (
      <>
        <Card
          style={{
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            borderRadius: "3px",
          }}
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer
              style={{ width: "100%", maxHeight: "calc(100vh - 255px)" }}
              hideScrollbars={false}
            >
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {this.tableHeaders?.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["course_id"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                            ? "right"
                            : "left"
                        }
                        key={headCell.id}
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state?.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.onAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this?.state?.courses &&
                    this?.state?.courses.map((row, index) => {
                      const { _id, course_id, domain, subject, type, remarks } =
                        row;

                      return (
                        <StyledTableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          onClick={() => {
                            this.onAction(_id);
                          }}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              zIndex: 10,
                              backgroundColor: index % 2 ? "white" : "#F6F7F8",
                              minWidth: "10rem",
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{
                                  typography: "body2",
                                  cursor: "pointer",
                                }}
                              >
                                <Typography variant="subtitle2">
                                  <Link
                                    onClick={() => {
                                      this.props.navigate(
                                        `/airtrace/course/${_id}`
                                      );
                                    }}
                                  >
                                    {course_id}
                                  </Link>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2">
                                  <Chip
                                    label={i18n.t(domain)}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showDomainDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showDomainDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showDomainDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                    style={{
                                      backgroundColor: getOptionData(
                                        config?.domainOptions,
                                        domain
                                      )?.color,
                                      cursor: "pointer",
                                      color: getOptionData(
                                        config?.domainOptions,
                                        domain
                                      )?.textColor,
                                    }}
                                    variant="outlined"
                                  />
                                  <Menu
                                    open={Boolean(
                                      this.state.showDomainDropDown
                                    )}
                                    onClose={() => {
                                      this.handleClose("showDomainDropDown");
                                    }}
                                    anchorEl={this.state.showDomainDropDown}
                                  >
                                    {this?.state?.domainOptions?.map(
                                      (option) => {
                                        return (
                                          <NestedMenuItem
                                            label={option?.label}
                                            value={option?.value}
                                            parentMenuOpen={Boolean(
                                              this.state.showDomainDropDown
                                            )}
                                          >
                                            {this.state?.subjectOptions?.[
                                              option?.value
                                            ]?.map((subjectOption) => {
                                              return (
                                                <MenuItem
                                                  value={subjectOption?.value}
                                                  onClick={() => {
                                                    this.onAction(
                                                      "updateCascade",
                                                      {
                                                        domain: option?.value,
                                                        subject:
                                                          subjectOption?.value,
                                                      },
                                                      this.state.courseData,
                                                      "domain",
                                                      this.state.rowIndex
                                                    );
                                                    this.handleClose(
                                                      "showDomainDropDown"
                                                    );
                                                  }}
                                                >
                                                  {subjectOption?.label}
                                                </MenuItem>
                                              );
                                            })}
                                          </NestedMenuItem>
                                        );
                                      }
                                    )}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2">
                                  <Chip
                                    label={i18n.t(subject)}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showSubjectDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showSubjectDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showSubjectDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    variant="outlined"
                                  />
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showSubjectDropDown
                                    )}
                                    anchorEl={this.state.showSubjectDropDown}
                                    onClose={() => {
                                      this.handleClose("showSubjectDropDown");
                                    }}
                                  >
                                    {this.state?.subjectOptions?.[
                                      this.state?.courseData?.domain
                                    ]?.map((option) => {
                                      return (
                                        <MenuItem
                                          value={option?.value}
                                          onClick={() => {
                                            this.onAction(
                                              "update",
                                              option?.value,
                                              this.state.courseData,
                                              "subject",
                                              this.state.rowIndex
                                            );
                                            this.handleClose(
                                              "showSubjectDropDown"
                                            );
                                          }}
                                        >
                                          {option?.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2">
                                  <Chip
                                    label={i18n.t(type)}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showCourseTypeDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showCourseTypeDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showCourseTypeDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                    style={{
                                      cursor: "pointer",
                                    }}
                                    variant="outlined"
                                  />
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showCourseTypeDropDown
                                    )}
                                    anchorEl={this.state.showCourseTypeDropDown}
                                    onClose={() => {
                                      this.handleClose(
                                        "showCourseTypeDropDown"
                                      );
                                    }}
                                  >
                                    {config?.courseTypeOptions?.map(
                                      (option) => {
                                        return (
                                          <MenuItem
                                            value={option?.value}
                                            onClick={() => {
                                              this.onAction(
                                                "update",
                                                option?.value,
                                                this.state.courseData,
                                                "type",
                                                this.state.rowIndex
                                              );
                                              this.handleClose(
                                                "showCourseTypeDropDown"
                                              );
                                            }}
                                          >
                                            {option?.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.props.navigate(`/airtrace/course/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={remarks}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {remarks}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              sx={{ padding: "2px" }}
                              color="inherit"
                              onClick={(e) => {
                                this.onAction("popOverAction", e, row);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this?.state?.courses?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t("no_records_found")}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        {this.state.courses?.length !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(this.state.total_courses / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  this.onAction("pagination", page);
                }}
                page={this.state.page}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Grid>
            <Grid item style={{ width: "10%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                <Select
                  value={this.state.limit}
                  label="Limit"
                  size="small"
                  onChange={async (e) => {
                    this.onAction("limit", e?.target?.value);
                  }}
                >
                  {this.tableLimitOptions?.map((limit) => {
                    return (
                      <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Popover
              open={Boolean(this.state.open)}
              anchorEl={this.state.open}
              onClose={() => {
                this.onAction("closeMenu");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.onAction("closeMenu");
                  this.airportId
                    ? this.props.navigate(
                        `/course/${this.state.course._id}?airport=${this.airportId}`
                      )
                    : this.props.navigate(
                        "/airtrace/course/" + this.state.course._id
                      );
                }}
              >
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                {i18n.t("edit")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.onAction("deleteCourse");
                }}
              >
                <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </MenuItem>
            </Popover>
            <Dialog
              open={this.state?.deleteCoursePopup}
              onClose={() => {
                this.onAction("closeConfirmationPopUp");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    this.onAction("proceed");
                  }}
                >
                  {i18n.t("proceed")}
                </Button>
                <Button
                  onClick={() => {
                    this.onAction("closeConfirmationPopUp");
                  }}
                  autoFocus
                >
                  {i18n.t("cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </>
    );
  }
}
// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <CoursesTable {...props} navigate={navigate} params={params} />;
}
