import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  FormHelperText,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import BadgeIcon from "@mui/icons-material/Badge";
import Link from "@mui/material/Link";

import config, {
  respOptions,
  divisionOptions,
  categoriesOptions,
  documentOptions,
  statusOptions,
} from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";

import {
  addAirTraceOffer,
  getAirTraceOfferDetail,
  editAirTraceOfferDetail,
  getAirTraceOfferContactPersonsList,
} from "../../../services/airTraceOffer.service";
import { getAirportList } from "../../../services/airport.service";
import { getUsers } from "../../../services/user.service";
import { getOptionData } from "../../../utils/commonUtils";
import { getCoursesList } from "../../../services/course.service";
import moment from "moment";

class AddEditAirTraceOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props?.params?.air_trace_offer_id ? "edit" : "add",
      air_trace_offer_id: props.params?.air_trace_offer_id,
      airTraceOfferDetails: {
        date: null,
        validity: null,
        airport: {},
        award_date: null,
        agreement_date:null,
        training_start_date:null,
        training_end_date:null,
        certificate_issue_date:null,
        
      },
      airportOptions: [],
      formErrors: {},
      loading: false,
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
    if (this.airportId) {
      this.getContactPersons(this.airportId);
    }
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getAirTraceOfferDetails();
    }
    await this.getAirports();
    await this.getUsersList();
  }

  getValue(value) {
    return value ? value : "";
  }

  async getAirports() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return {
        label: el?.name,
        value: el?._id,
        code_oaci:el?.code_oaci,
        code_iata:el?.code_iata
      };
    });
    let airportDetail;
    if (this.airportId) {
      airportDetail = response?.filter((el) => {
        return el?._id === this.airportId;
      })?.[0];
      this.setState({
        airportOptions: options,
        airTraceOfferDetails: { ...this.state?.airTraceOfferDetails, airport: airportDetail },
      });
    } else {
      this.setState({
        airportOptions: options,
      });
    }
  }

  async getAirTraceOfferDetails() {
    this.setState({
      loading: true,
    });
    let response = await getAirTraceOfferDetail(this.state.air_trace_offer_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      await this.setState({
        loading: false,
      });
      return;
    }
    if (response && response.airtrace_offer) {
      let airTraceOfferDetails = response.airtrace_offer;
      this.getContactPersons(airTraceOfferDetails?.airport?._id);
      let currency=getOptionData(config?.currencyOptions,airTraceOfferDetails?.currency)
      await this.setState({
        ...this.state,
        airTraceOfferDetails: {
          ...this.state?.airTraceOfferDetails,
          ...airTraceOfferDetails,
          contact_person: {
            name: airTraceOfferDetails?.contact_person?.name,
            _id: airTraceOfferDetails?.contact_person?._id,
          },
          award_date: moment(airTraceOfferDetails?.award_date),
          agreement_date:moment(airTraceOfferDetails?.agreement_date),
          training_start_date:moment(airTraceOfferDetails?.training_start_date),
          training_end_date:moment(airTraceOfferDetails?.training_end_date),
          certificate_issue_date:moment(airTraceOfferDetails?.certificate_issue_date),
          currency
        },
        loading: false,
      },()=>{
          this.getAirTraceCourses();
      });
    }
  }

  async saveOffer() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });
    let payload = {
      ...this.state.airTraceOfferDetails,
      airport: this.state.airTraceOfferDetails?.airport?._id,
      contact_person: this.state.airTraceOfferDetails?.contact_person?._id,
      btee_sa_manager: this.state.airTraceOfferDetails?.btee_sa_manager?._id,
      exp_air_manager: this.state.airTraceOfferDetails?.exp_air_manager?._id,
      currency: this.state.airTraceOfferDetails?.currency?.value,
      course: this.state.airTraceOfferDetails?.course?._id,
      date:this.state.handrailDetails?.date?.format('YYYY-MM-DD'),
      award_date: this.state.airTraceOfferDetails?.award_date?.format('YYYY-MM-DD'),
      agreement_date:this.state.airTraceOfferDetails?.agreement_date?.format('YYYY-MM-DD'),
      training_start_date:this.state.airTraceOfferDetails?.training_start_date?.format('YYYY-MM-DD'),
      training_end_date:this.state.airTraceOfferDetails?.training_end_date?.format('YYYY-MM-DD'),
      certificate_issue_date:this.state.airTraceOfferDetails?.certificate_issue_date?.format('YYYY-MM-DD'),
    };

    if (this.state.mode === "add") {
      let response = await addAirTraceOffer(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }
      if (response && response.airtrace_offer) {
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text:response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : i18n.t("airtrace_offer_added"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editAirTraceOfferDetail(this.state.air_trace_offer_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      if (response && response.status === 200) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text:response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message ,
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }
  goBack() {
    this.props.navigate(-1);
  }
  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors };

    if (field == null || field == "airport") {
      if (
        !this.state.airTraceOfferDetails?.airport?.name ||
        this.state.airTraceOfferDetails?.airport?.name == ""
      ) {
        error["airport"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["airport"];
      }
    }

    if (field == null || field == "code_iata") {
      if (
        !this.state?.airTraceOfferDetails?.code_iata ||
        this.state?.airTraceOfferDetails?.code_iata == ""
      ) {
        error["code_iata"] = i18n.t("this_field_is_required");
      } else {
        delete error["code_iata"];
      }
    }
    if (field == null || field == "code_oaci") {
      if (
        !this.state?.airTraceOfferDetails?.code_oaci ||
        this.state?.airTraceOfferDetails?.code_oaci == ""
      ) {
        error["code_oaci"] = i18n.t("this_field_is_required");
      } else {
        delete error["code_oaci"];
      }
    }
    if (field == null || field == "resp") {
      if (
        !this.state?.airTraceOfferDetails?.resp ||
        this.state.airTraceOfferDetails?.resp == ""
      ) {
        error["resp"] = i18n.t("this_field_is_required");
      } else {
        delete error["resp"];
      }
    }
    if (field == null || field == "division") {
      if (
        !this.state?.airTraceOfferDetails?.division ||
        this.state.airTraceOfferDetails?.division == ""
      ) {
        error["division"] = i18n.t("this_field_is_required");
      } else {
        delete error["division"];
      }
    }
    if (field == null || field == "category") {
      if (
        !this.state?.airTraceOfferDetails?.category ||
        this.state.airTraceOfferDetails?.category == ""
      ) {
        error["category"] = i18n.t("this_field_is_required");
      } else {
        delete error["category"];
      }
    }
    if (field == null || field == "mandate_number") {
      if (
        !this.state?.airTraceOfferDetails?.mandate_number ||
        this.state.airTraceOfferDetails?.mandate_number == ""
      ) {
        error["mandate_number"] = i18n.t("this_field_is_required");
      } else {
        delete error["mandate_number"];
      }
    }
    if (field == null || field == "currency") {
      if (
        !this.state?.airTraceOfferDetails?.currency ||
        this.state.airTraceOfferDetails?.currency == ""
      ) {
        error["currency"] = i18n.t("this_field_is_required");
      } else {
        delete error["currency"];
      }
    }
    if (this.state?.airTraceOfferDetails?.award_date ) {
      if (
        !this.state?.airTraceOfferDetails?.award_amount ||
        this.state.airTraceOfferDetails?.award_amount == ""
      ) {
        error["award_amount"] = i18n.t("this_field_is_required");
      } else {
        delete error["award_amount"];
      }
      if (field == null || field == "training_duration") {
        if (
          !this.state?.airTraceOfferDetails?.training_duration ||
          this.state.airTraceOfferDetails?.training_duration == ""
        ) {
          error["training_duration"] = i18n.t("this_field_is_required");
        } else {
          delete error["training_duration"];
        }
      }
      if (field == null || field == "training_start_date") {
        if (
          !this.state?.airTraceOfferDetails?.training_start_date ||
          this.state.airTraceOfferDetails?.training_start_date == ""
        ) {
          error["training_start_date"] = i18n.t("this_field_is_required");
        } else {
          delete error["training_start_date"];
        }
      }
      if (field == null || field == "training_end_date") {
        if (
          !this.state?.airTraceOfferDetails?.training_end_date ||
          this.state.airTraceOfferDetails?.training_end_date == ""
        ) {
          error["training_end_date"] = i18n.t("this_field_is_required");
        } else {
          delete error["training_end_date"];
        }
      }
      if (field == null || field == "type_of_certificate_issued") {
        if (
          !this.state?.airTraceOfferDetails?.type_of_certificate_issued ||
          this.state.airTraceOfferDetails?.type_of_certificate_issued == ""
        ) {
          error["type_of_certificate_issued"] = i18n.t("this_field_is_required");
        } else {
          delete error["type_of_certificate_issued"];
        }
      }
      if (field == null || field == "certificate_issue_date") {
        if (
          !this.state?.airTraceOfferDetails?.certificate_issue_date ||
          this.state.airTraceOfferDetails?.certificate_issue_date == ""
        ) {
          error["certificate_issue_date"] = i18n.t("this_field_is_required");
        } else {
          delete error["certificate_issue_date"];
        }
      }
    }
    if (field == null || field == "offer_amount") {
      if (
        !this.state?.airTraceOfferDetails?.offer_amount ||
        this.state.airTraceOfferDetails?.offer_amount == ""
      ) {
        error["offer_amount"] = i18n.t("this_field_is_required");
      } else {
        delete error["offer_amount"];
      }
    }

    if (field == null || field == "status") {
      if (
        !this.state?.airTraceOfferDetails?.status ||
        this.state.airTraceOfferDetails?.status == ""
      ) {
        error["status"] = i18n.t("this_field_is_required");
      } else {
        delete error["status"];
      }
    }
    if (field == null || field == "date") {
      if (
        !this.state?.airTraceOfferDetails?.date ||
        this.state.airTraceOfferDetails?.date == null
      ) {
        error["date"] = i18n.t("this_field_is_required");
      } else {
        delete error["date"];
      }
    }
    if (field == null || field == "validity") {
      if (
        !this.state?.airTraceOfferDetails?.validity ||
        this.state.airTraceOfferDetails?.validity == null
      ) {
        error["validity"] = i18n.t("this_field_is_required");
      } else {
        delete error["validity"];
      }
    }
    if (field == null || field == "description") {
      if (
        !this.state?.airTraceOfferDetails?.description ||
        this.state.airTraceOfferDetails?.description == ""
      ) {
        error["description"] = i18n.t("this_field_is_required");
      } else {
        delete error["description"];
      }
    }
    if (
      this.state?.airTraceOfferDetails?.training_agreement
    ) {
    if (field == null || field == "agreement_date") {
      if (
        !this.state?.airTraceOfferDetails?.agreement_date ||
        this.state.airTraceOfferDetails?.agreement_date == ""
      ) {
        error["agreement_date"] = i18n.t("this_field_is_required");
      } else {
        delete error["agreement_date"];
      }
    }
    }
    
    this.setState({
      formErrors: error,
    });
    return error;
  }
  async getUsersList() {
    let query = {
      minimal: true,
    };

    let users = await getUsers(query);
    if (users.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: users?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(users?.error_code))
          : users?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let tempOptions = users.users?.map((option) => {
      return { label: option?.name, value: option?._id };
    });
    await this.setState({
      userOptions: tempOptions,
    });
  }
  async getContactPersons(airportId) {
    let query = {
      airport: airportId,
    };
    let response = await getAirTraceOfferContactPersonsList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.persons?.map((el) => {
      return { label: el?.name, value: el?._id };
    });

    this.setState({
      contactPersons: options,
    });
  }
  async getAirTraceCourses() {
    let query = {
      minimal: true,
    };

    let response = await getCoursesList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
        ? i18n.t(convertMessageCodeToMessage(response?.error_code))
        : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.airtrace_courses?.map((el) => {
      let domain=getOptionData(config?.domainOptions,el?.domain)
      let subject=getOptionData(domain?.subject,el?.subject)
      return { label: `${domain?.label}-${subject?.label}`, _id: el?._id };
    });
    let courseDetail;
    if (response.airtrace_courses) {
      courseDetail = getOptionData(options,this.state?.airTraceOfferDetails?.course);
      this.setState({
        courseOption: options,
        courseDetails: { ...this.state?.courseDetails, course: courseDetail },
      });
    } else {
      this.setState({
        courseOptions: options,
      });
    }
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_offer")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("air_trace_offer")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                 this.props.navigate(`/airtrace/view/3`);
              }}
            >
              <LocalOfferOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("air_trace_offers")}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {this.state.mode === "add"
                ? i18n.t("add")
                : this.state?.airTraceOfferDetails?.mandate_number}
            </Typography>
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>

        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={this?.airportId && true}
                  options={
                    !this.state.airportOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.airportOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        airport: {
                          ...this.state.airTraceOfferDetails?.airport,
                          name: value?.label,
                          _id: value?.value,
                        },
                        code_iata: value?.code_iata,
                        code_oaci: value?.code_oaci,
                        contact_person: {},
                      },
                    });
                    await this.validateField("airport");
                    if (value?.value) {
                      this.getContactPersons(value?.value);
                    }
                  }}
                  value={this.state.airTraceOfferDetails?.airport?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("airport")}
                      error={this.state.formErrors?.airport ? true : false}
                      helperText={this.state.formErrors?.airport}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("code_OACI")}
                  variant="outlined"
                  disabled={true}
                  value={this.state?.airTraceOfferDetails?.code_oaci || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        code_oaci: e.target.value
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("code_IATA")}
                  variant="outlined"
                  disabled={true}
                  value={this.state?.airTraceOfferDetails?.code_iata || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        code_iata: e.target.value,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  multiline
                  rows={2}
                  maxRows={4}
                  label={i18n.t("description")}
                  variant="outlined"
                  value={this.state?.airTraceOfferDetails?.description || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        description: e.target.value,
                      },
                    });
                    await this.validateField("description");
                  }}
                  error={this.state.formErrors?.description ? true : false}
                  helperText={this.state.formErrors?.description}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel>{i18n.t("resp")}</InputLabel>
                  <Select
                    required
                    label={i18n.t("resp")}
                    value={this.state?.airTraceOfferDetails?.resp || ""}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          resp: e.target.value,
                        },
                      });
                      await this.validateField("resp");
                    }}
                    error={this.state.formErrors?.resp ? true : false}
                  >
                    <MenuItem value="">
                      <em>{i18n.t('none')}</em>
                    </MenuItem>
                    {respOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.resp}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-standard-label">
                    {i18n.t("division")}
                  </InputLabel>
                  <Select
                    required
                    label={i18n.t("division")}
                    value={this.state?.airTraceOfferDetails?.division || ""}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          division: e.target.value,
                        },
                      });
                      await this.validateField("division");
                    }}
                    error={this.state.formErrors?.division ? true : false}
                  >
                    <MenuItem value="">
                      <em>{i18n.t('none')}</em>
                    </MenuItem>
                    {divisionOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.division}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-standard-label">
                    {i18n.t("category")}
                  </InputLabel>
                  <Select
                    required
                    label={i18n.t("category")}
                    value={this.state?.airTraceOfferDetails?.category || ""}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          category: e.target.value,
                        },
                      });
                      await this.validateField("category");
                    }}
                    error={this.state.formErrors?.category ? true : false}
                  >
                    <MenuItem value="">
                      <em>{i18n.t('none')}</em>
                    </MenuItem>
                    {categoriesOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.category}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("mandate_number")}
                  variant="outlined"
                  type="number"
                  value={this.state?.airTraceOfferDetails?.mandate_number || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        mandate_number: e.target.value,
                      },
                    });
                    await this.validateField("mandate_number");
                  }}
                  error={this.state.formErrors?.mandate_number ? true : false}
                  helperText={this.state.formErrors?.mandate_number}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel id="demo-simple-select-standard-label">
                    {i18n.t("status")}
                  </InputLabel>
                  <Select
                    required
                    label={i18n.t("status")}
                    value={this.state?.airTraceOfferDetails?.status || ""}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          status: e.target.value,
                        },
                      });
                      await this.validateField("status");
                    }}
                    error={this.state.formErrors?.status ? true : false}
                  >
                    <MenuItem value="">
                      <em>{i18n.t('none')}</em>
                    </MenuItem>
                    {statusOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.status}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={4} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  options={
                    config?.currencyOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        currency: {label:value?.label,value:value?.value},
                      },
                    });
                    this.validateField("currency");
                  }}
                  value={this.state?.airTraceOfferDetails?.currency?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("currency")}
                      error={this.state.formErrors?.currency ? true : false}
                      helperText={this.state.formErrors?.currency}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  required
                  label={i18n.t("offer_amount")}
                  variant="outlined"
                  value={this.state?.airTraceOfferDetails?.offer_amount || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        offer_amount: e.target.value,
                      },
                    });
                    this.validateField("offer_amount");
                  }}
                  error={this.state.formErrors?.offer_amount ? true : false}
                  helperText={this.state.formErrors?.offer_amount}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  required
                  label={i18n.t("award_amount")}
                  variant="outlined"
                  value={this.state?.airTraceOfferDetails?.award_amount || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        award_amount: e.target.value,
                      },
                    });
                    this.validateField("award_amount");
                  }}
                  error={this.state.formErrors?.award_amount ? true : false}
                  helperText={this.state.formErrors?.award_amount}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.date ? true : false,
                        helperText: this.state.formErrors?.date,
                      },
                    }}
                    label={i18n.t("date_of_offer")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.airTraceOfferDetails?.date || null}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          date: e,
                        },
                      });
                      await this.validateField("date");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.validity ? true : false,
                        helperText: this.state.formErrors?.validity,
                      },
                    }}
                    label={i18n.t("validity_of_the_offer")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.airTraceOfferDetails?.validity || null}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          validity: e,
                        },
                      });
                      await this.validateField("validity");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
              <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  options={
                    !this.state.courseOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.courseOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        course: {
                          label: value?.label,
                          _id: value?.value,
                        },
                      },
                    });
                  }}
                  value={this.state.airTraceOfferDetails?.course?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("course")}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  options={
                    !this.state.contactPersons
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.contactPersons
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        contact_person: {
                          name: value?.label,
                          _id: value?.value,
                        },
                      },
                    });
                  }}
                  value={this.state.airTraceOfferDetails?.contact_person?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("airport_contact_person")}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  options={
                    !this.state.userOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.userOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        btee_sa_manager: {
                          name: value?.label,
                          _id: value?.value,
                        },
                      },
                    });
                  }}
                  value={this.state.airTraceOfferDetails?.btee_sa_manager?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("btee_sa_manager_of_the_offer")}
                    />
                  )}
                />
              </Stack>
            </Grid>
            </Grid>
            <Grid container spacing={3} mb={2}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                    label={i18n.t("award_date")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.airTraceOfferDetails?.award_date || null}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          award_date: e,
                          status: "valid",
                          document: "mandate",
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            </Grid>
            {this.state?.airTraceOfferDetails?.award_date && (
              <>
             <Grid container spacing={4} mb={3}>
             <Grid item xs={12}>
             <Typography variant="h6">
                {i18n.t("training_delivered")}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
              <FormControlLabel
                      required
                      control={
                        <Checkbox
                          checked={
                            this.state?.airTraceOfferDetails?.training_agreement
                          }
                          label={i18n.t("training_agreement")}
                          onChange={async (e) => {
                            await this.setState({
                              airTraceOfferDetails: {
                                ...this.state.airTraceOfferDetails,
                                training_agreement: e?.target?.checked,
                              },
                            });
                          }}
                        />
                      }
                      label={i18n.t("training_agreement")}
                    />
              </Stack>
            </Grid>
          </Grid>
           <Grid container spacing={4} mb={2}>
           {this.state?.airTraceOfferDetails?.training_agreement && 
            <Grid item xs={12} >
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.agreement_date ? true : false,
                        helperText: this.state.formErrors?.agreement_date,
                      },
                    }}
                    label={i18n.t("agreement_date")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.airTraceOfferDetails?.agreement_date || null}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          agreement_date: e,
                        },
                      });
                      await this.validateField("agreement_date");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            }
            </Grid>
           <Grid container spacing={4} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("training_duration")}
                  variant="outlined"
                  value={this.state?.airTraceOfferDetails?.training_duration || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        training_duration: e.target.value,
                      },
                    });
                    await this.validateField("training_duration");
                  }}
                  error={this.state.formErrors?.training_duration ? true : false}
                  helperText={this.state.formErrors?.training_duration}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4} >
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.training_start_date ? true : false,
                        helperText: this.state.formErrors?.training_start_date,
                      },
                    }}
                    label={i18n.t("training_start_date")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.airTraceOfferDetails?.training_start_date || null}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          training_start_date: e,
                        },
                      });
                      await this.validateField("training_start_date");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
             <Grid item xs={12} md={4} >
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.training_end_date ? true : false,
                        helperText: this.state.formErrors?.training_end_date,
                      },
                    }}
                    label={i18n.t("training_end_Date")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.airTraceOfferDetails?.training_end_date || null}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          training_end_date: e,
                        },
                      });
                      await this.validateField("training_end_date");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            </Grid>
           <Grid container spacing={4} mb={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("type_of_certificate_issued")}
                  variant="outlined"
                  value={this.state?.airTraceOfferDetails?.type_of_certificate_issued || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        type_of_certificate_issued: e.target.value,
                      },
                    });
                    await this.validateField("type_of_certificate_issued");
                  }}
                  error={this.state.formErrors?.type_of_certificate_issued ? true : false}
                  helperText={this.state.formErrors?.type_of_certificate_issued}
                />
              </Stack>
            </Grid>
             <Grid item xs={12} md={6} >
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.certificate_issue_date ? true : false,
                        helperText: this.state.formErrors?.certificate_issue_date,
                      },
                    }}
                    label={i18n.t("certificate_issue_date")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.airTraceOfferDetails?.certificate_issue_date || null}
                    onChange={async (e) => {
                      await this.setState({
                        airTraceOfferDetails: {
                          ...this.state.airTraceOfferDetails,
                          certificate_issue_date: e,
                        },
                      });
                      await this.validateField("certificate_issue_date");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
           </Grid>
            <Grid container spacing={3} mb={2}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("remarks")}
                  variant="outlined"
                  value={this.state?.airTraceOfferDetails?.remarks || ""}
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={async (e) => {
                    await this.setState({
                      airTraceOfferDetails: {
                        ...this.state.airTraceOfferDetails,
                        remarks: e.target.value,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
            </Grid>
           </>
            )}
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveOffer();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_offer")
                  : i18n.t("update_offer")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditAirTraceOffer {...props} navigate={navigate} params={params} />;
}
