// ----------------------------------------------------------------------

export default function Chip(theme) {
    return {
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: Number(theme.shape.borderRadius),
                    fontSize: '12px',
                    textWrap: 'nowrap',
                    fontWeight: 'bold',
                    width: '100%',
                  },
                  label: {
                    padding: '5px',
                  },
            },
          },
    };
  }
  