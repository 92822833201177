import Swal from "sweetalert2";
import i18n from "../i18n";
import config from "../config";

const getOptionData = (options , value)=>{
    let tempData = options?.filter(option=>option.value === value)?.[0];
    return tempData;
}

const copyToClipBoard=(url)=>{
    navigator.clipboard.writeText(url)
    .then(() => {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("copied_the_link_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    })
    .catch(err => {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("unable_to_copy_link_please_try_Again"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    });
}

const formatSeconds=(seconds)=> {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}`;

}


export { getOptionData , copyToClipBoard , formatSeconds} ;

