import { useEffect } from 'react';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import SimpleLayout from './layouts/simple';
import AdminLayout from './layouts/admin';
import Login from './pages/Login';
import Page404 from './pages/Page404';
import Dashboard from './pages/Dashboard';
import Airports from './pages/Airports/Airports';
import AddEditAirport from './pages/Airports/AddEditAirport';
import Offers from './pages/Offers/Offers';
import AddEditOffer from './pages/Offers/AddEditOffer';
import Tasks from './pages/Tasks/Tasks';
import AddEditTask from './pages/Tasks/AddEditTask';
import AirportDetails from './pages/AirportDetails/AirportDetails';
import Profile from './pages/Profile';
import TrackByAirport from './pages/TrackByAirport/TrackByAirport';
import Handrail from './pages/Handrail/Handrail';
import Awhm from './pages/AWHM-ABM/Awhm/Awhm';
import Users from './pages/Users/Users';
import AddEditUser from './pages/Users/AddEditUser';
import AddEditHandrail from './pages/Handrail/AddEditHandrail';
import AddEditAwhm from './pages/AWHM-ABM/Awhm/AddEditAwhm';
import config from './config';
import DocumentationBteeSa from './pages/DocumentationBteeSa/DocumentationBteeSa';
import AddEditDocumentationBteeSa from './pages/DocumentationBteeSa/AddEditDocumentationBteeSa';
import Airtrace from './pages/Airtrace/Airtrace';
import AddEditTrainee from './pages/Airtrace/Trainees/AddEditTrainee';
import AddEditCourse from './pages/Airtrace/Courses/AddEditCourse';
import AddEditAirTraceOffer from './pages/Airtrace/Offers/AddEditAirTraceOffer';
import AddEditTraining from './pages/Airtrace/Trainings/AddEditTraining';
import AddEditAbm from './pages/AWHM-ABM/ABM/AddEditAbm';
import AwhmAbm from './pages/AWHM-ABM/AWHM-ABM';
export default function Router() {
  let role = localStorage.getItem('role');
  
  const navigate = useNavigate();
  useEffect(() => {
    const token = localStorage.getItem(config.tokenKey);
    if (!token || token === "" || token == "undefined") {
      localStorage.clear();
      navigate('/login');
    } 
  }, [navigate]);
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/dashboard/view" />, index: true },
        { path: 'view', element: <Dashboard /> },
      ]
    },
    {
      path: '/users',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/users/view" />, index: true },
        { path: 'view', element: ['superadmin','admin'].includes(role) ? <Users /> : <Navigate to="/login" />},
        { path: 'add', element: ['superadmin','admin'].includes(role) ? <AddEditUser /> : <Navigate to="/login" />},
        { path: ':user_id', element: ['superadmin','admin'].includes(role) ? <AddEditUser /> : <Navigate to="/login" />}
      ]
    },
    {
      path: '/airports',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/airports/view" />, index: true },
        { path: 'view', element: <Airports /> },
        { path: 'add', element: <AddEditAirport /> },
        { path: ':airport_id', element: <AddEditAirport /> },
        { path: 'airportDetails/:airport_id', element: <AirportDetails /> },
      ]
    },
    {
      path: '/offers',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/offers/view" />, index: true },
        { path: 'view', element: <Offers /> },
        { path: 'add', element: <AddEditOffer /> },
        { path: ':offer_id', element: <AddEditOffer /> },
      ]
    },
    {
      path: '/tasks',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/tasks/view" />, index: true },
        { path: 'view', element: <Tasks /> },
        { path: 'add', element: <AddEditTask /> },
        { path: ':task_id', element: <AddEditTask /> },
      ]
    },
    {
      path: '/handrails',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/handrails/view" />, index: true },
        { path: 'view', element: <Handrail /> },
        { path: 'add', element: <AddEditHandrail /> },
        { path: ':handrail_id', element: <AddEditHandrail /> },
      ]
    },
    {
      path: '/awhm-awm',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/awhm-awm/view/awhm" />, index: true },
        { path: 'view/:tabId', element: <AwhmAbm /> },
        { path: 'awhm/add', element: <AddEditAwhm /> },
        { path: 'awhm/:awhm_id', element: <AddEditAwhm /> },
        { path: 'abm/add', element: <AddEditAbm /> },
        { path: 'abm/:abm_id', element: <AddEditAbm /> },
      ]
    },
    {
      path: '/documentation-btee-sa',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/documentation-btee-sa/view" />, index: true },
        { path: 'view', element: <DocumentationBteeSa /> },
        { path: 'add', element: <AddEditDocumentationBteeSa /> },
        { path: ':documentation_id', element: <AddEditDocumentationBteeSa /> },
      ]
    },
    {
      path: '/airtrace',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/airtrace/view/trainees" />, index: true },
        { path: 'view/:tabId', element: <Airtrace /> },
        { path: 'trainee/add', element: <AddEditTrainee /> },
        { path: 'trainee/:trainee_id', element: <AddEditTrainee /> },
        { path: 'course/add', element: <AddEditCourse /> },
        { path: 'course/:course_id', element: <AddEditCourse /> },
        { path: 'training/add', element: <AddEditTraining /> },
        { path: 'training/:training_id', element: <AddEditTraining /> },
        { path: 'air_trace_offer/add', element: <AddEditAirTraceOffer /> },
        { path: 'air_trace_offer/:air_trace_offer_id', element: <AddEditAirTraceOffer /> },
      ]
    },
    {
      path: '/track-by-airport',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/track-by-airport/view" />, index: true },
        { path: 'view', element: <TrackByAirport /> },
        { path: 'add', element: <AddEditAirport /> },
        { path: ':airport_id', element: <AddEditAirport /> },
        { path: 'airportDetails/:airport_id', element: <AirportDetails /> },
      ]
    },
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: '/',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="/dashboard/view" />, index: true },
        { path: 'view', element: <Dashboard /> },
      ]
    },
    {
      path: 'profile',
      element: <AdminLayout />,
      children: [
        { element: <Navigate to="profile" />, index: true },
        { path: 'view', element: <Profile /> },
      ]
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="login" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
