import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getTraineesList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/airtrace_trainees' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getTraineeDetail = async (airportId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/airtrace_trainees/' + airportId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addTrainee = async (payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post(config.SERVER_BASE_URL + '/airtrace_trainees', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editTraineeDetail = async (airportId, payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.patch( config.SERVER_BASE_URL + '/airtrace_trainees/' + airportId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteTrainee = async (traineeId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.SERVER_BASE_URL + '/airtrace_trainees/' +  traineeId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const exportTrainees = async (query) => {
    try {
        let response;
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/airtrace_trainees/export'+queryString,  { headers: headers ,responseType: 'blob'});
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export { getTraineesList , getTraineeDetail , addTrainee, editTraineeDetail , deleteTrainee , exportTrainees};
