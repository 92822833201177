import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../utils/debounce";
import config from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import Iconify from "../../components/iconify/Iconify";
import DocumentationBteeSaTable from "../../SharedComponent/DocumentationBteeSaTable";
import { getAirportList } from "../../services/airport.service";
import { getDocumentations ,deleteDocumentation,editDocumentationDetail} from "../../services/documentation.service";
const TABLE_HEAD = [
    { id: "wildlife_management_concept", label: i18n.t("wildlife_management_concept"), alignRight: false, sortable: true},
  {
    id: "manira_airport",
    label: i18n.t("manira_airport"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "airport_environment_management_plan",
    label: i18n.t("airport_environment_management_plan"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "rci_rco",
    label: i18n.t("rci_rco"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "emergency_plan",
    label: i18n.t("emergency_plan"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "crisis_management_guide",
    label: i18n.t("crisis_management_guide"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "intervention_sheets",
    label: i18n.t("intervention_sheets"),
    alignCenter: true,
    sortable: true,
  },
  { id: "aerodrome_manual", label: i18n.t("aerodrome_manual"), alignRight: false, sortable: true },
  { id: "" },
];
const TABLE_HEAD_2 = [
    { id: "initial_date", label: i18n.t("initial_date"), alignRight: false, sortable: true},
    { id: "last_revision_date", label: i18n.t("last_revision_date"), alignRight: false, sortable: true},
    { id: "initial_date", label: i18n.t("initial_date"), alignRight: false, sortable: true},
    { id: "last_revision_date", label: i18n.t("last_revision_date"), alignRight: false, sortable: true},
    { id: "initial_date", label: i18n.t("initial_date"), alignRight: false, sortable: true},
    { id: "last_revision_date", label: i18n.t("last_revision_date"), alignRight: false, sortable: true},
    { id: "initial_date", label: i18n.t("initial_date"), alignRight: false, sortable: true},
    { id: "last_revision_date", label: i18n.t("last_revision_date"), alignRight: false, sortable: true},
    { id: "initial_date", label: i18n.t("initial_date"), alignRight: false, sortable: true},
    { id: "last_revision_date", label: i18n.t("last_revision_date"), alignRight: false, sortable: true},
    { id: "initial_date", label: i18n.t("initial_date"), alignRight: false, sortable: true},
    { id: "last_revision_date", label: i18n.t("last_revision_date"), alignRight: false, sortable: true},
    { id: "initial_date", label: i18n.t("initial_date"), alignRight: false, sortable: true},
    { id: "last_revision_date", label: i18n.t("last_revision_date"), alignRight: false, sortable: true},
    { id: "initial_date", label: i18n.t("initial_date"), alignRight: false, sortable: true},
    { id: "last_revision_date", label: i18n.t("last_revision_date"), alignRight: false, sortable: true},
  { id: "" },
];
class DocumentationBteeSa extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      documentations: [],
      documentation: {},
      open: null,
      page: 1,
      filtersOptions: [],
      search: "",
      limit: 100,
      skip: 0,
      total_documentations: 0,
      expanded: false,
      tableLoader: false,
      pageLoader:false,
      filters: {},
      orderBy: { sort_key: "airport", sort_order: true },
      deleteDocumentationPopup:false
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
  }

  async componentDidMount() {
    await this.getAirportsForFilter();
    await this.getDocumentationsData();
  }
  async getAirportsForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
        ? i18n.t(convertMessageCodeToMessage(response?.error_code))
        : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      if(airport?._id === this.airportId){
        this.setState({
          filters:{
            airport:{ label: airport?.name, value: airport?._id }
          },
          expanded:true
        })
      }
      return { label: airport?.name, value: airport?._id };
    });

    this.setState({
      filtersOptions: { ...this.state.filtersOptions, airportOptions },
    });
  }

  async getDocumentationsData(action) {
    this.setState({
      tableLoader: true,
    });
    
    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    Object?.keys(this.state?.filters)?.map((key) => {
        if (this.state?.filters[key]?.value) {
          query[key] = this.state?.filters[key]?.value;
        } else {
          delete query[key];
        }
    });
    if(this.airportId){
      query['airport'] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }
    let documentations = await getDocumentations(query);
    if (documentations.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
          text: documentations?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(documentations?.error_code))
          : documentations?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false
      })
      return;
    }

    this.setState({
      tableLoader: false,
      documentations: documentations.documentations,
      total_documentations: documentations.count,
    });
  }
  async handleDeleteDocumentation(documentationId){
    this.setState({
        pageLoader: true,
      });
      let response = await deleteDocumentation(documentationId);
  
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        });
        return;
      }
  
      if (response) {
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message ,
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        await this.getDocumentationsData();
        this.setState({
          pageLoader: false,
        });
      }
}

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getDocumentationDataOnchange = debounce(() => {
    this.getDocumentationsData('filters');
  }, 500);
  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getDocumentationsData();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getDocumentationsData();
    } else if (action === "popOverAction") {
      this.setState({
        documentationData: rowData,
      });
      this.handleOpenMenu(data);
    } else if (action === "sort") {
            /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
            if (config?.airportJsonKeys.includes(data.toLowerCase())) {
              await this.setState({
                orderBy: {
                  sort_key:
                    data === "airport"
                      ? `airport_name`
                      : `airport_${data.toLowerCase()}`,
                  sort_order: !this.state.orderBy?.sort_order,
                },
              });
            } else {
      await this.setState({
        orderBy: {
          sort_key: data,
          sort_order: !this.state.orderBy?.sort_order,
        },
      });
    }
      this.getDocumentationsData();
    } else if(action === 'closeMenu'){
      this.handleCloseMenu();
    }else if(action === 'deleteDocumentation'){
      this.handleCloseMenu();
      await this.setState({
        deleteDocumentationPopup:true
      });
    }else if(action === 'closeConfirmationPopUp'){
      this.setState({
        deleteDocumentationPopup:false
      });
    }else if(action === 'proceed'){
      this.setState({
        deleteDocumentationPopup:false,
      });
      this.handleDeleteDocumentation(this.state.documentationData._id);
    }
  };
 
  render() {
    return (
      <Container maxWidth='xxl'>
        <Helmet>
          <title>
            {" "}
            {i18n.t("documentation_btee_sa")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Typography className='pageHeader' variant="h4" gutterBottom>
              {i18n.t("documentation_btee_sa")}
        </Typography>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
           <div style={{width:'100%',marginRight:'10px'}}>
          <Accordion
            TransitionProps={{ unmountOnExit: true }}
            expanded={this.state.expanded}
            sx={{
              background: "white",
            }}
          >
            <AccordionSummary
              style={{ background: "white" }}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{margin:0}}
            >
              <Input
                autoFocus
                fullWidth
                disableUnderline
                placeholder="Search…"
                onClick={(e) => {
                  e.stopPropagation();
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <Iconify
                      icon="eva:search-fill"
                      sx={{ color: "text.disabled", width: 20, height: 20 }}
                    />
                  </InputAdornment>
                }
                onChange={(e) => {
                  this.setState({
                    search: e?.target?.value,
                  });
                  this.getDocumentationDataOnchange();
                }}
                sx={{ mr: 1, fontWeight: "fontWeightBold" }}
              />
              <IconButton
                onClick={() => {
                  this.handleExpandClick();
                }}
              >
                <FilterAltIcon />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                <Grid container spacing={3} mb={2}>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <Autocomplete
                        disablePortal
                        noOptionsText={i18n.t('no_options')}
                        options={
                          !this.state?.filtersOptions?.airportOptions
                            ? [{ label: "Loading...", id: 0 }]
                            : this.state?.filtersOptions?.airportOptions
                        }
                        onChange={async (option, value) => {
                          await this.setState({
                            filters: {
                              ...this.state?.filters,
                              airport: {
                                label: value?.label,
                                value: value?.value,
                              },
                            },
                          });
                          this.getDocumentationDataOnchange();
                        }}
                        value={this.state?.filters?.airport?.label || ""}
                        renderInput={(params) => (
                          <TextField {...params} label={i18n.t("airport")} />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Typography>
            </AccordionDetails>
          </Accordion>
        </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/documentation-btee-sa/add");
              }}
            >
              {i18n.t("documentation")}
            </Button>
          </Stack>
        </Grid>

       

        <DocumentationBteeSaTable
          data={this.state}
          onAction={this.handleTableAction}
          tableHeaders={TABLE_HEAD}
          tableHeaders2={TABLE_HEAD_2}
        />
        
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <DocumentationBteeSa {...props} navigate={navigate} params={params} />;
}
