import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  Card,
  Paper,
  IconButton,
  Modal,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Stack,
  Typography,
  Button,
  Backdrop,
} from "@mui/material";
import i18n from "../i18n";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate, useParams } from "react-router-dom";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class RenewedContractsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airportId: props.airportId,
      renewed_contracts: [],
      renewed_contract_details:{date_of_renewed_contract:null,expiry_date_of_renewed_contract:null},
      tableLoader: false,
      deleteEditPopUpDetails: { show: false },
      mode: "",
      showRenewalDetailsModel: false,
      formErrors: { },
    };
    this.useStyles = {
      modelClass: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      renewed_contracts:nextProps?.renewed_contracts
    })
  }
  async tableActions(action, data, index) {
    if (action === "edit") {
      this.setState({
        formErrors: { renewalFormErrors: {} },
        renewed_contract_details: data,
        showRenewalDetailsModel: true,
        deleteEditPopUpDetails: { rowIndex: index },
        mode: "edit",
      });
    } else if (action === "confirmDelete") {
      let temp = this.state?.renewed_contracts;
      let deleteIndex = this.state.deleteEditPopUpDetails?.rowIndex;
      delete temp[deleteIndex];
      let filteredRenewalDetails = temp?.filter(function (el) {
        return el != null;
      });
      await this.setState({
        showRenewalDetailsModel: false,
        renewed_contracts:filteredRenewalDetails,
        deleteEditPopUpDetails: { rowIndex: "" },
        mode: "edit",
      });
      this.props.saveRenewalData(filteredRenewalDetails);
    } else if (action === "delete") {
      this.setState({
        deleteEditPopUpDetails: { show: true, rowIndex: index },
      });
    }
  }
  async saveRenewalDetailsPopUp() {
    let error = await this.validateRenewalForm();
    if (Object?.keys(error)?.length > 0) {
      return;
    }
    this.setState({
      showRenewalDetailsModel: false,
      deleteEditPopUpDetails: { rowIndex: "" },
      mode: "",
    });
    if (this.state.mode === "edit") {
      this.handleRenewalDetails("editRenewalData");
    } else {
      this.handleRenewalDetails("addRenewalData");
    }
  }
  async handleRenewalDetails(action) {
    
    if (action === "addRenewalData") {
      let tempData = { ...this.state?.renewed_contract_details };
      let tempMergedData = [...this.state?.renewed_contracts, tempData];
      let filteredRenewalDetails = tempMergedData?.filter(function (el) {
        return el != null;
      });
      this.setState({
        renewed_contracts:tempMergedData
      })
      this.props.saveRenewalData(filteredRenewalDetails);
    } else if (action === "editRenewalData") {
      let editIndex = this.state.deleteEditPopUpDetails?.rowIndex;
      let tempData = this.state?.renewed_contracts;
      tempData[editIndex] = this.state?.renewed_contract_details;
      let filteredRenewalDetails = tempData?.filter(function (el) {
        return el != null;
      });
      this.props.saveRenewalData(filteredRenewalDetails);
    }
  }

  async validateRenewalForm(field) {
    let error = { ...this.state.formErrors?.renewalFormErrors };
    if (field == null || field === "date_of_renewed_contract") {
      if (!this.state.renewed_contract_details?.date_of_renewed_contract || this.state.renewed_contract_details?.date_of_renewed_contract === "") {
        error["date_of_renewed_contract"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["date_of_renewed_contract"];
      }
    }
    if (field == null || field === "expiry_date_of_renewed_contract") {
      if (!this.state.renewed_contract_details?.expiry_date_of_renewed_contract || this.state.renewed_contract_details?.expiry_date_of_renewed_contract === "") {
        error["expiry_date_of_renewed_contract"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["expiry_date_of_renewed_contract"];
      }
    }
    this.setState({
      formErrors: error,
    });
    return error;
  }

  render() {
    return (
      <>
        <Grid container spacing={2} style={{ margin: "10px 0", width: "100%" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8} style={{ textAlign: "start", marginTop: "0"}}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
                mt={3}
              >
                <Typography variant="h4" gutterBottom>
                  {i18n.t("renewed_contracts")}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "end" ,paddingRight:'25px'}}>
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                className="BtnWidth"
                onClick={() => {
                  this.setState({
                    showRenewalDetailsModel: true,
                    renewed_contract_details: {date_of_renewed_contract:null,expiry_date_of_renewed_contract:null},
                    formErrors: { renewalFormErrors: {} },
                    mode: "add",
                  });
                }}
              >
                {i18n.t("renewal")}
              </Button>
            </Grid>
          </Grid>
          <Card
            style={{
              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              width: "100%",
              borderRadius:'3px'
            }}
          >
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader size="small" aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align={"left"}>
                        <Typography variant="subtitle2">
                          {i18n.t("date_of_renewed_contract")}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align={"left"}>
                        <Typography variant="subtitle2">
                          {i18n.t("expiry_date_of_renewed_contract")}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align={"center"}>
                        <Typography variant="subtitle2">
                          {i18n.t("actions")}
                        </Typography>
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state?.renewed_contracts?.map((row, index) => {
                      const { _id, date_of_renewed_contract, expiry_date_of_renewed_contract } = row;

                      return (
                        <StyledTableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                        >
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              background: index % 2 ? "white" : "#F6F7F8",
                              minWidth: "10rem",
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {moment(date_of_renewed_contract).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            component="th"
                            scope="row"
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {moment(expiry_date_of_renewed_contract).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <div
                              className="col-2"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems:'center'
                              }}
                            >
                              <Typography
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.tableActions("edit", row, index);
                                }}
                              >
                                <IconButton>
                                  <EditIcon />
                                </IconButton>
                              </Typography>
                              <Typography
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.tableActions("delete", "", index);
                                }}
                              >
                                <IconButton sx={{padding:'2px'}}>
                                  <DeleteOutlineIcon style={{ color: "red" }} />
                                </IconButton>
                              </Typography>
                            </div>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                    {this.state?.tableLoader && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}

                    {!this.state?.tableLoader &&
                      this.state?.renewed_contracts?.length === 0 && (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={"100%"}
                          >
                            {i18n.t('no_records_found')}
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Modal
            open={this.state.showRenewalDetailsModel}
            onClose={() => {
              this.setState({
                showRenewalDetailsModel: false,
                deleteEditPopUpDetails: { rowIndex: "" },
              });
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={this.useStyles?.modelClass}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {i18n.t("renew_contract_details")}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={12} md={6}>
            <Stack spacing={1}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                  disabled={this.enableEditOption}
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.date_of_renewed_contract ? true : false,
                        helperText: this.state.formErrors?.date_of_renewed_contract,
                        required:true
                      },
                    }}
                    label={i18n.t("date_of_renewed_contract")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.renewed_contract_details?.date_of_renewed_contract || null}
                    onChange={async (e) => {
                      await this.setState({
                        renewed_contract_details: {
                          ...this.state.renewed_contract_details,
                          date_of_renewed_contract: e,
                        },
                      });
                      await this.validateRenewalForm("date_of_renewed_contract");
                    }}
                  />
                </LocalizationProvider>
                </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                  <Stack spacing={1}>
            <LocalizationProvider dateAdapter={AdapterMoment} >
                  <DatePicker
                  disabled={this.enableEditOption}
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.expiry_date_of_renewed_contract ? true : false,
                        helperText: this.state.formErrors?.expiry_date_of_renewed_contract,
                        required:true
                      },
                    }}
                    label={i18n.t("expiry_date_of_renewed_contract")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.renewed_contract_details?.expiry_date_of_renewed_contract || null}
                    onChange={async (e) => {
                      await this.setState({
                        renewed_contract_details: {
                          ...this.state.renewed_contract_details,
                          expiry_date_of_renewed_contract: e,
                        },
                      });
                      await this.validateRenewalForm("expiry_date_of_renewed_contract");
                    }}
                  />
                </LocalizationProvider>
                </Stack>
                  </Grid>
                </Grid>
              </Typography>
              <Grid container justifyContent="end">
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.saveRenewalDetailsPopUp();
                    }}
                  >
                    {i18n.t("Ok")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.setState({
                        showRenewalDetailsModel: false,
                        deleteEditPopUpDetails: { rowIndex: "" },
                        mode: "",
                      });
                    }}
                  >
                    {i18n.t("cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
        <Dialog
          open={this.state?.deleteEditPopUpDetails?.show}
          onClose={() => {
            this.setState({
              deleteEditPopUpDetails: {
                show: false,
              },
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.tableActions("confirmDelete")}>
            {i18n.t("proceed")}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  deleteEditPopUpDetails: {
                    show: false,
                  },
                });
              }}
              autoFocus
            >
              {i18n.t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <RenewedContractsTable {...props} navigate={navigate} params={params} />;
}
