import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getCoursesList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/airtrace_courses' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getCourseDetail = async (airportId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/airtrace_courses/' + airportId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addCourse = async (payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post(config.SERVER_BASE_URL + '/airtrace_courses', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editCourseDetail = async (airportId, payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.put( config.SERVER_BASE_URL + '/airtrace_courses/' + airportId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const deleteCourse = async (traineeId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.SERVER_BASE_URL + '/airtrace_courses/' +  traineeId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const exportCourses = async (query) => {
    try {
        let response;
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/airtrace_courses/export'+ queryString,  { headers: headers ,responseType: 'blob'});
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export { getCoursesList , getCourseDetail , addCourse, editCourseDetail , deleteCourse , exportCourses};
