import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  TableRow,
  Container,
  Typography,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  TextField,
  Autocomplete,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../utils/debounce";
import config from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import Iconify from "../../components/iconify/Iconify";
import {
  editHandrail,
  getHandrailList,
  deleteHandrail,
} from "../../services/handrail.service";
import CircularProgress from "@mui/material/CircularProgress";
import { getAirportList } from "../../services/airport.service";
import { getOfferList } from "../../services/offer.service";
import HandrailsTable from "../../SharedComponent/HandrailsTable";

const TABLE_HEAD = [
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "mandate_number",
    label: i18n.t("mandate_number"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "date",
    label: i18n.t("date"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "hour",
    label: i18n.t("hour"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "trades",
    label: i18n.t("trades"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "information",
    label: i18n.t("information"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "importance",
    label: i18n.t("importance"),
    alignCenter: true,
    sortable: true,
  },
  { id: "" },
];

class Handrail extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      handrails: [],
      handrail: {},
      open: null,
      page: 1,
      search: "",
      limit: 100,
      skip: 0,
      total_handrails: 0,
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: {},
      filtersOptions: {},
      orderBy: { sort_key: "", sort_order: true },
      showIsOpenDropDown: false,
      deleteHandrailPopUp: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
  }

  async componentDidMount() {
    await this.getHandrails();
    this.getAirportsForFilter();
    this.getOffersForFilter();
  }

  async getHandrails(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
    };
    if (this.state?.orderBy?.sort_key) {
      (query["sort_key"] = this.state?.orderBy?.sort_key),
        (query["sort_order"] = this.state?.orderBy?.sort_order
          ? "ASC"
          : "DESC");
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters[key] !== "") {
        query[key] = this.state?.filters[key]?.value;
      }
    });
    if (this.state.search) {
      query.search = this.state.search;
    }
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    let handrails = await getHandrailList(query);
    if (handrails.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: handrails?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(handrails?.error_code))
          : handrails?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }
    await this.setState({
      tableLoader: false,
      handrails: handrails.handrails,
      total_handrails: handrails.count,
    });
  }
  async getAirportsForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      if (airport?._id === this.airportId) {
        this.setState({
          filters: {
            airport: { label: airport?.name, value: airport?._id },
          },
          expanded: true,
        });
      }
      return { label: airport?.name, value: airport?._id };
    });

    this.setState({
      filtersOptions: { ...this.state.filtersOptions, airportOptions },
    });
  }
  async getOffersForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getOfferList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let offerOptions = response?.offers?.map((offer) => {
      return { label: offer?.mandate_number, value: offer?._id };
    });

    this.setState({
      filtersOptions: { ...this.state.filtersOptions, offerOptions },
    });
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getHandrailDataOnchange = debounce(() => {
    this.getHandrails("filters");
  }, 500);

  async updateHandrailData(payload, handrailId, backUpData) {
    let response = await editHandrail(handrailId, payload);
    if (response && response.error) {
      let tempData = this.state.handrails;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        ...this.state,
        handrails: tempData,
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.handrail) {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("handrail_updated_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }
  async handleDeleteHandrail(handrailId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteHandrail(handrailId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response) {
      await this.getHandrails();
      this.setState({
        pageLoader: false,
      });
    }
  }

  getHandrailsDataOnchange = debounce(() => {
    this.getHandrails("filters");
  }, 500);

  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getHandrails();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getHandrails();
    } else if (action === "popOverAction") {
      this.setState({
        handrail: rowData,
      });
      this.handleOpenMenu(data);
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      if (config?.airportJsonKeys.includes(data.toLowerCase())) {
        await this.setState({
          orderBy: {
            sort_key:
              data === "airport"
                ? `airport_name`
                : `airport_${data.toLowerCase()}`,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      } else {
        await this.setState({
          orderBy: {
            sort_key: data,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      }
      this.getHandrails();
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.handrails;
      tempData[rowIndex] = { ...payload };
      payload["airport"] = payload["airport"]?._id;
      let temp_btee_sa_employees = rowData?.btee_sa_employees?.map(
        (data) => data?._id
      );
      let temp_airport_employees = rowData?.airport_employees?.map(
        (data) => data?._id
      );
      payload["btee_sa_employees"] = temp_btee_sa_employees;
      payload["airport_employees"] = temp_airport_employees;
      payload["airport"] = rowData?.airport?._id;
      payload["offer_number"] = rowData?.offer_number?._id;
      payload["trades"] = rowData?.trades;
      this.setState({
        ...this.state,
        handrails: tempData,
      });
      this.updateHandrailData(payload, payload?._id, backUpData);
    } else if (action === "deleteHandrail") {
      this.handleCloseMenu();
      await this.setState({
        deleteHandrailPopUp: true,
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteHandrailPopUp: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteHandrailPopUp: false,
      });
      this.handleDeleteHandrail(this.state.handrail._id);
    }
  };

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("handrails")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("handrails")}
        </Typography>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      this.setState({
                        search: e?.target?.value,
                      });
                      this.getHandrailDataOnchange();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  <IconButton
                    onClick={() => {
                      this.handleExpandClick();
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.airportOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.airportOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  airport: {
                                    label: value?.label,
                                    value: value ? value?.value : "",
                                  },
                                },
                              });
                              this.getHandrailsDataOnchange();
                            }}
                            value={this.state?.filters?.airport?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("airport")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            noOptionsText={i18n.t('no_options')}
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.offerOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.offerOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  offer: {
                                    label: value?.label,
                                    value: value ? value?.value : "",
                                  },
                                },
                              });
                              this.getHandrailDataOnchange();
                            }}
                            value={this.state?.filters?.offer?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("mandate_number")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/handrails/add");
              }}
            >
              {i18n.t("handrail")}
            </Button>
          </Stack>
        </Grid>

        <HandrailsTable
          data={this.state}
          onAction={this.handleTableAction}
          tableHeaders={TABLE_HEAD}
        />
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Handrail {...props} navigate={navigate} params={params} />;
}
