import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Menu,
  Link,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { getOptionData } from "../utils/commonUtils";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import VerifiedIcon from "@mui/icons-material/Verified";
import GppBadIcon from "@mui/icons-material/GppBad";
import VisibilityIcon from "@mui/icons-material/Visibility";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    border: '1px solid rgba(224, 224, 224, 1)'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class DocumentationBteeSaTable extends React.Component {
  constructor(props) {
    super(props);
    this.airportId = props?.airportId;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.onAction = props?.onAction;
    this.tableHeaders = props?.tableHeaders;
    this.tableHeaders2 = props?.tableHeaders2;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.hideAirportDetails = props?.hideAirportDetails;
    this.respOptions = config?.respOptions;
    this.confirmOptions = config?.confirmOptions;
    this.state = {
      ...props.data,
      showRespDropDown: false,
      showExecutedDropDown: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    this.enableEditOption = config?.enableEditOptionRenewal;
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps?.data });
  }

  handleClick = (event, data, rowIndex, key) => {
    this.setState({
      ...this.state,
      [key]: event.currentTarget,
      documentationData: data,
      rowIndex,
    });
  };
  handleClose = (key) => {
    this.setState({
      ...this.state,
      [key]: null,
    });
  };
  applyHeaderStyles=(headCell,index,data)=>{
    let styles={};
    if(["airport"].includes(headCell.id)){
      styles = {...this.useStyles?.sticky,...styles}
    } 
    if(index === data?.length - 2){
      styles['borderRight']='none'
    }
    if(index === data?.length - 1){
      styles['borderLeft']='none'
    }
    return styles;
  }

  render() {
    return (
      <>
        <Card
          style={{
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            borderRadius: "3px",
          }}
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer
              style={{ width: "100%", maxHeight: "calc(100vh - 255px)" }}
              hideScrollbars={false}
            >
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {/* {this.tableHeaders?.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["airport"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        align={ headCell.alignCenter
                          ? "center"
                          : headCell.alignRight
                          ? "right"
                          : "left"}
                        key={headCell.id}
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state?.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.onAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))} */}
                    <StyledTableCell
                      align="center"
                      style={this.useStyles?.sticky}
                      rowSpan={3}
                    >
                      {i18n.t("airport")}
                    </StyledTableCell>
                    <StyledTableCell align="center" colSpan={8}>
                      {i18n.t("animal_risk")}
                    </StyledTableCell>
                    <StyledTableCell align="center" colSpan={6}>
                      {i18n.t("crisis_management")}
                    </StyledTableCell>
                    <StyledTableCell style={{borderRight:'none'}} align="center" colSpan={2}>
                      {i18n.t("safety_management_system")}
                    </StyledTableCell>
                    <StyledTableCell style={{borderLeft:'none'}} align="center"></StyledTableCell>
                  </TableRow>
                  <TableRow>
                    {this.tableHeaders?.map((headCell,index) => {
                      return (
                        <StyledTableCell
                          colSpan={headCell?.label !== "" && 2}
                          sx={{ textAlign: "center" ,border:index === this.tableHeaders?.length-2?'none':index === this.tableHeaders?.length-1 && '1px solid rgba(224, 224, 224, 1)'}}
                          style={
                            this.applyHeaderStyles(headCell,index,this.tableHeaders)
                          }
                        >
                          {i18n.t(headCell?.label)}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                  <TableRow>
                    {this.tableHeaders2?.map((headCell,index) => {
                      return (
                        <StyledTableCell
                          sx={{ minWidth: "150px" }}
                          style={
                            this.applyHeaderStyles(headCell,index,this.tableHeaders2)
                          }
                        >
                          {i18n.t(headCell?.label)}
                        </StyledTableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this?.state?.documentations &&
                    this?.state?.documentations.map((row, index) => {
                      const {
                        _id,
                        airport,
                        animal_risk,
                        crisis_management,
                        safety_management_system,
                      } = row;

                      return (
                        <StyledTableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          onClick={() => {
                            this.onAction(_id);
                          }}
                        >
                          {!this.hideAirportDetails && (
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{
                                position: "sticky",
                                left: 0,
                                background: "primary.light",
                                backgroundColor:
                                  index % 2 ? "white" : "#F6F7F8",
                                minWidth: "10rem",
                                borderRight: '1px solid black'
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={6}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{
                                    typography: "body2",
                                    cursor: "pointer",
                                  }}
                                  onDoubleClick={() => {
                                    this.airportId
                                      ? this.props.navigate(
                                          `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                        )
                                      : this.props.navigate(`/documentation-btee-sa/${_id}`);
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    <Link
                                      onClick={() => {
                                        this.props.navigate(
                                          `/airports/airportDetails/${row?.airport?._id}`
                                        );
                                      }}
                                    >
                                      {airport?.name}
                                    </Link>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                          )}

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                animal_risk?.wildlife_management_concept
                                  ?.initial_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {animal_risk?.wildlife_management_concept
                                      ?.initial_date ? moment(
                                    animal_risk?.wildlife_management_concept
                                      ?.initial_date
                                  ).format(i18n.t("OPTIONS.date_format")):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" ,borderRight: '1px solid black'}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                animal_risk?.wildlife_management_concept
                                  ?.last_revision_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {animal_risk?.wildlife_management_concept
                                      ?.last_revision_date ? moment(
                                    animal_risk?.wildlife_management_concept
                                      ?.last_revision_date
                                  ).format(i18n.t("OPTIONS.date_format")):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer"}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={animal_risk?.manira_airport?.initial_date}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {animal_risk?.manira_airport?.initial_date ? moment(
                                    animal_risk?.manira_airport?.initial_date
                                  ).format(i18n.t("OPTIONS.date_format")):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" ,borderRight: '1px solid black'}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                animal_risk?.airport_environment_management_plan
                                  ?.last_revision_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {animal_risk
                                      ?.airport_environment_management_plan
                                      ?.last_revision_date?moment(
                                    animal_risk
                                      ?.airport_environment_management_plan
                                      ?.last_revision_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer"}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                animal_risk?.airport_environment_management_plan
                                  ?.initial_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {animal_risk
                                      ?.airport_environment_management_plan
                                      ?.initial_date ? moment(
                                    animal_risk
                                      ?.airport_environment_management_plan
                                      ?.initial_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" ,borderRight: '1px solid black'}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                animal_risk?.airport_environment_management_plan
                                  ?.last_revision_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  { animal_risk
                                      ?.airport_environment_management_plan
                                      ?.last_revision_date ? moment(
                                    animal_risk
                                      ?.airport_environment_management_plan
                                      ?.last_revision_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={animal_risk?.rci_rco?.initial_date}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  { animal_risk?.rci_rco?.initial_date ? moment(
                                    animal_risk?.rci_rco?.initial_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" ,borderRight: '2px solid black'}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={animal_risk?.rci_rco?.last_revision_date}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  { animal_risk?.rci_rco?.last_revision_date ? moment(
                                    animal_risk?.rci_rco?.last_revision_date
                                  ).format(i18n.t("OPTIONS.date_format")) : '-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                crisis_management?.emergency_plan?.initial_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {crisis_management?.emergency_plan
                                      ?.initial_date ? moment(
                                    crisis_management?.emergency_plan
                                      ?.initial_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" ,borderRight: '1px solid black'}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                crisis_management?.emergency_plan
                                  ?.last_revision_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {crisis_management?.emergency_plan
                                      ?.last_revision_date ? moment(
                                    crisis_management?.emergency_plan
                                      ?.last_revision_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                crisis_management?.crisis_management_guide
                                  ?.initial_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  { crisis_management?.crisis_management_guide
                                      ?.initial_date ? moment(
                                    crisis_management?.crisis_management_guide
                                      ?.initial_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" ,borderRight: '1px solid black'}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                crisis_management?.crisis_management_guide
                                  ?.last_revision_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {crisis_management?.crisis_management_guide
                                      ?.last_revision_date ? moment(
                                    crisis_management?.crisis_management_guide
                                      ?.last_revision_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                crisis_management?.intervention_sheets
                                  ?.initial_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {crisis_management?.intervention_sheets
                                      ?.initial_date? moment(
                                    crisis_management?.intervention_sheets
                                      ?.initial_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" ,borderRight: '2px solid black'}}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                crisis_management?.intervention_sheets
                                  ?.last_revision_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {crisis_management?.intervention_sheets
                                      ?.last_revision_date ? moment(
                                    crisis_management?.intervention_sheets
                                      ?.last_revision_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                safety_management_system?.aerodrome_manual
                                  ?.initial_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {safety_management_system?.aerodrome_manual
                                      ?.initial_date? moment(
                                    safety_management_system?.aerodrome_manual
                                      ?.initial_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/documentation-btee-sa/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/documentation-btee-sa/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={
                                safety_management_system?.aerodrome_manual
                                  ?.last_revision_date
                              }
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {safety_management_system?.aerodrome_manual
                                      ?.last_revision_date ? moment(
                                    safety_management_system?.aerodrome_manual
                                      ?.last_revision_date
                                  ).format(i18n.t("OPTIONS.date_format")):'-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              sx={{ padding: "2px" }}
                              color="inherit"
                              onClick={(e) => {
                                this.onAction("popOverAction", e, row);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this?.state?.documentations?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        {this.state.documentations?.length !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(this.state.total_documentations / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  this.onAction("pagination", page);
                }}
                page={this.state.page}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Grid>
            <Grid item style={{ width: "10%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                <Select
                  value={this.state.limit}
                  label="Limit"
                  size="small"
                  onChange={async (e) => {
                    this.onAction("limit", e?.target?.value);
                  }}
                >
                  {this.tableLimitOptions?.map((limit) => {
                    return (
                      <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Popover
              open={Boolean(this.state.open)}
              anchorEl={this.state.open}
              onClose={() => {
                this.onAction("closeMenu");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.onAction("closeMenu");
                  this.airportId
                    ? this.props.navigate(
                        `/documentation-btee-sa/${this.state.documentationData._id}?airport=${this.airportId}`
                      )
                    : this.props.navigate("/documentation-btee-sa/" + this.state.documentationData._id);
                }}
              >
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                    {i18n.t("edit")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.onAction("deleteDocumentation");
                }}
              >
                <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </MenuItem>
            </Popover>
            <Dialog
              open={this.state?.deleteDocumentationPopup}
              onClose={() => {
                this.onAction("closeConfirmationPopUp");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    this.onAction("proceed");
                  }}
                >
                  {i18n.t("proceed")}
                </Button>
                <Button
                  onClick={() => {
                    this.onAction("closeConfirmationPopUp");
                  }}
                  autoFocus
                >
                  {i18n.t("cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </>
    );
  }
}
// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <DocumentationBteeSaTable {...props} navigate={navigate} params={params} />
  );
}
