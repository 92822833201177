import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  Container,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  Popover,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  Input,
  InputAdornment,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { debounce } from "../../utils/debounce";
import config from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import Iconify from "../../components/iconify/Iconify";
import { getUsers, deleteUser } from "../../services/user.service";
import CircularProgress from "@mui/material/CircularProgress";
import { getCountries } from "../../services/common.service";
import ScrollContainer from "react-indiana-drag-scroll";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const TABLE_HEAD = [
  { id: "name", label: i18n.t("name"), alignRight: false, sortable: true },
  {
    id: "email",
    label: i18n.t("email"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "phone_number",
    label: i18n.t("phone_number"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "role",
    label: i18n.t("role"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "username",
    label: i18n.t("username"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "code",
    label: i18n.t("code"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "color",
    label: i18n.t("color"),
    alignRight: true,
    sortable: true,
  },
  { id: "" },
];

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      users: [],
      userData: {},
      open: null,
      page: 1,
      selected: [],
      search: "",
      limit: 100,
      skip: 0,
      total_users: 0,
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: { name: "", country: "", position: "" },
      filtersOptions: {},
      orderBy: { sort_key: "name", sort_order: true },
      showIsOpenDropDown: false,
      deleteUserPopUp: false,
    };
    this.confirmOptions = config.confirmOptions;
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
  }

  async componentDidMount() {
    await this.getUsers();
    this.getCountriesList();
  }

  async getUsers(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      internal: true,
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters[key] !== "") {
        query[key] = this.state?.filters[key];
      }
    });
    if (this.state.search) {
      query.search = this.state.search;
    }

    let users = await getUsers(query);
    if (users.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: users?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(users?.error_code))
          : users?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }
    await this.setState({
      tableLoader: false,
      users: users.users,
      total_users: users.count,
    });
  }
  async getCountriesList() {
    let response = await getCountries();
    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.countries) {
      let countriesList = response.countries;
      let options = countriesList?.map((country) => {
        return {
          label: country?.name,
          value: country?.name,
          phone: country?.dial_code,
          code: country?.code,
        };
      });
      await this.setState({
        filtersOptions: {
          ...this.state.filtersOptions,
          countriesList: options,
        },
      });
    }
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getUserDataOnchange = debounce(() => {
    this.getUsers("filters");
  }, 500);

  handleRowClick = (userId) => {
    this.props.navigate(`/users/${userId}`);
  };

  async handleDeleteUser(userId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteUser(userId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response) {
      await this.getUsers();
      this.setState({
        pageLoader: false,
      });
    }
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("users")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("users")}
        </Typography>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      this.setState({
                        search: e?.target?.value,
                      });
                      this.getUserDataOnchange();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  <IconButton
                    onClick={() => {
                      this.handleExpandClick();
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label={i18n.t("name")}
                            variant="outlined"
                            value={this.state?.filters?.name || ""}
                            onChange={async (e) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  name: e.target.value,
                                },
                              });
                              this.getUserDataOnchange();
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label={i18n.t("email")}
                            variant="outlined"
                            value={this.state?.filters?.email || ""}
                            onChange={async (e) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  email: e.target.value,
                                },
                              });
                              this.getUserDataOnchange();
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <TextField
                            type="number"
                            id="outlined-basic"
                            label={i18n.t("phone_number")}
                            variant="outlined"
                            value={this.state?.filters?.phone_number || ""}
                            onChange={async (e) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  phone_number: e.target.value,
                                },
                              });
                              this.getUserDataOnchange();
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label={i18n.t("username")}
                            variant="outlined"
                            value={this.state?.filters?.username || ""}
                            onChange={async (e) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  username: e.target.value,
                                },
                              });
                              this.getUserDataOnchange();
                            }}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <TextField
                            id="outlined-basic"
                            label={i18n.t("code")}
                            variant="outlined"
                            value={this.state?.filters?.code || ""}
                            onChange={async (e) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  code: e.target.value,
                                },
                              });
                              this.getUserDataOnchange();
                            }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/users/add");
              }}
            >
              {i18n.t("user")}
            </Button>
          </Stack>
        </Grid>

        <Card
          style={{
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            borderRadius: "5px",
          }}
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer
              style={{ width: "100%", maxHeight: "calc(100vh - 370px)" }}
              hideScrollbars={false}
            >
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {TABLE_HEAD.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["name"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        key={headCell.id}
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                            ? "right"
                            : "left"
                        }
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              await this.setState({
                                orderBy: {
                                  sort_key: headCell.id,
                                  sort_order: !this.state.orderBy?.sort_order,
                                },
                              });
                              this.getUsers();
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy?.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this.state.users.map((row, index) => {
                      const {
                        _id,
                        name,
                        email,
                        phone_number,
                        role,
                        username,
                        code,
                        color,
                      } = row;

                      return (
                        <StyledTableRow key={_id} tabIndex={-1}>
                          <StyledTableCell
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: index % 2 ? "white" : "#F6F7F8",
                              minWidth: "10rem",
                              cursor: "pointer",
                            }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align={"center"}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              style={{ margin: "auto", display: "block" }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {phone_number}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={code}
                              style={{ margin: "auto", display: "block" }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {
                                    config?.roleOptions?.filter(
                                      (roleValue) => roleValue?.value === role
                                    )?.[0]?.label
                                  }
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={username}
                              style={{ margin: "auto", display: "block" }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {username}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="right"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="column"
                              spacing={0}
                              sx={{ typography: "body2" }}
                            >
                              <Typography variant="subtitle2">
                                {code}
                              </Typography>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              sx={{ justifyContent: "end" }}
                              key={color}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                justifyContent="center"
                                sx={{ typography: "body2" }}
                              >
                                <Grid
                                  item
                                  justifyContent="center"
                                  sx={{
                                    backgroundColor: color,
                                    height: "20px",
                                    width: "20px",
                                  }}
                                />
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              sx={{ padding: "2px" }}
                              onClick={(e) => {
                                this.setState({
                                  user: row,
                                });
                                this.handleOpenMenu(e);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state.users?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        <Grid
          container
          spacing={2}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Grid></Grid>
          <Grid item>
            <Pagination
              count={Math.ceil(this.state.total_users / this.state.limit)}
              shape="rounded"
              size="large"
              onChange={async (event, page) => {
                await this.setState({
                  skip: (page - 1) * this.state.limit,
                  page,
                });
                this.getUsers();
              }}
              page={this.state.page}
              showFirstButton={true}
              showLastButton={true}
              boundaryCount={2}
              style={{ margin: 20 }}
            />
          </Grid>
          <Grid item style={{ width: "10%" }}>
            <FormControl fullWidth size="small">
              <InputLabel id="demo-simple-select-label">Limit</InputLabel>
              <Select
                value={this.state.limit}
                label="Limit"
                size="small"
                onChange={async (e) => {
                  await this.setState({
                    ...this.state,
                    limit: e?.target?.value,
                  });
                  this.getUsers();
                }}
              >
                {this.tableLimitOptions?.map((limit) => {
                  return (
                    <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Popover
          open={Boolean(this.state.open)}
          anchorEl={this.state.open}
          onClose={this.handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              this.props.navigate("/users/" + this.state.user._id);
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            {i18n.t("edit")}
          </MenuItem>
          <MenuItem
            onClick={() => {
              this.setState({
                deleteUserPopUp: true,
              });
              this.handleCloseMenu();
            }}
          >
            <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
            {i18n.t("delete")}
          </MenuItem>
        </Popover>
        <Dialog
          open={this.state?.deleteUserPopUp}
          onClose={() => {
            this.setState({
              deleteUserPopUp: false,
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={async () => {
                await this.setState({
                  deleteUserPopUp: false,
                });
                this.handleDeleteUser(this.state.user._id);
              }}
            >
             {i18n.t("proceed")}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  deleteUserPopUp: false,
                });
              }}
              autoFocus
            >
              {i18n.t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Users {...props} navigate={navigate} params={params} />;
}
