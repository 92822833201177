import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Breadcrumbs,
  Button,
  Chip,
  Container,
  FormHelperText,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightIcon from "@mui/icons-material/Flight";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import BadgeIcon from "@mui/icons-material/Badge";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import {
  addDocumentation,
  getDocumentationDetail,
  editDocumentationDetail,
} from "../../services/documentation.service";
import { getAirportList } from "../../services/airport.service";
import { getUsers } from "../../services/user.service";
import { getOptionData } from "../../utils/commonUtils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MultipleDatesPicker from "@ambiot/material-ui-multiple-dates-picker";
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";


class AddEditDocumentationBteeSa extends React.Component {
  constructor(props) {
    super(props);
    this.respOptions = config?.respOptions;
    this.state = {
      mode: props?.params?.documentation_id ? "edit" : "add",
      documentation_id: props.params?.documentation_id,
      documentationDetails: {
        date_of_offer: null,
        date_of_initial_contract: null,
        date_of_completion: null,
      },
      formErrors: {},
      loading: false,
      airportOptions: [],
      usersOptions: [],
      confirmOptions: config?.confirmOptions,
      expandedPanel: {},
      datePickerOpenClosed: {},
      dateSelectorStyles: {
        padding: "11px 10px",
        display: "flex",
        alignItems: "center",
        fontSize: "15px",
        border: "1px solid #DCE0E4",
        borderRadius: "5px",
        margin: "0 15px",
        cursor: "pointer",
        height: "auto",
        color: "#637381",
      },
    };
    this.titleStyles={
      position: 'absolute',
      marginTop: '-10px',
      marginLeft: '25px',
      background: 'white',
      padding: '0 4px',
      fontSize: '12px'
    }
    this.styles={
      largeLabel:{
      fontSize:'1.2rem'
      },
      mediumLabel:{
      fontSize:'1rem'
      }

    }
    this.renewalTypeOptions = config?.renewalOptions;
    this.DurationOptions = config?.contractDurationOptions;
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];

    this.enableEditOption = false;
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getDocumentationDetails();
    }
    this.getAirports();
    this.getUsersList();
  }

  async getDocumentationDetails() {
    this.setState({
      loading: true,
    });
    let response = await getDocumentationDetail(this.state.documentation_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.documentation) {
      let documentationData = response.documentation;
      let renewal_type = getOptionData(
        this.renewalTypeOptions,
        documentationData?.renewal_type
      );
      let contract_duration = getOptionData(
        this.DurationOptions,
        documentationData?.contract_duration
      );
      let duration_of_renewal = getOptionData(
        this.DurationOptions,
        documentationData?.duration_of_renewal
      );
      await this.setState({
        documentationDetails: {
          ...documentationData,
          renewal_type,
          contract_duration,
          duration_of_renewal,
        },
        loading: false,
      });
    }
  }

  async getAirports() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return { label: el?.name, value: el?._id };
    });
    let airportDetail;
    if (this.airportId) {
      airportDetail = response?.filter((el) => {
        return el?._id === this.airportId;
      })?.[0];
      this.setState({
        airportOptions: options,
        documentationDetails: {
          ...this.state?.documentationDetails,
          airport: airportDetail,
        },
      });
    } else {
      this.setState({
        airportOptions: options,
      });
    }
  }
  async saveDocumentation() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });

    let payload = {
      ...this.state.documentationDetails,
      airport: this.state.documentationDetails?.airport?._id,
    };

    if (this.state.mode === "add") {
      let response = await addDocumentation(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        this.setState({
          loading: false,
        });
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("documentation_added_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editDocumentationDetail(
        this.state.documentation_id,
        payload
      );
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("documentation_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }
  goBack() {
    this.props.navigate(-1);
  }
  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors };

    if (field == null || field == "airport") {
      if (
        !this.state.documentationDetails?.airport?.name ||
        this.state.documentationDetails?.airport?.name == ""
      ) {
        error["airport"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["airport"];
      }
    }
    //  if (field == null || field == "wildlife_management_concept_initial_date") {
    //       if (
    //         !this.state?.documentationDetails?.animal_risk?.wildlife_management_concept?.initial_date ||
    //         this.state?.documentationDetails?.animal_risk?.wildlife_management_concept?.initial_date == ''
    //       ) {
    //         error["wildlife_management_concept_initial_date"] = i18n.t("this_field_is_required");
    //       } else {
    //         delete error["wildlife_management_concept_initial_date"];
    //       }
    //     }
    //     if (field == null || field == "wildlife_management_concept_revision_dates") {
    //       if (
    //         !this.state?.documentationDetails?.animal_risk?.wildlife_management_concept?.revision_dates ||
    //         this.state?.documentationDetails?.animal_risk?.wildlife_management_concept?.revision_dates?.length == 0
    //       ) {
    //         error["wildlife_management_concept_revision_dates"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["wildlife_management_concept_revision_dates"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "manira_airport_initial_date") {
    //       if (
    //         !this.state?.documentationDetails?.animal_risk?.manira_airport?.initial_date ||
    //         this.state?.documentationDetails?.animal_risk?.manira_airport?.initial_date == ''
    //       ) {
    //         error["manira_airport_initial_date"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["manira_airport_initial_date"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "manira_airport_revision_dates") {
    //       if (
    //         !this.state?.documentationDetails?.animal_risk?.manira_airport?.revision_dates ||
    //         this.state?.documentationDetails?.animal_risk?.manira_airport?.revision_dates?.length == 0
    //       ) {
    //         error["manira_airport_revision_dates"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["manira_airport_revision_dates"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "airport_environment_management_plan_initial_date") {
    //       if (
    //         !this.state?.documentationDetails?.animal_risk?.airport_environment_management_plan?.initial_date ||
    //         this.state?.documentationDetails?.animal_risk?.airport_environment_management_plan?.initial_date == ''
    //       ) {
    //         error["airport_environment_management_plan_initial_date"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["airport_environment_management_plan_initial_date"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "airport_environment_management_plan_revision_dates") {
    //       if (
    //         !this.state?.documentationDetails?.animal_risk?.airport_environment_management_plan?.revision_dates ||
    //         this.state?.documentationDetails?.animal_risk?.airport_environment_management_plan?.revision_dates?.length == 0
    //       ) {
    //         error["airport_environment_management_plan_revision_dates"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["airport_environment_management_plan_revision_dates"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }

    //     if (field == null || field == "rci_rco_initial_date") {
    //       if (
    //         !this.state?.documentationDetails?.animal_risk?.rci_rco?.initial_date ||
    //         this.state?.documentationDetails?.animal_risk?.rci_rco?.initial_date == ''
    //       ) {
    //         error["rci_rco_initial_date"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["rci_rco_initial_date"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "rci_rco_revision_dates") {
    //       if (
    //         !this.state?.documentationDetails?.animal_risk?.rci_rco?.revision_dates ||
    //         this.state?.documentationDetails?.animal_risk?.rci_rco?.revision_dates?.length == 0
    //       ) {
    //         error["rci_rco_revision_dates"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["rci_rco_revision_dates"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }

    //     if (field == null || field == "emergency_plan_initial_date") {
    //       if (
    //         !this.state?.documentationDetails?.crisis_management?.emergency_plan?.initial_date ||
    //         this.state?.documentationDetails?.crisis_management?.emergency_plan?.initial_date == ''
    //       ) {
    //         error["emergency_plan_initial_date"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["emergency_plan_initial_date"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "emergency_plan_revision_dates") {
    //       if (
    //         !this.state?.documentationDetails?.crisis_management?.emergency_plan?.revision_dates ||
    //         this.state?.documentationDetails?.crisis_management?.emergency_plan?.revision_dates?.length == 0
    //       ) {
    //         error["emergency_plan_revision_dates"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["emergency_plan_revision_dates"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }

    //     if (field == null || field == "crisis_management_guide_initial_date") {
    //       if (
    //         !this.state?.documentationDetails?.crisis_management?.crisis_management_guide?.initial_date ||
    //         this.state?.documentationDetails?.crisis_management?.crisis_management_guide?.initial_date == ''
    //       ) {
    //         error["crisis_management_guide_initial_date"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["crisis_management_guide_initial_date"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "crisis_management_guide_revision_dates") {
    //       if (
    //         !this.state?.documentationDetails?.crisis_management?.crisis_management_guide?.revision_dates ||
    //         this.state?.documentationDetails?.crisis_management?.crisis_management_guide?.revision_dates?.length == 0
    //       ) {
    //         error["crisis_management_guide_revision_dates"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["crisis_management_guide_revision_dates"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }

    //     if (field == null || field == "intervention_sheets_initial_date") {
    //       if (
    //         !this.state?.documentationDetails?.crisis_management?.intervention_sheets?.initial_date ||
    //         this.state?.documentationDetails?.crisis_management?.intervention_sheets?.initial_date == ''
    //       ) {
    //         error["intervention_sheets_initial_date"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["intervention_sheets_initial_date"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "intervention_sheets_revision_dates") {
    //       if (
    //         !this.state?.documentationDetails?.crisis_management?.intervention_sheets?.revision_dates ||
    //         this.state?.documentationDetails?.crisis_management?.intervention_sheets?.revision_dates?.length == 0
    //       ) {
    //         error["intervention_sheets_revision_dates"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["intervention_sheets_revision_dates"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }

    //     if (field == null || field == "aerodrome_manual_initial_date") {
    //       if (
    //         !this.state?.documentationDetails?.safety_management_system?.aerodrome_manual?.initial_date ||
    //         this.state?.documentationDetails?.safety_management_system?.aerodrome_manual?.initial_date == ''
    //       ) {
    //         error["aerodrome_manual_initial_date"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["aerodrome_manual_initial_date"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    //     if (field == null || field == "aerodrome_manual_revision_dates") {
    //       if (
    //         !this.state?.documentationDetails?.safety_management_system?.aerodrome_manual?.revision_dates ||
    //         this.state?.documentationDetails?.safety_management_system?.aerodrome_manual?.revision_dates?.length == 0
    //       ) {
    //         error["aerodrome_manual_revision_dates"] = i18n.t("this_field_is_required");
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'red',
    //             border:'1px solid red'
    //           }
    //         })
    //       } else {
    //         delete error["aerodrome_manual_revision_dates"];
    //         this.setState({
    //           dateSelectorStyles:{
    //             ...this.state?.dateSelectorStyles,
    //             color:'#DCE0E4',
    //             border:'1px solid #637381'
    //           }
    //         })
    //       }
    //     }
    this.setState({
      formErrors: error,
    });

    return error;
  }
  async getUsersList() {
    let query = {
      minimal: true,
    };

    let users = await getUsers(query);
    if (users.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: users?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(users?.error_code))
          : users?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let tempOptions = users.users?.map((option) => {
      return { label: option?.name, value: option?._id };
    });
    await this.setState({
      usersOptions: tempOptions,
    });
  }

  handleChange = (panel) => {
    this.expandedPanel = {
      ...this.expandedPanel,
      [panel]: !this.expandedPanel?.[panel] || true,
    };
  };

    
  formatDates=(dates)=>{
    let formattedDates = dates?.map((date)=>{
      return(new Date(date))
    })
    return formattedDates;
  }
  formatRevisionDates=(dates)=>{
      let tempRevisionDates = dates?.map((date)=>{
        return(moment(date).format('YYYY-MM-DD'));
      })
      return tempRevisionDates?.sort();
  }
//   {
//     "animal_risk": {
//         "wildlife_management_concept": {
//             "initial_date": "2024-04-20",
//             "revision_dates": [
//                 "2024-04-25T18:30:00.000Z"
//             ],
//             "last_revision_date": "2024-04-25T18:30:00.000Z"
//         },
//         "manira_airport": {
//             "initial_date": "2024-04-25T18:30:00.000Z",
//             "revision_dates": [
//                 "2024-04-28T18:30:00.000Z",
//                 "2024-04-29T18:30:00.000Z"
//             ],
//             "last_revision_date": "2024-04-29T18:30:00.000Z"
//         },
//         "airport_environment_management_plan": {
//             "initial_date": "2024-04-17T18:30:00.000Z",
//             "revision_dates": [
//                 "2024-04-28T18:30:00.000Z",
//                 "2024-04-29T18:30:00.000Z"
//             ],
//             "last_revision_date": "2024-04-29T18:30:00.000Z"
//         },
//         "rci_rco": {
//             "initial_date": "2024-04-29T18:30:00.000Z",
//             "revision_dates": [
//                 "2024-05-16T18:30:00.000Z",
//                 "2024-05-15T18:30:00.000Z"
//             ],
//             "last_revision_date": "2024-05-15T18:30:00.000Z"
//         }
//     },
//     "crisis_management": {
//         "emergency_plan": {
//             "initial_date": "2024-04-25T18:30:00.000Z",
//             "revision_dates": [
//                 "2024-04-25T18:30:00.000Z",
//                 "2024-04-26T18:30:00.000Z"
//             ],
//             "last_revision_date": "2024-04-26T18:30:00.000Z"
//         },
//         "crisis_management_guide": {
//             "initial_date": "2024-04-18T18:30:00.000Z",
//             "revision_dates": [
//                 "2024-04-05T18:30:00.000Z",
//                 "2024-04-12T18:30:00.000Z",
//                 "2024-04-19T18:30:00.000Z"
//             ],
//             "last_revision_date": "2024-04-19T18:30:00.000Z"
//         },
//         "intervention_sheets": {
//             "initial_date": "2024-04-28T18:30:00.000Z",
//             "revision_dates": [
//                 "2024-04-29T18:30:00.000Z"
//             ],
//             "last_revision_date": "2024-04-29T18:30:00.000Z"
//         }
//     },
//     "safety_management_system": {
//         "aerodrome_manual": {
//             "initial_date": "2024-04-03T18:30:00.000Z",
//             "revision_dates": [
//                 "2024-04-25T18:30:00.000Z"
//             ],
//             "last_revision_date": "2024-04-25T18:30:00.000Z"
//         }
//     },
//     "_id": "660ef5c324d8f08bad52b7b4",
//     "airport": "655a00080772661e97f065ca",
//     "__v": 0
// }
  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_documentation")} | BTEE </title>
        </Helmet>
        <Typography className='pageHeader' variant="h4" gutterBottom>
              {i18n.t("documentation_btee_sa")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            {this?.airportId && (
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                color="inherit"
                onClick={() => {
                  this.props.navigate("/airports/view");
                }}
              >
                <FlightTakeoffIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("airports")}
              </Link>
            )}
            {this?.airportId && (
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                color="inherit"
                onClick={() => {
                  this.props.navigate(
                    `/airports/airportDetails/${this.airportId}`
                  );
                }}
              >
                <FlightIcon sx={{ mr: 0.5 }} fontSize="inherit" />

                {this.state.documentationDetails?.airport?.name}
              </Link>
            )}
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.airportId
                  ? this.props.navigate(
                      `/documentation-btee-sa/view?airportId=${this.airportId}`
                    )
                  : this.props.navigate(`/documentation-btee-sa/view`);
              }}
            >
              <AssignmentOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("documentations")}
            </Link>
            {this.state.mode === "add" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("add")}
              </Typography>
            )}
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={this.props?.urlParams?.airport ? true : false}
                  onOpen={async () => {
                    await this.getAirports();
                  }}
                  options={
                    !this.state.airportOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.airportOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      documentationDetails: {
                        ...this.state.documentationDetails,
                        airport: { name: value?.label, _id: value?.value },
                      },
                    });
                    await this.validateField("airport");
                  }}
                  value={this.state.documentationDetails?.airport?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("airport")}
                      error={this.state.formErrors?.airport ? true : false}
                      helperText={this.state.formErrors?.airport}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
                <Accordion
                  expanded={this.expandedPanel?.panel1}
                  onChange={this.handleChange("panel1")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography style={this?.styles.largeLabel}>{i18n.t("animal_risk")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accordion
                      expanded={this.expandedPanel?.panel1_1}
                      onChange={this.handleChange("panel1_1")}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={this?.styles.mediumLabel}>
                          {i18n.t("wildlife_management_concept")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: this.state.formErrors
                                        ?.wildlife_management_concept_initial_date
                                        ? true
                                        : false,
                                      helperText:
                                        this.state.formErrors
                                          ?.wildlife_management_concept_initial_date,
                                    },
                                  }}
                                  label={i18n.t("initial_date")}
                                  value={
                                    this.state?.documentationDetails
                                      ?.animal_risk?.wildlife_management_concept
                                      ?.initial_date
                                      ? moment(
                                          this.state?.documentationDetails
                                            ?.animal_risk
                                            ?.wildlife_management_concept
                                            ?.initial_date
                                        )
                                      : null
                                  }
                                  format={i18n.t("OPTIONS.date_format")}
                                  onChange={async (e) => {
                                    await this.setState({
                                      documentationDetails: {
                                        ...this.state.documentationDetails,
                                        animal_risk: {
                                          ...this.state.documentationDetails
                                            ?.animal_risk,
                                          wildlife_management_concept: {
                                            ...this.state?.documentationDetails
                                              ?.animal_risk
                                              ?.wildlife_management_concept,
                                            initial_date: e?.format('YYYY-MM-DD'),
                                          },
                                        },
                                      },
                                    });
                                    await this.validateField(
                                      "wildlife_management_concept_initial_date"
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <div style={this.titleStyles}>{i18n.t('revision_dates')}</div>
                              <div
                                style={this?.state?.dateSelectorStyles}
                                onClick={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      wildlife_management_concept:
                                        !this.state?.datePickerOpenClosed
                                          ?.wildlife_management_concept,
                                    },
                                  });
                                }}
                              >
                                {this.state?.documentationDetails?.animal_risk
                                  ?.wildlife_management_concept?.revision_dates
                                  ?.length > 0 ? (
                                  <Grid container>
                                    {this.state?.documentationDetails?.animal_risk?.wildlife_management_concept?.revision_dates?.map(
                                      (date) => {
                                        return (
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ margin: "0 5px" }}
                                          >
                                            <Chip
                                              label={moment(date).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid container sx={{padding:'4px'}}>
                                  {i18n.t('select_revision_dates')}
                                  </Grid>
                                )}
                              </div>
                              <MultipleDatesPicker
                                open={
                                  this.state?.datePickerOpenClosed
                                    ?.wildlife_management_concept
                                }
                                selectedDates={
                                  this.formatDates(this.state?.documentationDetails?.animal_risk
                                    ?.wildlife_management_concept
                                    ?.revision_dates)
                                }
                                onSubmit={async (e) => {
                                  await this.setState({
                                    documentationDetails: {
                                      ...this.state.documentationDetails,
                                      animal_risk: {
                                        ...this.state.documentationDetails
                                          ?.animal_risk,
                                        wildlife_management_concept: {
                                          ...this.state?.documentationDetails
                                            ?.animal_risk
                                            ?.wildlife_management_concept,
                                          revision_dates: this.formatRevisionDates(e),
                                        },
                                      },
                                    },
                                    datePickerOpenClosed: {
                                      wildlife_management_concept:
                                        !this.state?.datePickerOpenClosed
                                          ?.wildlife_management_concept,
                                    },
                                  });
                                  await this.validateField(
                                    "wildlife_management_concept_revision_dates"
                                  );
                                }}
                                onCancel={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      wildlife_management_concept:
                                        !this.state?.datePickerOpenClosed
                                          ?.wildlife_management_concept,
                                    },
                                  });
                                }}
                              />
                              <FormHelperText
                                style={{ color: "red", padding: "0 20px" }}
                              >
                                {
                                  this.state.formErrors
                                    ?.wildlife_management_concept_revision_dates
                                }
                              </FormHelperText>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={this.expandedPanel?.panel1_2}
                      onChange={this.handleChange("panel1_2")}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={this?.styles.mediumLabel}>{i18n.t("manira_airport")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment} >
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: this.state.formErrors
                                        ?.manira_airport_initial_date
                                        ? true
                                        : false,
                                      helperText:
                                        this.state.formErrors
                                          ?.manira_airport_initial_date,
                                    },
                                  }}
                                  label={i18n.t("initial_date")}
                                  value={
                                    this.state?.documentationDetails
                                      ?.animal_risk?.manira_airport
                                      ?.initial_date
                                      ? moment(
                                          this.state?.documentationDetails
                                            ?.animal_risk?.manira_airport
                                            ?.initial_date
                                        )
                                      : null
                                  }
                                  format={i18n.t("OPTIONS.date_format")}
                                  onChange={async (e) => {
                                    await this.setState({
                                      documentationDetails: {
                                        ...this.state.documentationDetails,
                                        animal_risk: {
                                          ...this.state.documentationDetails
                                            ?.animal_risk,
                                          manira_airport: {
                                            ...this.state?.documentationDetails
                                              ?.animal_risk?.manira_airport,
                                            initial_date: e?.format('YYYY-MM-DD'),
                                          },
                                        },
                                      },
                                    });
                                    await this.validateField(
                                      "manira_airport_initial_date"
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                            <div style={this.titleStyles}>{i18n.t('revision_dates')}</div>
                              <div
                                style={this?.state?.dateSelectorStyles}
                                onClick={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      manira_airport:
                                        !this.state?.datePickerOpenClosed
                                          ?.manira_airport,
                                    },
                                  });
                                }}
                              >
                                {this.state?.documentationDetails?.animal_risk
                                  ?.manira_airport?.revision_dates?.length >
                                0 ? (
                                  <Grid container>
                                    {this.state?.documentationDetails?.animal_risk?.manira_airport?.revision_dates?.map(
                                      (date) => {
                                        return (
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ margin: "0 5px" }}
                                          >
                                            <Chip
                                              label={moment(date).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid container sx={{padding:'4px'}}>
                                  {i18n.t('select_revision_dates')}
                                  </Grid>
                                )}
                              </div>
                              <MultipleDatesPicker
                                open={
                                  this.state?.datePickerOpenClosed
                                    ?.manira_airport
                                }
                                selectedDates={
                                  this.formatDates(this.state?.documentationDetails?.animal_risk
                                    ?.manira_airport?.revision_dates)
                                }
                                onSubmit={async (e) => {
                                  await this.setState({
                                    documentationDetails: {
                                      ...this.state.documentationDetails,
                                      animal_risk: {
                                        ...this.state.documentationDetails
                                          ?.animal_risk,
                                        manira_airport: {
                                          ...this.state?.documentationDetails
                                            ?.animal_risk?.manira_airport,
                                          revision_dates: this.formatRevisionDates(e),
                                        },
                                      },
                                    },
                                    datePickerOpenClosed: {
                                      manira_airport:
                                        !this.state?.datePickerOpenClosed
                                          ?.manira_airport,
                                    },
                                  });
                                  await this.validateField(
                                    "manira_airport_revision_dates"
                                  );
                                }}
                                onCancel={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      manira_airport:
                                        !this.state?.datePickerOpenClosed
                                          ?.manira_airport,
                                    },
                                  });
                                }}
                              />
                              <FormHelperText
                                style={{ color: "red", padding: "0 20px" }}
                              >
                                {
                                  this.state.formErrors
                                    ?.manira_airport_revision_dates
                                }
                              </FormHelperText>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={this.expandedPanel?.panel1_3}
                      onChange={this.handleChange("panel1_3")}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={this?.styles.mediumLabel}>
                          {i18n.t("airport_environment_management_plan")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment} >
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: this.state.formErrors
                                        ?.airport_environment_management_plan_initial_date
                                        ? true
                                        : false,
                                      helperText:
                                        this.state.formErrors
                                          ?.airport_environment_management_plan_initial_date,
                                    },
                                  }}
                                  label={i18n.t("initial_date")}
                                  value={
                                    this.state?.documentationDetails
                                      ?.animal_risk
                                      ?.airport_environment_management_plan
                                      ?.initial_date
                                      ? moment(
                                          this.state?.documentationDetails
                                            ?.animal_risk
                                            ?.airport_environment_management_plan
                                            ?.initial_date
                                        )
                                      : null
                                  }
                                  format={i18n.t("OPTIONS.date_format")}
                                  onChange={async (e) => {
                                    await this.setState({
                                      documentationDetails: {
                                        ...this.state.documentationDetails,
                                        animal_risk: {
                                          ...this.state.documentationDetails
                                            ?.animal_risk,
                                          airport_environment_management_plan: {
                                            ...this.state?.documentationDetails
                                              ?.animal_risk
                                              ?.airport_environment_management_plan,
                                            initial_date: e?.format('YYYY-MM-DD'),
                                          },
                                        },
                                      },
                                    });
                                    await this.validateField(
                                      "airport_environment_management_plan_initial_date"
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                            <div style={this.titleStyles}>{i18n.t('revision_dates')}</div>
                              <div
                                style={this?.state?.dateSelectorStyles}
                                onClick={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      airport_environment_management_plan:
                                        !this.state?.datePickerOpenClosed
                                          ?.airport_environment_management_plan,
                                    },
                                  });
                                }}
                              >
                                {this.state?.documentationDetails?.animal_risk
                                  ?.airport_environment_management_plan
                                  ?.revision_dates?.length > 0 ? (
                                  <Grid container>
                                    {this.state?.documentationDetails?.animal_risk?.airport_environment_management_plan?.revision_dates?.map(
                                      (date) => {
                                        return (
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ margin: "0 5px" }}
                                          >
                                            <Chip
                                              label={moment(date).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid container sx={{padding:'4px'}}>
                                  {i18n.t('select_revision_dates')}
                                  </Grid>
                                )}
                              </div>
                              <MultipleDatesPicker
                                open={
                                  this.state?.datePickerOpenClosed
                                    ?.airport_environment_management_plan
                                }
                                selectedDates={
                                  this.formatDates(this.state?.documentationDetails?.animal_risk
                                    ?.airport_environment_management_plan
                                    ?.revision_dates)
                                }
                                onSubmit={async (e) => {
                                  await this.setState({
                                    documentationDetails: {
                                      ...this.state.documentationDetails,
                                      animal_risk: {
                                        ...this.state.documentationDetails
                                          ?.animal_risk,
                                        airport_environment_management_plan: {
                                          ...this.state?.documentationDetails
                                            ?.animal_risk
                                            ?.airport_environment_management_plan,
                                          revision_dates: this.formatRevisionDates(e),
                                        },
                                      },
                                    },
                                    datePickerOpenClosed: {
                                      airport_environment_management_plan:
                                        !this.state?.datePickerOpenClosed
                                          ?.airport_environment_management_plan,
                                    },
                                  });
                                  await this.validateField(
                                    "airport_environment_management_plan_revision_dates"
                                  );
                                }}
                                onCancel={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      airport_environment_management_plan:
                                        !this.state?.datePickerOpenClosed
                                          ?.airport_environment_management_plan,
                                    },
                                  });
                                }}
                              />
                              <FormHelperText
                                style={{ color: "red", padding: "0 20px" }}
                              >
                                {
                                  this.state.formErrors
                                    ?.airport_environment_management_plan_revision_dates
                                }
                              </FormHelperText>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={this.expandedPanel?.panel1_4}
                      onChange={this.handleChange("panel1_4")}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={this?.styles.mediumLabel}>{i18n.t("rci_rco")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment} >
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: this.state.formErrors
                                        ?.rci_rco_initial_date
                                        ? true
                                        : false,
                                      helperText:
                                        this.state.formErrors
                                          ?.rci_rco_initial_date,
                                    },
                                  }}
                                  label={i18n.t("initial_date")}
                                  value={
                                    this.state?.documentationDetails
                                      ?.animal_risk?.rci_rco?.initial_date
                                      ? moment(
                                          this.state?.documentationDetails
                                            ?.animal_risk?.rci_rco?.initial_date
                                        )
                                      : null
                                  }
                                  format={i18n.t("OPTIONS.date_format")}
                                  onChange={async (e) => {
                                    await this.setState({
                                      documentationDetails: {
                                        ...this.state.documentationDetails,
                                        animal_risk: {
                                          ...this.state.documentationDetails
                                            ?.animal_risk,
                                          rci_rco: {
                                            ...this.state?.documentationDetails
                                              ?.animal_risk?.rci_rco,
                                            initial_date: e?.format('YYYY-MM-DD'),
                                          },
                                        },
                                      },
                                    });
                                    await this.validateField(
                                      "rci_rco_initial_date"
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                            <div style={this.titleStyles}>{i18n.t('revision_dates')}</div>
                              <div
                                style={this?.state?.dateSelectorStyles}
                                onClick={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      rci_rco:
                                        !this.state?.datePickerOpenClosed
                                          ?.rci_rco,
                                    },
                                  });
                                }}
                              >
                                {this.state?.documentationDetails?.animal_risk
                                  ?.rci_rco?.revision_dates?.length > 0 ? (
                                  <Grid container>
                                    {this.state?.documentationDetails?.animal_risk?.rci_rco?.revision_dates?.map(
                                      (date) => {
                                        return (
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ margin: "0 5px" }}
                                          >
                                            <Chip
                                              label={moment(date).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid container sx={{padding:'4px'}}>
                                  {i18n.t('select_revision_dates')}
                                  </Grid>
                                )}
                              </div>
                              <MultipleDatesPicker
                                open={this.state?.datePickerOpenClosed?.rci_rco}
                                selectedDates={
                                  this.formatDates(this.state?.documentationDetails?.animal_risk
                                    ?.rci_rco?.revision_dates)
                                }
                                onSubmit={async (e) => {
                                  await this.setState({
                                    documentationDetails: {
                                      ...this.state.documentationDetails,
                                      animal_risk: {
                                        ...this.state.documentationDetails
                                          ?.animal_risk,
                                        rci_rco: {
                                          ...this.state?.documentationDetails
                                            ?.animal_risk?.rci_rco,
                                          revision_dates: this.formatRevisionDates(e),
                                        },
                                      },
                                    },
                                    datePickerOpenClosed: {
                                      rci_rco:
                                        !this.state?.datePickerOpenClosed
                                          ?.rci_rco,
                                    },
                                  });
                                  await this.validateField(
                                    "rci_rco_revision_dates"
                                  );
                                }}
                                onCancel={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      rci_rco:
                                        !this.state?.datePickerOpenClosed
                                          ?.rci_rco,
                                    },
                                  });
                                }}
                              />
                              <FormHelperText
                                style={{ color: "red", padding: "0 20px" }}
                              >
                                {this.state.formErrors?.rci_rco_revision_dates}
                              </FormHelperText>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={this.expandedPanel?.panel2}
                  onChange={this.handleChange("panel2")}
                >
                  <AccordionSummary
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography style={this?.styles.largeLabel}>{i18n.t("crisis_management")}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accordion
                      expanded={this.expandedPanel?.panel2_1}
                      onChange={this.handleChange("panel2_1")}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={this?.styles.mediumLabel}>{i18n.t("emergency_plan")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="az">
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: this.state.formErrors
                                        ?.emergency_plan_initial_date
                                        ? true
                                        : false,
                                      helperText:
                                        this.state.formErrors
                                          ?.emergency_plan_initial_date,
                                    },
                                  }}
                                  label={i18n.t("initial_date")}
                                  value={
                                    this.state?.documentationDetails
                                      ?.crisis_management?.emergency_plan
                                      ?.initial_date
                                      ? moment(
                                          this.state?.documentationDetails
                                            ?.crisis_management?.emergency_plan
                                            ?.initial_date
                                        )
                                      : null
                                  }
                                  format={i18n.t("OPTIONS.date_format")}
                                  onChange={async (e) => {
                                    await this.setState({
                                      documentationDetails: {
                                        ...this.state.documentationDetails,
                                        crisis_management: {
                                          ...this.state.documentationDetails
                                            ?.crisis_management,
                                          emergency_plan: {
                                            ...this.state?.documentationDetails
                                              ?.crisis_management
                                              ?.emergency_plan,
                                            initial_date: e?.format('YYYY-MM-DD'),
                                          },
                                        },
                                      },
                                    });
                                    await this.validateField(
                                      "emergency_plan_initial_date"
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                            <div style={this.titleStyles}>{i18n.t('revision_dates')}</div>
                              <div
                                style={this?.state?.dateSelectorStyles}
                                onClick={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      emergency_plan:
                                        !this.state?.datePickerOpenClosed
                                          ?.emergency_plan,
                                    },
                                  });
                                }}
                              >
                                {this.state?.documentationDetails
                                  ?.crisis_management?.emergency_plan
                                  ?.revision_dates?.length > 0 ? (
                                  <Grid container>
                                    {this.state?.documentationDetails?.crisis_management?.emergency_plan?.revision_dates?.map(
                                      (date) => {
                                        return (
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ margin: "0 5px" }}
                                          >
                                            <Chip
                                              label={moment(date).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid container sx={{padding:'4px'}}>
                                  {i18n.t('select_revision_dates')}
                                  </Grid>
                                )}
                              </div>
                              <MultipleDatesPicker
                                open={
                                  this.state?.datePickerOpenClosed
                                    ?.emergency_plan
                                }
                                selectedDates={
                                  this.formatDates(this.state?.documentationDetails
                                    ?.crisis_management?.emergency_plan
                                    ?.revision_dates)
                                }
                                onSubmit={async (e) => {
                                  await this.setState({
                                    documentationDetails: {
                                      ...this.state.documentationDetails,
                                      crisis_management: {
                                        ...this.state.documentationDetails
                                          ?.crisis_management,
                                        emergency_plan: {
                                          ...this.state?.documentationDetails
                                            ?.crisis_management?.emergency_plan,
                                          revision_dates: this.formatRevisionDates(e),
                                        },
                                      },
                                    },
                                    datePickerOpenClosed: {
                                      emergency_plan:
                                        !this.state?.datePickerOpenClosed
                                          ?.emergency_plan,
                                    },
                                  });
                                  await this.validateField(
                                    "emergency_plan_revision_dates"
                                  );
                                }}
                                onCancel={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      emergency_plan:
                                        !this.state?.datePickerOpenClosed
                                          ?.emergency_plan,
                                    },
                                  });
                                }}
                              />
                              <FormHelperText
                                style={{ color: "red", padding: "0 20px" }}
                              >
                                {
                                  this.state.formErrors
                                    ?.emergency_plan_revision_dates
                                }
                              </FormHelperText>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={this.expandedPanel?.panel2_2}
                      onChange={this.handleChange("panel2_2")}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={this?.styles.mediumLabel}>
                          {i18n.t("crisis_management_guide")}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment} >
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: this.state.formErrors
                                        ?.crisis_management_guide_initial_date
                                        ? true
                                        : false,
                                      helperText:
                                        this.state.formErrors
                                          ?.crisis_management_guide_initial_date,
                                    },
                                  }}
                                  label={i18n.t("initial_date")}
                                  value={
                                    this.state?.documentationDetails
                                      ?.crisis_management
                                      ?.crisis_management_guide?.initial_date
                                      ? moment(
                                          this.state?.documentationDetails
                                            ?.crisis_management
                                            ?.crisis_management_guide
                                            ?.initial_date
                                        )
                                      : null
                                  }
                                  format={i18n.t("OPTIONS.date_format")}
                                  onChange={async (e) => {
                                    await this.setState({
                                      documentationDetails: {
                                        ...this.state.documentationDetails,
                                        crisis_management: {
                                          ...this.state.documentationDetails
                                            ?.crisis_management,
                                          crisis_management_guide: {
                                            ...this.state?.documentationDetails
                                              ?.crisis_management
                                              ?.crisis_management_guide,
                                            initial_date: e?.format('YYYY-MM-DD'),
                                          },
                                        },
                                      },
                                    });
                                    await this.validateField(
                                      "crisis_management_guide_initial_date"
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                            <div style={this.titleStyles}>{i18n.t('revision_dates')}</div>
                              <div
                                style={this?.state?.dateSelectorStyles}
                                onClick={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      crisis_management_guide:
                                        !this.state?.datePickerOpenClosed
                                          ?.crisis_management_guide,
                                    },
                                  });
                                }}
                              >
                                {this.state?.documentationDetails
                                  ?.crisis_management?.crisis_management_guide
                                  ?.revision_dates?.length > 0 ? (
                                  <Grid container>
                                    {this.state?.documentationDetails?.crisis_management?.crisis_management_guide?.revision_dates?.map(
                                      (date) => {
                                        return (
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ margin: "0 5px" }}
                                          >
                                            <Chip
                                              label={moment(date).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid container sx={{padding:'4px'}}>
                                  {i18n.t('select_revision_dates')}
                                  </Grid>
                                )}
                              </div>
                              <MultipleDatesPicker
                                open={
                                  this.state?.datePickerOpenClosed
                                    ?.crisis_management_guide
                                }
                                selectedDates={
                                  this.formatDates(this.state?.documentationDetails
                                    ?.crisis_management?.crisis_management_guide
                                    ?.revision_dates)
                                }
                                onSubmit={async (e) => {
                                  await this.setState({
                                    documentationDetails: {
                                      ...this.state.documentationDetails,
                                      crisis_management: {
                                        ...this.state.documentationDetails
                                          ?.crisis_management,
                                        crisis_management_guide: {
                                          ...this.state?.documentationDetails
                                            ?.crisis_management
                                            ?.crisis_management_guide,
                                          revision_dates: this.formatRevisionDates(e),
                                        },
                                      },
                                    },
                                    datePickerOpenClosed: {
                                      crisis_management_guide:
                                        !this.state?.datePickerOpenClosed
                                          ?.crisis_management_guide,
                                    },
                                  });
                                  await this.validateField(
                                    "crisis_management_guide_revision_dates"
                                  );
                                }}
                                onCancel={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      crisis_management_guide:
                                        !this.state?.datePickerOpenClosed
                                          ?.crisis_management_guide,
                                    },
                                  });
                                }}
                              />
                              <FormHelperText
                                style={{ color: "red", padding: "0 20px" }}
                              >
                                {
                                  this.state.formErrors
                                    ?.crisis_management_guide_revision_dates
                                }
                              </FormHelperText>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                    <Accordion
                      expanded={this.expandedPanel?.panel2_3}
                      onChange={this.handleChange("panel2_3")}
                    >
                      <AccordionSummary
                        aria-controls="panel2d-content"
                        id="panel2d-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={this?.styles.mediumLabel}>{i18n.t("intervention_sheets")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: this.state.formErrors
                                        ?.intervention_sheets_initial_date
                                        ? true
                                        : false,
                                      helperText:
                                        this.state.formErrors
                                          ?.intervention_sheets_initial_date,
                                    },
                                  }}
                                  label={i18n.t("initial_date")}
                                  value={
                                    this.state?.documentationDetails
                                      ?.crisis_management?.intervention_sheets
                                      ?.initial_date
                                      ? moment(
                                          this.state?.documentationDetails
                                            ?.crisis_management
                                            ?.intervention_sheets?.initial_date
                                        )
                                      : null
                                  }
                                  format={i18n.t("OPTIONS.date_format")}
                                  onChange={async (e) => {
                                    await this.setState({
                                      documentationDetails: {
                                        ...this.state.documentationDetails,
                                        crisis_management: {
                                          ...this.state.documentationDetails
                                            ?.crisis_management,
                                          intervention_sheets: {
                                            ...this.state?.documentationDetails
                                              ?.crisis_management
                                              ?.intervention_sheets,
                                            initial_date: e?.format('YYYY-MM-DD'),
                                          },
                                        },
                                      },
                                    });
                                    await this.validateField(
                                      "intervention_sheets_initial_date"
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                            <div style={this.titleStyles}>{i18n.t('revision_dates')}</div>
                              <div
                                style={this?.state?.dateSelectorStyles}
                                onClick={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      intervention_sheets:
                                        !this.state?.datePickerOpenClosed
                                          ?.intervention_sheets,
                                    },
                                  });
                                }}
                              >
                                {this.state?.documentationDetails
                                  ?.crisis_management?.intervention_sheets
                                  ?.revision_dates?.length > 0 ? (
                                  <Grid container>
                                    {this.state?.documentationDetails?.crisis_management?.intervention_sheets?.revision_dates?.map(
                                      (date) => {
                                        return (
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ margin: "0 5px" }}
                                          >
                                            <Chip
                                              label={moment(date).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid container sx={{padding:'4px'}}>
                                  {i18n.t('select_revision_dates')}
                                  </Grid>
                                )}
                              </div>
                              <MultipleDatesPicker
                                open={
                                  this.state?.datePickerOpenClosed
                                    ?.intervention_sheets
                                }
                                selectedDates={
                                  this.formatDates(this.state?.documentationDetails
                                    ?.crisis_management?.intervention_sheets
                                    ?.revision_dates)
                                }
                                onSubmit={async (e) => {
                                  await this.setState({
                                    documentationDetails: {
                                      ...this.state.documentationDetails,
                                      crisis_management: {
                                        ...this.state.documentationDetails
                                          ?.crisis_management,
                                        intervention_sheets: {
                                          ...this.state?.documentationDetails
                                            ?.crisis_management
                                            ?.intervention_sheets,
                                          revision_dates: this.formatRevisionDates(e),
                                        },
                                      },
                                    },
                                    datePickerOpenClosed: {
                                      intervention_sheets:
                                        !this.state?.datePickerOpenClosed
                                          ?.intervention_sheets,
                                    },
                                  });
                                  await this.validateField(
                                    "intervention_sheets_revision_dates"
                                  );
                                }}
                                onCancel={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      intervention_sheets:
                                        !this.state?.datePickerOpenClosed
                                          ?.intervention_sheets,
                                    },
                                  });
                                }}
                              />
                              <FormHelperText
                                style={{ color: "red", padding: "0 20px" }}
                              >
                                {
                                  this.state.formErrors
                                    ?.intervention_sheets_revision_dates
                                }
                              </FormHelperText>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={this.expandedPanel?.panel3}
                  onChange={this.handleChange("panel3")}
                >
                  <AccordionSummary
                    aria-controls="panel3d-content"
                    id="panel3d-header"
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Typography style={this?.styles.largeLabel}>
                      {i18n.t("safety_management_system")}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Accordion
                      expanded={this.expandedPanel?.panel3_1}
                      onChange={this.handleChange("panel3_1")}
                    >
                      <AccordionSummary
                        aria-controls="panel3d-content"
                        id="panel3d-header"
                        expandIcon={<ExpandMoreIcon />}
                      >
                        <Typography style={this?.styles.mediumLabel}>{i18n.t("aerodrome_manual")}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DatePicker
                                  slotProps={{
                                    textField: {
                                      error: this.state.formErrors
                                        ?.aerodrome_manual_initial_date
                                        ? true
                                        : false,
                                      helperText:
                                        this.state.formErrors
                                          ?.aerodrome_manual_initial_date,
                                    },
                                  }}
                                  label={i18n.t("initial_date")}
                                  value={
                                    this.state?.documentationDetails
                                      ?.safety_management_system
                                      ?.aerodrome_manual?.initial_date
                                      ? moment(
                                          this.state?.documentationDetails
                                            ?.safety_management_system
                                            ?.aerodrome_manual?.initial_date
                                        )
                                      : null
                                  }
                                  format={i18n.t("OPTIONS.date_format")}
                                  onChange={async (e) => {
                                    await this.setState({
                                      documentationDetails: {
                                        ...this.state.documentationDetails,
                                        safety_management_system: {
                                          ...this.state.documentationDetails
                                            ?.safety_management_system,
                                          aerodrome_manual: {
                                            ...this.state?.documentationDetails
                                              ?.safety_management_system
                                              ?.aerodrome_manual,
                                            initial_date: e?.format('YYYY-MM-DD'),
                                          },
                                        },
                                      },
                                    });
                                    await this.validateField(
                                      "aerodrome_manual_initial_date"
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Stack>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <Stack spacing={1}>
                            <div style={this.titleStyles}>{i18n.t('revision_dates')}</div>
                              <div
                                style={this?.state?.dateSelectorStyles}
                                onClick={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      aerodrome_manual:
                                        !this.state?.datePickerOpenClosed
                                          ?.aerodrome_manual,
                                    },
                                  });
                                }}
                              >
                                {this.state?.documentationDetails
                                  ?.safety_management_system?.aerodrome_manual
                                  ?.revision_dates?.length > 0 ? (
                                  <Grid container>
                                    {this.state?.documentationDetails?.safety_management_system?.aerodrome_manual?.revision_dates?.map(
                                      (date) => {
                                        return (
                                          <Grid
                                            item
                                            xs={2}
                                            sx={{ margin: "0 5px" }}
                                          >
                                            <Chip
                                              label={moment(date).format(
                                                i18n.t("OPTIONS.date_format")
                                              )}
                                              variant="outlined"
                                            />
                                          </Grid>
                                        );
                                      }
                                    )}
                                  </Grid>
                                ) : (
                                  <Grid container sx={{padding:'4px'}}>
                                  {i18n.t('select_revision_dates')}
                                  </Grid>
                                )}
                              </div>
                              <MultipleDatesPicker
                                open={
                                  this.state?.datePickerOpenClosed
                                    ?.aerodrome_manual
                                }
                                selectedDates={
                                  this.formatDates(this.state?.documentationDetails
                                    ?.safety_management_system?.aerodrome_manual
                                    ?.revision_dates)
                                }
                                onSubmit={async (e) => {
                                  await this.setState({
                                    documentationDetails: {
                                      ...this.state.documentationDetails,
                                      safety_management_system: {
                                        ...this.state.documentationDetails
                                          ?.safety_management_system,
                                        aerodrome_manual: {
                                          ...this.state?.documentationDetails
                                            ?.safety_management_system
                                            ?.aerodrome_manual,
                                          revision_dates: this.formatRevisionDates(e),
                                        },
                                      },
                                    },
                                    datePickerOpenClosed: {
                                      aerodrome_manual:
                                        !this.state?.datePickerOpenClosed
                                          ?.aerodrome_manual,
                                    },
                                  });
                                  await this.validateField(
                                    "aerodrome_manual_revision_dates"
                                  );
                                }}
                                onCancel={async () => {
                                  await this.setState({
                                    datePickerOpenClosed: {
                                      aerodrome_manual:
                                        !this.state?.datePickerOpenClosed
                                          ?.aerodrome_manual,
                                    },
                                  });
                                }}
                              />
                              <FormHelperText
                                style={{ color: "red", padding: "0 20px" }}
                              >
                                {
                                  this.state.formErrors
                                    ?.aerodrome_manual_revision_dates
                                }
                              </FormHelperText>
                            </Stack>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </AccordionDetails>
                </Accordion>
              </Stack>
            </Grid>
          </Grid>

          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveDocumentation();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_documentation")
                  : i18n.t("update_documentation")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();
  let urlParams = new URLSearchParams(location.search);
  urlParams = Object.fromEntries(urlParams.entries());

  return (
    <AddEditDocumentationBteeSa {...props} navigate={navigate} params={params} urlParams={urlParams} />
  );
}
