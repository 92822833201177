import React from "react";
import { useLocation, useNavigate, useParams  } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightIcon from '@mui/icons-material/Flight';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import BadgeIcon from "@mui/icons-material/Badge";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";

import {
  addTask,
  getTaskDetail,
  editTaskDetail,
} from "../../services/task.service";
import { getAirportList } from "../../services/airport.service";
import { getUsers } from "../../services/user.service";
import { getOfferList } from "../../services/offer.service";
import moment from "moment";

class AddEditTask extends React.Component {
  constructor(props) {
    super(props);
    this.respOptions = config?.respOptions;
    this.state = {
      mode: props?.params?.task_id ? "edit" : "add",
      task_id: props.params?.task_id,
      taskDetails: {
        date: null,
        deadline: null,
        date_of_completion:null,
        executed:'no'
      },
      formErrors: {},
      loading: false,
      airportOptions: [],
      usersOptions:[],
      confirmOptions: config?.confirmOptions,
    };
    this.airportId = props?.urlParams?.airport;
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getTaskDetail();
    }
    this.getAirports();
    this.getUsersList();
  }

  async getTaskDetail() {
    this.setState({
      loading: true,
    });
    let response = await getTaskDetail(this.state.task_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
        ? i18n.t(convertMessageCodeToMessage(response?.error_code))
        : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.task) {
      let taskData = response.task;
      let mandate_number = {
        label:taskData?.mandate_number?.mandate_number,
        value:taskData?.mandate_number?._id
      }
      let resp = {
        label:taskData?.resp?.name,
        value:taskData?.resp?._id
      }
      let assigned_to = {
        label:taskData?.assigned_to?.name,
        value:taskData?.assigned_to?._id
      }
      let airport = {
        ...taskData?.airport,
        label:taskData?.airport?.name,
        value:taskData?.airport?._id
      }
      await this.setState({
        taskDetails: {
          ...taskData,
          airport,
          mandate_number,
          resp,
          assigned_to,
          date_of_completion: taskData?.date_of_completion ? moment(taskData?.date_of_completion) : null,
          deadline: taskData?.deadline ? moment(taskData?.deadline) : null,
          date: taskData?.date ? moment(taskData?.date) : null
        },
        loading: false,
      },()=>{
      this.getOffers();
      });

    }
  }

  async getAirports() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
        ? i18n.t(convertMessageCodeToMessage(response?.error_code))
        : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return { label: el?.name, value: el?._id };
    });
    if (this.airportId) {
      let airportDetail = options.find((el) => String(el.value) === String(this.airportId));
      await this.setStateAsync({
        airportOptions: options,
        taskDetails: { ...this.state?.taskDetails, airport: airportDetail },
      });
      this.getOffers();
    } else {
      this.setState({
        airportOptions: options,
      });
    }
  }
  async saveTask() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });

    let payload = {
      ...this.state.taskDetails,
      airport: this.state.taskDetails?.airport?.value,
      assigned_to:this.state.taskDetails?.assigned_to?.value,
      mandate_number:this.state.taskDetails?.mandate_number?.value,
      resp: this.state.taskDetails?.resp?.value,
      date_of_completion:this.state.taskDetails?.date_of_completion?.format('YYYY-MM-DD'),
      deadline:this.state.taskDetails?.deadline?.format('YYYY-MM-DD'),
      date:this.state.taskDetails?.date?.format('YYYY-MM-DD')
    };

    if (this.state.mode === "add") {
      let response = await addTask(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }
      if (response && response.task) {
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editTaskDetail(this.state.task_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      if (response && response.task) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("task_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }
  goBack() {
    this.props.navigate(-1)
  }
  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors };

    if (field == null || field == "airport") {
      if (
        !this.state.taskDetails?.airport?.label ||
        this.state.taskDetails?.airport?.label == ""
      ) {
        error["airport"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["airport"];
      }
    }

    if (field == null || field == "mandate_number") {
      if (
        !this.state?.taskDetails?.mandate_number?.value ||
        this.state?.taskDetails?.mandate_number?.value == ""
      ) {
        error["mandate_number"] = i18n.t("this_field_is_required");
      } else {
        delete error["mandate_number"];
      }
    }
    if (field == null || field == "resp") {
      if (
        !this.state?.taskDetails?.resp.value ||
        this.state?.taskDetails?.resp.value == ""
      ) {
        error["resp"] = i18n.t("this_field_is_required");
      } else {
        delete error["resp"];
      }
    }
    if (field == null || field == "executed") {
      if (
        !this.state?.taskDetails?.executed ||
        this.state.taskDetails?.executed == ""
      ) {
        error["executed"] = i18n.t("this_field_is_required");
      } else {
        delete error["executed"];
      }
    }
    if (field == null || field == "date") {
      if (
        !this.state?.taskDetails?.date ||
        this.state.taskDetails?.date == null
      ) {
        error["date"] = i18n.t("this_field_is_required");
      } else {
        delete error["date"];
      }
    }
    if (field == null || field == "deadline") {
      if (
        !this.state?.taskDetails?.deadline ||
        this.state.taskDetails?.deadline == null
      ) {
        error["deadline"] = i18n.t("this_field_is_required");
      } else {
        delete error["deadline"];
      }
    }
    if (field == null || field == "assigned_to") {
      if (
        !this.state?.taskDetails?.assigned_to ||
        this.state.taskDetails?.assigned_to == null
      ) {
        error["assigned_to"] = i18n.t("this_field_is_required");
      } else {
        delete error["assigned_to"];
      }
    }
    if (field == null || field == "information") {
      if (
        !this.state?.taskDetails?.information ||
        this.state.taskDetails?.information == null
      ) {
        error["information"] = i18n.t("this_field_is_required");
      } else {
        delete error["information"];
      }
    }
    this.setState({
      formErrors: error,
    });

    return error;
  }
  async getUsersList() {
    let query = {
      internal: true,
      minimal:true
    };

    let users = await getUsers(query);
    if (users.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: users?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(users?.error_code))
          : users?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let tempOptions = users.users?.map((option) => {
      return { label: option?.name, value: option?._id };
    });
    await this.setState({
      usersOptions: tempOptions,
    });
  }

  async getOffers() {
    let query={};
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    query["minimal"]=true;

    let offersData = await getOfferList(query);
    if (offersData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: offersData?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(offersData?.error_code))
          : offersData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = offersData?.offers?.map((el) => {
      return {
        label: el?.mandate_number,
        value: el?._id,
      };
    });
    this.setState({
      offersOptions: options,
    });
  }

  render() {
    return (
      <Container maxWidth='xxl'>
        <Helmet>
          <title> {i18n.t("add_task")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("task")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            {this?.airportId && 
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/airports/view");
              }}
            >
              <FlightTakeoffIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("airports")}
            </Link>
  }
            {this?.airportId && <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate(`/airports/airportDetails/${this.airportId}`);
              }}
            >
              <FlightIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              
              {this.state.taskDetails?.airport?.label}
            </Link>
            }
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.airportId ?
                this.props.navigate(`/tasks/view?airportId=${this.airportId}`)
                :
                this.props.navigate(`/tasks/view`);
              }}
            >
              <AssignmentOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("tasks")}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {this.state.mode === "add"
                ? i18n.t("add")
                : this.state.taskDetails?.mandate_number?.label}
            </Typography>
            {this.state.mode === "edit" && <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BorderColorOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("edit")}
            </Typography>
            }
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={this.props?.urlParams?.airport ? true : false}
                  onOpen={async () => {
                    await this.getAirports();
                  }}
                  options={
                    !this.state.airportOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.airportOptions
                  }
                  onChange={async (option, value) => {
                    await this.setStateAsync({
                      taskDetails: {
                        ...this.state.taskDetails,
                        airport: value,
                      },
                    });
                    this.airportId = value?.value;
                    await this.validateField("airport");
                    this.getOffers();
                  }}
                  value={this.state.taskDetails?.airport?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("airport")}
                      error={this.state.formErrors?.airport ? true : false}
                      helperText={this.state.formErrors?.airport}
                    />
                  )}
                />
              </Stack>
            </Grid>
               <Grid item xs={12} md={3}>
              <Stack spacing={1}>
              <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={!this.state.taskDetails?.airport?.value}
                  onOpen={async () => {
                    await this.getOffers();
                  }}
                  options={
                    !this.state.offersOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.offersOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      taskDetails: {
                        ...this.state.taskDetails,
                        mandate_number: { label: value?.label, value: value?.value },
                      },
                    });
                    await this.validateField("mandate_number");
                  }}
                  value={this.state.taskDetails?.mandate_number?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("mandate_number")}
                      error={this.state.formErrors?.mandate_number ? true : false}
                      helperText={this.state.formErrors?.mandate_number}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  opOpen={() => {
                    this.getUsersList();
                  }}
                  options={
                    !this?.state.usersOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this?.state.usersOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      taskDetails: {
                        ...this.state.taskDetails,
                        resp: value,
                      },
                    });
                    await this.validateField("resp");
                  }}
                  value={this.state.taskDetails?.resp?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("resp")}
                      error={this.state.formErrors?.resp ? true : false}
                      helperText={this.state.formErrors?.resp}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Autocomplete
                disablePortal
                noOptionsText={i18n.t('no_options')}
                onOpen={async () => {
                  await this.getUsersList();
                }}
                options={
                  !this.state.usersOptions
                    ? [{ label: "Loading...", id: 0 }]
                    : this.state.usersOptions
                }
                onChange={async (option, value) => {
                  await this.setState({
                    taskDetails: {
                      ...this.state.taskDetails,
                      assigned_to: {label:value?.label,value:value?.value},
                    },
                  });
                  await this.validateField("assigned_to");
                }}
                value={i18n.t(this.state.taskDetails?.assigned_to?.label) || ""}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={i18n.t("assigned_to")}
                    error={this.state.formErrors?.assigned_to ? true : false}
                    helperText={this.state.formErrors?.assigned_to}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={3}>
              <Autocomplete
                disablePortal
                noOptionsText={i18n.t('no_options')}
                options={
                  !this.state.confirmOptions
                    ? [{ label: "Loading...", id: 0 }]
                    : this.state.confirmOptions
                }
                onChange={async (option, value) => {
                  await this.setState({
                    taskDetails: {
                      ...this.state.taskDetails,
                      executed: value?.value,
                    },
                  });
                  await this.validateField("executed");
                }}
                value={i18n.t(this.state.taskDetails?.executed) || ""}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={i18n.t("executed")}
                    error={this.state.formErrors?.executed ? true : false}
                    helperText={this.state.formErrors?.executed}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                     slotProps={{
                      textField: {
                        error: this.state.formErrors?.date ? true : false,
                        helperText: this.state.formErrors?.date
                      },
                    }}
                    label={i18n.t("task_establishment_date")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={(this.state?.taskDetails?.date) || null}
                    onChange={async (e) => {
                      await this.setState({
                        taskDetails: {
                          ...this.state.taskDetails,
                          date: e,
                        },
                      });
                      await this.validateField("date");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                     slotProps={{
                      textField: {
                        error: this.state.formErrors?.deadline ? true : false,
                        helperText: this.state.formErrors?.deadline
                      },
                    }}
                    label={i18n.t("deadline")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={(this.state?.taskDetails?.deadline) || null}
                    onChange={async (e) => {
                      await this.setState({
                        taskDetails: {
                          ...this.state.taskDetails,
                          deadline: e,
                        },
                      });
                      await this.validateField("deadline");
                    }}
                    error={this.state.formErrors?.deadline ? true : false}
                    helperText={this.state.formErrors?.deadline}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                     slotProps={{
                      textField: {
                        error: this.state.formErrors?.date_of_completion ? true : false,
                        helperText: this.state.formErrors?.date_of_completion
                      },
                    }}
                    label={i18n.t("date_of_completion")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={(this.state?.taskDetails?.date_of_completion) || null}
                    onChange={async (e) => {
                      await this.setState({
                        taskDetails: {
                          ...this.state.taskDetails,
                          date_of_completion: e,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  required
                  multiline
                  rows={2}
                  maxRows={4}
                  label={i18n.t("information")}
                  variant="outlined"
                  value={this.state?.taskDetails?.information || ""}
                  onChange={async (e) => {
                    await this.setState({
                      taskDetails: {
                        ...this.state.taskDetails,
                        information: e.target.value,
                      },
                    });
                    await this.validateField("information");
                  }}
                  error={this.state.formErrors?.information ? true : false}
                  helperText={this.state.formErrors?.information}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveTask();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_task")
                  : i18n.t("update_task")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();
  let urlParams = new URLSearchParams(location.search);
  urlParams = Object.fromEntries(urlParams.entries());

  return <AddEditTask {...props} navigate={navigate} params={params} urlParams={urlParams} />;
}
