import React from "react";
import { useLocation, useNavigate, useParams  } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightIcon from '@mui/icons-material/Flight';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import BadgeIcon from "@mui/icons-material/Badge";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import {
  addAbm,
  getAbmDetail,
  editAbmDetail,
} from "../../../services/abm.service";
import { getAirportList } from "../../../services/airport.service";
import { getOptionData } from "../../../utils/commonUtils";
import RenewedContractsTable from "../../../SharedComponent/RenewedContractsTable";
import moment from "moment";
import { Constants } from "../../../shared/constants";
class AddEditAbm extends React.Component {
  constructor(props) {
    super(props);
    this.respOptions = config?.respOptions;
    this.state = {
      mode: props?.params?.abm_id ? "edit" : "add",
      abm_id: props.params?.abm_id,
      abmDetails: {
        date_of_offer: null,
        date_of_initial_contract: null,
        date_of_completion:null,
        expiry_date_of_initial_contract:null,
        renewed_contracts:[]
      },
      formErrors: {},
      loading: false,
      airportOptions: [],
      confirmOptions: config?.confirmOptions,
    };
    this.renewalTypeOptions = config?.renewalOptions
    this.DurationOptions = config?.contractDurationOptions
    this.airportId = props?.urlParams?.airport;
    this.saveRenewalData = this.saveRenewalData.bind(this)
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getAbmDetails();
    }
    this.getAirports();
  }

  async getAbmDetails() {
    this.setState({
      loading: true,
    });
    let response = await getAbmDetail(this.state.abm_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
        ? i18n.t(convertMessageCodeToMessage(response?.error_code))
        : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.abm) {
      let abmData = response.abm;
      abmData.airport = {
        ...abmData?.airport,
        label: abmData?.airport?.name,
        value: abmData?.airport?._id
      };
      let renewal_type = getOptionData(this.renewalTypeOptions,abmData?.renewal_type)
      let contract_duration = getOptionData(this.DurationOptions,abmData?.contract_duration)
      let duration_of_renewal = getOptionData(this.DurationOptions,abmData?.duration_of_renewal) || null
      let tempRenewedContracts = abmData?.renewed_contracts?.map((contract)=>{
        return({...contract,date_of_renewed_contract:moment(contract?.date_of_renewed_contract),expiry_date_of_renewed_contract:moment(contract?.expiry_date_of_renewed_contract) })
      })
      await this.setState({
        abmDetails: {
          ...abmData,
          renewal_type,
          contract_duration,
          duration_of_renewal,
          date_of_offer:moment(abmData?.date_of_offer),
          date_of_initial_contract:moment(abmData?.date_of_initial_contract),
          expiry_date_of_initial_contract:moment(abmData?.expiry_date_of_initial_contract),
          renewed_contracts:tempRenewedContracts
        },
        loading: false,
      });
    }
  }

  async getAirports() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
        ? i18n.t(convertMessageCodeToMessage(response?.error_code))
        : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return { label: el?.name, value: el?._id };
    });
    if (this.airportId) {
      let airportDetail = options.find((el) => el.value == this.airportId);
      this.setState({
        airportOptions: options,
        abmDetails: { ...this.state?.abmDetails, airport: airportDetail },
      });
    } else {
      this.setState({
        airportOptions: options,
      });
    }
  }
  async saveAbm() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });
    let tempRenewedContracts = this.state?.abmDetails?.renewed_contracts?.map((renewedContract)=>{
      return({...renewedContract, date_of_renewed_contract:renewedContract?.date_of_renewed_contract?.format('YYYY-MM-DD'),
      expiry_date_of_renewed_contract:renewedContract?.expiry_date_of_renewed_contract?.format('YYYY-MM-DD')})
    })
    let payload = {
      ...this.state.abmDetails,
      airport: this.state.abmDetails?.airport?.value,
      contract_duration:this.state.abmDetails?.contract_duration?.value,
      duration_of_renewal:this.state.abmDetails?.duration_of_renewal?.value || null,
      renewal_type:this.state.abmDetails?.renewal_type?.value,
      renewed_contracts:tempRenewedContracts,
      date_of_offer:this.state.abmDetails?.date_of_offer?.format('YYYY-MM-DD'),
      date_of_initial_contract:this.state.abmDetails?.date_of_initial_contract?.format('YYYY-MM-DD'),
      expiry_date_of_initial_contract:this.state.abmDetails?.expiry_date_of_initial_contract?.format('YYYY-MM-DD')
    };

    if (this.state.mode === "add") {
      let response = await addAbm(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        this.setState({
          loading: false,
        });
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
           text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : i18n.t('abm_added_successfully'),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
    }
    } else {
      let response = await editAbmDetail(this.state.abm_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }else{
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
           text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message,
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
    }
  }
}

saveRenewalData(data){
  this.setState({
    ...this.state,
    abmDetails:{
      ...this.state?.abmDetails,
      renewed_contracts:data
    }
  })
}
  goBack() {
    this.props.navigate(-1)
  }
  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors };

    if (field == null || field == "airport") {
      if (
        !this.state.abmDetails?.airport?.value ||
        this.state.abmDetails?.airport?.value == ""
      ) {
        error["airport"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["airport"];
      }
    }

    if (field == null || field == "date_of_offer") {
      if (
        !this.state?.abmDetails?.date_of_offer ||
        this.state?.abmDetails?.date_of_offer == ""||
        this.state?.abmDetails?.date_of_offer == null
      ) {
        error["date_of_offer"] = i18n.t("this_field_is_required");
      } else {
        delete error["date_of_offer"];
      }
    }
    if (field == null || field == "date_of_initial_contract") {
      if (
        !this.state?.abmDetails?.date_of_initial_contract ||
        this.state?.abmDetails?.date_of_initial_contract == "" ||
        this.state?.abmDetails?.date_of_initial_contract == null
      ) {
        error["date_of_initial_contract"] = i18n.t("this_field_is_required");
      } else {
        delete error["date_of_initial_contract"];
      }
    }
    if (field == null || field == "expiry_date_of_initial_contract") {
      if (
        !this.state?.abmDetails?.expiry_date_of_initial_contract ||
        this.state?.abmDetails?.expiry_date_of_initial_contract == "" ||
        this.state?.abmDetails?.expiry_date_of_initial_contract == null
      ) {
        error["expiry_date_of_initial_contract"] = i18n.t("this_field_is_required");
      } else {
        delete error["expiry_date_of_initial_contract"];
      }
    }
    if (field == null || field == "contract_duration") {
      if (
        !this.state?.abmDetails?.contract_duration?.value ||
        this.state.abmDetails?.contract_duration?.value == ""
      ) {
        error["contract_duration"] = i18n.t("this_field_is_required");
      } else {
        delete error["contract_duration"];
      }
    }
    if (field == null || field == "renewal_type") {
      if (
        !this.state?.abmDetails?.renewal_type?.value ||
        this.state.abmDetails?.renewal_type?.value == null
      ) {
        error["renewal_type"] = i18n.t("this_field_is_required");
      } else {
        delete error["renewal_type"];
      }
    }
    this.setState({
      formErrors: error,
    });

    return error;
  }

  render() {
    return (
      <Container maxWidth='xxl'>
        <Helmet>
          <title> {i18n.t("add_abm")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("abm")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            {this?.airportId && 
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/airports/view");
              }}
            >
              <FlightTakeoffIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("airports")}
            </Link>
  }
            {this?.airportId && <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate(`/airports/airportDetails/${this.airportId}`);
              }}
            >
              <FlightIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              
              {this.state.abmDetails?.airport?.label}
            </Link>
            }
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.airportId ?
                this.props.navigate(`/awhm-awm/view/abm?airportId=${this.airportId}`)
                :
                this.props.navigate(`/awhm-awm/view/abm`);
              }}
            >
              <AssignmentOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("abm")}
            </Link>
            {this.state.mode === "add" && <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              { i18n.t("add")}
            </Typography>}
            {this.state.mode === "edit" && <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BorderColorOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("edit")}
            </Typography>
            }
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={this.props?.urlParams?.airport ? true : false}
                  onOpen={async () => {
                    await this.getAirports();
                  }}
                  options={
                    !this.state.airportOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.airportOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      abmDetails: {
                        ...this.state.abmDetails,
                        airport: value
                      },
                    });
                    this.airportId = value?.value;
                    await this.validateField("airport");
                  }}
                  value={this.state.abmDetails?.airport?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("airport")}
                      error={this.state.formErrors?.airport ? true : false}
                      helperText={this.state.formErrors?.airport}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
            <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.date_of_offer ? true : false,
                        helperText: this.state.formErrors?.date_of_offer,
                        required:true
                      },
                    }}
                    label={i18n.t("date_of_offer")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.abmDetails?.date_of_offer || null}
                    onChange={async (e) => {
                      await this.setState({
                        abmDetails: {
                          ...this.state.abmDetails,
                          date_of_offer: e,
                        },
                      });
                      await this.validateField("date_of_offer");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
            <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.date_of_initial_contract ? true : false,
                        helperText: this.state.formErrors?.date_of_initial_contract,
                        required:true
                      },
                    }}
                    label={i18n.t("date_of_initial_contract")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.abmDetails?.date_of_initial_contract || null}
                    onChange={async (e) => {
                      await this.setState({
                        abmDetails: {
                          ...this.state.abmDetails,
                          date_of_initial_contract: e,
                        },
                      });
                      await this.validateField("date_of_initial_contract");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
            <Stack spacing={1}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.expiry_date_of_initial_contract ? true : false,
                        helperText: this.state.formErrors?.expiry_date_of_initial_contract,
                        required:true
                      },
                    }}
                    label={i18n.t("expiry_date_of_initial_contract")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.abmDetails?.expiry_date_of_initial_contract || null}
                    onChange={async (e) => {
                      await this.setState({
                        abmDetails: {
                          ...this.state.abmDetails,
                          expiry_date_of_initial_contract: e,
                        },
                      });
                      await this.validateField("expiry_date_of_initial_contract");
                    }}
                  />
                </LocalizationProvider>
                </Stack>
                </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  options={
                    !this.DurationOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.DurationOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      abmDetails: {
                        ...this.state.abmDetails,
                        contract_duration: { label: value?.label, value: value?.value }
                      },
                    });
                    await this.validateField("contract_duration");
                  }}
                  value={this.state.abmDetails?.contract_duration?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("contract_duration")}
                      error={this.state.formErrors?.contract_duration ? true : false}
                      helperText={this.state.formErrors?.contract_duration}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={this.state?.abmDetails?.renewal_type?.value === Constants?.None}
                  options={
                    !this.DurationOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.DurationOptions
                  }
                  value={this.state.abmDetails?.duration_of_renewal?.label || ""}
                  onChange={async (option, value) => {
                    await this.setState({
                      abmDetails: {
                        ...this.state.abmDetails,
                        duration_of_renewal: { label: value?.label, value: value?.value },
                      },
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("duration_of_renewal")}
                      error={this.state.formErrors?.duration_of_renewal ? true : false}
                      helperText={this.state.formErrors?.duration_of_renewal}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  options={
                    !this.renewalTypeOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.renewalTypeOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      abmDetails: {
                        ...this.state.abmDetails,
                        renewal_type: { label: value?.label, value: value?.value },
                        duration_of_renewal:value?.value === Constants?.None ?
                         null
                         :
                         this.state.abmDetails?.duration_of_renewal
                      },
                    });
                    await this.validateField("renewal_type");
                  }}
                  value={this.state.abmDetails?.renewal_type?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("renewal_type")}
                      error={this.state.formErrors?.renewal_type ? true : false}
                      helperText={this.state.formErrors?.renewal_type}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
          <Grid item xs={12}>
            <RenewedContractsTable saveRenewalData={this.saveRenewalData} renewed_contracts={this.state?.abmDetails?.renewed_contracts}/>
          </Grid>
          </Grid>

          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveAbm();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_abm")
                  : i18n.t("update_abm")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();
  let urlParams = new URLSearchParams(location.search);
  urlParams = Object.fromEntries(urlParams.entries());

  return <AddEditAbm {...props} navigate={navigate} params={params} urlParams={urlParams} />;
}
