import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Input,
  InputAdornment,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../utils/debounce";
import config from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import Iconify from "../../components/iconify/Iconify";
import {
  deleteOffer,
  editOfferDetail,
  exportOffers,
  getOfferList,
} from "../../services/offer.service";
import OffersTable from "../../SharedComponent/OffersTable";
import { getAirportList } from "../../services/airport.service";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateRange } from 'react-date-range';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import moment from "moment";
import { enGB } from 'date-fns/locale'; 
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
const TABLE_HEAD = [
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "description",
    label: i18n.t("description"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_OACI",
    label: i18n.t("code_OACI"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_IATA",
    label: i18n.t("code_IATA"),
    alignRight: false,
    sortable: true,
  },
  { id: "resp", label: i18n.t("resp"), alignRight: false, sortable: true },
  {
    id: "division",
    label: i18n.t("division"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "category",
    label: i18n.t("domain"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "mandate_number",
    label: i18n.t("mandate_number"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "document",
    label: i18n.t("document"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "currency",
    label: i18n.t("currency"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "offer_amount",
    label: i18n.t("offer_amount"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "award_amount",
    label: i18n.t("award_amount"),
    alignRight: true,
    sortable: true,
  },
  { id: "status", label: i18n.t("status"), alignCenter: true, sortable: true },
  {
    id: "date",
    label: i18n.t("date_of_offer"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "award_date",
    label: i18n.t("award_date"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "validity",
    label: i18n.t("validity"),
    alignRight: false,
    sortable: true,
  },
  // { id: 'contact_person', label: i18n.t('contact_person'), alignRight: false, sortable: true },
  {
    id: "remarks",
    label: i18n.t("remarks"),
    alignRight: false,
    sortable: true,
  },
  { id: "" },
];

class Offers extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      offers: [],
      offer: {},
      open: null,
      page: 1,
      search: "",
      limit: 100,
      skip: 0,
      total_offers: 0,
      expanded: false,
      deleteOfferPopUp: false,
      tableLoader: false,
      pageLoader: false,
      filters: {
        airport: "",
        resp: "",
        division: "",
        category: "",
        document: "",
        status: "",
        start_award_date:null,
        end_award_date:null
      },
      filtersOptions: {
        resp: config?.respOptions,
        divisions: config?.divisionOptions,
        categories: config?.categoriesOptions,
        documents: config?.documentOptions,
        statusOptions: config?.statusOptions,
      },
      orderBy: { sort_key: "airport", sort_order: true },
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
  }

  async componentDidMount() {
    await this.getOffers();
    await this.getAirportsForFilter();
  }

  async getOffers(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
    };
    Object?.keys(this.state?.filters)?.map((key) => {
      if (key === "airport") {
        if (this.state?.filters[key]?.value) {
          query[key] = this.state?.filters[key]?.value;
        } else {
          delete query[key];
        }
      } else if (this.state?.filters[key] !== "" && this.state?.filters[key] !== null) {
        query[key] = this.state?.filters[key];
      }
    });
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }

    if (this.state?.orderBy?.sort_key) {
      query.sort_key = this.state?.orderBy?.sort_key;
      query.sort_order = this.state?.orderBy?.sort_order ? "ASC" : "DESC";
    }

    let offersData = await getOfferList(query);
    if (offersData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: offersData?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(offersData?.error_code))
          : offersData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState({
      tableLoader: false,
      offers: offersData.offers,
      total_offers: offersData.count,
      total:offersData?.total
    });
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getOffersDataOnchange = debounce(() => {
    this.getOffers("filters");
  }, 500);

  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getOffers();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getOffers();
    } else if (action === "popOverAction") {
      this.setState({
        offer: rowData,
      });
      this.handleOpenMenu(data);
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      if (config?.airportJsonKeys.includes(data.toLowerCase())) {
        await this.setState({
          orderBy: {
            sort_key:
              data === "airport"
                ? `airport_name`
                : `airport_${data.toLowerCase()}`,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      }else if(data === 'domain'){
        await this.setState({
          orderBy: {
            sort_key: 'category',
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      } else {
        await this.setState({
          orderBy: {
            sort_key: data,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      }
      this.getOffers();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.offers;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        offers: tempData,
      });
      this.updateOfferData(payload, payload?._id, backUpData);
    }else if (action === "updateCascade") {
      if (rowData[key] === data[key] && rowData['category'] === data['category']) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData,...data };
      let tempData = this.state.offers;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        offers: tempData,
      });
      this.updateOfferData(payload, payload?._id, backUpData);
    }  else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "deleteOffer") {
      this.handleCloseMenu();
      await this.setState({
        deleteOfferPopUp: true,
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteOfferPopUp: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteOfferPopUp: false,
      });
      this.handleDeleteOffer(this.state.offer._id);
    }
  };
  async updateOfferData(payload, offerId, backUpData) {
    let response = await editOfferDetail(offerId, payload);
    if (response && response.error) {
      let tempData = this.state.offers;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        ...this.state,
        offers: tempData,
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.offer) {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("offer_updated_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }
  async getAirportsForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      if (airport?._id === this.airportId) {
        this.setState({
          filters: {
            airport: { label: airport?.name, value: airport?._id },
          },
          expanded: true,
        });
      }
      return { label: airport?.name, value: airport?._id };
    });

    this.setState({
      filtersOptions: { ...this.state.filtersOptions, airportOptions },
    });
  }
  async handleDeleteOffer(offerId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteOffer(offerId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response) {
      await this.getOffers();
      this.setState({
        pageLoader: false,
      });
    }
  }

  async exportOffersData() {
    this.setState({
      pageLoader: true,
    });
    let query = {};
    Object?.keys(this.state?.filters)?.map((key) => {
      if (key === "airport") {
        if (this.state?.filters[key]?.value) {
          query[key] = this.state?.filters[key]?.value;
        } else {
          delete query[key];
        }
      } else if (this.state?.filters[key] !== "" && this.state?.filters[key] !== null) {
        query[key] = this.state?.filters[key];
      }
    });
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    let response = await exportOffers(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false,
      });
      return;
    }else{
      const newBlob = new Blob([response]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${i18n.t('offers_or_mandates')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      
      this.setState({
        pageLoader: false,
      });
    }

  }
  handleSelect(ranges){
    this.setState({
      filters: {
        ...this.state?.filters,
        start_award_date:ranges[0] ? moment(ranges).format(
          'YYYY-MM-DD'
        ) : null,
        end_award_date: ranges[1] ? moment(ranges).format(
          'YYYY-MM-DD'
        ) : null,
      },
    });
  }
  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("offers")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("complete_dashboard")}
        </Typography>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={this.state.expanded}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      this.setState({
                        search: e?.target?.value,
                      });
                      this.getOffersDataOnchange();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  <IconButton
                    onClick={() => {
                      this.handleExpandClick();
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.airportOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.airportOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  airport: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getOffersDataOnchange();
                            }}
                            value={this.state?.filters?.airport?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("airport")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            noOptionsText={i18n.t('no_options')}
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.resp
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.resp
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  resp: value?.value ? value?.value : "",
                                },
                              });
                              this.getOffersDataOnchange();
                            }}
                            value={this.state?.filters?.resp || ""}
                            renderInput={(params) => (
                              <TextField {...params} label={i18n.t("resp")} />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            noOptionsText={i18n.t('no_options')}
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.divisions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.divisions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  division: value?.value ? value?.value : "",
                                },
                              });
                              this.getOffersDataOnchange();
                            }}
                            value={this.state?.filters?.division || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("division")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            noOptionsText={i18n.t('no_options')}
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.categories
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.categories
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  category: value?.value ? value?.value : "",
                                },
                              });
                              this.getOffersDataOnchange();
                            }}
                            value={i18n.t(this.state?.filters?.category) || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("domain")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            noOptionsText={i18n.t('no_options')}
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.documents
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.documents
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  document: value?.value ? value?.value : "",
                                },
                              });
                              this.getOffersDataOnchange();
                            }}
                            value={i18n.t(this.state?.filters?.document) || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("document")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                             noOptionsText={i18n.t('no_options')}
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.statusOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.statusOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  status: value?.value ? value?.value : "",
                                },
                              });
                              this.getOffersDataOnchange();
                            }}
                            value={i18n.t(this.state?.filters?.status) || ""}
                            renderInput={(params) => (
                              <TextField {...params} label={i18n.t("status")} />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={12}>
              <Stack spacing={1}>
              <DateRangePicker  onChange={async (e) => {
                      if(new Date(e?.[0])<new Date(this.state?.filters?.start_award_date)){
                        this.setState({
                          filters: {
                            ...this.state?.filters,
                            end_award_date: null,
                          },
                        });
                      Swal.fire({
                        toast: true,
                        position: "bottom-end",
                        icon: "warning",
                        title: i18n.t("warning"),
                         text: i18n.t('end_award_date_should_be_greater_than_start_award_date'),
                        showConfirmButton: false,
                        timer: config.toastDelay,
                        timerProgressBar: true,
                      }); 
                      return;
                    }
                      await this.setState({
                        filters: {
                          ...this.state?.filters,
                          start_award_date: e?.[0] ? moment(e?.[0]).format(
                            'YYYY-MM-DD'
                          ) : null,
                          end_award_date: e?.[1] ? moment(e?.[1]).format(
                            'YYYY-MM-DD'
                          ) : null,
                        },
                      });
                      moment(this.state?.filters?.end_award_date).isValid() &&this.getOffersDataOnchange();
                      e === null && this.getOffers();  
                    }}
              value={[this.state?.filters?.start_award_date || null, this.state?.filters?.end_award_date|| null]} />
              </Stack>
            </Grid>

                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{marginRight:"10px"}}>
          <Button
              variant="contained"
              startIcon={<SystemUpdateAltIcon />}
              className="BtnWidth"
              onClick={() => {
                this.exportOffersData();
              }}
            >
              {i18n.t("export_offers")}
            </Button>
          </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/offers/add");
              }}
              style={{ textTransform: 'none' }}
            >
              {i18n.t("offer_or_mandate")}
            </Button>
          </Stack>
        </Grid>

        <OffersTable
          data={this.state}
          onAction={this.handleTableAction}
          tableHeaders={TABLE_HEAD}
        />
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Offers {...props} navigate={navigate} params={params} />;
}
