import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Breadcrumbs,
  Button,
  Grid,
  Autocomplete,
  Stack,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  Container,
  FormControlLabel,
  Checkbox,
  FormHelperText,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import BadgeIcon from "@mui/icons-material/Badge";
import Link from "@mui/material/Link";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import {
  addTraining,
  getTrainingDetail,
  editTrainingDetail,
} from "../../../services/training.service";
import { getCoursesList } from "../../../services/course.service";
import TrainingTraineeTable from "../../../SharedComponent/TrainingTraineeTable";
import config from "../../../config";
import moment from "moment";
import TimePicker from "../../../SharedComponent/TimePicker";

class AddEditTraining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props?.params?.training_id ? "edit" : "add",
      training_id: props.params?.training_id,
      trainingDetails: {
        training_duration: null,
        training_start_date: null,
        training_end_date: null,
        certificate_issue_date: null,
        training_agreement: false,
        trainees: [],
        hours: 0,
        minutes: 0,
      },
      formErrors: {},
      loading: false,
    };
    this.saveTraineesData = this.saveTraineesData.bind(this);
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getTrainingDetails();
    } else {
      this.getAirTraceCourses();
    }
  }

  getValue(value) {
    return value ? value : "";
  }

  async getTrainingDetails() {
    this.setState({
      loading: true,
    });
    let response = await getTrainingDetail(this.state.training_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      await this.setState({
        loading: false,
      });
      return;
    }
    if (response && response.training) {
      let trainingDetails = response.training;
      let tempData = trainingDetails?.trainees?.map((data) => {
        return {
          ...data,
          trainee: {
            ...data?.trainee,
            name: `${data?.trainee?.first_name} ${data?.trainee?.last_name}`,
          },
        };
      });
      let { hours, minutes } = this.secondsToHoursMinutes(
        trainingDetails?.training_duration
      );
      await this.setState(
        {
          ...this.state,
          loading: false,
          trainingDetails: {
            ...this.state?.trainingDetails,
            ...trainingDetails,
            trainees: tempData,
            course: {
              label: trainingDetails?.course?.course_id,
              _id: trainingDetails?.course?._id,
            },
            agreement_date: moment(trainingDetails?.agreement_date),
            training_start_date: moment(trainingDetails?.training_start_date),
            training_end_date: moment(trainingDetails?.training_end_date),
            certificate_issue_date: trainingDetails?.certificate_issue_date ? 
            moment(
              trainingDetails?.certificate_issue_date
            ) : null,
            hours,
            minutes,
          },
        },
        () => {
          this.getAirTraceCourses();
        }
      );
    }
  }

  async saveTrainingDetails() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });
    let payload = {
      ...this.state.trainingDetails,
      course: this.state.trainingDetails?.course?._id,
      agreement_date:
        this.state.trainingDetails?.agreement_date?.format("YYYY-MM-DD"),
      training_start_date:
        this.state.trainingDetails?.training_start_date?.format("YYYY-MM-DD"),
      training_end_date:
        this.state.trainingDetails?.training_end_date?.format("YYYY-MM-DD"),
      certificate_issue_date:
        this.state.trainingDetails?.certificate_issue_date?.format(
          "YYYY-MM-DD"
        ),
      training_duration:
        Number(this.state?.trainingDetails?.hours) * 3600 +
        Number(this.state?.trainingDetails?.minutes) * 60,
    };
    delete payload?.hours;
    delete payload?.minutes;
    if (this.state.mode === "add") {
      let response = await addTraining(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }
      if (response && response.training) {
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.message_code
            ? i18n.t(convertMessageCodeToMessage(response?.message_code))
            : i18n.t("training_added_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editTrainingDetail(this.state.training_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      if (response && response.status === 200) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.message_code
            ? i18n.t(convertMessageCodeToMessage(response?.message_code))
            : response?.message,
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
      }
    }
  }
  goBack() {
    this.props.navigate(-1);
  }
  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors };
    if (field == null || field == "course") {
      if (
        !this.state?.trainingDetails?.course?._id ||
        this.state.trainingDetails?.course?._id == ""
      ) {
        error["course"] = i18n.t("this_field_is_required");
      } else {
        delete error["course"];
      }
    }
    if (field == null || field == "hours" || field == "minutes") {
      if (
        (!this.state?.trainingDetails?.hours ||
          this.state.trainingDetails?.hours == 0) &&
        (!this.state?.trainingDetails?.minutes ||
          this.state.trainingDetails?.minutes == 0)
      ) {
        error["training_duration"] = i18n.t("this_field_is_required");
      } else {
        delete error["training_duration"];
      }
    }
    if (field == null || field == "training_start_date") {
      if (
        !this.state?.trainingDetails?.training_start_date ||
        this.state.trainingDetails?.training_start_date == ""
      ) {
        error["training_start_date"] = i18n.t("this_field_is_required");
      } else {
        delete error["training_start_date"];
      }
    }
    if (field == null || field == "training_end_date") {
      if (
        !this.state?.trainingDetails?.training_end_date ||
        this.state.trainingDetails?.training_end_date == ""
      ) {
        error["training_end_date"] = i18n.t("this_field_is_required");
      } else {
        delete error["training_end_date"];
      }
    }

    this.setState({
      formErrors: error,
    });
    return error;
  }

  async getAirTraceCourses() {
    let query = {
      minimal: true,
    };

    let response = await getCoursesList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return { label: el?.course_id, value: el?._id };
    });
    this.setState({
      courseOptions: options,
    });
  }

  saveTraineesData(data) {
    this.setState({
      ...this.state,
      trainingDetails: {
        ...this.state?.trainingDetails,
        trainees: data,
      },
    });
  }

  handleTimePicker = (action, event) => {
    if (action === "hourIncrement") {
      this.setState(() =>
        this.setState({
          ...this.state,
          trainingDetails: {
            ...this.state?.trainingDetails,
            hours: this.state?.trainingDetails?.hours + 1,
          },
        })
      );
    } else if (action === "hourDecrement") {
      this.setState(() =>
        this.setState({
          ...this.state,
          trainingDetails: {
            ...this.state?.trainingDetails,
            hours:
              this.state?.trainingDetails?.hours > 0
                ? this.state?.trainingDetails?.hours - 1
                : 0,
          },
        })
      );
    } else if (action === "hourOnchange") {
      this.setState(() =>
        this.setState({
          ...this.state,
          trainingDetails: {
            ...this.state?.trainingDetails,
            hours: Number(event?.target?.value),
          },
        })
      );
    }
    if (action === "minutesIncrement") {
      let minutes;
      let hours;
      if (this.state?.trainingDetails?.minutes === 59) {
        minutes = 0;
        hours = this.state?.trainingDetails?.hours + 1;
      } else {
        minutes = this.state?.trainingDetails?.minutes + 1;
        hours = this.state?.trainingDetails?.hours;
      }
      this.setState(() =>
        this.setState({
          ...this.state,
          trainingDetails: {
            ...this.state?.trainingDetails,
            hours,
            minutes,
          },
        })
      );
    } else if (action === "minutesDecrement") {
      let minutes;
      let hours;
      if (this.state?.trainingDetails?.minutes === 0) {
        minutes = 59;
        hours =
          this.state?.trainingDetails?.hours > 0
            ? this.state?.trainingDetails?.hours - 1
            : 0;
      } else {
        minutes = this.state?.trainingDetails?.minutes - 1;
        hours = this.state?.trainingDetails?.hours;
      }
      this.setState(() =>
        this.setState({
          ...this.state,
          trainingDetails: {
            ...this.state?.trainingDetails,
            hours,
            minutes,
          },
        })
      );
    } else if (action === "minutesOnChange") {
      this.setState(() =>
        this.setState({
          ...this.state,
          trainingDetails: {
            ...this.state?.trainingDetails,
            minutes: Math.max(0, Math.min(59, Number(event.target.value))),
          },
        })
      );
    }
  };

  secondsToHoursMinutes = (seconds) => {
    var hours = Math.floor(seconds / 3600);
    var minutes = Math.floor((seconds % 3600) / 60);
    return { hours, minutes };
  };

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_offer")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("training")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate(`/airtrace/view/trainings`);
              }}
            >
              <ModelTrainingIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("trainings")}
            </Link>
            {this.state.mode === "add" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("add")}
              </Typography>
            )}
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>

        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t("no_options")}
                  onOpen={async () => {
                    await this.getAirTraceCourses();
                  }}
                  options={
                    !this.state.courseOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.courseOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      trainingDetails: {
                        ...this.state.trainingDetails,
                        course: {
                          label: value?.label,
                          _id: value?.value,
                        },
                      },
                    });
                    await this.validateField("course");
                  }}
                  value={this.state.trainingDetails?.course?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={i18n.t("course_id")}
                      error={this.state.formErrors?.course ? true : false}
                      helperText={this.state.formErrors?.course}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>

          <>
            <Grid container spacing={4} mb={3}>
              <Grid item xs={12}>
                <Typography variant="h6">
                  {i18n.t("training_delivered")}
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          this.state?.trainingDetails?.training_agreement
                        }
                        label={i18n.t("training_agreement")}
                        onChange={async (e) => {
                          await this.setState({
                            trainingDetails: {
                              ...this.state.trainingDetails,
                              training_agreement: e?.target?.checked,
                            },
                          });
                        }}
                      />
                    }
                    label={i18n.t("training_agreement")}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={4} mb={2}>
              {this.state?.trainingDetails?.training_agreement && (
                <Grid item xs={12}>
                  <Stack spacing={1}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <DatePicker
                        slotProps={{
                          textField: {
                            error: this.state.formErrors?.agreement_date
                              ? true
                              : false,
                            helperText: this.state.formErrors?.agreement_date,
                          },
                        }}
                        label={i18n.t("agreement_date")}
                        format={i18n.t("OPTIONS.date_format")}
                        value={
                          this.state?.trainingDetails?.agreement_date || null
                        }
                        onChange={async (e) => {
                          await this.setState({
                            trainingDetails: {
                              ...this.state.trainingDetails,
                              agreement_date: e,
                            },
                          });
                          await this.validateField("agreement_date");
                        }}
                      />
                    </LocalizationProvider>
                  </Stack>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={4} mb={2} style={{ alignItems: "center" }}>
              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <TimePicker
                    handleChange={this.handleTimePicker}
                    hours={this.state?.trainingDetails?.hours}
                    minutes={this.state?.trainingDetails?.minutes}
                  />
                  {this.state.formErrors?.training_duration && (
                    <FormHelperText style={{ color: "red" }}>
                      {this.state.formErrors?.training_duration}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          error: this.state.formErrors?.training_start_date
                            ? true
                            : false,
                          helperText:
                            this.state.formErrors?.training_start_date,
                          required: true,
                        },
                      }}
                      label={i18n.t("training_start_date")}
                      format={i18n.t("OPTIONS.date_format")}
                      value={
                        this.state?.trainingDetails?.training_start_date || null
                      }
                      onChange={async (e) => {
                        let error = { ...this.state?.formErrors };
                        if (
                          this.state?.trainingDetails?.training_end_date &&
                          new Date(e?._d) >
                            new Date(
                              this.state?.trainingDetails?.training_end_date
                            )
                        ) {
                          error["training_start_date"] =
                            i18n.t("invalid_start_date");
                        } else {
                          delete error["training_start_date"];
                          delete error["training_end_date"];
                        }
                        this.setState({
                          formErrors: { ...error },
                        });
                        await this.setState({
                          trainingDetails: {
                            ...this.state.trainingDetails,
                            training_start_date: e,
                          },
                        });
                        await this.validateField("training_start_date");
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4}>
                <Stack spacing={1}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          error: this.state.formErrors?.training_end_date
                            ? true
                            : false,
                          helperText: this.state.formErrors?.training_end_date,
                          required: true,
                        },
                      }}
                      label={i18n.t("training_end_Date")}
                      format={i18n.t("OPTIONS.date_format")}
                      value={
                        this.state?.trainingDetails?.training_end_date || null
                      }
                      onChange={async (e) => {
                        let error = { ...this.state?.formErrors };
                        if (
                          this.state?.trainingDetails?.training_start_date &&
                          new Date(e?._d) <
                            new Date(
                              this.state?.trainingDetails?.training_start_date
                            )
                        ) {
                          error["training_end_date"] =
                            i18n.t("invalid_end_date");
                        } else {
                          delete error["training_start_date"];
                          delete error["training_end_date"];
                        }
                        this.setState({
                          formErrors: { ...error },
                        });
                        await this.setState({
                          trainingDetails: {
                            ...this.state.trainingDetails,
                            training_end_date: e,
                          },
                        });
                        await this.validateField("training_end_date");
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={4} mb={2}>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <TextField
                    id="outlined-basic"
                    label={i18n.t("type_of_certificate_issued")}
                    variant="outlined"
                    value={
                      this.state?.trainingDetails?.type_of_certificate_issued ||
                      ""
                    }
                    onChange={async (e) => {
                      await this.setState({
                        trainingDetails: {
                          ...this.state.trainingDetails,
                          type_of_certificate_issued: e.target.value,
                        },
                      });
                    }}
                    error={
                      this.state.formErrors?.type_of_certificate_issued
                        ? true
                        : false
                    }
                    helperText={
                      this.state.formErrors?.type_of_certificate_issued
                    }
                  />
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={1}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      slotProps={{
                        textField: {
                          error: this.state.formErrors?.certificate_issue_date
                            ? true
                            : false,
                          helperText:
                            this.state.formErrors?.certificate_issue_date,
                        },
                      }}
                      label={i18n.t("certificate_issue_date")}
                      format={i18n.t("OPTIONS.date_format")}
                      value={
                        this.state?.trainingDetails?.certificate_issue_date ||
                        null
                      }
                      onChange={async (e) => {
                        await this.setState({
                          trainingDetails: {
                            ...this.state.trainingDetails,
                            certificate_issue_date: e,
                          },
                        });
                        await this.validateField("certificate_issue_date");
                      }}
                    />
                  </LocalizationProvider>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12}>
                <Stack spacing={1}>
                  <TextField
                    id="outlined-basic"
                    label={i18n.t("remarks")}
                    variant="outlined"
                    value={this.state?.trainingDetails?.remarks || ""}
                    multiline
                    rows={2}
                    maxRows={4}
                    onChange={async (e) => {
                      await this.setState({
                        trainingDetails: {
                          ...this.state.trainingDetails,
                          remarks: e.target.value,
                        },
                      });
                    }}
                  />
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={3} mb={2}>
              <Grid item xs={12}>
                <TrainingTraineeTable
                  saveTraineesData={this.saveTraineesData}
                  trainees={this.state?.trainingDetails?.trainees}
                />
              </Grid>
            </Grid>
          </>
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveTrainingDetails();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_training")
                  : i18n.t("update_training")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditTraining {...props} navigate={navigate} params={params} />;
}
