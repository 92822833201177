import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../../utils/debounce";
import config from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import Iconify from "../../../components/iconify/Iconify";
import {
  deleteAbm,
  editAbmDetail,
  getAbmList,
} from "../../../services/abm.service";
import AbmTable from "../../../SharedComponent/AbmTable";
import { getAirportList } from "../../../services/airport.service";

const TABLE_HEAD = [
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "date_of_offer",
    label: i18n.t("date_of_offer"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "date_of_initial_contract",
    label: i18n.t("date_of_initial_contract"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "contract_duration",
    label: i18n.t("contract_duration"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "expiry_date_of_initial_contract",
    label: i18n.t("expiry_date_of_initial_contract"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "renewal_type",
    label: i18n.t("renewal_type"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "date_of_renewed_contract",
    label: i18n.t("date_of_renewed_contract"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "duration_of_renewal",
    label: i18n.t("duration_of_renewal"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "expiry_date_of_renewed_contract",
    label: i18n.t("expiry_date_of_renewed_contract"),
    alignCenter: true,
    sortable: true,
  },
  { id: "" },
];
class Abm extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      abms: [],
      abm: {},
      open: null,
      page: 1,
      filtersOptions: [],
      search: "",
      limit: 100,
      skip: 0,
      total_abms: 0,
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: {},
      orderBy: { sort_key: "airport", sort_order: true },
      deleteAbmPopup: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
  }

  async componentDidMount() {
    await this.getAirportsForFilter();
    await this.getAbms();
  }
  async getAirportsForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      if (airport?._id === this.airportId) {
        this.setState({
          filters: {
            airport: { label: airport?.name, value: airport?._id },
          },
          expanded: true,
        });
      }
      return { label: airport?.name, value: airport?._id };
    });

    this.setState({
      filtersOptions: { ...this.state.filtersOptions, airportOptions },
    });
  }

  async getAbms(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters[key]?.value) {
        query[key] = this.state?.filters[key]?.value;
      } else {
        delete query[key];
      }
    });
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }
    let abms = await getAbmList(query);
    if (abms.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: abms?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(abms?.error_code))
          : abms?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState({
      tableLoader: false,
      abms: abms.abms,
      total_abms: abms.count,
    });
  }
  async handleDeleteAbm(abmId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteAbm(abmId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
      });
      return;
    }

    if (response) {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message,
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
      });
      await this.getAbms();
      this.setState({
        pageLoader: false,
      });
    }
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getAbmsDataOnchange = debounce(() => {
    this.getAbms("filters");
  }, 500);
  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getAbms();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getAbms();
    } else if (action === "popOverAction") {
      this.setState({
        abm: rowData,
      });
      this.handleOpenMenu(data);
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      if (config?.airportJsonKeys.includes(data.toLowerCase())) {
        await this.setState({
          orderBy: {
            sort_key:
              data === "airport"
                ? `airport_name`
                : `airport_${data.toLowerCase()}`,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      } else {
        await this.setState({
          orderBy: {
            sort_key: data,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      }
      this.getAbms();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.abms;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        abms: tempData,
      });
      this.updateAbmData(payload, payload?._id, backUpData);
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "deleteAbm") {
      this.handleCloseMenu();
      await this.setState({
        deleteAbmPopup: true,
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteAbmPopup: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteAbmPopup: false,
      });
      this.handleDeleteAbm(this.state.abm._id);
    }
  };
  async updateAbmData(payload, abmId, backUpData) {
    let response = await editAbmDetail(abmId, payload);
    if (response && response.error) {
      let tempData = this.state.abms;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        ...this.state,
        abms: tempData,
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    } else {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message,
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }
  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("abm")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={this.state.expanded}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      this.setState({
                        search: e?.target?.value,
                      });
                      this.getAbmsDataOnchange();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  <IconButton
                    onClick={() => {
                      this.handleExpandClick();
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.airportOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.airportOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  airport: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getAbmsDataOnchange();
                            }}
                            value={this.state?.filters?.airport?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("airport")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !config?.contractDurationOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : config?.contractDurationOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  contract_duration: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getAbmsDataOnchange();
                            }}
                            value={
                              this.state?.filters?.contract_duration?.label ||
                              ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("contract_duration")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !config?.contractDurationOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : config?.contractDurationOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  duration_of_renewal: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getAbmsDataOnchange();
                            }}
                            value={
                              this.state?.filters?.duration_of_renewal?.label ||
                              ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("duration_of_renewal")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !config?.renewalOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : config?.renewalOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  renewal_type: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getAbmsDataOnchange();
                            }}
                            value={
                              this.state?.filters?.renewal_type?.label || ""
                            }
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("renewal_type")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/awhm-awm/abm/add");
              }}
            >
              {i18n.t("abm")}
            </Button>
          </Stack>
        </Grid>

        <AbmTable
          data={this.state}
          onAction={this.handleTableAction}
          tableHeaders={TABLE_HEAD}
        />

        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Abm {...props} navigate={navigate} params={params} />;
}
