// TimePicker.js
import React, { Component } from "react";
import { TextField, IconButton, Box, Typography } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import i18n from "../i18n";

class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.handleChange = props?.handleChange;
  }

  formatNumber = (num) => {
    return num.toString().padStart(2, "0");
  };

  render() {
    return (
      <>
      
    <Box mx={1} alignItems="center">
    <Typography style={{marginBottom:'-10px'}}  gutterBottom>
      {i18n.t("training_duration")}
    </Typography>
      <Box display="flex" alignItems="center">
        <Box display="flex" style={{width:'100%'}} flexDirection="column" alignItems="center" mx={1}>
          <IconButton
            size="small"
            onClick={() => this.handleChange("hourIncrement")}
          >
            <ArrowDropUpIcon />
          </IconButton>
          <TextField
            value={this.formatNumber(this.props?.hours)}
            type="number"
            style={{width:'100%'}}
            onChange={(event) => this.handleChange("hourOnchange", event)}
            variant="outlined"
            size="small"
            label={'HH'}
            inputProps={{ style: { textAlign: "center" } }}
          />
          <IconButton
            size="small"
            onClick={() => this.handleChange("hourDecrement")}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </Box>
        <Box mx={1} display="flex" alignItems="center">
          :
        </Box>
        <Box display="flex" style={{width:'100%'}} flexDirection="column" alignItems="center" mx={1}>
          <IconButton
            size="small"
            onClick={() => this.handleChange("minutesIncrement")}
          >
            <ArrowDropUpIcon />
          </IconButton>
          <TextField
            value={this.formatNumber(this.props?.minutes)}
            onChange={(event) => this.handleChange("minutesOnChange", event)}
            variant="outlined"
            type="number"
            style={{width:'100%'}}
            label={'MM'}
            size="small"
            inputProps={{ style: { textAlign: "center" } }}
          />
          <IconButton
            size="small"
            onClick={() => this.handleChange("minutesDecrement")}
          >
            <ArrowDropDownIcon />
          </IconButton>
        </Box>
      </Box>
    </Box>

      </>
    );
  }
}

export default TimePicker;
