import React from "react";
import i18n from "../../i18n";
import config from "../../config";
import { Helmet } from "react-helmet-async";
import {
  exportAirportDetails,
  getAirportDetail,
} from "../../services/airport.service";
import { useNavigate, useParams } from "react-router-dom";
import {
  Backdrop,
  Breadcrumbs,
  Button,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  Typography,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightIcon from "@mui/icons-material/Flight";
import Iconify from "../../components/iconify/Iconify";
import Swal from "sweetalert2";
import ContactPersonsTable from "../../SharedComponent/ContactPersonsTable";
import ModeEditOutlineIcon from "@mui/icons-material/ModeEditOutline";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";
import OffersCombinedTable from "../../SharedComponent/OffersCombinedTable";
import TasksCombinedTable from "../../SharedComponent/TasksCombinedTable";
import HandrailsCombinedTable from "../../SharedComponent/HandrailsCombinedTable";
import AwhmCombinedTable from "../../SharedComponent/AwhmCombinedTable";
import AbmCombinedTable from "../../SharedComponent/AbmCombinedTable";
import TraineesCombinedTable from "../../SharedComponent/TraineesCombinedTable";
import DocumentationCombinedBteeSaTable from "../../SharedComponent/DocumentationCombinedBteeSaTable";
import Offers from "../Offers/Offers";

const styles = {
  headerStyles: {
    fontWeight: "500",
    paddingRight: '5px',
    fontSize: "18px",
  },
  headerValueStyles: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "16px",
    color: "rgb(137 139 141)",
  },
  headerGridStyles: {
    display: "flex",
    padding: 0,
    alignItems: "center"
  },
};
class AirportDetails extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      airport_id: props.params?.airport_id,
      airportDetails: {}
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
  }

  async componentDidMount() {
    try {
      await this.getAirportData();
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  async setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async getAirportData() {
    let response = await getAirportDetail(this.state.airport_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.airport) {
      let airportData = response.airport;
      await this.setStateAsync({
        airportDetails: {
          ...airportData,
        },
      });
    }
  }

  async exportAirportsDetailsData() {
    this.setState({
      pageLoader: true,
    });
    let response = await exportAirportDetails(this.state.airport_id);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false,
      });
      return;
    } else {
      const newBlob = new Blob([response]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${i18n.t("airport_details")}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      this.setState({
        pageLoader: false,
      });
    }
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("airport_details")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("airport_details")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={1}>
          <Grid
            container
            spacing={4}
            style={{ marginBottom: "10px" }}
            alignItems="center"
          >
            <Grid item xs={10}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link
                  underline="hover"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  color="inherit"
                  onClick={() => {
                    this.props.navigate("/dashboard/view");
                  }}
                >
                  <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {i18n.t("home")}
                </Link>
                <Link
                  underline="hover"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  color="inherit"
                  onClick={() => {
                    this.props.navigate("/airports/view");
                  }}
                >
                  <FlightTakeoffIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {i18n.t("airports")}
                </Link>
                <Typography
                  sx={{ display: "flex", alignItems: "center" }}
                  color="text.primary"
                >
                  <FlightIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {this.state?.airportDetails?.name}
                </Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>
        </Stack>
        <Grid container alignItems={"center"}>
          <Grid item xs={11}>
            <Typography variant="h4" gutterBottom>
              {this.state?.airportDetails?.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={1}
            style={{
              display: "flex",
              paddingRight: "25px",
              marginBottom: "10px",
            }}
            justifyContent="flex-end"
          >
            <div style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                startIcon={<SystemUpdateAltIcon />}
                className="BtnWidth"
                onClick={() => {
                  this.exportAirportsDetailsData();
                }}
              >
                {i18n.t("export_airport_details")}
              </Button>
            </div>
            <Button
              variant="contained"
              className="BtnWidth"
              mb={1}
              onClick={() => {
                this.props.navigate(`/airports/${this.state?.airport_id}`);
              }}
            >
              <ModeEditOutlineIcon />
            </Button>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          spacing={4}
          style={{
            margin: "10px 0",
            paddingRight: "11rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("country")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.country &&
                i18n.t("COUNTRIES." + this.state?.airportDetails?.country)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("continent")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.continent &&
                i18n.t("CONTINENTS." + this.state?.airportDetails?.continent)}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("code_IATA")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.code_iata}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          spacing={4}
          style={{
            margin: "10px 0",
            paddingRight: "11rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("code_OACI")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.code_oaci}
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("representative")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.representative?.name}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("is_open")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {i18n.t(this.state?.airportDetails?.is_open)}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          spacing={4}
          style={{
            margin: "10px 0",
            paddingRight: "11rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("email")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.email}
            </Typography>
          </Grid>

          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("phone_number")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.phone_number}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("address")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.address}
            </Typography>
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          spacing={4}
          style={{
            margin: "10px 0",
            paddingRight: "11rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("city")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.city}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
            <Typography style={styles.headerStyles}>
              {i18n.t("zip_code")}
            </Typography>
            <Typography style={styles.headerValueStyles}>
              {this.state?.airportDetails?.zip_code}
            </Typography>
          </Grid>
          <Grid
            item
            xs={3}
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: 0,
            }}
          >
          </Grid>
        </Grid>
        <Divider />
        <Grid
          container
          spacing={4}
          style={{
            margin: "10px 0",
            paddingRight: "11rem",
            display: "flex",
            justifyContent: "space-between",
          }}
        ></Grid>
        
        <ContactPersonsTable
          airportId={this.state?.airport_id}
          showAdd={true}
          showSearch={false}
          showFilter={false}
          showTitle={true}
        />

        <Grid container spacing={4} style={{ margin: "15px 0" }}></Grid>
        
        <OffersCombinedTable
          airportId={this.state?.airport_id}
          showAdd={true}
          showSearch={false}
          showFilter={false}
          showTitle={true}
        />

        <HandrailsCombinedTable
          airportId={this.state?.airport_id}
          showAdd={true}
          showSearch={false}
          showFilter={false}
          showTitle={true}
        />

        <TasksCombinedTable
          airportId={this.state?.airport_id}
          showAdd={true}
          showSearch={false}
          showFilter={false}
          showTitle={true}
        />

        <AwhmCombinedTable
          airportId={this.state?.airport_id}
          showAdd={true}
          showSearch={false}
          showFilter={false}
          showTitle={true}
        />

        <AbmCombinedTable
          airportId={this.state?.airport_id}
          showAdd={true}
          showSearch={false}
          showFilter={false}
          showTitle={true}
        />

        <DocumentationCombinedBteeSaTable
          airportId={this.state?.airport_id}
          showAdd={true}
          showSearch={false}
          showFilter={false}
          showTitle={true}
        />

        <TraineesCombinedTable
          airportId={this.state?.airport_id}
          showAdd={true}
          showSearch={false}
          showFilter={false}
          showTitle={true}
        />

        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}
// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AirportDetails {...props} navigate={navigate} params={params} />;
}
