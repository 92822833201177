import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import BadgeIcon from "@mui/icons-material/Badge";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import {
  addCourse,
  getCourseDetail,
  editCourseDetail,
} from "../../../services/course.service";
import { getOptionData } from "../../../utils/commonUtils";
import {
  getDomainList,
  getDomainSubjectsList,
} from "../../../services/domain.service";
import OffersTable from "../../../SharedComponent/OffersTable";
import { getOfferList } from "../../../services/offer.service";
import { getTrainingsList } from "../../../services/training.service";
import TrainingTraineeTable from "../../../SharedComponent/TrainingTraineeTable";

const TABLE_HEAD = [
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "description",
    label: i18n.t("description"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_OACI",
    label: i18n.t("code_OACI"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_IATA",
    label: i18n.t("code_IATA"),
    alignRight: false,
    sortable: true,
  },
  { id: "resp", label: i18n.t("resp"), alignRight: false, sortable: true },
  {
    id: "division",
    label: i18n.t("division"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "category",
    label: i18n.t("category"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "mandate_number",
    label: i18n.t("mandate_number"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "document",
    label: i18n.t("document"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "currency",
    label: i18n.t("currency"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "offer_amount",
    label: i18n.t("offer_amount"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "award_amount",
    label: i18n.t("award_amount"),
    alignRight: true,
    sortable: true,
  },
  { id: "status", label: i18n.t("status"), alignCenter: true, sortable: true },
  {
    id: "date",
    label: i18n.t("date_of_offer"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "award_date",
    label: i18n.t("award_date"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "validity",
    label: i18n.t("validity"),
    alignRight: false,
    sortable: true,
  },
  // { id: 'contact_person', label: i18n.t('contact_person'), alignRight: false, sortable: true },
  {
    id: "remarks",
    label: i18n.t("remarks"),
    alignRight: false,
    sortable: true,
  },
  // { id: "" },
];

class AddEditCourse extends React.Component {
  constructor(props) {
    super(props);
    this.courseTypeOptions = config?.courseTypeOptions;
    this.state = {
      mode: props?.params?.course_id ? "edit" : "add",
      course_id: props.params?.course_id,
      courseDetails: {},
      formErrors: {},
      loading: false,
      domainOptions: [],
      subjectOptions: [],
      offersData: {
        offers: [],
        offer: {},
        open: null,
        page: 1,
        search: "",
        limit: 100,
        skip: 0,
        total_offers: 0,
        deleteOfferPopUp: false,
        orderBy: { sort_key: "airport", sort_order: true },
      },
      trainees:[]
    };
  }

  async componentDidMount() {
    await this.getDomainsList();
    if (this.state.mode === "edit") {
      await this.getCourseDetails();
      this.getOffers();
      this.getTraineesData();
    }
  }

  async getCourseDetails() {
    this.setState({
      loading: true,
    });
    let response = await getCourseDetail(this.state.course_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.airtrace_course) {
      let courseData = response.airtrace_course;
      let domain = getOptionData(
        this?.state?.domainOptions,
        courseData?.domain
      );
      let type = getOptionData(this.courseTypeOptions, courseData?.type);
      await this.setState({
        courseDetails: {
          ...courseData,
          domain,
          type,
        },
        loading: false,
      },()=>this.getDomainSubjectsList(domain?.value));
    }
  }

  async saveCourse() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });

    let payload = {
      ...this.state.courseDetails,
      domain: this.state.courseDetails?.domain?.value,
      subject: this.state.courseDetails?.subject?.value,
      type: this.state.courseDetails?.type?.value,
    };

    if (this.state.mode === "add") {
      let response = await addCourse(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        this.setState({
          loading: false,
        });
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.message))
            : i18n.t("course_added_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editCourseDetail(this.state.course_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.message_code))
            : response?.message,
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }
  goBack() {
    this.props.navigate(-1);
  }
  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors };

    if (field == null || field == "course_id") {
      if (
        !this.state.courseDetails?.course_id ||
        this.state.courseDetails?.course_id == ""
      ) {
        error["course_id"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["course_id"];
      }
    }

    if (field == null || field == "domain") {
      if (
        !this.state.courseDetails?.domain?.value ||
        this.state.courseDetails?.domain?.value == ""
      ) {
        error["domain"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["domain"];
      }
    }
    if (field == null || field == "subject") {
      if (
        !this.state.courseDetails?.subject?.value ||
        this.state.courseDetails?.subject?.value == ""
      ) {
        error["subject"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["subject"];
      }
    }
    if (field == null || field == "type") {
      if (
        !this.state.courseDetails?.type?.value ||
        this.state.courseDetails?.type?.value == ""
      ) {
        error["type"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["type"];
      }
    }


    this.setState({
      formErrors: error,
    });

    return error;
  }

  async getDomainsList() {
    let response = await getDomainList();
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.domains?.map((el) => {
      return {
        label: i18n.t(el?.key),
        value: el?.key,
      };
    });
    this.setState({
      domainOptions: options,
    });
  }

  async getDomainSubjectsList(domain) {
    let response = await getDomainSubjectsList(domain);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.subjects?.map((el) => {
      return {
        label: i18n.t(el?.key),
        value: el?.key,
      };
    });
    let subject = getOptionData(options, this.state?.courseDetails?.subject);
    this.setState({
      subjectOptions: options,
      courseDetails: {
        ...this.state?.courseDetails,
        subject,
      },
    });
  }

  async getOffers() {
    this.setState({
      tableLoader: true,
    });
    let query = {
      limit: this.state?.offersData.limit,
      skip: this.state?.offersData.skip,
      course: this.state.course_id,
    };

    if (this.state?.offersData?.orderBy?.sort_key) {
      query.sort_key = this.state?.offersData?.orderBy?.sort_key;
      query.sort_order = this.state?.offersData?.orderBy?.sort_order
        ? "ASC"
        : "DESC";
    }

    let offersData = await getOfferList(query);
    if (offersData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: offersData?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(offersData?.error_code))
          : offersData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState({
      tableLoader: false,
      offersData: {
        ...this.state?.offersData,
        offers: offersData.offers,
        total_offers: offersData.count,
      },
    });
  }

  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      offersData: { ...this.state.offersData, open: null },
    });
  };

  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        ...this.state,
        offersData: {
          ...this.state?.offersData,
          skip: (data - 1) * this.state.limit,
          page: data,
        },
      });
      this.getOffers();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        offersData: {
          ...this.state?.offersData,
          limit: data,
        },
      });
      this.getOffers();
    } else if (action === "popOverAction") {
      this.setState({
        ...this.state,
        offersData: {
          ...this.state?.offersData,
          offer: rowData,
          open: data.currentTarget,
        },
      });
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      if (config?.airportJsonKeys.includes(data.toLowerCase())) {
        await this.setState({
          ...this.state,
          offersData: {
            ...this.state?.offersData,
            orderBy: {
              sort_key:
                data === "airport"
                  ? `airport_name`
                  : `airport_${data.toLowerCase()}`,
              sort_order: !this.state.orderBy?.sort_order,
            },
          },
        });
      } else {
        await this.setState({
          ...this.state,
          offersData: {
            ...this.state?.offersData,
            orderBy: {
              sort_key: data,
              sort_order: !this.state.orderBy?.sort_order,
            },
          },
        });
      }
      this.getOffers();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.offers;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        offersData: {
          ...this.state?.offersData,
          offers: tempData,
        },
      });
      this.updateOfferData(payload, payload?._id, backUpData);
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "deleteOffer") {
      this.handleCloseMenu();
      await this.setState({
        ...this.state,
        offersData: {
          ...this.state?.offersData,
          deleteOfferPopUp: true,
        },
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        ...this.state,
        offersData: {
          ...this.state?.offersData,
          deleteOfferPopUp: false,
        },
      });
    } else if (action === "proceed") {
      this.setState({
        ...this.state,
        offersData: {
          ...this.state?.offersData,
          deleteOfferPopUp: false,
        },
      });
      this.handleDeleteOffer(this.state.offer._id);
    }
  };
  
  async getTraineesData() {
   
    let query = {
      course: this.state?.course_id,
      view:'trainees'
    };

    let trainees = await getTrainingsList(query);
    if (trainees.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: trainees?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(trainees?.error_code))
          : trainees?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        ...this.state,
        trainingDetails: {
          ...this.state?.trainingDetails,
          tableLoader: true,
        },
      });
      return;
    }
    let tempData=trainees?.trainees?.map((data)=>{
      return({...data,trainee:{...data?.trainee,name:`${data?.trainee?.first_name} ${data?.trainee?.last_name}`}})
    })
    this.setState({
      ...this.state,
      trainees:tempData
    });
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_course")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("course")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate(`/airtrace/view/courses`);
              }}
            >
              <AssignmentOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("courses")}
            </Link>
            {this.state.mode === "add" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("add")}
              </Typography>
            )}
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("course_id")}
                  variant="outlined"
                  value={this.state?.courseDetails?.course_id || ""}
                  onChange={async (event) => {
                    await this.setState({
                      courseDetails: {
                        ...this.state.courseDetails,
                        course_id: event?.target?.value,
                      },
                    });
                    await this.validateField("course_id");
                  }}
                  error={this.state.formErrors?.course_id ? true : false}
                  helperText={this.state.formErrors?.course_id}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  onOpen={async () => {
                    await this.getDomainsList();
                  }}
                  options={
                    !this?.state.domainOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this?.state?.domainOptions
                  }
                  onChange={async (option, value) => {
                    if(this.state.courseDetails?.domain && this.state.courseDetails?.domain !== value){
                      this.getDomainSubjectsList(
                        this.state.courseDetails?.domain?.value
                      );
                      await this.setState({
                        courseDetails: {
                          ...this.state.courseDetails,
                          subject:{label:"",value:""}
                        },
                      });
                    }
                    await this.setState({
                      courseDetails: {
                        ...this.state.courseDetails,
                        domain: { name: value?.label, value: value?.value },
                      },
                    });
                    if(value?.value){
                      this.getDomainSubjectsList(
                        value?.value
                      );
                    }
                    await this.validateField("domain");
                  }}
                  value={i18n.t(this.state.courseDetails?.domain?.value) || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("domain")}
                      error={this.state.formErrors?.domain ? true : false}
                      helperText={this.state.formErrors?.domain}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={!this.state?.courseDetails?.domain?.value}
                  onOpen={async () => {
                    await this.getDomainSubjectsList(
                      this.state.courseDetails?.domain?.value
                    );
                  }}
                  options={
                    !this.state?.subjectOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state?.subjectOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      courseDetails: {
                        ...this.state.courseDetails,
                        subject: { name: value?.label, value: value?.value },
                      },
                    });
                    await this.validateField("subject");
                  }}
                  value={i18n.t(this.state.courseDetails?.subject?.value) || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("subject")}
                      error={this.state.formErrors?.subject ? true : false}
                      helperText={this.state.formErrors?.subject}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  options={
                    !this.courseTypeOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.courseTypeOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      courseDetails: {
                        ...this.state.courseDetails,
                        type: { label : value?.label, value: value?.value },
                      },
                    });
                    await this.validateField("type");
                  }}
                  value={this.state.courseDetails?.type?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("type")}
                      error={this.state.formErrors?.type ? true : false}
                      helperText={this.state.formErrors?.type}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("remarks")}
                  variant="outlined"
                  value={this.state?.courseDetails?.remarks || ""}
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={async (e) => {
                    await this.setState({
                      courseDetails: {
                        ...this.state.courseDetails,
                        remarks: e.target.value,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          {this.state.mode === "edit" && (
              <>
              <Typography variant="h4" gutterBottom>
                {i18n.t("offers")}
              </Typography>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={12}>
                  <OffersTable
                    data={this.state?.offersData}
                    onAction={this.handleTableAction}
                    tableHeaders={TABLE_HEAD}
                    hideEdit={true}
                    hideDelete={true}
                    hideActionButton={true}
                  />
                </Grid>
            </Grid>
              </>
          )}
          <Grid container spacing={3} mb={2}>
              <Grid item xs={12}>
                <TrainingTraineeTable
                  trainees={this.state?.trainees}
                  hideEditAddDeleteActions={true}
                />
              </Grid>
            </Grid>
          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveCourse();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_course")
                  : i18n.t("update_course")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();
  return <AddEditCourse {...props} navigate={navigate} params={params} />;
}
