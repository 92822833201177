import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Menu,
  Link,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { getOptionData } from "../utils/commonUtils";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import VerifiedIcon from '@mui/icons-material/Verified';
import GppBadIcon from '@mui/icons-material/GppBad';
import VisibilityIcon from '@mui/icons-material/Visibility';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class AbmTable extends React.Component {
  constructor(props) {
    super(props);
    this.airportId = props?.airportId;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.onAction = props?.onAction;
    this.tableHeaders = props?.tableHeaders;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.hideAirportDetails = props?.hideAirportDetails;
    this.respOptions = config?.respOptions;
    this.confirmOptions = config?.confirmOptions;
    this.state = {
      ...props.data,
      showRespDropDown: false,
      showExecutedDropDown: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps?.data });
  }

  handleClick = (event, data, rowIndex, key) => {
    this.setState({
      ...this.state,
      [key]: event.currentTarget,
      abmData: data,
      rowIndex,
    });
  };
  handleClose = (key) => {
    this.setState({
      ...this.state,
      [key]: null,
    });
  };

  render() {
    return (
      <>
        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" ,borderRadius:'5px'}}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer
              style={{ width: "100%", maxHeight: "calc(100vh - 255px)" }}
              hideScrollbars={false}
            >
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {this.tableHeaders?.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["airport"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        align={ headCell.alignCenter
                          ? "center"
                          : headCell.alignRight
                          ? "right"
                          : "left"}
                        key={headCell.id}
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state?.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.onAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this?.state?.abms &&
                    this?.state?.abms.map((row, index) => {
                      const {
                        _id,
                        airport,
                        contract_duration,
                        date_of_initial_contract,
                        date_of_renewed_contract,
                        date_of_offer,
                        duration_of_renewal,
                        expiry_date_of_initial_contract,
                        expiry_date_of_renewed_contract,
                        renewal_type,
                        renewal_status
                      } = row;

                      return (
                        <StyledTableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          onClick={() => {
                            this.onAction(_id);
                          }}
                        >
                          {!this.hideAirportDetails && (
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{
                                position: "sticky",
                                left: 0,
                                background: "primary.light",
                                backgroundColor:
                                  index % 2 ? "white" : "#F6F7F8",
                                minWidth: "10rem",
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={6}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{
                                    typography: "body2",
                                    cursor: "pointer",
                                  }}
                                  onDoubleClick={() => {
                                    this.airportId
                                      ? this.props.navigate(
                                          `/abm/${_id}?airport=${this?.airportId}`
                                        )
                                      : this.props.navigate(`/awhm-awm/abm/${_id}`);
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    <Link
                                      onClick={() => {
                                        this.props.navigate(
                                          `/airports/airportDetails/${row?.airport?._id}`
                                        );
                                      }}
                                    >
                                      {airport?.name}
                                    </Link>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                          )}

                        <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/abm/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/awhm-awm/abm/${_id}`);;
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={date_of_offer}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {date_of_offer ? moment(date_of_offer).format(
                                    i18n.t("OPTIONS.date_format")
                                  ):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/abm/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/awhm-awm/abm/${_id}`);;
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={date_of_initial_contract}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {date_of_initial_contract ? moment(date_of_initial_contract).format(
                                    i18n.t("OPTIONS.date_format")
                                  ):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      backgroundColor: getOptionData(
                                        config?.contractDurationOptions,
                                        contract_duration
                                      )?.color,
                                      color: getOptionData(
                                        config?.contractDurationOptions,
                                        contract_duration
                                      )?.textColor,
                                      padding: 5,
                                      borderRadius: 5,
                                      fontSize: 12,
                                      textWrap: "nowrap",
                                      fontWeight: "bold",
                                      marginLeft: "20px",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showContractDuration"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showContractDuration)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showContractDuration)
                                        ? "true"
                                        : undefined
                                    }
                                  >
                                    {
                                      getOptionData(
                                        config?.contractDurationOptions,
                                        contract_duration
                                      )?.label
                                    }
                                  </span>
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showContractDuration
                                    )}
                                    anchorEl={this.state.showContractDuration}
                                    onClose={() => {
                                      this.handleClose("showContractDuration");
                                    }}
                                  >
                                    {config?.contractDurationOptions?.map(
                                      (option) => {
                                        return (
                                          <MenuItem
                                            value={option?.value}
                                            onClick={() => {
                                              this.onAction(
                                                "update",
                                                option?.value,
                                                this.state.abmData,
                                                "contract_duration",
                                                this.state.rowIndex
                                              );
                                              this.handleClose(
                                                "showContractDuration"
                                              );
                                            }}
                                          >
                                            {option?.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/abm/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/awhm-awm/abm/${_id}`);;
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={expiry_date_of_initial_contract}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {expiry_date_of_initial_contract?moment(
                                    expiry_date_of_initial_contract
                                  ).format(i18n.t("OPTIONS.date_format")):"-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell>
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      backgroundColor: getOptionData(
                                        config?.renewalOptions,
                                        renewal_type
                                      )?.color,
                                      padding: 5,
                                      borderRadius: 5,
                                      fontSize: 12,
                                      textWrap: "nowrap",
                                      fontWeight: "bold",
                                      marginLeft: "20px",
                                      cursor:"pointer",
                                    }}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showRenewalDropdown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showRenewalDropdown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showRenewalDropdown)
                                        ? "true"
                                        : undefined
                                    }
                                  >
                                    {
                                      getOptionData(
                                        config?.renewalOptions,
                                        renewal_type
                                      )?.label
                                    }
                                  </span>
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showRenewalDropdown
                                    )}
                                    anchorEl={this.state.showRenewalDropdown}
                                    onClose={() => {
                                      this.handleClose("showRenewalDropdown");
                                    }}
                                  >
                                    {config?.renewalOptions?.map((option) => {
                                      return (
                                        <MenuItem
                                          value={option?.value}
                                          onClick={() => {
                                            this.onAction(
                                              "update",
                                              option?.value,
                                              this.state.abmData,
                                              "renewal_type",
                                              this.state.rowIndex
                                            );
                                            this.handleClose(
                                              "showRenewalDropdown"
                                            );
                                          }}
                                        >
                                          {option?.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/abm/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/awhm-awm/abm/${_id}`);;
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={date_of_renewed_contract}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {date_of_renewed_contract ? moment(
                                    date_of_renewed_contract
                                  ).format(i18n.t("OPTIONS.date_format")) : "-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  <span
                                    style={{
                                      backgroundColor: getOptionData(
                                        config?.contractDurationOptions,
                                        duration_of_renewal
                                      )?.color,
                                      color: getOptionData(
                                        config?.contractDurationOptions,
                                        duration_of_renewal
                                      )?.textColor,
                                      padding: 5,
                                      borderRadius: 5,
                                      fontSize: 12,
                                      textWrap: "nowrap",
                                      fontWeight: "bold",
                                      marginLeft: "20px",
                                      cursor:"pointer",
                                    }}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showDurationOfRenewal"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showDurationOfRenewal)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showDurationOfRenewal)
                                        ? "true"
                                        : undefined
                                    }
                                  >
                                    {
                                      getOptionData(
                                        config?.contractDurationOptions,
                                        duration_of_renewal
                                      )?.label
                                    }
                                  </span>
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showDurationOfRenewal
                                    )}
                                    anchorEl={this.state.showDurationOfRenewal}
                                    onClose={() => {
                                      this.handleClose("showDurationOfRenewal");
                                    }}
                                  >
                                    {config?.contractDurationOptions?.map(
                                      (option) => {
                                        return (
                                          <MenuItem
                                            value={option?.value}
                                            onClick={() => {
                                              this.onAction(
                                                "update",
                                                option?.value,
                                                this.state.abmData,
                                                "duration_of_renewal",
                                                this.state.rowIndex
                                              );
                                              this.handleClose(
                                                "showDurationOfRenewal"
                                              );
                                            }}
                                          >
                                            {option?.label}
                                          </MenuItem>
                                        );
                                      }
                                    )}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                ? this.props.navigate(
                                    `/abm/${_id}?airport=${this?.airportId}`
                                  )
                                : this.props.navigate(`/awhm-awm/abm/${_id}`);;
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={expiry_date_of_renewed_contract}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  { expiry_date_of_renewed_contract ? moment(
                                    expiry_date_of_renewed_contract
                                  ).format(i18n.t("OPTIONS.date_format")): "-"}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              sx={{padding:'2px'}}
                              color="inherit"
                              onClick={(e) => {
                                this.onAction("popOverAction", e, row);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this?.state?.abms?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        {this.state.abms?.length !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(this.state.total_abms / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  this.onAction("pagination", page);
                }}
                page={this.state.page}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Grid>
            <Grid item style={{ width: "10%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                <Select
                  value={this.state.limit}
                  label="Limit"
                  size="small"
                  onChange={async (e) => {
                    this.onAction("limit", e?.target?.value);
                  }}
                >
                  {this.tableLimitOptions?.map((limit) => {
                    return (
                      <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Popover
              open={Boolean(this.state.open)}
              anchorEl={this.state.open}
              onClose={() => {
                this.onAction("closeMenu");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.onAction("closeMenu");
                  this.airportId
                    ? this.props.navigate(
                        `/awhm-awm/abm/${this.state.abm._id}?airport=${this.airportId}`
                      )
                    : this.props.navigate("/awhm-awm/abm/" + this.state.abm._id);
                }}
              >
                <>
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                {i18n.t("edit")}
                </>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.onAction("deleteAbm");
                }}
              >
                <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </MenuItem>
            </Popover>
            <Dialog
              open={this.state?.deleteAbmPopup}
              onClose={() => {
                this.onAction("closeConfirmationPopUp");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    this.onAction("proceed");
                  }}
                >
                  {i18n.t("proceed")}
                </Button>
                <Button
                  onClick={() => {
                    this.onAction("closeConfirmationPopUp");
                  }}
                  autoFocus
                >
                  {i18n.t("cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </>
    );
  }
}
// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AbmTable {...props} navigate={navigate} params={params} />;
}
