import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Container, Typography
} from "@mui/material";
import config from "../../config";
import i18n from "../../i18n";
import AWHM from "./Awhm/Awhm";
import ABM from "./ABM/Abm";

class AwhmAbm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab:props.params?.tabId || 'awhm',
      tabId: props.params?.tabId}
    
  }
  componentDidUpdate(prevProps) {
    if (this.state.tabId !== this.props.params.tabId) {
      this.setState({ tabId: this.props.params.tabId ,currentTab:this.props.params.tabId});
    }
  }


  render() {
    return (
    <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("awhm")}/{i18n.t("abm")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        {this.state?.currentTab === 'awhm' && <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("awhm")}
        </Typography>}
        {this.state?.currentTab === 'abm' && <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("abm")}
        </Typography>}
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={this.state?.currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(event, newValue)=>{
            this.setState({
                currentTab:newValue
            })
            this.props.navigate(`/awhm-awm/view/${newValue}`)
          }} >
            <Tab label={i18n.t('awhm')} value={'awhm'} />
            <Tab label={i18n.t('abm')} value={'abm'} />
          </TabList>
        </Box>
        <TabPanel value={'awhm'} sx={{padding:'10px 0'}}><AWHM/></TabPanel>
        <TabPanel value={'abm'} sx={{padding:'10px 0'}}><ABM/></TabPanel>
      </TabContext>
    </Box>
      </Container>
    )
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AwhmAbm {...props} navigate={navigate} params={params} />;
}
