import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Chip,
  Menu,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Popover,
  Accordion,
  AccordionSummary,
  Input,
  InputAdornment,
  AccordionDetails,
  Autocomplete,
  TextField,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { getOptionData } from "../utils/commonUtils";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { NestedMenuItem } from "mui-nested-menu";
import { getDomainList } from "../services/domain.service";
import { convertMessageCodeToMessage } from "../utils/messageCodeToMessage";
import {
  deleteOffer,
  editOfferDetail,
  getOfferList,
} from "../services/offer.service";
import Swal from "sweetalert2";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const tableHeaders = [
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "description",
    label: i18n.t("description"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_OACI",
    label: i18n.t("code_OACI"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_IATA",
    label: i18n.t("code_IATA"),
    alignRight: false,
    sortable: true,
  },
  { id: "resp", label: i18n.t("resp"), alignRight: false, sortable: true },
  {
    id: "division",
    label: i18n.t("division"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "category",
    label: i18n.t("domain"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "mandate_number",
    label: i18n.t("mandate_number"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "document",
    label: i18n.t("document"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "currency",
    label: i18n.t("currency"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "offer_amount",
    label: i18n.t("offer_amount"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "award_amount",
    label: i18n.t("award_amount"),
    alignRight: true,
    sortable: true,
  },
  { id: "status", label: i18n.t("status"), alignCenter: true, sortable: true },
  {
    id: "date",
    label: i18n.t("date_of_offer"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "award_date",
    label: i18n.t("award_date"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "validity",
    label: i18n.t("validity"),
    alignRight: false,
    sortable: true,
  },
  // { id: 'contact_person', label: i18n.t('contact_person'), alignRight: false, sortable: true },
  {
    id: "remarks",
    label: i18n.t("remarks"),
    alignRight: false,
    sortable: true,
  },
  { id: "" },
];


const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class OffersCombinedTable extends React.Component {
  constructor(props) {
    super(props);
    this.airportId = props?.airportId;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.documentOptions = config?.documentOptions;
    this.statusOptions = config?.statusOptions;
    this.divisionOptions = config?.divisionOptions;
    this.respOptions = config?.respOptions;
    this.state = {
      expanded: false,
      offers: [],
      tableLoader: false,
      offer: {},
      search: "",
      page: 1,
      limit: props?.limit || 25,
      skip: 0,
      open: false,
      total_offers: 0,
      orderBy: { sort_key: "airport", sort_order: true },
      showRespDropDown: false,
      showDivisionDropDown: false,
      showCategoryDropDown: false,
      showDocumentDropDown: false,
      showStatusDropDown: false,
      offerData: {},
      isScrolling: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "11",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps?.data });
  }
  componentDidMount() {
    this.getOffers();
    this.getDomainsList();
  }
  async setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }
  async getOffers() {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    if (this.airportId) {
      query["airport"] = this.airportId;
    }

    if (this.state.search) {
      query["search"] = this.state.search;
    }

    let offers = await getOfferList(query);
    if (offers.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: offers?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(offers?.error_code))
          : offers?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    await this.setStateAsync({
      tableLoader: false,
      offers: offers.offers,
      total_offers: offers.count,
      total: offers?.total,
    });
  }
  handleClick = (event, data, rowIndex, key) => {
    this.setState({
      [key]: event.currentTarget,
      offerData: data,
      rowIndex,
    });
  };
  handleClose = (key) => {
    this.setState({
      [key]: null,
    });
  };

  async getDomainsList() {
    let response = await getDomainList();
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.domains?.map((el) => {
      return {
        label: el?.name,
        value: el?.key
      };
    });
    this.setState({
      categoriesOptions: {
        AIRTRACE: options,
        AERO: config?.categoriesOptions
      }
    });
  }
  handleOffersTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state?.limit,
      });
      this.getOffers();
    } else if (action === "limit") {
      await this.setState({
        limit: data,
      });
      this.getOffers();
    } else if (action === "popOverAction") {
      this.setState({
        open: data.currentTarget,
        offer: rowData,
      });
    } else if (action === "sort") {
      await this.setState({
        orderBy: {
          sort_key: data,
          sort_order: !this.state?.orderBy?.sort_order,
        },
      });
      this.getOffers();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.offers;
      tempData[rowIndex] = payload;
      this.setState({
        offers: tempData,
      });
      this.updateOfferData(payload, payload?._id, backUpData);
    } else if (action === "updateCascade") {
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData, ...data };
      let tempData = this.state.offers;
      tempData[rowIndex] = payload;
      this.setState({
        offers: tempData,
      });
      this.updateOfferData(payload, payload?._id, backUpData);
    } else if (action === "closeMenu") {
      this.setState({
        open: null
      });
    } else if (action === "deleteOffer") {
      await this.setState({
        deleteOfferPopUp: true,
        open: null
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteOfferPopUp: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteOfferPopUp: false,
      });
      this.handleDeleteOffer(this.state?.offer?._id);
    }
  };

  async handleDeleteOffer(offerId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteOffer(offerId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false,
      });
      return;
    }

    if (response) {
      await this.getOffers();
      this.setState({
        pageLoader: false,
      });
    }
  }
  async updateOfferData(payload, offerId, backUpData) {
    let response = await editOfferDetail(offerId, payload);
    if (response && response.error) {
      backUpData;
      let tempData = this.state?.offers;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        offers: tempData,
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.offer) {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("offer_updated_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }

  render() {
    return (
      <>

        {(this.props?.showTitle) ? (
          <Typography variant="h4" gutterBottom>
            {i18n.t("offers_or_mandates")}
          </Typography>
        ) : null}

        <Stack direction="row" spacing={2} style={{ marginBottom: 10 }} justifyContent={'flex-end'}>
          {(this.props?.showSearch || this.props?.showFilter) ? (
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={this.state.expanded}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={async (e) => {
                      await this.setStateAsync({
                        search: e?.target?.value,
                      });
                      this.getOffers();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  {(this.props?.showFilter) ? (
                    <IconButton
                      onClick={() => {
                        this.setState({ expanded: !this.state.expanded });
                      }}
                    >
                      <FilterAltIcon />
                    </IconButton>
                  ) : null}
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                    {(!this.props?.airportId) ? (
                        <Grid item xs={12} md={4}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.airportOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.airportOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  airport: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getOffersDataOnchange();
                            }}
                            value={this.state?.filters?.airport?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("airport")}
                              />
                            )}
                          />
                        </Grid>
                      ) : null}

                      {/* TODO : Resp, may be it should list users */}
                      {/* <Grid item xs={12} md={4}>
                        <Autocomplete
                          required
                          noOptionsText={i18n.t('no_options')}
                          disablePortal
                          options={
                            !this.state?.filtersOptions?.resp
                              ? [{ label: "Loading...", id: 0 }]
                              : this.state?.filtersOptions?.resp
                          }
                          onChange={async (option, value) => {
                            await this.setState({
                              filters: {
                                ...this.state?.filters,
                                resp: value?.value ? value?.value : "",
                              },
                            });
                            this.getOffersDataOnchange();
                          }}
                          value={this.state?.filters?.resp || ""}
                          renderInput={(params) => (
                            <TextField {...params} label={i18n.t("resp")} />
                          )}
                        />
                      </Grid> */}
                      
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          required
                          noOptionsText={i18n.t('no_options')}
                          disablePortal
                          options={
                            !this.state?.filtersOptions?.divisions
                              ? [{ label: "Loading...", id: 0 }]
                              : this.state?.filtersOptions?.divisions
                          }
                          onChange={async (option, value) => {
                            await this.setState({
                              filters: {
                                ...this.state?.filters,
                                division: value?.value ? value?.value : "",
                              },
                            });
                            this.getOffersDataOnchange();
                          }}
                          value={this.state?.filters?.division || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={i18n.t("division")}
                            />
                          )}
                        />
                      </Grid>
                      
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          required
                          noOptionsText={i18n.t('no_options')}
                          disablePortal
                          options={
                            !this.state?.filtersOptions?.categories
                              ? [{ label: "Loading...", id: 0 }]
                              : this.state?.filtersOptions?.categories
                          }
                          onChange={async (option, value) => {
                            await this.setState({
                              filters: {
                                ...this.state?.filters,
                                category: value?.value ? value?.value : "",
                              },
                            });
                            this.getOffersDataOnchange();
                          }}
                          value={i18n.t(this.state?.filters?.category) || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={i18n.t("domain")}
                            />
                          )}
                        />
                      </Grid>
                      
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          required
                          noOptionsText={i18n.t('no_options')}
                          disablePortal
                          options={
                            !this.state?.filtersOptions?.documents
                              ? [{ label: "Loading...", id: 0 }]
                              : this.state?.filtersOptions?.documents
                          }
                          onChange={async (option, value) => {
                            await this.setState({
                              filters: {
                                ...this.state?.filters,
                                document: value?.value ? value?.value : "",
                              },
                            });
                            this.getOffersDataOnchange();
                          }}
                          value={i18n.t(this.state?.filters?.document) || ""}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={i18n.t("document")}
                            />
                          )}
                        />
                      </Grid>
                      
                      <Grid item xs={12} md={4}>
                        <Autocomplete
                          required
                          noOptionsText={i18n.t('no_options')}
                          disablePortal
                          options={
                            !this.state?.filtersOptions?.statusOptions
                              ? [{ label: "Loading...", id: 0 }]
                              : this.state?.filtersOptions?.statusOptions
                          }
                          onChange={async (option, value) => {
                            await this.setState({
                              filters: {
                                ...this.state?.filters,
                                status: value?.value ? value?.value : "",
                              },
                            });
                            this.getOffersDataOnchange();
                          }}
                          value={i18n.t(this.state?.filters?.status) || ""}
                          renderInput={(params) => (
                            <TextField {...params} label={i18n.t("status")} />
                          )}
                        />
                      </Grid>
                    
                      <Grid item xs={12}>
                        <DateRangePicker
                          onChange={async (e) => {
                            if (new Date(e?.[0]) < new Date(this.state?.filters?.start_award_date)) {
                              this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  end_award_date: null,
                                },
                              });
                              Swal.fire({
                                toast: true,
                                position: "bottom-end",
                                icon: "warning",
                                title: i18n.t("warning"),
                                text: i18n.t('end_award_date_should_be_greater_than_start_award_date'),
                                showConfirmButton: false,
                                timer: config.toastDelay,
                                timerProgressBar: true,
                              });
                              return;
                            }
                            await this.setState({
                              filters: {
                                ...this.state?.filters,
                                start_award_date: e?.[0] ? moment(e?.[0]).format(
                                  'YYYY-MM-DD'
                                ) : null,
                                end_award_date: e?.[1] ? moment(e?.[1]).format(
                                  'YYYY-MM-DD'
                                ) : null,
                              },
                            });
                            moment(this.state?.filters?.end_award_date).isValid() && this.getOffersDataOnchange();
                            e === null && this.getOffers();
                          }}
                          value={[this.state?.filters?.start_award_date || null, this.state?.filters?.end_award_date || null]}
                        />
                      </Grid>

                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          ) : null}
          {(this.props?.showAdd) ? (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate(
                  `/offers/add?airport=${this.airportId}`
                );
              }}
            >
              {i18n.t("offer_or_mandate")}
            </Button>
          ) : null}
        </Stack>



        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", borderRadius: '3px' }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer style={{ width: "100%", maxHeight: 'calc(100vh - 255px)' }} hideScrollbars={false}>
              <Table
                stickyHeader
                size="small"
                aria-label="customized table"
                className="child"
              >
                <TableHead>
                  <TableRow>
                    {tableHeaders?.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["airport"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        key={headCell.id}
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                              ? "right"
                              : "left"
                        }
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state?.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.handleOffersTableAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy?.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                              headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this.state?.offers?.map((row, index) => {
                      const {
                        _id,
                        airport,
                        resp,
                        division,
                        category,
                        mandate_number,
                        document,
                        currency,
                        award_amount,
                        offer_amount,
                        award_date,
                        status,
                        date,
                        validity,
                        contact_person,
                        description,
                        remarks,
                        l,
                      } = row;

                      return (
                        <>
                          <StyledTableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            onClick={() => {
                              this.handleOffersTableAction(_id);
                            }}
                            className="scroller"
                            ref={this.attachScroller}
                            onMouseDown={this.onScroll}
                            onScroll={this.onMouseMove}
                          >
                            {(
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{
                                  position: "sticky",
                                  left: 0,
                                  zIndex: 10,
                                  backgroundColor:
                                    index % 2 ? "white" : "#F6F7F8",
                                  minWidth: "10rem",
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={6}
                                >
                                  <Stack
                                    direction="column"
                                    spacing={0}
                                    sx={{
                                      typography: "body2",
                                      cursor: "pointer",
                                    }}
                                    onDoubleClick={() => {
                                      this.airportId
                                        ? this.props.navigate(
                                          `/offers/${_id}?airport=${this?.airportId}`
                                        )
                                        : this.props.navigate(`/offers/${_id}`);
                                    }}
                                  >
                                    <Typography variant="subtitle2">
                                      <Link
                                        onClick={() => {
                                          this.props.navigate(
                                            `/airports/airportDetails/${row?.airport?._id}`
                                          );
                                        }}
                                      >
                                        {airport?.name}
                                      </Link>
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </StyledTableCell>
                            )}
                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {description}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            {(
                              <StyledTableCell
                                align="left"
                                style={{ cursor: "pointer" }}
                                onDoubleClick={() => {
                                  this.airportId
                                    ? this.props.navigate(
                                      `/offers/${_id}?airport=${this?.airportId}`
                                    )
                                    : this.props.navigate(`/offers/${_id}`);
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="centre"
                                  spacing={2}
                                  key={airport?.code_oaci}
                                >
                                  <Stack
                                    direction="column"
                                    spacing={0}
                                    sx={{ typography: "body2" }}
                                  >
                                    <Typography variant="subtitle2">
                                      {airport?.code_oaci}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </StyledTableCell>
                            )}

                            {(
                              <StyledTableCell
                                component="th"
                                scope="row"
                                style={{ cursor: "pointer" }}
                                onDoubleClick={() => {
                                  this.airportId
                                    ? this.props.navigate(
                                      `/offers/${_id}?airport=${this?.airportId}`
                                    )
                                    : this.props.navigate(`/offers/${_id}`);
                                }}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Stack
                                    direction="column"
                                    spacing={0}
                                    sx={{ typography: "body2" }}
                                  >
                                    <Typography variant="subtitle2">
                                      {airport?.code_iata}
                                    </Typography>
                                  </Stack>
                                </Stack>
                              </StyledTableCell>
                            )}

                            <StyledTableCell component="th" scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {resp?.name}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell component="th" scope="row">
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                                justifyContent="center"
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                  width="100%"
                                >
                                  <Typography variant="subtitle2">
                                    <Chip
                                      label={division}
                                      onClick={(e) => {
                                        this.handleClick(
                                          e,
                                          row,
                                          index,
                                          "showDivisionDropDown"
                                        );
                                      }}
                                      aria-controls={
                                        Boolean(this.state.showDivisionDropDown)
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        Boolean(this.state.showDivisionDropDown)
                                          ? "true"
                                          : undefined
                                      }
                                      style={{
                                        backgroundColor: getOptionData(
                                          this.divisionOptions,
                                          division
                                        )?.color,
                                        color: getOptionData(
                                          this.divisionOptions,
                                          division
                                        )?.textColor,
                                        cursor: "pointer",
                                      }}
                                      variant="outlined"
                                    />
                                    {<Menu
                                      id="basic-menu"
                                      open={Boolean(
                                        this.state.showDivisionDropDown
                                      )}
                                      anchorEl={this.state.showDivisionDropDown}
                                      onClose={() => {
                                        this.handleClose("showDivisionDropDown");
                                      }}
                                    >
                                      {this.divisionOptions?.map((option) => {
                                        return (
                                          <NestedMenuItem
                                            label={option?.label}
                                            value={option?.value}
                                            parentMenuOpen={Boolean(
                                              this.state.showDivisionDropDown
                                            )}
                                          >
                                            {this?.state.categoriesOptions?.[option?.value]?.map((categoryOption) => {
                                              return (
                                                <MenuItem
                                                  value={option?.value}
                                                  onClick={() => {
                                                    // this.handleOffersTableAction(
                                                    //   "update",
                                                    //   option?.value,
                                                    //   this.state.offerData,
                                                    //   "division",
                                                    //   this.state.rowIndex
                                                    // );
                                                    this.handleOffersTableAction(
                                                      "updateCascade",
                                                      {
                                                        division: option?.value,
                                                        category:
                                                          categoryOption?.value,
                                                      },
                                                      this.state.offerData,
                                                      "division",
                                                      this.state.rowIndex
                                                    );
                                                    this.handleClose(
                                                      "showDivisionDropDown"
                                                    );
                                                  }}
                                                >
                                                  {categoryOption?.label}
                                                </MenuItem>
                                              );
                                            })}
                                          </NestedMenuItem>
                                        );
                                      })}
                                    </Menu>}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell align="center">
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                justifyContent="center"
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                  width="100%"
                                >
                                  <Typography variant="subtitle2">
                                    <Chip
                                      label={i18n.t(category)}
                                      onClick={(e) => {
                                        this.handleClick(
                                          e,
                                          row,
                                          index,
                                          "showCategoryDropDown"
                                        );
                                      }}
                                      aria-controls={
                                        Boolean(this.state.showCategoryDropDown)
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        Boolean(this.state.showCategoryDropDown)
                                          ? "true"
                                          : undefined
                                      }
                                      style={{
                                        backgroundColor: getOptionData(
                                          config?.categoriesOptions,
                                          category
                                        )?.color,
                                        cursor: "pointer",
                                        color: getOptionData(
                                          config?.categoriesOptions,
                                          category
                                        )?.textColor
                                      }}
                                      variant="outlined"
                                    />
                                    {<Menu
                                      id="basic-menu"
                                      open={Boolean(
                                        this.state.showCategoryDropDown
                                      )}
                                      anchorEl={this.state.showCategoryDropDown}
                                      onClose={() => {
                                        this.handleClose("showCategoryDropDown");
                                      }}
                                    >
                                      {this.state?.categoriesOptions?.[this.state.offerData?.division]?.map((option) => {
                                        return (
                                          <MenuItem
                                            value={option?.value}
                                            onClick={() => {
                                              this.handleOffersTableAction(
                                                "update",
                                                option?.value,
                                                this.state.offerData,
                                                "category",
                                                this.state.rowIndex
                                              );
                                              this.handleClose(
                                                "showCategoryDropDown"
                                              );
                                            }}
                                          >
                                            {option?.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Menu>}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                justifyContent="end"
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {mandate_number}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell align="centre">
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                justifyContent="center"
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                  width="100%"
                                >
                                  <Typography variant="subtitle2">
                                    <Chip
                                      label={i18n.t(document)}
                                      onClick={(e) => {
                                        this.handleClick(
                                          e,
                                          row,
                                          index,
                                          "showDocumentDropDown"
                                        );
                                      }}
                                      aria-controls={
                                        Boolean(this.state.showDocumentDropDown)
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        Boolean(this.state.showDocumentDropDown)
                                          ? "true"
                                          : undefined
                                      }
                                      style={{
                                        backgroundColor: getOptionData(
                                          this.documentOptions,
                                          document
                                        )?.color,
                                        cursor: "pointer",
                                      }}
                                      variant="outlined"
                                    />
                                    {<Menu
                                      id="basic-menu"
                                      open={Boolean(
                                        this.state.showDocumentDropDown
                                      )}
                                      anchorEl={this.state.showDocumentDropDown}
                                      onClose={() => {
                                        this.handleClose("showDocumentDropDown");
                                      }}
                                    >
                                      {this.documentOptions?.map((option) => {
                                        return (
                                          <MenuItem
                                            value={option?.value}
                                            onClick={() => {
                                              this.handleOffersTableAction(
                                                "update",
                                                option?.value,
                                                this.state.offerData,
                                                "document",
                                                this.state.rowIndex
                                              );
                                              this.handleClose(
                                                "showDocumentDropDown"
                                              );
                                            }}
                                          >
                                            {option?.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Menu>}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                justifyContent="center"
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {getOptionData(config?.currencyOptions, currency)?.label}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                justifyContent="end"
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {offer_amount?.toFixed(2)}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                justifyContent="end"
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {award_amount?.toFixed(2)}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell align="left">
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                justifyContent="center"
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                  width="100%"
                                >
                                  <Typography variant="subtitle2">
                                    <Chip
                                      label={i18n.t(status)}
                                      onClick={(e) => {
                                        this.handleClick(
                                          e,
                                          row,
                                          index,
                                          "showStatusDropDown"
                                        );
                                      }}
                                      aria-controls={
                                        Boolean(this.state.showStatusDropDown)
                                          ? "basic-menu"
                                          : undefined
                                      }
                                      aria-haspopup="true"
                                      aria-expanded={
                                        Boolean(this.state.showStatusDropDown)
                                          ? "true"
                                          : undefined
                                      }
                                      style={{
                                        backgroundColor: getOptionData(
                                          this.statusOptions,
                                          status
                                        )?.color,
                                        color: getOptionData(
                                          this.statusOptions,
                                          status
                                        )?.textColor,
                                        cursor: "pointer",
                                      }}
                                      variant="outlined"
                                    />
                                    {<Menu
                                      id="basic-menu"
                                      open={Boolean(
                                        this.state.showStatusDropDown
                                      )}
                                      anchorEl={this.state.showStatusDropDown}
                                      onClose={() => {
                                        this.handleClose("showStatusDropDown");
                                      }}
                                    >
                                      {this.statusOptions?.map((option) => {
                                        return (
                                          <MenuItem
                                            value={option?.value}
                                            onClick={() => {
                                              this.handleOffersTableAction(
                                                "update",
                                                option?.value,
                                                this.state.offerData,
                                                "status",
                                                this.state.rowIndex
                                              );
                                              this.handleClose(
                                                "showStatusDropDown"
                                              );
                                            }}
                                          >
                                            {option?.label}
                                          </MenuItem>
                                        );
                                      })}
                                    </Menu>}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {date ? moment(date).format(
                                      i18n.t("OPTIONS.date_format")
                                    ) : "-"}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                sx={{ justifyContent: 'center' }}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {award_date ? moment(award_date).format(
                                      i18n.t("OPTIONS.date_format")
                                    ) : '-'}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                sx={{ justifyContent: 'center' }}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {validity ? moment(validity).format(
                                      i18n.t("OPTIONS.date_format")
                                    ) : '-'}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.airportId
                                  ? this.props.navigate(
                                    `/offers/${_id}?airport=${this?.airportId}`
                                  )
                                  : this.props.navigate(`/offers/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                sx={{ justifyContent: 'center' }}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {remarks}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>

                            <StyledTableCell align="right">
                              <IconButton
                                size="large"
                                color="inherit"
                                sx={{ padding: '2px' }}
                                onClick={(e) => {
                                  this.handleOffersTableAction("popOverAction", e, row);
                                }}
                              >
                                <Iconify icon={"eva:more-vertical-fill"} />
                              </IconButton>
                            </StyledTableCell>
                          </StyledTableRow>
                        </>
                      );
                    })}
                  {!this.state?.tableLoader && this.state?.offers?.length > 0 && <StyledTableRow
                    hover
                    tabIndex={-1}
                    className="scroller"
                    ref={this.attachScroller}
                    onMouseDown={this.onScroll}
                    onScroll={this.onMouseMove}
                    style={{
                      position: "sticky",
                      bottom: 0,
                      zIndex: 10,
                      backgroundColor: "white",
                    }}
                  >
                    {(
                      <StyledTableCell
                        component="th"
                        scope="row"
                        style={{
                          position: "sticky",
                          left: 0,
                          zIndex: 10,
                          backgroundColor: "white",
                          minWidth: "10rem",
                        }}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={6}
                        >
                          <Stack
                            direction="column"
                            spacing={0}
                          >
                            <Typography variant="subtitle1">
                              Total
                            </Typography>
                          </Stack>
                        </Stack>
                      </StyledTableCell>
                    )}
                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>

                    {(
                      <StyledTableCell
                        align="left"
                      >
                        <Stack
                          direction="row"
                          alignItems="centre"
                          spacing={2}
                        >
                          <Stack
                            direction="column"
                            spacing={0}
                            sx={{ typography: "body2" }}
                          >
                          </Stack>
                        </Stack>
                      </StyledTableCell>
                    )}

                    {(
                      <StyledTableCell
                        component="th"
                        scope="row"
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={2}
                        >
                          <Stack
                            direction="column"
                            spacing={0}
                            sx={{ typography: "body2" }}
                          >
                          </Stack>
                        </Stack>
                      </StyledTableCell>
                    )}

                    <StyledTableCell component="th" scope="row">
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>

                    <StyledTableCell component="th" scope="row">
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        justifyContent="center"
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                          width="100%"
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>

                    <StyledTableCell align="center">
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                        justifyContent="center"
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                          width="100%"
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                        justifyContent="end"
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>

                    <StyledTableCell align="centre">
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                        justifyContent="center"
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          width="100%"
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                        justifyContent="center"
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                        justifyContent="end"
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                          <Typography variant="subtitle1">
                            {(!isNaN(this.state?.total?.offer_amount)) ? this.state?.total?.offer_amount.toFixed(2) : null}
                          </Typography>
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                        justifyContent="end"
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                          <Typography variant="subtitle1">
                            {(!isNaN(this.state?.total?.award_amount)) ? this.state?.total?.award_amount.toFixed(2) : null}
                          </Typography>
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                        justifyContent="center"
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                          width="100%"
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>

                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>
                    <StyledTableCell
                      align="left"
                    >
                      <Stack
                        direction="row"
                        alignItems="centre"
                        spacing={2}
                      >
                        <Stack
                          direction="column"
                          spacing={0}
                          sx={{ typography: "body2" }}
                        >
                        </Stack>
                      </Stack>
                    </StyledTableCell>

                    <StyledTableCell align="right">
                    </StyledTableCell>
                  </StyledTableRow>}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state.offers?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        {this.state.offers?.length !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(this.state.total_offers / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  this.handleOffersTableAction("pagination", page);
                }}
                page={this.state.page}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Grid>
            <Grid item style={{ width: "10%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                <Select
                  value={this.state.limit}
                  label="Limit"
                  size="small"
                  onChange={async (e) => {
                    this.handleOffersTableAction("limit", e?.target?.value);
                  }}
                >
                  {this.tableLimitOptions?.map((limit) => {
                    return (
                      <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Popover
              open={Boolean(this.state.open)}
              anchorEl={this.state.open}
              onClose={() => {
                this.handleOffersTableAction("closeMenu");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              {<MenuItem
                onClick={() => {
                  this.handleOffersTableAction("closeMenu");
                  this.airportId
                    ? this.props.navigate(
                      `/offers/${this.state?.offer?._id}?airport=${this.airportId}`
                    )
                    : this.props.navigate("/offers/" + this.state?.offer?._id);
                }}
              >
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                {i18n.t("edit")}
              </MenuItem>}
              {<MenuItem
                onClick={() => {
                  this.handleOffersTableAction("deleteOffer");
                }}
              >
                <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </MenuItem>}
            </Popover>
            <Dialog
              open={this.state?.deleteOfferPopUp}
              onClose={() => {
                this.handleOffersTableAction("closeConfirmationPopUp");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    this.handleOffersTableAction("proceed");
                  }}
                >
                  {i18n.t("proceed")}
                </Button>
                <Button
                  onClick={() => {
                    this.handleOffersTableAction("closeConfirmationPopUp");
                  }}
                  autoFocus
                >
                  {i18n.t("cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();
  return <OffersCombinedTable {...props} navigate={navigate} params={params} />;
}
