import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Menu,
  Link,
  Popover,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
import { deleteTrainee, getTraineesList } from "../services/trainee.service";
import Swal from "sweetalert2";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const tableHeaders = [
  {
    id: "last_name",
    label: i18n.t("last_name"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "first_name",
    label: i18n.t("first_name"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "date_of_birth",
    label: i18n.t("date_of_birth"),
    alignCenter: false,
    sortable: true,
  },
  {
    id: "email",
    label: i18n.t("email"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },
  { id: "" },
];

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class TraineesCombinedTable extends React.Component {
  constructor(props) {
    super(props);
    this.airportId = props?.airportId;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.respOptions = config?.respOptions;
    this.confirmOptions = config?.confirmOptions;
    this.state = {
      trainees: [],
      trainee: {},
      page: 1,
      limit: 25,
      skip: 0,
      total_trainees: 0,
      tableLoader: false,
      pageLoader: false,
      orderBy: { sort_key: "airport", sort_order: true },
      deleteTraineePopup: false,
      showRespDropDown: false,
      showExecutedDropDown: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    this.enableEditOption = config?.enableEditOptionRenewal;
  }

  setStateAsync = (state) => {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  componentDidMount() {
    this.getTrainees();
  }


  handleTraineesTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state?.limit,
        page: data,
      });
      this.getTrainees();
    } else if (action === "limit") {
      await this.setState({
        limit: data,
      });
      this.getTrainees();
    } else if (action === "popOverAction") {
      this.setState({
        trainee: rowData,
        open: data?.target,
      });
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      await this.setState({
        orderBy: {
          sort_key: data,
          sort_order: !this.state?.orderBy?.sort_order,
        },
      });
      this.getTrainees();
    } else if (action === "closeMenu") {
      this.setState({
        open: null
      });
    } else if (action === "deleteTrainee") {
      this.handleCloseMenu();
      await this.setState({
        deleteTraineePopup: true,
        open: null,
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteTraineePopup: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteTraineePopup: false,
      });
      this.handleDeleteTrainee(this.state?.trainee._id);
    }
  };

  async getTrainees() {
    this.setStateAsync({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order
        ? "ASC"
        : "DESC",
    };
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    let trainees = await getTraineesList(query);
    if (trainees.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: trainees?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(trainees?.error_code))
          : trainees?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setStateAsync({
        tableLoader: false,
      });
      return;
    }

    await this.setStateAsync({
      tableLoader: false,
      trainees: trainees.airtrace_trainees,
      total_trainees: trainees.count,
    });
  }
  async handleDeleteTrainee(traineeId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteTrainee(traineeId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
      });
      return;
    }

    if (response) {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message,
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
      });
      await this.getTrainees();
      this.setState({
        pageLoader: false,
      });
    }
  }

  handleClick = (event, data, rowIndex, key) => {
    this.setState({
      [key]: event.currentTarget,
      traineeData: data,
      rowIndex,
    });
  };
  handleClose = (key) => {
    this.setState({
      [key]: null,
    });
  };

  render() {
    return (
      <>

        {(this.props?.showTitle) ? (
          <Typography variant="h4" gutterBottom>
            {i18n.t("trainees")}
          </Typography>
        ) : null}

        <Stack direction="row" spacing={2} style={{ marginBottom: 10 }} justifyContent={'flex-end'}>
          {(this.props?.showAdd) ? (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate(
                  `/airtrace/trainee/add?airport=${this.airportId}`
                );
              }}
            >
              {i18n.t("trainee")}
            </Button>
          ) : null}
        </Stack>


        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)", borderRadius: '3px', marginBottom: '20px' }}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer
              style={{ width: "100%", maxHeight: "calc(100vh - 255px)" }}
              hideScrollbars={false}
            >
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {tableHeaders?.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["airport"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        align={headCell.alignCenter
                          ? "center"
                          : headCell.alignRight
                            ? "right"
                            : "left"}
                        key={headCell.id}
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state?.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.handleTraineesTableAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                              headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this?.state?.trainees.map((row, index) => {
                      const {
                        _id,
                        airport,
                        first_name,
                        last_name,
                        email,
                        date_of_birth
                      } = row;

                      return (
                        <StyledTableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          onClick={() => {
                            this.handleTraineesTableAction(_id);
                          }}
                        >
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                      ? this.props.navigate(`/airtrace/trainee/${_id}?airport=${this?.airportId}`)
                                      : this.props.navigate(`/airtrace/trainee/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={last_name}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {last_name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                      ? this.props.navigate(`/airtrace/trainee/${_id}?airport=${this?.airportId}`)
                                      : this.props.navigate(`/airtrace/trainee/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={first_name}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {first_name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>


                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                      ? this.props.navigate(`/airtrace/trainee/${_id}?airport=${this?.airportId}`)
                                      : this.props.navigate(`/airtrace/trainee/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={date_of_birth}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {date_of_birth ? moment(
                                    date_of_birth
                                  ).format(i18n.t("OPTIONS.date_format")) : '-'}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.airportId
                                      ? this.props.navigate(`/airtrace/trainee/${_id}?airport=${this?.airportId}`)
                                      : this.props.navigate(`/airtrace/trainee/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={email}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {email}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>


                          {(
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{
                                position: "sticky",
                                left: 0,
                                background: "primary.light",
                                backgroundColor:
                                  index % 2 ? "white" : "#F6F7F8",
                                minWidth: "10rem",
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={6}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{
                                    typography: "body2",
                                    cursor: "pointer",
                                  }}
                                  onDoubleClick={() => {
                                    this.airportId
                                            ? this.props.navigate(`/airtrace/trainee/${_id}?airport=${this?.airportId}`)
                                            : this.props.navigate(`/airtrace/trainee/${_id}`);
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    <Link
                                      onClick={() => {
                                        this.props.navigate(
                                          `/airports/airportDetails/${row?.airport?._id}`
                                        );
                                      }}
                                    >
                                      {airport?.name}
                                    </Link>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                          )}

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              sx={{ padding: '2px' }}
                              color="inherit"
                              onClick={(e) => {
                                this.handleTraineesTableAction("popOverAction", e, row);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this?.state?.trainees?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        {this.state.trainees?.length !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(this.state.total_trainees / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  this.handleTraineesTableAction("pagination", page);
                }}
                page={this.state.page}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Grid>
            <Grid item style={{ width: "10%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                <Select
                  value={this.state.limit}
                  label="Limit"
                  size="small"
                  onChange={async (e) => {
                    this.handleTraineesTableAction("limit", e?.target?.value);
                  }}
                >
                  {this.tableLimitOptions?.map((limit) => {
                    return (
                      <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Popover
              open={Boolean(this.state.open)}
              anchorEl={this.state.open}
              onClose={() => {
                this.handleTraineesTableAction("closeMenu");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.handleTraineesTableAction("closeMenu");
                  this.airportId
                          ? this.props.navigate(`/airtrace/trainee/${this.state.trainee._id}?airport=${this?.airportId}`)
                          : this.props.navigate(`/airtrace/trainee/${this.state.trainee._id}`);
                }}
              >
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                {i18n.t("edit")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.handleTraineesTableAction("deleteTrainee");
                }}
              >
                <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </MenuItem>
            </Popover>
            <Dialog
              open={this.state?.deleteTraineePopup}
              onClose={() => {
                this.handleTraineesTableAction("closeConfirmationPopUp");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    this.handleTraineesTableAction("proceed");
                  }}
                >
                  {i18n.t("proceed")}
                </Button>
                <Button
                  onClick={() => {
                    this.handleTraineesTableAction("closeConfirmationPopUp");
                  }}
                  autoFocus
                >
                  {i18n.t("cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </>
    );
  }
}
// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <TraineesCombinedTable {...props} navigate={navigate} params={params} />;
}
