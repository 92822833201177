import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  Card,
  Paper,
  IconButton,
  Modal,
  Box,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Stack,
  Typography,
  Button,
  Backdrop,
} from "@mui/material";
import config from "../config";
import i18n from "../i18n";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate, useParams } from "react-router-dom";
import {
  getAirportList,
} from "../services/airport.service";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "../utils/messageCodeToMessage";
import { getTraineesList } from "../services/trainee.service";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class TrainingTraineeTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airportId: props.airportId,
      trainees: [],
      traineeDetails: {},
      tableLoader: false,
      deleteEditPopUpDetails: { show: false },
      mode: "",
      showTraineeDetailsModel: false,
      formErrors: {},
    };
    this.hideEditAddDeleteActions = props?.hideEditAddDeleteActions;
    this.useStyles = {
      modelClass: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      trainees: nextProps?.trainees,
    });
  }
  componentDidMount() {
    this.getAirportsOptions();
  }
  async tableActions(action, data, index) {
    if (action === "edit") {
       this.setState({
        formErrors: { traineeFormErrors: {} },
        traineeDetails: data,
        showTraineeDetailsModel: true,
        deleteEditPopUpDetails: { rowIndex: index },
        mode: "edit",
      },()=>this.getTrainees());
    } else if (action === "confirmDelete") {
      let temp = this.state?.trainees;
      let deleteIndex = this.state.deleteEditPopUpDetails?.rowIndex;
      delete temp[deleteIndex];
      let filteredTraineeDetails = temp?.filter(function (el) {
        return el != null;
      });
      await this.setState({
        showTraineeDetailsModel: false,
        trainees: filteredTraineeDetails,
        deleteEditPopUpDetails: { rowIndex: "" },
        mode: "edit",
      });
      this.props.saveTraineesData(filteredTraineeDetails);
    } else if (action === "delete") {
      this.setState({
        deleteEditPopUpDetails: { show: true, rowIndex: index },
      });
    }
  }
  async saveTraineeDetailsPopUp() {
    let error = await this.validateTraineeForm();
    if (Object?.keys(error)?.length > 0) {
      return;
    }
    this.setState({
      showTraineeDetailsModel: false,
      deleteEditPopUpDetails: { rowIndex: "" },
      mode: "",
    });
    if (this.state.mode === "edit") {
      this.handleTraineeDetails("editTraineeData");
    } else {
      this.handleTraineeDetails("addTraineeData");
    }
  }
  async handleTraineeDetails(action) {
    if (action === "addTraineeData") {
      let tempData = { ...this.state?.traineeDetails };
      let tempMergedData = [];
      if (this.state?.trainees?.length > 0) {
        tempMergedData = [...this.state?.trainees, tempData];
      } else {
        tempMergedData = [tempData];
      }
      let filteredTraineeDetails = tempMergedData?.filter(function (el) {
        return el != null;
      });
      this.setState({
        trainees: filteredTraineeDetails,
      });
      this.props.saveTraineesData(filteredTraineeDetails);
    } else if (action === "editTraineeData") {
      let editIndex = this.state.deleteEditPopUpDetails?.rowIndex;
      let tempData = this.state?.trainees;
      tempData[editIndex] = this.state?.traineeDetails;
      let filteredTraineeDetails = tempData?.filter(function (el) {
        return el != null;
      });
      this.setState({
        trainees: filteredTraineeDetails,
      });
      this.props.saveTraineesData(filteredTraineeDetails);
    }
  }

  async validateTraineeForm(field) {
    let error = { ...this.state.formErrors?.traineeFormErrors };
    if (field == null || field === "airport") {
      if (
        !this.state.traineeDetails?.airport?._id ||
        this.state.traineeDetails?.airport?._id === ""
      ) {
        error["airport"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["airport"];
      }
    }
    if (field == null || field === "trainee") {
      if (
        !this.state.traineeDetails?.trainee?._id ||
        this.state.traineeDetails?.trainee?._id === ""
      ) {
        error["trainee"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["trainee"];
      }
    }
    this.setState({
      formErrors: error,
    });
    return error;
  }
  async getAirportsOptions() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      return { label: airport?.name, _id: airport?._id };
    });

    this.setState({
      ...this.state,
      airportOptions,
    });
  }
  async getTrainees() {
    let query = { airport: this.state?.traineeDetails?.airport?._id };

    let trainees = await getTraineesList(query);
    if (trainees.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: trainees?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(trainees?.error_code))
          : trainees?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let traineeOptions = trainees?.airtrace_trainees?.map((trainee) => {
      return {
        label: `${trainee?.first_name} ${trainee?.last_name}`,
        _id: trainee?._id,
      };
    });
    this.setState({
      traineeOptions,
    });
  }

  render() {
    return (
      <>
        <Grid container spacing={2} style={{ margin: "10px 0", width: "100%" }}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={8} style={{ textAlign: "start", marginTop: "0" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                mb={2}
                mt={3}
              >
                <Typography variant="h4" gutterBottom>
                  {i18n.t("trainee_details")}
                </Typography>
              </Stack>
            </Grid>
            {!this?.hideEditAddDeleteActions && <Grid
              item
              xs={4}
              style={{ textAlign: "end", paddingRight: "25px" }}
            >
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                className="BtnWidth"
                onClick={() => {
                  this.setState({
                    showTraineeDetailsModel: true,
                    traineeDetails: {},
                    formErrors: { traineeFormErrors: {} },
                    mode: "add",
                  });
                }}
              >
                {i18n.t("trainee")}
              </Button>
            </Grid>}
          </Grid>
          <Card
            style={{
              boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
              width: "100%",
              borderRadius: "3px",
            }}
          >
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <TableContainer>
                <Table stickyHeader size="small" aria-label="customized table">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align={"left"}>
                        <Typography variant="subtitle2">
                          {i18n.t("airport")}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell align={"left"}>
                        <Typography variant="subtitle2">
                          {i18n.t("trainee")}
                        </Typography>
                      </StyledTableCell>
                      {!this?.hideEditAddDeleteActions && <StyledTableCell align={"center"}>
                        <Typography variant="subtitle2">
                          {i18n.t("actions")}
                        </Typography>
                      </StyledTableCell>}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state?.trainees?.map((row, index) => {
                      const { _id, airport, trainee } = row;

                      return (
                        <StyledTableRow hover key={_id} tabIndex={-1}>
                          <StyledTableCell
                            component="th"
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              background: index % 2 ? "white" : "#F6F7F8",
                              minWidth: "10rem",
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {
                                   airport?.name
                                  }
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {trainee?.name}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          {!this?.hideEditAddDeleteActions && 
                          <StyledTableCell align="right">
                            <div
                              className="col-2"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.tableActions("edit", row, index);
                                }}
                              >
                                <IconButton>
                                  <EditIcon />
                                </IconButton>
                              </Typography>
                              <Typography
                                style={{ cursor: "pointer" }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.tableActions("delete", "", index);
                                }}
                              >
                                <IconButton sx={{ padding: "2px" }}>
                                  <DeleteOutlineIcon style={{ color: "red" }} />
                                </IconButton>
                              </Typography>
                            </div>
                          </StyledTableCell>
                          }
                        </StyledTableRow>
                      );
                    })}
                    {this.state?.tableLoader && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    )}

                    {!this.state?.tableLoader &&
                      this.state?.trainees?.length === 0 && (
                        <TableRow>
                          <TableCell
                            style={{ textAlign: "center" }}
                            colSpan={"100%"}
                          >
                            {i18n.t('no_records_found')}
                          </TableCell>
                        </TableRow>
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Card>
        </Grid>

        <Grid item xs={12} md={6}>
          <Modal
            open={this.state.showTraineeDetailsModel}
            onClose={() => {
              this.setState({
                showTraineeDetailsModel: false,
                deleteEditPopUpDetails: { rowIndex: "" },
              });
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={this.useStyles?.modelClass}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {i18n.t("trainee_details")}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <Autocomplete
                        disablePortal
                        noOptionsText={i18n.t('no_options')}
                        onOpen={async () => {
                          await this.getAirportsOptions();
                        }}
                        options={
                          !this.state?.airportOptions
                            ? [{ label: "Loading...", id: 0 }]
                            : this.state?.airportOptions
                        }
                        onChange={async (option, value) => {
                          await this.setState({
                            traineeDetails: {
                              ...this.state?.traineeDetails,
                              airport: {
                                name: value?.label,
                                _id: value ? value?._id : "",
                              },
                            },
                          });
                          await this.validateTraineeForm("airport");
                          this.getTrainees();
                        }}
                        value={this.state?.traineeDetails?.airport?.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={i18n.t("airport")}
                            error={
                              this.state.formErrors?.airport ? true : false
                            }
                            helperText={this.state.formErrors?.airport}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={1}>
                      <Autocomplete
                        required
                        noOptionsText={i18n.t('no_options')}
                        disablePortal
                        disabled={!this.state?.traineeDetails?.airport?.name}
                        options={
                          !this.state?.traineeOptions
                            ? [{ label: "Loading...", id: 0 }]
                            : this.state?.traineeOptions
                        }
                        onChange={async (option, value) => {
                          await this.setState({
                            traineeDetails: {
                              ...this.state?.traineeDetails,
                              trainee: {
                                name: value?.label,
                                _id: value ? value?._id : "",
                              },
                            },
                          });
                          await this.validateTraineeForm("trainee");
                        }}
                        value={this.state?.traineeDetails?.trainee?.name || ""}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            required
                            label={i18n.t("trainee")}
                            error={
                              this.state.formErrors?.trainee ? true : false
                            }
                            helperText={this.state.formErrors?.trainee}
                          />
                        )}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Typography>
              <Grid container justifyContent="end">
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.saveTraineeDetailsPopUp();
                    }}
                  >
                    {i18n.t("Ok")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.setState({
                        showTraineeDetailsModel: false,
                        deleteEditPopUpDetails: { rowIndex: "" },
                        mode: "",
                      });
                    }}
                  >
                    {i18n.t("cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
        <Dialog
          open={this.state?.deleteEditPopUpDetails?.show}
          onClose={() => {
            this.setState({
              deleteEditPopUpDetails: {
                show: false,
              },
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.tableActions("confirmDelete")}>
            {i18n.t("proceed")}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  deleteEditPopUpDetails: {
                    show: false,
                  },
                });
              }}
              autoFocus
            >
              {i18n.t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return (
    <TrainingTraineeTable {...props} navigate={navigate} params={params} />
  );
}
