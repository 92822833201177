import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../../utils/debounce";
import config from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import Iconify from "../../../components/iconify/Iconify";
import TraineesTable from "../../../SharedComponent/TraineesTable";
import { getAirportList } from "../../../services/airport.service";
import { deleteTrainee, exportTrainees, getTraineesList } from "../../../services/trainee.service";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';

const TABLE_HEAD = [
  {
    id: "last_name",
    label: i18n.t("last_name"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "first_name",
    label: i18n.t("first_name"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "date_of_birth",
    label: i18n.t("date_of_birth"),
    alignCenter: false,
    sortable: true,
  },
  {
    id: "email",
    label: i18n.t("email"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },  
  { id: "" },
];
class Trainees extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      trainees: [],
      trainee: {},
      open: null,
      page: 1,
      filtersOptions: [],
      search: "",
      limit: 100,
      skip: 0,
      total_trainees: 0,
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: {},
      orderBy: { sort_key: "airport", sort_order: true },
      deleteTraineePopup: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
  }

  async componentDidMount() {
    this.getAirportsForFilter();
    this.getTrainees();
  }
  async getAirportsForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      if (airport?._id === this.airportId) {
        this.setState({
          filters: {
            airport: { label: airport?.name, value: airport?._id },
          },
          expanded: true,
        });
      }
      return { label: airport?.name, value: airport?._id };
    });

    this.setState({
      filtersOptions: { ...this.state.filtersOptions, airportOptions },
    });
  }

  async getTrainees(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters[key]?.value) {
        query[key] = this.state?.filters[key]?.value;
      } else {
        delete query[key];
      }
    });
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }
    let trainees = await getTraineesList(query);
    if (trainees.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: trainees?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(trainees?.error_code))
          : trainees?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState({
      tableLoader: false,
      trainees: trainees.airtrace_trainees,
      total_trainees: trainees.count,
    });
  }
  async handleDeleteTrainee(traineeId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteTrainee(traineeId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
      });
      return;
    }

    if (response) {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message,
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
      });
      await this.getTrainees();
      this.setState({
        pageLoader: false,
      });
    }
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getTraineesDataOnchange = debounce(() => {
    this.getTrainees("filters");
  }, 500);
  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getTrainees();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getTrainees();
    } else if (action === "popOverAction") {
      this.setState({
        trainee: rowData,
      });
      this.handleOpenMenu(data);
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
        await this.setState({
          orderBy: {
            sort_key: data,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      this.getTrainees();
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "deleteTrainee") {
      this.handleCloseMenu();
      await this.setState({
        deleteTraineePopup: true,
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteTraineePopup: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteTraineePopup: false,
      });
      this.handleDeleteTrainee(this.state.trainee._id);
    }
  };
  async exportTraineesData() {
    this.setState({
      pageLoader: true,
    });
    let query = {};
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters?.[key]?.value) {
        query[key] = this.state.filters[key].value;
      } else {
        delete query[key];
      }
    });
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    let response = await exportTrainees(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false,
      });
      return;
    }else{
      const newBlob = new Blob([response]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${i18n.t('trainees')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      
      this.setState({
        pageLoader: false,
      });
    }

  }

  render() {
    return (
      <Container maxWidth="xxl" sx={{padding:'0!important'}}>
        <Helmet>
          <title>
            {" "}
            {i18n.t("trainee")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={this.state.expanded}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      this.setState({
                        search: e?.target?.value,
                      });
                      this.getTraineesDataOnchange();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  <IconButton
                    onClick={() => {
                      this.handleExpandClick();
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.airportOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.airportOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  airport: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getTraineesDataOnchange();
                            }}
                            value={this.state?.filters?.airport?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("airport")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{marginRight:"10px"}}>
          <Button
              variant="contained"
              startIcon={<SystemUpdateAltIcon />}
              className="BtnWidth"
              onClick={() => {
                this.exportTraineesData();
              }}
            >
              {i18n.t("export_trainees")}
            </Button>
          </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/airtrace/trainee/add");
              }}
            >
              {i18n.t("trainee")}
            </Button>

          </Stack>
        </Grid>

        <TraineesTable
          data={this.state}
          onAction={this.handleTableAction}
          tableHeaders={TABLE_HEAD}
        />

        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Trainees {...props} navigate={navigate} params={params} />;
}
