import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Input,
  InputAdornment,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../../utils/debounce";
import config from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import Iconify from "../../../components/iconify/Iconify";
import {
  deleteAirTraceOffer,
  editAirTraceOfferDetail,
  getAirTraceOfferList,
} from "../../../services/airTraceOffer.service";
import OffersTable from "../../../SharedComponent/OffersTable";
import { getAirportList } from "../../../services/airport.service";
import AirTraceOffersTable from "../../../SharedComponent/AirTraceOffersTable";
import { getCoursesList } from "../../../services/course.service";
import { getOptionData } from "../../../utils/commonUtils";

const TABLE_HEAD = [
  {
    id: "airport",
    label: i18n.t("airport"),
    alignRight: false,
    sortable: true,
  },
  { id: 'course', label: i18n.t('course'), alignRight: false, sortable: true },
  {
    id: "description",
    label: i18n.t("description"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_OACI",
    label: i18n.t("code_OACI"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "code_IATA",
    label: i18n.t("code_IATA"),
    alignRight: false,
    sortable: true,
  },
  { id: "resp", label: i18n.t("resp"), alignRight: false, sortable: true },
  {
    id: "division",
    label: i18n.t("division"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "category",
    label: i18n.t("category"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "mandate_number",
    label: i18n.t("mandate_number"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "currency",
    label: i18n.t("currency"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "offer_amount",
    label: i18n.t("offer_amount"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "award_amount",
    label: i18n.t("award_amount"),
    alignRight: true,
    sortable: true,
  },
  {
    id: "date",
    label: i18n.t("date_of_offer"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "award_date",
    label: i18n.t("award_date"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "validity",
    label: i18n.t("validity"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "remarks",
    label: i18n.t("remarks"),
    alignRight: false,
    sortable: true,
  },
  { id: "" },
];

class AirTraceOffers extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      airTraceOffers: [],
      airTraceOffer: {},
      open: null,
      page: 1,
      search: "",
      limit: 100,
      skip: 0,
      total_airTraceOffers: 0,
      expanded: false,
      deleteAirTraceOfferPopUp: false,
      tableLoader: false,
      pageLoader: false,
      filters: {
        airport: "",
        resp: "",
        division: "",
        category: "",
        status: "",
      },
      filtersOptions: {
        resp: config?.respOptions,
        divisions: config?.divisionOptions,
        categories: config?.categoriesOptions,
        statusOptions: config?.statusOptions,
      },
      orderBy: { sort_key: "airport", sort_order: true },
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
  }

  async componentDidMount() {
    await this.getAirTraceOffers();
    await this.getAirportsForFilter();
  }

  async getAirTraceOffers(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
    };
    Object?.keys(this.state?.filters)?.map((key) => {
      if (key === "airport") {
        if (this.state?.filters[key]?.value) {
          query[key] = this.state?.filters[key]?.value;
        } else {
          delete query[key];
        }
      } else if (this.state?.filters[key] !== "") {
        query[key] = this.state?.filters[key];
      }
    });
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }

    if (this.state?.orderBy?.sort_key) {
      query.sort_key = this.state?.orderBy?.sort_key;
      query.sort_order = this.state?.orderBy?.sort_order ? "ASC" : "DESC";
    }

    let airTraceOffersData = await getAirTraceOfferList(query);
    if (airTraceOffersData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: airTraceOffersData?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(airTraceOffersData?.error_code))
          : airTraceOffersData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState({
      tableLoader: false,
      airTraceOffers: airTraceOffersData.airtrace_offers,
      total_airTraceOffers: airTraceOffersData.count,
    },()=>{
      this.getAirTraceCourses();
    });
  }
  async getAirTraceCourses() {
    let query = {
      minimal: true,
    };

    let response = await getCoursesList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
        ? i18n.t(convertMessageCodeToMessage(response?.error_code))
        : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.airtrace_courses?.map((el) => {
      let domain=getOptionData(config?.domainOptions,el?.domain)
      let subject=getOptionData(domain?.subject,el?.subject)
      return { label: `${domain?.label}-${subject?.label}`, value: el?._id };
    });
    if (response.airtrace_courses) {
      let tempData = this.state?.airTraceOffers?.map((airTraceOffer)=>{
        return({...airTraceOffer,course:getOptionData(options,airTraceOffer?.course)})
      })
      this.setState({
        airTraceOffers:tempData
      });
    } 
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getAirTraceOffersDataOnchange = debounce(() => {
    this.getAirTraceOffers("filters");
  }, 500);

  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getAirTraceOffers();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getAirTraceOffers();
    } else if (action === "popOverAction") {
      this.setState({
        airTraceOffer: rowData,
      });
      this.handleOpenMenu(data);
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      if (config?.airportJsonKeys.includes(data.toLowerCase())) {
        await this.setState({
          orderBy: {
            sort_key:
              data === "airport"
                ? `airport_name`
                : `airport_${data.toLowerCase()}`,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      } else {
        await this.setState({
          orderBy: {
            sort_key: data,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      }
      this.getAirTraceOffers();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.airTraceOffers;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        airTraceOffers: tempData,
      });
      this.updateAirTraceOfferData(payload, payload?._id, backUpData);
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "deleteAirTraceOffer") {
      this.handleCloseMenu();
      await this.setState({
        deleteAirTraceOfferPopUp: true,
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteAirTraceOfferPopUp: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteAirTraceOfferPopUp: false,
      });
      this.handleAirTraceDeleteOffer(this.state.airTraceOffer._id);
    }
  };
  async updateAirTraceOfferData(payload, airTraceOfferId, backUpData) {
    let response = await editOfferDetail(airTraceOfferId, payload);
    if (response && response.error) {
      let tempData = this.state.airTraceOffers;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        ...this.state,
        airTraceOffers: tempData,
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.airtrace_offers) {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("airTraceOffer_updated_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }
  async getAirportsForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      if (airport?._id === this.airportId) {
        this.setState({
          filters: {
            airport: { label: airport?.name, value: airport?._id },
          },
          expanded: true,
        });
      }
      return { label: airport?.name, value: airport?._id };
    });

    this.setState({
      filtersOptions: { ...this.state.filtersOptions, airportOptions },
    });
  }
  async handleAirTraceDeleteOffer(airTraceOfferId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteAirTraceOffer(airTraceOfferId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response) {
      await this.getAirTraceOffers();
      this.setState({
        pageLoader: false,
      });
    }
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("air_trace_offers")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={this.state.expanded}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      this.setState({
                        search: e?.target?.value,
                      });
                      this.getAirTraceOffersDataOnchange();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  <IconButton
                    onClick={() => {
                      this.handleExpandClick();
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.airportOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.airportOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  airport: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getAirTraceOffersDataOnchange();
                            }}
                            value={this.state?.filters?.airport?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("airport")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.resp
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.resp
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  resp: value?.value ? value?.value : "",
                                },
                              });
                              this.getAirTraceOffersDataOnchange();
                            }}
                            value={this.state?.filters?.resp || ""}
                            renderInput={(params) => (
                              <TextField {...params} label={i18n.t("resp")} />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.divisions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.divisions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  division: value?.value ? value?.value : "",
                                },
                              });
                              this.getAirTraceOffersDataOnchange();
                            }}
                            value={this.state?.filters?.division || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("division")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.categories
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.categories
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  category: value?.value ? value?.value : "",
                                },
                              });
                              this.getAirTraceOffersDataOnchange();
                            }}
                            value={i18n.t(this.state?.filters?.category) || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("category")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            noOptionsText={i18n.t('no_options')}
                            options={
                              !this.state?.filtersOptions?.statusOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.statusOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  status: value?.value ? value?.value : "",
                                },
                              });
                              this.getAirTraceOffersDataOnchange();
                            }}
                            value={i18n.t(this.state?.filters?.status) || ""}
                            renderInput={(params) => (
                              <TextField {...params} label={i18n.t("status")} />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/airtrace/air_trace_offer/add");
              }}
            >
              {i18n.t("air_trace_offer")}
            </Button>
          </Stack>
        </Grid>

        <AirTraceOffersTable
          data={this.state}
          onAction={this.handleTableAction}
          tableHeaders={TABLE_HEAD}
        />
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AirTraceOffers {...props} navigate={navigate} params={params} />;
}
