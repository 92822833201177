import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Popover,
  Link,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { getOptionData } from "../utils/commonUtils";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class HandrailsTable extends React.Component {
  constructor(props) {
    super(props);
    this.airportId = props?.airportId;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.onAction = props?.onAction;
    this.tableHeaders = props?.tableHeaders;
    this.tableLimitOptions = config?.tableLimitOptions;


    this.state = {
      ...props.data,
      handrailData: {},
      isScrolling: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "11",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps?.data });
  }
  
  handleDropdown = (event, data, rowIndex, key) => {
    this.setState({
      ...this.state,
      [key]: event.currentTarget,
      handrail: data,
      rowIndex,
    });
  };
  handleCloseDropdown = (key) => {
    this.setState({
      ...this.state,
      [key]: null,
    });
  };

  handleClick = (event, data, rowIndex, key) => {
    this.setState({
      ...this.state,
      [key]: event.currentTarget,
      handrailData: data,
      rowIndex,
    });
  };
  handleClose = (key) => {
    this.setState({
      ...this.state,
      [key]: null,
    });
  };
  handleRowClick = (handrailId) => {
    this.props.navigate(`/handrails/${handrailId}`);
  };

  render() {
    return (
      <>
        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" ,borderRadius:'3px'}}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer style={{ width: "100%" , maxHeight: 'calc(100vh - 255px)'}} hideScrollbars={false}>
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    {this.tableHeaders.map((headCell) => (
                      <StyledTableCell
                      style={
                        ["name"].includes(headCell.id)
                          ? this.useStyles?.sticky
                          : {}
                      }
                        key={headCell.id}
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                            ? "right"
                            : "left"
                        }
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.onAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy?.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this.state?.handrails?.map((row, index) => {
                      const {
                        _id,
                        airport,
                        offer_number,
                        date,
                        hour,
                        trades,
                        information,
                        importance
                      } = row;

                      return (
                        <StyledTableRow key={_id} tabIndex={-1}>
                          <StyledTableCell
                            scope="row"
                            style={{
                              position: "sticky",
                              left: 0,
                              backgroundColor: index % 2 ? "white" : "#F6F7F8",
                              minWidth: "10rem",
                              cursor: "pointer",
                            }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={6}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                <Link onClick={()=> this.props.navigate(`/airports/airportDetails/${airport?._id}`)}>{airport?.name}</Link>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                <Link
                                      onClick={() => {
                                        this.props.navigate(
                                          `/offers/${offer_number?._id}`
                                        );
                                      }}
                                    >
                                      {offer_number?.mandate_number}
                                    </Link>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align={"center"}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              style={{ margin: "auto", display: "block" }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {moment(date).format(i18n.t("OPTIONS.date_format"))}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={hour}
                              style={{ margin: "auto", display: "block" }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {moment(hour, 'HH:mm').format(i18n.t("OPTIONS.time_format"))}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="center"
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              key={trades}
                              justifyContent='center'
                              style={{ display: "flex" }}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {i18n.t(trades)}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                         

                          <StyledTableCell
                            align="right"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.handleRowClick(_id);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent='center'
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {information}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent='center'
                              sx={{display:'flex'}}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                style={{ display: "block" }}
                              >
                                <Typography variant="subtitle2">
                                  <span
                                   style={{
                                      backgroundColor: getOptionData(
                                        config?.importanceOptions,
                                        importance
                                      )?.color,
                                      color: getOptionData(
                                        config?.importanceOptions,
                                        importance
                                      )?.textColor,
                                      padding: 5,
                                      borderRadius: 5,
                                      fontSize: 12,
                                      textWrap: "nowrap",
                                      fontWeight: "bold",
                                      cursor: "pointer",
                                    }}
                                    onClick={(e) => {
                                      this.handleDropdown(
                                        e,
                                        row,
                                        index,
                                        "showImportanceDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showImportanceDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showImportanceDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                  >
                                    {i18n.t(importance)}
                                  </span>
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showImportanceDropDown
                                    )}
                                    anchorEl={this.state.showImportanceDropDown}
                                    onClose={() => {
                                      this.handleCloseDropdown(
                                        "showImportanceDropDown"
                                      );
                                    }}
                                  >
                                    {config?.importanceOptions?.map((option) => {
                                      return (
                                        <MenuItem
                                          value={option?.value}
                                          onClick={() => {
                                            this.onAction(
                                              "update",
                                              option?.value,
                                              this.state.handrail,
                                              "importance",
                                              this.state.rowIndex
                                            );
                                            this.handleCloseDropdown(
                                              "showImportanceDropDown"
                                            );
                                          }}
                                        >
                                          {option?.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              sx={{padding:'2px'}}
                              color="inherit"
                              onClick={(e) => {
                                this.onAction("popOverAction", e, row);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state.handrails?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        {this.state.handrails?.length !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(this.state.total_handrails / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  this.onAction("pagination", page);
                }}
                page={this.state.page}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Grid>

            <Grid item style={{ width: "10%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                <Select
                  value={this.state.limit}
                  label="Limit"
                  size="small"
                  onChange={async (e) => {
                    this.onAction("limit", e?.target?.value);
                  }}
                >
                  {this.tableLimitOptions?.map((limit) => {
                    return (
                      <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Popover
              open={Boolean(this.state.open)}
              anchorEl={this.state.open}
              onClose={() => {
                this.onAction("closeMenu");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.onAction("closeMenu");
                  this.props.navigate("/handrails/" + this.state.handrail._id);
                }}
              >
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                {i18n.t("edit")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.onAction("deleteHandrail");
                }}
              >
                <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </MenuItem>
            </Popover>
            <Dialog
              open={this.state?.deleteHandrailPopUp}
              onClose={() => {
                this.onAction("closeConfirmationPopUp");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    this.onAction("proceed");
                  }}
                >
                  {i18n.t("proceed")}
                </Button>
                <Button
                  onClick={() => {
                    this.onAction("closeConfirmationPopUp");
                  }}
                  autoFocus
                >
                  {i18n.t("cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();
  return <HandrailsTable {...props} navigate={navigate} params={params} />;
}
