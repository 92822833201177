import config from "src/config";
import axios from 'axios';
import i18n from '../i18n';

const getRequestHeaders = () => {
    try {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'token': localStorage.getItem('token'),
            'Accept-Language':i18n.language
        }
    } catch (error) {
        return null;
    }
}

const getQueryStringFromObject = (obj) => {
    let queryString = '';
    let queryStringArray = [];
    for (const [key, value] of Object.entries(obj)) {
        queryStringArray.push(`${key}=${value}`);
    }
    queryString = queryStringArray.join('&');
    return queryString;
}

const getRolesForSelection = (maxValue=null, minValue=5) => {
    let current_role_key = localStorage.getItem('role');

    if(!maxValue) {
        let temp = Object.keys(config.ROLES).find(role => config.ROLES[role].key === current_role_key);
        let current_role = config.ROLES[temp];
        maxValue = current_role.value;
    }

    let roles = [];
    for (const [key, value] of Object.entries(config.ROLES)) {
        if(value.value < maxValue && value.value >= minValue) {
            roles.push({
                id: value.key,
                label: value.key
            });
        }
    }
    return roles;
}

const getRestaurantTypesForSelection = () => {
    let restaurant_types = [];
    for (const [key, value] of Object.entries(config.RESTAURANT_TYPES)) {
        restaurant_types.push({
            id: value.key,
            label: value.key
        });
    }
    return restaurant_types;
}

const getItemTagsForSelection = () => {
    let item_tags = [];
    for (const [key, value] of Object.entries(config.ITEM_TAGS)) {
        item_tags.push({
            id: value.key,
            label: value.key
        });
    }
    return item_tags;
}
const getCountries = async () => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/countries');
        } catch (error) {
            return error.response?.data;
        }
        return response?.data;
    } catch (error) {
        return { error: error.message };
    }
}

const getContinents = async () => {
    try {
        let response;
        try {
            response = await axios.get( config.SERVER_BASE_URL + '/continents');
        } catch (error) {
            return error.response?.data;
        }
        return response?.data;
    } catch (error) {
        return { error: error.message };
    }
}

export {
    getRequestHeaders,
    getQueryStringFromObject,
    getRolesForSelection,
    getRestaurantTypesForSelection,
    getItemTagsForSelection,
    getCountries,
    getContinents
}