import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Input,
  InputAdornment,
  Grid,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../../utils/debounce";
import config from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import Iconify from "../../../components/iconify/Iconify";
import {
  deleteTraining,
  editTrainingDetail,
  exportTrainings,
  getTrainingsList,
} from "../../../services/training.service";
import { getAirportList } from "../../../services/airport.service";
import TrainingsTable from "../../../SharedComponent/TrainingsTable";
import { getCoursesList } from "../../../services/course.service";
import { getOptionData } from "../../../utils/commonUtils";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

const TABLE_HEAD = [
  {
    id: "course",
    label: i18n.t("course_id"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "training_agreement",
    label: i18n.t("training_agreement"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "agreement_date",
    label: i18n.t("agreement_date"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "training_duration",
    label: i18n.t("training_duration"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "training_start_date",
    label: i18n.t("training_start_date"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "training_end_date",
    label: i18n.t("training_end_date"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "type_of_certificate_issued",
    label: i18n.t("type_of_certificate_issued"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "certificate_issue_date",
    label: i18n.t("certificate_issue_date"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "remarks",
    label: i18n.t("remarks"),
    alignCenter: true,
    sortable: true,
  },
  { id: "" },
];

class Trainings extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      trainings: [],
      training: {},
      open: null,
      page: 1,
      search: "",
      limit: 100,
      skip: 0,
      total_trainings: 0,
      expanded: false,
      deleteTrainingPopUp: false,
      tableLoader: false,
      pageLoader: false,
      filters: {
        airport: "",
        resp: "",
        division: "",
        category: "",
        status: "",
      },
      filtersOptions: {
        resp: config?.respOptions,
        divisions: config?.divisionOptions,
        categories: config?.categoriesOptions,
        statusOptions: config?.statusOptions,
      },
      orderBy: { sort_key: "airport", sort_order: true },
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
  }

  async componentDidMount() {
    await this.getTrainingsData();
    await this.getAirportsForFilter();
  }

  async getTrainingsData(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
    };
    Object?.keys(this.state?.filters)?.map((key) => {
      if (key === "airport") {
        if (this.state?.filters[key]?.value) {
          query[key] = this.state?.filters[key]?.value;
        } else {
          delete query[key];
        }
      } else if (this.state?.filters[key] !== "") {
        query[key] = this.state?.filters[key];
      }
    });
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }

    if (this.state?.orderBy?.sort_key) {
      query.sort_key = this.state?.orderBy?.sort_key;
      query.sort_order = this.state?.orderBy?.sort_order ? "ASC" : "DESC";
    }

    let trainingsData = await getTrainingsList(query);
    if (trainingsData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: trainingsData?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(trainingsData?.error_code))
          : trainingsData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState(
      {
        tableLoader: false,
        trainings: trainingsData.trainings,
        total_trainings: trainingsData.count,
      },
      () => {
        this.getAirTraceCourses();
      }
    );
  }
  async getAirTraceCourses() {
    let query = {
      minimal: true,
    };

    let response = await getCoursesList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.airtrace_courses?.map((el) => {
      let domain = getOptionData(config?.domainOptions, el?.domain);
      let subject = getOptionData(domain?.subject, el?.subject);
      return { label: `${domain?.label}-${subject?.label}`, value: el?._id };
    });
    if (response.airtrace_courses) {
      let tempData = this.state?.trainings?.map((training) => {
        return {
          ...training,
          course: getOptionData(options, training?.course),
        };
      });
      this.setState({
        trainings: tempData,
      });
    }
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getTrainingsDataOnchange = debounce(() => {
    this.getTrainingsData("filters");
  }, 500);

  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getTrainingsData();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getTrainingsData();
    } else if (action === "popOverAction") {
      this.setState({
        training: rowData,
      });
      this.handleOpenMenu(data);
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      if (config?.airportJsonKeys.includes(data.toLowerCase())) {
        await this.setState({
          orderBy: {
            sort_key:
              data === "airport"
                ? `airport_name`
                : `airport_${data.toLowerCase()}`,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      } else {
        await this.setState({
          orderBy: {
            sort_key: data,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      }
      this.getTrainingsData();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.trainings;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        trainings: tempData,
      });
      this.updateTrainingData(payload, payload?._id, backUpData);
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "deleteTraining") {
      this.handleCloseMenu();
      await this.setState({
        deleteTrainingPopUp: true,
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteTrainingPopUp: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteTrainingPopUp: false,
      });
      this.handleTrainingDelete(this.state.training._id);
    }
  };
  async updateTrainingData(payload, training_id, backUpData) {
    let response = await editTrainingDetail(training_id, payload);
    if (response && response.error) {
      let tempData = this.state.trainings;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        ...this.state,
        trainings: tempData,
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.trainings) {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: i18n.t("training_updated_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }
  async getAirportsForFilter() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let airportOptions = response?.map((airport) => {
      if (airport?._id === this.airportId) {
        this.setState({
          filters: {
            airport: { label: airport?.name, value: airport?._id },
          },
          expanded: true,
        });
      }
      return { label: airport?.name, value: airport?._id };
    });

    this.setState({
      filtersOptions: { ...this.state.filtersOptions, airportOptions },
    });
  }
  async handleTrainingDelete(training_id) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteTraining(training_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response) {
      await this.getTrainingsData();
      this.setState({
        pageLoader: false,
      });
    }
  }

  async exportTrainingsData() {
    this.setState({
      pageLoader: true,
    });
    let query = {};
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters?.[key]?.value) {
        query[key] = this.state.filters[key].value;
      } else {
        delete query[key];
      }
    });
    if (this.state.search) {
      query.search = this.state.search;
    }
    let response;
    if (Object.keys(query)?.length > 0) {
      response = await exportTrainings(query);
    } else {
      response = await exportTrainings();
    }
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false,
      });
      return;
    } else {
      const newBlob = new Blob([response]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${i18n.t("trainings")}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      this.setState({
        pageLoader: false,
      });
    }
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("trainings")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={this.state.expanded}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={(e) => {
                      this.setState({
                        search: e?.target?.value,
                      });
                      this.getTrainingsDataOnchange();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  {/* <IconButton
                    onClick={() => {
                      this.handleExpandClick();
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton> */}
                </AccordionSummary>
                {/* <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.airportOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.airportOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  airport: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getTrainingsDataOnchange();
                            }}
                            value={this.state?.filters?.airport?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("airport")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.resp
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.resp
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  resp: value?.value ? value?.value : "",
                                },
                              });
                              this.getTrainingsDataOnchange();
                            }}
                            value={this.state?.filters?.resp || ""}
                            renderInput={(params) => (
                              <TextField {...params} label={i18n.t("resp")} />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.divisions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.divisions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  division: value?.value ? value?.value : "",
                                },
                              });
                              this.getTrainingsDataOnchange();
                            }}
                            value={this.state?.filters?.division || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("division")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.categories
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.categories
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  category: value?.value ? value?.value : "",
                                },
                              });
                              this.getTrainingsDataOnchange();
                            }}
                            value={i18n.t(this.state?.filters?.category) || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("category")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            required
                            disablePortal
                            options={
                              !this.state?.filtersOptions?.statusOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.statusOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  status: value?.value ? value?.value : "",
                                },
                              });
                              this.getTrainingsDataOnchange();
                            }}
                            value={i18n.t(this.state?.filters?.status) || ""}
                            renderInput={(params) => (
                              <TextField {...params} label={i18n.t("status")} />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails> */}
              </Accordion>
            </div>
            <div style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                startIcon={<SystemUpdateAltIcon />}
                className="BtnWidth"
                onClick={() => {
                  this.exportTrainingsData();
                }}
              >
                {i18n.t("export_trainings")}
              </Button>
            </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/airtrace/training/add");
              }}
            >
              {i18n.t("training")}
            </Button>
          </Stack>
        </Grid>

        <TrainingsTable
          data={this.state}
          onAction={this.handleTableAction}
          tableHeaders={TABLE_HEAD}
        />
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Trainings {...props} navigate={navigate} params={params} />;
}
