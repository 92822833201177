import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import BadgeIcon from "@mui/icons-material/Badge";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import config from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import {
  addTrainee,
  getTraineeDetail,
  editTraineeDetail,
} from "../../../services/trainee.service";
import { getAirportList } from "../../../services/airport.service";
import TraineeTrainingsTable from "../../../SharedComponent/TraineeTrainingsTable";
import {
  deleteTraining,
  getTrainingsList,
} from "../../../services/training.service";
import moment from "moment";

const TABLE_HEAD = [
  {
    id: "course",
    label: i18n.t("course"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "training_start_date",
    label: i18n.t("training_start_date"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "training_end_date",
    label: i18n.t("training_end_date"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "training_duration",
    label: i18n.t("training_duration"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "type_of_certificate_issued",
    label: i18n.t("type_of_certificate_issued"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "certificate_issue_date",
    label: i18n.t("certificate_issue_date"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "agreement_date",
    label: i18n.t("agreement_date"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "remarks",
    label: i18n.t("remarks"),
    alignCenter: true,
    sortable: true,
  },
  {
    id: "training_agreement",
    label: i18n.t("training_agreement"),
    alignCenter: true,
    sortable: true,
  },
  { id: "" },
];
class AddEditTrainee extends React.Component {
  constructor(props) {
    super(props);
    this.respOptions = config?.respOptions;
    this.state = {
      mode: props?.params?.trainee_id ? "edit" : "add",
      trainee_id: props.params?.trainee_id,
      traineeDetails: {
        date_of_birth: null,
      },
      trainingDetails: {
        trainings: [],
        training: {},
        open: null,
        page: 1,
        search: "",
        limit: 100,
        skip: 0,
        total_trainings: 0,
        expanded: false,
        deleteTrainingPopUp: false,
        tableLoader: false,
        orderBy: { sort_key: "airport", sort_order: true },
      },
      formErrors: {},
      loading: false,
      airportOptions: [],
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];

    this.enableEditOption = false;
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      this.getTraineeDetails();
      this.getTrainingsData();
    }
    this.getAirports();
  }

  async getTraineeDetails() {
    this.setState({
      loading: true,
    });
    let response = await getTraineeDetail(this.state.trainee_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.airtrace_trainee) {
      let traineeData = response.airtrace_trainee;
      await this.setState({
        traineeDetails: {
          ...traineeData,
          date_of_birth:moment(traineeData?.date_of_birth)
        },
        loading: false,
      });
    }
  }

  async getAirports() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return { label: el?.name, value: el?._id };
    });
    let airportDetail;
    if (this.airportId) {
      airportDetail = response?.filter((el) => {
        return el?._id === this.airportId;
      })?.[0];
      this.setState({
        airportOptions: options,
        traineeDetails: {
          ...this.state?.traineeDetails,
          airport: airportDetail,
        },
      });
    } else {
      this.setState({
        airportOptions: options,
      });
    }
  }
  async saveTrainee() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });

    let payload = {
      ...this.state.traineeDetails,
      airport: this.state.traineeDetails?.airport?._id,
      date_of_birth:this.state.traineeDetails?.date_of_birth?.format('YYYY-MM-DD')
    };

    if (this.state.mode === "add") {
      let response = await addTrainee(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        this.setState({
          loading: false,
        });
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.message))
            : i18n.t("trainee_added_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editTraineeDetail(this.state.trainee_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      } else {
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: response?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.message_code))
            : response?.message,
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }
  goBack() {
    this.props.navigate(-1);
  }
  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors };

    if (field == null || field == "airport") {
      if (
        !this.state.traineeDetails?.airport?.name ||
        this.state.traineeDetails?.airport?.name == ""
      ) {
        error["airport"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["airport"];
      }
    }

    if (field == null || field == "first_name") {
      if (
        !this.state?.traineeDetails?.first_name ||
        this.state?.traineeDetails?.first_name == "" ||
        this.state?.traineeDetails?.first_name == null
      ) {
        error["first_name"] = i18n.t("this_field_is_required");
      } else {
        delete error["first_name"];
      }
    }
    if (field == null || field == "last_name") {
      if (
        !this.state?.traineeDetails?.last_name ||
        this.state?.traineeDetails?.last_name == "" ||
        this.state?.traineeDetails?.last_name == null
      ) {
        error["last_name"] = i18n.t("this_field_is_required");
      } else {
        delete error["last_name"];
      }
    }

    this.setState({
      formErrors: error,
    });

    return error;
  }
  handleCloseMenu = () => {
    this.setState({
      ...this.state,
      trainingDetails: {
        ...this.state?.trainingDetails,
        open: null,
      },
    });
  };
  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        ...this.state,
        trainingDetails: {
          ...this.state?.trainingDetails,
          skip: (data - 1) * this.state.limit,
          page: data,
        },
      });
      this.getTrainingsData();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        trainingDetails: {
          ...this.state?.trainingDetails,
          limit: data,
        },
      });
      this.getTrainingsData();
    } else if (action === "popOverAction") {
      this.setState({
        ...this.state,
        trainingDetails: {
          ...this.state?.trainingDetails,
          training: rowData,
          open: data?.target,
        },
      });
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      if (config?.airportJsonKeys.includes(data.toLowerCase())) {
        await this.setState({
          orderBy: {
            sort_key:
              data === "airport"
                ? `airport_name`
                : `airport_${data.toLowerCase()}`,
            sort_order: !this.state.orderBy?.sort_order,
          },
        });
      } else {
        await this.setState({
          ...this.state,
          trainingDetails: {
            ...this.state?.trainingDetails,
            orderBy: {
              sort_key: data,
              sort_order: !this.state.orderBy?.sort_order,
            },
          },
        });
      }
      this.getTrainingsData();
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "deleteTraining") {
      this.handleCloseMenu();
      await this.setState({
        ...this.state,
        trainingDetails: {
          ...this.state?.trainingDetails,
          deleteTrainingPopUp: true,
          open: null,
        },
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        ...this.state,
        trainingDetails: {
          ...this.state?.trainingDetails,
          deleteTrainingPopUp: false,
        },
      });
    } else if (action === "proceed") {
      this.setState({
        ...this.state,
        trainingDetails: {
          ...this.state?.trainingDetails,
          deleteTrainingPopUp: false,
        },
      });
      this.handleTrainingDelete(this.state?.trainingDetails?.training._id);
    }
  };
  async handleTrainingDelete(training_id) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteTraining(training_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response) {
      await this.getTrainingsData();
      this.setState({
        pageLoader: false,
      });
    }
  }

  async getTrainingsData() {
    this.setState({
      ...this.state,
      trainingDetails: {
        ...this.state?.trainingDetails,
        tableLoader: true,
      },
    });

    let query = {
      limit: this.state?.trainingDetails.limit,
      skip: this.state?.trainingDetails.skip,
      trainee: this.state?.trainee_id,
    };

    if (this.state?.trainingDetails?.orderBy?.sort_key) {
      query.sort_key = this.state?.trainingDetails?.orderBy?.sort_key;
      query.sort_order = this.state?.trainingDetails?.orderBy?.sort_order
        ? "ASC"
        : "DESC";
    }

    let trainingsData = await getTrainingsList(query);
    if (trainingsData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: trainingsData?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(trainingsData?.error_code))
          : trainingsData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        ...this.state,
        trainingDetails: {
          ...this.state?.trainingDetails,
          tableLoader: true,
        },
      });
      return;
    }

    this.setState({
      ...this.state,
      trainingDetails: {
        ...this.state?.trainingDetails,
        tableLoader: false,
        trainings: trainingsData.trainings,
        total_trainings: trainingsData.count,
      },
    });
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_trainee")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("trainee")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate(`/airtrace/view/trainees`);
              }}
            >
              <AssignmentOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("trainees")}
            </Link>
            {this.state.mode === "add" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("add")}
              </Typography>
            )}
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("last_name")}
                  variant="outlined"
                  value={this.state?.traineeDetails?.last_name || ""}
                  onChange={async (e) => {
                    await this.setState({
                      traineeDetails: {
                        ...this.state.traineeDetails,
                        last_name: e.target.value,
                      },
                    });
                    await this.validateField("last_name");
                  }}
                  error={this.state.formErrors?.last_name ? true : false}
                  helperText={this.state.formErrors?.last_name}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("first_name")}
                  variant="outlined"
                  value={this.state?.traineeDetails?.first_name || ""}
                  onChange={async (e) => {
                    await this.setState({
                      traineeDetails: {
                        ...this.state.traineeDetails,
                        first_name: e.target.value,
                      },
                    });
                    await this.validateField("first_name");
                  }}
                  error={this.state.formErrors?.first_name ? true : false}
                  helperText={this.state.formErrors?.first_name}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.date_of_birth
                          ? true
                          : false,
                        helperText: this.state.formErrors?.date_of_birth,
                      },
                    }}
                    label={i18n.t("date_of_birth")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={
                      this.state?.traineeDetails?.date_of_birth || null
                    }
                    onChange={async (e) => {
                      await this.setState({
                        traineeDetails: {
                          ...this.state.traineeDetails,
                          date_of_birth: e,
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("email")}
                  variant="outlined"
                  value={this.state?.traineeDetails?.email || ""}
                  onChange={async (e) => {
                    await this.setState({
                      traineeDetails: {
                        ...this.state.traineeDetails,
                        email: e.target.value,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={this.props?.urlParams?.airport ? true : false}
                  onOpen={async () => {
                    await this.getAirports();
                  }}
                  options={
                    !this.state.airportOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.airportOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      traineeDetails: {
                        ...this.state.traineeDetails,
                        airport: { name: value?.label, _id: value?.value },
                      },
                    });
                    await this.validateField("airport");
                  }}
                  value={this.state.traineeDetails?.airport?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("airport")}
                      error={this.state.formErrors?.airport ? true : false}
                      helperText={this.state.formErrors?.airport}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>

          {this.state.mode === "edit" && (
            <>
              <Typography variant="h4" gutterBottom>
                {i18n.t("trainings")}
              </Typography>
              <Grid container spacing={3} mb={2}>
                <Grid item xs={12}>
                  <TraineeTrainingsTable
                    data={this.state?.trainingDetails}
                    onAction={this.handleTableAction}
                    tableHeaders={TABLE_HEAD}
                  />
                </Grid>
              </Grid>
            </>
          )}
          {!this.enableEditOption && (
            <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
              <LoadingButton>
                <Button
                  disableElevation
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={this.state.loading}
                  onClick={async () => {
                    await this.saveTrainee();
                  }}
                >
                  {this.state.loading
                    ? i18n.t("saving")
                    : this.state.mode === "add"
                    ? i18n.t("add_trainee")
                    : i18n.t("update_trainee")}
                </Button>
              </LoadingButton>
            </Grid>
          )}
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();
  let urlParams = new URLSearchParams(location.search);
  urlParams = Object.fromEntries(urlParams.entries());

  return <AddEditTrainee {...props} navigate={navigate} params={params} urlParams={urlParams} />;
}
