import React from "react";
import {
  Table,
  Stack,
  TableRow,
  TableBody,
  Typography,
  TableHead,
  TableSortLabel,
  Box,
  MenuItem,
  IconButton,
  Pagination,
  Grid,
  FormControl,
  InputLabel,
  Select,
  styled,
  Chip,
  Menu,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Popover,
} from "@mui/material";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import config from "../config";
import i18n from "../i18n";
import Iconify from "../components/iconify/Iconify";
import CircularProgress from "@mui/material/CircularProgress";
import moment from "moment";
import { getOptionData } from "../utils/commonUtils";
import { useNavigate, useParams } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
  "&:nth-of-type(odd):hover": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:nth-of-type(even):hover": {
    backgroundColor: "white",
  },
}));

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: "1px",
  height: "1px",
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
};
class AirTraceOffersTable extends React.Component {
  constructor(props) {
    super(props);
    this.airportId = props?.airportId;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.onAction = props?.onAction;
    this.tableHeaders = props?.tableHeaders;
    this.tableLimitOptions = config?.tableLimitOptions;
    this.hideAirportDetails = props?.hideAirportDetails;
    this.categoriesOptions = config?.categoriesOptions;
    this.documentOptions = config?.documentOptions;
    this.statusOptions = config?.statusOptions;
    this.divisionOptions = config?.divisionOptions;
    this.respOptions = config?.respOptions;

    this.state = {
      ...props.data,
      showRespDropDown: false,
      showDivisionDropDown: false,
      showCategoryDropDown: false,
      showDocumentDropDown: false,
      showStatusDropDown: false,
      airTraceOfferData: {},
      isScrolling: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "11",
      },
    };
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ ...nextProps?.data });
  }
  handleClick = (event, data, rowIndex, key) => {
    this.setState({
      ...this.state,
      [key]: event.currentTarget,
      airTraceOfferData: data,
      rowIndex,
    });
  };
  handleClose = (key) => {
    this.setState({
      ...this.state,
      [key]: null,
    });
  };

  render() {
    return (
      <>
        <Card style={{ boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)" ,borderRadius:'5px'}}>
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <ScrollContainer style={{ width: "100%" , maxHeight: 'calc(100vh - 255px)'}} hideScrollbars={false}>
              <Table
                stickyHeader
                size="small"
                aria-label="customized table"
                className="child"
              >
                <TableHead>
                  <TableRow>
                    {this.tableHeaders?.map((headCell) => (
                      <StyledTableCell
                        style={
                          ["airport"].includes(headCell.id)
                            ? this.useStyles?.sticky
                            : {}
                        }
                        key={headCell.id}
                        align={
                          headCell.alignCenter
                            ? "center"
                            : headCell.alignRight
                            ? "right"
                            : "left"
                        }
                      >
                        {headCell.sortable ? (
                          <TableSortLabel
                            direction={
                              this.state?.orderBy?.sort_order ? "asc" : "desc"
                            }
                            onClick={async (e) => {
                              this.onAction("sort", headCell.id);
                            }}
                            style={{ color: "white" }}
                            hideSortIcon={headCell.sortable ? false : true}
                            active={
                              this.state?.orderBy?.sort_key === headCell.id
                            }
                          >
                            {headCell.label}
                            {this.state?.orderBy?.sort_key === headCell.id &&
                            headCell.sortable ? (
                              <Box sx={{ ...visuallyHidden }}>
                                {this.state?.orderBy?.sort_order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        ) : (
                          <Typography variant="subtitle2">
                            {headCell.label}
                          </Typography>
                        )}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!this.state?.tableLoader &&
                    this.state?.airTraceOffers?.map((row, index) => {
                      const {
                        _id,
                        airport,
                        code_oaci,
                        code_iata,
                        resp,
                        division,
                        category,
                        mandate_number,
                        currency,
                        award_amount,
                        offer_amount,
                        award_date,
                        date_of_offer,
                        validity,
                        course,
                        description,
                        remarks
                      } = row;

                      return (
                        <StyledTableRow
                          hover
                          key={_id}
                          tabIndex={-1}
                          onClick={() => {
                            this.onAction(_id);
                          }}
                          className="scroller"
                          ref={this.attachScroller}
                          onMouseDown={this.onScroll}
                          onScroll={this.onMouseMove}
                        >
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{
                                position: "sticky",
                                left: 0,
                                zIndex: 10,
                                backgroundColor:
                                  index % 2 ? "white" : "#F6F7F8",
                                minWidth: "10rem",
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={6}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{
                                    typography: "body2",
                                    cursor: "pointer",
                                  }}
                                  onDoubleClick={() => {
                                       this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                                  }}
                                >
                                  <Typography variant="subtitle2">
                                    <Link
                                      onClick={() => {
                                        this.props.navigate(
                                          `/airports/airportDetails/${row?.airport?._id}`
                                        );
                                      }}
                                    >
                                      {airport?.name}
                                    </Link>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                          
                            <StyledTableCell align="left">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2">
                                  <Link
                                    sx={{cursor:'pointer'}}
                                      onClick={() => {
                                        this.props.navigate(
                                          `/airtrace/course/${course?.value}`
                                        );
                                      }}
                                    >
                                      {course?.label}
                                    </Link>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                                 this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {description}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          {!this.hideAirportDetails && (
                            <StyledTableCell
                              align="left"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                 this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="centre"
                                spacing={2}
                                key={code_oaci}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {code_oaci}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                          )}

                          {!this.hideAirportDetails && (
                            <StyledTableCell
                              component="th"
                              scope="row"
                              style={{ cursor: "pointer" }}
                              onDoubleClick={() => {
                                this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                              }}
                            >
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Stack
                                  direction="column"
                                  spacing={0}
                                  sx={{ typography: "body2" }}
                                >
                                  <Typography variant="subtitle2">
                                    {code_iata}
                                  </Typography>
                                </Stack>
                              </Stack>
                            </StyledTableCell>
                          )}

                          <StyledTableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  <Chip
                                    label={resp}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showRespDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showRespDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showRespDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                    style={{
                                      backgroundColor: getOptionData(
                                        this.respOptions,
                                        resp
                                      )?.color,
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                    variant="outlined"
                                  />
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(this.state.showRespDropDown)}
                                    anchorEl={this.state.showRespDropDown}
                                    onClose={() => {
                                      this.handleClose("showRespDropDown");
                                    }}
                                  >
                                    {this.respOptions?.map((option) => {
                                      return (
                                        <MenuItem
                                          value={option?.value}
                                          onClick={() => {
                                            this.onAction(
                                              "update",
                                              option?.value,
                                              this.state.offerData,
                                              "resp",
                                              this.state.rowIndex
                                            );
                                            this.handleClose(
                                              "showRespDropDown"
                                            );
                                          }}
                                        >
                                          {option?.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              alignItems="center"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2">
                                  <Chip
                                    label={division}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showDivisionDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showDivisionDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showDivisionDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                    style={{
                                      backgroundColor: getOptionData(
                                        this.divisionOptions,
                                        division
                                      )?.color,
                                      color: getOptionData(
                                        this.divisionOptions,
                                        division
                                      )?.textColor,
                                      cursor: "pointer",
                                    }}
                                    variant="outlined"
                                  />
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showDivisionDropDown
                                    )}
                                    anchorEl={this.state.showDivisionDropDown}
                                    onClose={() => {
                                      this.handleClose("showDivisionDropDown");
                                    }}
                                  >
                                    {this.divisionOptions?.map((option) => {
                                      return (
                                        <MenuItem
                                          value={option?.value}
                                          onClick={() => {
                                            this.onAction(
                                              "update",
                                              option?.value,
                                              this.state.offerData,
                                              "division",
                                              this.state.rowIndex
                                            );
                                            this.handleClose(
                                              "showDivisionDropDown"
                                            );
                                          }}
                                        >
                                          {option?.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2">
                                  <Chip
                                    label={i18n.t(category)}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showCategoryDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showCategoryDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showCategoryDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                    style={{
                                      backgroundColor: getOptionData(
                                        this.categoriesOptions,
                                        category
                                      )?.color,
                                      cursor: "pointer",
                                      color:getOptionData(
                                        this.categoriesOptions,
                                        category
                                      )?.textColor
                                    }}
                                    variant="outlined"
                                  />
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showCategoryDropDown
                                    )}
                                    anchorEl={this.state.showCategoryDropDown}
                                    onClose={() => {
                                      this.handleClose("showCategoryDropDown");
                                    }}
                                  >
                                    {this.categoriesOptions?.map((option) => {
                                      return (
                                        <MenuItem
                                          value={option?.value}
                                          onClick={() => {
                                            this.onAction(
                                              "update",
                                              option?.value,
                                              this.state.offerData,
                                              "category",
                                              this.state.rowIndex
                                            );
                                            this.handleClose(
                                              "showCategoryDropDown"
                                            );
                                          }}
                                        >
                                          {option?.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="end"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {mandate_number}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
{/* 
                          <StyledTableCell align="centre">
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                                width="100%"
                              >
                                <Typography variant="subtitle2">
                                  <Chip
                                    label={i18n.t(document)}
                                    onClick={(e) => {
                                      this.handleClick(
                                        e,
                                        row,
                                        index,
                                        "showDocumentDropDown"
                                      );
                                    }}
                                    aria-controls={
                                      Boolean(this.state.showDocumentDropDown)
                                        ? "basic-menu"
                                        : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={
                                      Boolean(this.state.showDocumentDropDown)
                                        ? "true"
                                        : undefined
                                    }
                                    style={{
                                      backgroundColor: getOptionData(
                                        this.documentOptions,
                                        document
                                      )?.color,
                                      cursor: "pointer",
                                    }}
                                    variant="outlined"
                                  />
                                  <Menu
                                    id="basic-menu"
                                    open={Boolean(
                                      this.state.showDocumentDropDown
                                    )}
                                    anchorEl={this.state.showDocumentDropDown}
                                    onClose={() => {
                                      this.handleClose("showDocumentDropDown");
                                    }}
                                  >
                                    {this.documentOptions?.map((option) => {
                                      return (
                                        <MenuItem
                                          value={option?.value}
                                          onClick={() => {
                                            this.onAction(
                                              "update",
                                              option?.value,
                                              this.state.offerData,
                                              "document",
                                              this.state.rowIndex
                                            );
                                            this.handleClose(
                                              "showDocumentDropDown"
                                            );
                                          }}
                                        >
                                          {option?.label}
                                        </MenuItem>
                                      );
                                    })}
                                  </Menu>
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell> */}
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                               this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="center"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {getOptionData(config?.currencyOptions,currency)?.label}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                               this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="end"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {offer_amount?.toFixed(2)}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                              justifyContent="end"
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {award_amount?.toFixed(2)}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                               this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {moment(date_of_offer).format(
                                    i18n.t("OPTIONS.date_format")
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>
                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                               this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {moment(award_date).format(
                                    i18n.t("OPTIONS.date_format")
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                               this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                {moment(validity).format(
                                    i18n.t("OPTIONS.date_format")
                                  )}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            align="left"
                            style={{ cursor: "pointer" }}
                            onDoubleClick={() => {
                              this.props.navigate(`/airtrace/air_trace_offer/${_id}`);
                            }}
                          >
                            <Stack
                              direction="row"
                              alignItems="centre"
                              spacing={2}
                            >
                              <Stack
                                direction="column"
                                spacing={0}
                                sx={{ typography: "body2" }}
                              >
                                <Typography variant="subtitle2">
                                  {remarks}
                                </Typography>
                              </Stack>
                            </Stack>
                          </StyledTableCell>

                          

                          <StyledTableCell align="right">
                            <IconButton
                              size="large"
                              color="inherit"
                              sx={{padding:'2px'}}
                              onClick={(e) => {
                                this.onAction("popOverAction", e, row);
                              }}
                            >
                              <Iconify icon={"eva:more-vertical-fill"} />
                            </IconButton>
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state.airTraceOffers?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </ScrollContainer>
          </Paper>
        </Card>

        {this.state.airTraceOffers?.length !== 0 && (
          <Grid
            container
            spacing={2}
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid></Grid>
            <Grid item>
              <Pagination
                count={Math.ceil(this.state.total_airTraceOffers / this.state.limit)}
                shape="rounded"
                size="large"
                onChange={async (event, page) => {
                  this.onAction("pagination", page);
                }}
                page={this.state.page}
                showFirstButton={true}
                showLastButton={true}
                boundaryCount={2}
                style={{ margin: 20 }}
              />
            </Grid>
            <Grid item style={{ width: "10%" }}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">Limit</InputLabel>
                <Select
                  value={this.state.limit}
                  label="Limit"
                  size="small"
                  onChange={async (e) => {
                    this.onAction("limit", e?.target?.value);
                  }}
                >
                  {this.tableLimitOptions?.map((limit) => {
                    return (
                      <MenuItem value={limit?.value}>{limit?.label}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Popover
              open={Boolean(this.state.open)}
              anchorEl={this.state.open}
              onClose={() => {
                this.onAction("closeMenu");
              }}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  this.onAction("closeMenu");
                   this.props.navigate("/airtrace/air_trace_offer/" + this.state?.airTraceOffer?._id);
                }}
              >
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                {i18n.t("edit")}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  this.onAction("deleteAirTraceOffer");
                }}
              >
                <Iconify icon={"ant-design:delete-filled"} sx={{ mr: 2 }} />
                {i18n.t("delete")}
              </MenuItem>
            </Popover>
            <Dialog
              open={this.state?.deleteAirTraceOfferPopUp}
              onClose={() => {
                this.onAction("closeConfirmationPopUp");
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={async () => {
                    this.onAction("proceed");
                  }}
                >
                  {i18n.t("proceed")}
                </Button>
                <Button
                  onClick={() => {
                    this.onAction("closeConfirmationPopUp");
                  }}
                  autoFocus
                >
                  {i18n.t("cancel")}
                </Button>
              </DialogActions>
            </Dialog>
          </Grid>
        )}
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();
  return <AirTraceOffersTable {...props} navigate={navigate} params={params} />;
}
