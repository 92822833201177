import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  FormHelperText,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import BadgeIcon from "@mui/icons-material/Badge";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import i18n from "../../i18n";
import {convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";

import {
  addAirport,
  getAirportDetail,
  editAirportDetail,
} from "../../services/airport.service";
import { getContinents, getCountries } from "../../services/common.service";
import config from "../../config";

class AddEditAirport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props?.params?.airport_id ? "edit" : "add",
      airport_id: props.params?.airport_id,
      airportDetails: {
      },
      loading: false,
      tableLoader: false,
      formErrors: {errors: {} },
      countriesList: [],
      continent: []
    };
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getAirportData();
    }
    await this.getCountriesList();
    await this.getContinentsList();
  }

  async getAirportData() {
    this.setState({
      loading: true,
      tableLoader: true,
    });
    let response = await getAirportDetail(this.state.airport_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.airport) {
      let airportData = response.airport;
      await this.setState({
        airportDetails: {
          ...airportData,
          representative: airportData?.representative && config.bteeSaRepresentativeOptions.find(option=>option.value === airportData?.representative),
          country:airportData?.country && {label:i18n.t('COUNTRIES.'+airportData?.country),value:airportData?.country},
          continent:airportData?.continent && {label:i18n.t('CONTINENTS.'+airportData?.continent),value:airportData?.continent}
        },
        loading: false,
        tableLoader: false,
      });
    }
  }
  async getCountriesList(showLoading = true) {
    if (showLoading) {
      this.setState({
        loading: true,
      });
    }
    let response = await getCountries();
    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.countries) {
      let countriesList = response.countries;
      let options = countriesList?.map((country) => {
        return {
          label: i18n.t('COUNTRIES.'+country?.name),
          country: country?.name,
          value:country?.code,
          phone: country?.dial_code,
        };
      });
      await this.setState({
        countriesList: options
      });
      if (showLoading) {
        this.setState({
          loading: false,
        });
      }
    }
  }
  async getContinentsList() {
    this.setState({
      loading: true,
    });
    let response = await getContinents();
    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.continents) {
      let continentsList = response.continents;
      let options = continentsList?.map((continent) => {
        return {
          label: i18n.t('CONTINENTS.'+continent?.name),
          continent: continent?.name,
          value: continent?.code,
          phone: continent?.dial_code,
        };
      });
      await this.setState({
        continentsList: options,
        loading: false,
      });
    }
  }

  async saveAirport() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });

    let payload = {
      ...this.state.airportDetails,
      representative:this.state.airportDetails?.representative?.value,
      country:this.state.airportDetails?.country?.value,
      continent:this.state.airportDetails?.continent?.value
    };
    delete payload?._id;
    delete payload?._v;
    delete payload?.deleted;

    if (this.state.mode === "add") {
      let response = await addAirport(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }
      if (response && response.airport) {
        this.setState({
          loading: false,
        });
        this.props.navigate("/airports/view");
      }
    } else {
      let response = await editAirportDetail(this.state.airport_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      if (response && response.airport) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("airport_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }

  goBack() {
    this.props.navigate(-1);
  }

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.formErrors?.errors };

    if (field == null || field == "name") {
      if (
        !this.state?.airportDetails?.name ||
        this.state.airportDetails?.name == ""
      ) {
        error["name"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["name"];
      }
    }

    if (field == null || field == "country") {
      if (
        !this.state?.airportDetails?.country ||
        this.state.airportDetails?.country == ""
      ) {
        error["country"] = i18n.t("this_field_is_required");
      } else {
        delete error["country"];
      }
    }
    if (field == null || field == "continent") {
      if (
        !this.state?.airportDetails?.continent ||
        this.state.airportDetails?.continent == ""
      ) {
        error["continent"] = i18n.t("this_field_is_required");
      } else {
        delete error["continent"];
      }
    }
    if (field == null || field == "code_iata") {
      if (
        !this.state?.airportDetails?.code_iata ||
        this.state.airportDetails?.code_iata == ""
      ) {
        error["code_iata"] = i18n.t("this_field_is_required");
      } else {
        delete error["code_iata"];
      }
    }
    if (field == null || field == "code_oaci") {
      if (
        !this.state?.airportDetails?.code_oaci ||
        this.state.airportDetails?.code_oaci == ""
      ) {
        error["code_oaci"] = i18n.t("this_field_is_required");
      } else {
        delete error["code_oaci"];
      }
    }
    if (field == null || field == "is_open") {
      if (
        !this.state?.airportDetails?.is_open ||
        this.state.airportDetails?.is_open == ""
      ) {
        error["is_open"] = i18n.t("this_field_is_required");
      } else {
        delete error["is_open"];
      }
    }
    if (field == null || field == "representative") {
      if (
        !this.state?.airportDetails?.representative?.value ||
        this.state.airportDetails?.representative?.value == ""
      ) {
        error["representative"] = i18n.t("this_field_is_required");
      } else {
        delete error["representative"];
      }
    }
    if (field === "email") {
      if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.airportDetails?.email)){
        error['email'] = i18n.t('invalid_email_format');
      }else {
        delete error['email'];
      }
    }

    this.setState({
      formErrors: { ...this.state.formErrors, errors: error },
    });

    return error;
  }
  
  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_airport")} | BTEE </title>
        </Helmet>
        <Typography  className='pageHeader' variant="h4" gutterBottom>
              {i18n.t("airport")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/airports/view");
              }}
            >
              <FlightTakeoffIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("airports")}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              {this.state.mode === "add" ? (
                <>
                  <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {i18n.t("add")}
                </>
              ) : (
                <Link
                  underline="hover"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  color="inherit"
                  onClick={() => {
                    this.props.navigate(
                      `/airports/airportDetails/${this.state.airport_id}`
                    );
                  }}
                >
                  <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                  {this.state?.airportDetails?.name}
                </Link>
              )}
            </Typography>
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("name")}
                  variant="outlined"
                  value={this.state?.airportDetails?.name || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        name: e.target.value,
                      },
                    });
                    await this.validateField("name");
                  }}
                  error={this.state.formErrors?.errors?.name ? true : false}
                  helperText={this.state.formErrors?.errors?.name}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                 noOptionsText={i18n.t('no_options')}
                  required
                  disablePortal
                  options={
                    !this.state.continentsList
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.continentsList
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        continent: {label:value?.label,value:value?.continent},
                      },
                    });
                    await this.validateField("continent");
                  }}
                  value={this.state?.airportDetails?.continent?.label || ""}
                  renderOption={(props, option) => {
                    if (option?.value && option?.label) {
                      console
                      return (
                        <Box
                          component="li"
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            loading="lazy"
                            width="20"
                            srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                            src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                            alt=""
                          />
                          {option.label} ({option.value}) {option.phone}
                        </Box>
                      )
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={i18n.t("continent")}
                      error={
                        this.state.formErrors?.errors?.continent ? true : false
                      }
                      helperText={this.state.formErrors?.errors?.continent}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  required
                  noOptionsText={i18n.t('no_options')}
                  disablePortal
                  onOpen={async () => {
                    await this.getCountriesList(false);
                  }}
                  options={
                    !this.state.countriesList
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.countriesList
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        country: {label:value?.label,value:value?.country},
                      },
                    });
                    await this.validateField("country");
                  }}
                  value={this.state?.airportDetails?.country?.label || ""}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://flagcdn.com/w40/${option.value.toLowerCase()}.png 2x`}
                        src={`https://flagcdn.com/w20/${option.value.toLowerCase()}.png`}
                        alt=""
                      />
                      {option.label} ({option.value}) {option.phone}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={i18n.t("country")}
                      error={
                        this.state.formErrors?.errors?.country ? true : false
                      }
                      helperText={this.state.formErrors?.errors?.country}
                    />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("code_iata")}
                  variant="outlined"
                  value={this.state?.airportDetails?.code_iata || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        code_iata: e.target.value,
                      },
                    });
                    await this.validateField("code_iata");
                  }}
                  error={
                    this.state.formErrors?.errors?.code_iata ? true : false
                  }
                  helperText={this.state.formErrors?.errors?.code_iata}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("code_oaci")}
                  variant="outlined"
                  value={this.state?.airportDetails?.code_oaci || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        code_oaci: e.target.value,
                      },
                    });
                    await this.validateField("code_oaci");
                  }}
                  error={
                    this.state.formErrors?.errors?.code_oaci ? true : false
                  }
                  helperText={this.state.formErrors?.errors?.code_oaci}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                <InputLabel>
                  {i18n.t("is_open")}
                  <span> * </span>
                </InputLabel>
                  <Select
                    required
                    label={i18n.t("is_open")}
                    value={this.state?.airportDetails?.is_open || ""}
                    onChange={async (e) => {
                      await this.setState({
                        airportDetails: {
                          ...this.state.airportDetails,
                          is_open: e.target.value,
                        },
                      });
                      await this.validateField("is_open");
                    }}
                    error={
                      this.state.formErrors?.errors?.is_open ? true : false
                    }
                  >
                    <MenuItem value="yes">{i18n.t('yes')}</MenuItem>
                    <MenuItem value="no">{i18n.t('no')}</MenuItem>
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.errors?.is_open}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Autocomplete
                disablePortal
                noOptionsText={i18n.t('no_options')}
                options={config?.bteeSaRepresentativeOptions}
                onChange={async (option, value) => {
                  await this.setState({
                    airportDetails: {
                      ...this.state.airportDetails,
                      representative: value,
                    },
                  });
                  await this.validateField("representative");
                }}
                value={i18n.t(this.state.airportDetails?.representative?.label) || ""}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label={i18n.t("btee_sa_representative")}
                    error={this.state.formErrors?.errors?.representative ? true : false}
                    helperText={this.state.formErrors?.errors?.representative}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("email")}
                  variant="outlined"
                  value={this.state?.airportDetails?.email || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        email: e.target.value,
                      },
                    });
                    await this.validateField("email")
                  }}
                  error={
                    this.state.formErrors?.errors?.email
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.email}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("phone_number")}
                  variant="outlined"
                  value={this.state?.airportDetails?.phone_number || ""}
                  onChange={async (e) => {
                    // allow '+' and numbers only, '+' should be at the start and optional
                    let regex = /^[+]?[0-9]*$/;
                    if (e.target.value === '' || regex.test(e.target.value)) {
                      await this.setState({
                        airportDetails: {
                          ...this.state.airportDetails,
                          phone_number: e.target.value,
                        },
                      });
                    }
                  }}
                />
              </Stack>
            </Grid>
            <Grid item  xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("address")}
                  variant="outlined"
                  value={this.state?.airportDetails?.address || ""}
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={async (e) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        address: e.target.value,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("city")}
                  variant="outlined"
                  value={this.state?.airportDetails?.city || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        city: e.target.value,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("zip_code")}
                  variant="outlined"
                  value={this.state?.airportDetails?.zip_code || ""}
                  onChange={async (e) => {
                    await this.setState({
                      airportDetails: {
                        ...this.state.airportDetails,
                        zip_code: e.target.value,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveAirport();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_airport")
                  : i18n.t("update_airport")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditAirport {...props} navigate={navigate} params={params} />;
}
