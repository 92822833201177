// component
import i18n from 'src/i18n';
import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AssignmentIcon from '@mui/icons-material/Assignment';
import InfoIcon from '@mui/icons-material/Info';
import StairsIcon from '@mui/icons-material/Stairs';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AirlinesIcon from '@mui/icons-material/Airlines';
// ----------------------------------------------------------------------

const navConfig = [
  {
    title: i18n.t('home'),
    path: '/dashboard',
    icon: <BarChartRoundedIcon />,
  },
  {
    title: i18n.t('airports'),
    path: '/airports',
    icon: <AirplanemodeActiveIcon />,
  },
  {
    title: i18n.t('complete_dashboard'),
    path: '/offers',
    icon: <LocalOfferIcon />,
  },
  {
    title: i18n.t('handrail'),
    path: '/handrails',
    icon: <StairsIcon />,
  },
  {
    title: i18n.t('documentation_btee_sa'),
    path: '/documentation-btee-sa',
    icon: <SummarizeIcon />,
  },
  {
    title: `${i18n.t('awhm')} / ${i18n.t('abm')}`,
    path: '/awhm-awm',
    icon: <AllInclusiveIcon />,
  },
  {
    title: i18n.t('tasks'),
    path: '/tasks',
    icon: <AssignmentIcon />,
  },
  {
    title: i18n.t('trainees'),
    path: '/airtrace',
    icon: <AirlinesIcon />,
  },
  {
    title: i18n.t('users'),
    path: '/users',
    access:['superadmin','admin'],
    icon: <PeopleOutlineIcon />,
  }
  // {
  //   title: i18n.t('track_by_airport'),
  //   path: '/track-by-airport',
  //   icon: <GpsFixedIcon />,
  // }
];

export default navConfig;
