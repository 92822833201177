import React from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
  styled,
  TableCell,
  tableCellClasses,
  Card,
  Paper,
  IconButton,
  Modal,
  Box,
  Autocomplete,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  Stack,
  Typography,
  Button,
  Backdrop,
  TableSortLabel,
} from "@mui/material";
import config from "../config";
import i18n from "../i18n";
import CircularProgress from "@mui/material/CircularProgress";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { convertMessageCodeToMessage } from "../utils/messageCodeToMessage";
import {
  getAirportPersons,
  editContactPerson,
  addContactPerson,
  deleteContactPerson,
  getAirportPerson,
} from "../services/person.service";
import Iconify from "src/components/iconify/Iconify";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

class ContactPersonsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      airportId: props.airportId,
      contact_persons: [],
      tableLoader: false,
      deleteEditPopUpDetails: { show: false },
      mode: "",
      roleData: {},
      roleOptions: [],
      orderBy: { sort_key: "role", sort_order: true },
      showRoleDetailsModel: false,
      formErrors: { roleFormErrors: {} },
    };
    this.useStyles = {
      modelClass: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "60%",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      },
    };
  }
  async componentDidMount() {
    this.getContactPersons();
    let roleOptions = config?.roleOptions?.filter((role) => !role?.internal);
    await this.setState({
      roleOptions,
    });
  }

  async getContactPersons() {
    this.setState({
      tableLoader: true,
      contact_persons: [],
    });

    let query = {
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    if (this.state.airportId) {
      query["airport"] = this.state.airportId;
    }

    let contactPersons = await getAirportPersons(query);
    if (contactPersons.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: contactPersons?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(contactPersons?.error_code))
          : contactPersons?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState({
      ...this.state,
      tableLoader: false,
      contact_persons: contactPersons.persons,
    });
  }

  async getContactPerson(personId) {
    this.setState({
      loading: true,
    });

    let contactPerson = await getAirportPerson(personId);
    if (contactPerson.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: contactPerson?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(contactPerson?.error_code))
          : contactPerson?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState({
      loading: false,
      formErrors: { roleFormErrors: {} },
      roleData: contactPerson?.person,
      showRoleDetailsModel: true,
      mode: "edit",
    });
  }

  async tableActions(action, data, index) {
    if (action === "edit") {
      // this.setState({
      //   formErrors: { roleFormErrors: {} },
      //   roleData: data,
      //   showRoleDetailsModel: true,
      //   mode: "edit",
      // });
      this.getContactPerson(data?._id)
    } else if (action === "sort") {
      await this.setState({
        orderBy: {
          sort_key: data,
          sort_order: !this.state.orderBy?.sort_order,
        },
      });
      this.getContactPersons();
    } else if (action === "confirmDelete") {
      this.deleteContactPerson();
    } else if (action === "delete") {
      this.setState({
        deleteEditPopUpDetails: { show: true, person_id: data?._id },
      });
    }
  }

  async deleteContactPerson() {
    this.setState({
      loading: true,
      deleteEditPopUpDetails: { show: false },
    });

    let response = await deleteContactPerson(
      this.state?.deleteEditPopUpDetails?.person_id
    );
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        loading: false,
      });
      return;
    } else {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message,
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
      });
      this.setState({
        ...this.state,
        loading: false,
      });
      this.getContactPersons();
    }
  }

  async saveContactPersons() {
    let error = await this.validateRoleFormField();
    if (Object?.keys(error)?.length > 0) {
      return;
    }
    this.setState({
      loading: true,
    });
    let payload = {
      ...this.state?.roleData,
      airport: this.state?.airportId,
    };
    delete payload?._id;
    delete payload?.id;
    let response;
    if (this.state.mode === "edit") {
      response = await editContactPerson(this.state?.roleData?._id, payload);
    } else {
      response = await addContactPerson(payload);
    }
    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        loading: false,
      });
      return;
    }

    if (response.person || response?.message) {
      await this.setState({
        contact_persons: response.airport?.contact_persons,
        showRoleDetailsModel: false,
        editRole: false,
        deleteEditPopUpDetails: { show: false, person_id: "" },
      });
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text:
          this.state.mode === "edit"
            ? response?.message
              ? i18n.t(convertMessageCodeToMessage(response?.message_code))
              : response?.message
            : i18n.t("contact_person_details_added_successfully"),
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
      });
      this.getContactPersons();
      this.setState({
        loading: false,
      });
    }
  }
  async validateRoleFormField(field) {
    let error = { ...this.state.formErrors?.roleFormErrors };
    if (field == null || field === "name") {
      if (!this.state.roleData?.name || this.state.roleData?.name === "") {
        error["name"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["name"];
      }
    }
    if (field == null || field === "role") {
      if (!this.state.roleData?.role || this.state.roleData?.role === "") {
        error["role"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["role"];
      }
    }
    if (field === "email") {
      if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.roleData?.email)){
        error['email'] = i18n.t('invalid_email_format');
      }else {
        delete error['email'];
      }
    }
    this.setState({
      formErrors: { ...this.state.formErrors, roleFormErrors: error },
    });

    return error;
  }

  render() {
    return (
      <>
        {(this.props?.showTitle) ? (
          <Typography variant="h4" gutterBottom>
            {i18n.t("contact_details")}
          </Typography>
        ) : null}

        <Stack direction="row" spacing={2} style={{ marginBottom: 10 }} justifyContent={'flex-end'}>
          {(this.props?.showAdd) ? (
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.setState({
                  showRoleDetailsModel: true,
                  roleData: {},
                  formErrors: { roleFormErrors: {} },
                  mode: "add",
                });
              }}
            >
              {i18n.t("contact")}
            </Button>
          ) : null}
        </Stack>
          
        <Card
          style={{
            boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
            width: "100%",
            borderRadius: "3px",
          }}
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <TableContainer>
              <Table stickyHeader size="small" aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell align={"left"}>
                      <TableSortLabel
                        direction={
                          this.state?.orderBy?.sort_order ? "asc" : "desc"
                        }
                        onClick={async (e) => {
                          this.tableActions("sort", "name");
                        }}
                        style={{ color: "white" }}
                        active={this.state?.orderBy?.sort_key === "name"}
                      >
                        <Typography variant="subtitle2">
                          {i18n.t("name")}
                        </Typography>
                      </TableSortLabel>
                    </StyledTableCell>

                    <StyledTableCell align={"left"}>
                      <TableSortLabel
                        direction={
                          this.state?.orderBy?.sort_order ? "asc" : "desc"
                        }
                        onClick={async (e) => {
                          this.tableActions("sort", "role");
                        }}
                        style={{ color: "white" }}
                        active={this.state?.orderBy?.sort_key === "role"}
                      >
                        {i18n.t("role")}
                      </TableSortLabel>
                    </StyledTableCell>

                    <StyledTableCell align={"left"}>
                      <TableSortLabel
                        direction={
                          this.state?.orderBy?.sort_order ? "asc" : "desc"
                        }
                        onClick={async (e) => {
                          this.tableActions("sort", "phone_number");
                        }}
                        style={{ color: "white" }}
                        active={
                          this.state?.orderBy?.sort_key === "phone_number"
                        }
                      >
                        <Typography variant="subtitle2">
                          {i18n.t("phone_number")}
                        </Typography>
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align={"left"}>
                      <TableSortLabel
                        direction={
                          this.state?.orderBy?.sort_order ? "asc" : "desc"
                        }
                        onClick={async (e) => {
                          this.tableActions("sort", "cellphone_number");
                        }}
                        style={{ color: "white" }}
                        active={
                          this.state?.orderBy?.sort_key === "cellphone_number"
                        }
                      >
                        <Typography variant="subtitle2">
                          {i18n.t("cellphone")}
                        </Typography>
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align={"left"}>
                      <TableSortLabel
                        direction={
                          this.state?.orderBy?.sort_order ? "asc" : "desc"
                        }
                        onClick={async (e) => {
                          this.tableActions("sort", "email");
                        }}
                        style={{ color: "white" }}
                        active={this.state?.orderBy?.sort_key === "email"}
                      >
                        <Typography variant="subtitle2">
                          {i18n.t("email")}
                        </Typography>
                      </TableSortLabel>
                    </StyledTableCell>
                    <StyledTableCell align={"center"}>
                      <Typography variant="subtitle2">
                        {i18n.t("actions")}
                      </Typography>
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state?.contact_persons?.map((row, index) => {
                    const { _id, name, role, email, phone_number ,cellphone_number} = row;

                    return (
                      <StyledTableRow hover key={_id} tabIndex={-1}>
                        <StyledTableCell
                          component="th"
                          scope="row"
                          style={{
                            position: "sticky",
                            left: 0,
                            background: index % 2 ? "white" : "#F6F7F8",
                            minWidth: "10rem",
                          }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={6}
                          >
                            <Stack
                              direction="column"
                              spacing={0}
                              sx={{ typography: "body2" }}
                            >
                              <Typography variant="subtitle2">
                                {name}
                              </Typography>
                            </Stack>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Stack
                              direction="column"
                              spacing={0}
                              sx={{ typography: "body2" }}
                            >
                              <Typography variant="subtitle2">
                                {i18n.t(role)}
                              </Typography>
                            </Stack>
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Stack
                              direction="column"
                              spacing={0}
                              sx={{ typography: "body2" }}
                            >
                              <Typography variant="subtitle2">
                                {phone_number}
                              </Typography>
                            </Stack>
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Stack
                              direction="column"
                              spacing={0}
                              sx={{ typography: "body2" }}
                            >
                              <Typography variant="subtitle2">
                                {cellphone_number}
                              </Typography>
                            </Stack>
                          </Stack>
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Stack
                              direction="column"
                              spacing={0}
                              sx={{ typography: "body2" }}
                            >
                              <Typography variant="subtitle2">
                                {email}
                              </Typography>
                            </Stack>
                          </Stack>
                        </StyledTableCell>

                        <StyledTableCell align="right">
                          <div
                            className="col-2"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.tableActions("edit", row, index);
                              }}
                            >
                              <IconButton>
                                <EditIcon />
                              </IconButton>
                            </Typography>
                            <Typography
                              style={{ cursor: "pointer" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                this.tableActions("delete", row, index);
                              }}
                            >
                              <IconButton sx={{ padding: "2px" }}>
                                <DeleteOutlineIcon style={{ color: "red" }} />
                              </IconButton>
                            </Typography>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                  {this.state?.tableLoader && (
                    <TableRow>
                      <TableCell
                        style={{ textAlign: "center" }}
                        colSpan={"100%"}
                      >
                        <CircularProgress />
                      </TableCell>
                    </TableRow>
                  )}

                  {!this.state?.tableLoader &&
                    this.state?.contact_persons?.length === 0 && (
                      <TableRow>
                        <TableCell
                          style={{ textAlign: "center" }}
                          colSpan={"100%"}
                        >
                          {i18n.t('no_records_found')}
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Card>
        

        <Grid item xs={12} md={6}>
          <Modal
            open={this.state.showRoleDetailsModel}
            onClose={() => {
              this.setState({
                showRoleDetailsModel: false,
                deleteEditPopUpDetails: { person_id: "" },
              });
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={this.useStyles?.modelClass}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {i18n.t("contact_details")}
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={12} md={6}>
                    <Autocomplete
                      required
                      noOptionsText={i18n.t('no_options')}
                      disablePortal
                      options={
                        !this.state.roleOptions
                          ? [{ label: "Loading...", id: 0 }]
                          : this.state.roleOptions
                      }
                      onChange={(option, value) => {
                        this.setState({
                          roleData: {
                            ...this.state.roleData,
                            role: value?.value,
                          },
                        });
                      }}
                      value={i18n.t(this.state.roleData?.role)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          required
                          label={i18n.t("role")}
                          error={
                            this.state.formErrors?.roleFormErrors?.role
                              ? true
                              : false
                          }
                          helperText={
                            this.state.formErrors?.roleFormErrors?.role
                          }
                        />
                      )}
                      style={{ background: "white", marginBottom: "15px" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      required
                      fullWidth
                      type="text"
                      id="name"
                      label={i18n.t("name")}
                      name="name"
                      style={{ marginBottom: "15px" }}
                      autoComplete="name"
                      onChange={async (e) => {
                        await this.setState({
                          roleData: {
                            ...this.state.roleData,
                            name: e.target.value,
                          },
                        });
                        await this.validateRoleFormField("name");
                      }}
                      error={
                        this.state.formErrors?.roleFormErrors?.name
                          ? true
                          : false
                      }
                      helperText={this.state.formErrors?.roleFormErrors?.name}
                      value={this.state.roleData?.name}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  rowSpacing={2}
                  columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                  style={{ alignItems: "center" }}
                >
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      value={this.state.roleData?.email}
                      type="email"
                      id="email"
                      label={i18n.t("email")}
                      name="email"
                      autoComplete="email"
                      onChange={async (e) => {
                        await this.setState({
                          roleData: {
                            ...this.state.roleData,
                            email: e.target.value,
                          },
                        });
                        await this.validateRoleFormField("email");
                      }}
                      error={
                        this.state.formErrors?.roleFormErrors?.email
                          ? true
                          : false
                      }
                      helperText={this.state.formErrors?.roleFormErrors?.email}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      value={this.state.roleData?.phone_number || ""}
                      id="phone_number"
                      label={i18n.t("phone_number")}
                      name="phone_number"
                      autoComplete="phone_number"
                      onChange={async (e) => {
                        // allow '+' and numbers only, '+' should be at the start and optional
                        let regex = /^[+]?[0-9]*$/;
                        if (e.target.value === '' || regex.test(e.target.value)) {
                          await this.setState({
                            roleData: {
                              ...this.state.roleData,
                              phone_number: e.target.value,
                            },
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextField
                      fullWidth
                      value={this.state.roleData?.cellphone_number || ""}
                      id="cellphone_number"
                      label={i18n.t("cellphone")}
                      name="cellphone_number"
                      autoComplete="cellphone_number"
                      onChange={async (e) => {
                        // allow '+' and numbers only, '+' should be at the start and optional
                        let regex = /^[+]?[0-9]*$/;
                        if (e.target.value === '' || regex.test(e.target.value)) {
                          await this.setState({
                            roleData: {
                              ...this.state.roleData,
                              cellphone_number: e.target.value,
                            },
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={1}>
                      <TextField
                        id="outlined-basic"
                        label={i18n.t("remarks")}
                        variant="outlined"
                        value={this.state?.roleData?.remarks || ""}
                        multiline
                        rows={2}
                        maxRows={4}
                        onChange={async (e) => {
                          await this.setState({
                            roleData: {
                              ...this.state.roleData,
                              remarks: e.target.value,
                            },
                          });
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Typography>
              <Grid container justifyContent="end">
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.saveContactPersons();
                    }}
                  >
                    {i18n.t("Ok")}
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ margin: "10px" }}
                    onClick={() => {
                      this.setState({
                        showRoleDetailsModel: false,
                        deleteEditPopUpDetails: { person_id: "" },
                        mode: "",
                      });
                    }}
                  >
                    {i18n.t("cancel")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Modal>
        </Grid>
        <Dialog
          open={this.state?.deleteEditPopUpDetails?.show}
          onClose={() => {
            this.setState({
              deleteEditPopUpDetails: {
                show: false,
              },
            });
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{i18n.t("delete")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {i18n.t('are_you_sure_of_deleting_this_action_is_irreversible')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.tableActions("confirmDelete")}>
            {i18n.t("proceed")}
            </Button>
            <Button
              onClick={() => {
                this.setState({
                  deleteEditPopUpDetails: {
                    show: false,
                  },
                });
              }}
              autoFocus
            >
              {i18n.t("cancel")}
            </Button>
          </DialogActions>
        </Dialog>
        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <ContactPersonsTable {...props} navigate={navigate} params={params} />;
}
