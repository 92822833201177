import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

// const getDashboardData = async (query=null) => {
//     try {
//         let queryString = '';
//         if (query) {
//             queryString = '?' + getQueryStringFromObject(query);
//         }
//         let response;
//         try {
//             let headers = getRequestHeaders();
//             response = await axios.get( config.SERVER_BASE_URL + '/dashboard' + queryString, { headers: headers });
//         } catch (error) {
//             return error.response.data;
//         }
//         return response.data;
//     } catch (error) {
//         return { error: error.message };
//     }
// }
const getDashboardData = async () => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/dashboard' , { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}
export { getDashboardData};