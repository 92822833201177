import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  Autocomplete,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
  FormHelperText,
  Container,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import BorderColorOutlinedIcon from "@mui/icons-material/BorderColorOutlined";
import BadgeIcon from "@mui/icons-material/Badge";
import Link from "@mui/material/Link";

import config, {
  expAirManagerOptions,
  divisionOptions,
  categoriesOptions,
  documentOptions,
  statusOptions,
} from "../../config";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";

import {
  addOffer,
  getOfferDetail,
  editOfferDetail,
  getContactPersonsList,
} from "../../services/offer.service";
import { getDomainList } from "../../services/domain.service";
import { getAirportList } from "../../services/airport.service";
import { getUsers } from "../../services/user.service";
import { getOptionData } from "../../utils/commonUtils";
import { getAirTraceOfferList } from "../../services/airTraceOffer.service";
import { getCoursesList } from "../../services/course.service";
import moment from "moment";

class AddEditOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: props?.params?.offer_id ? "edit" : "add",
      offer_id: props.params?.offer_id,
      offerDetails: {
        date: null,
        validity: null,
        airport: {},
        award_date: null,
      },
      contactPersons: [],
      airportOptions: [],
      domainOptions: [],
      formErrors: {},
      loading: false,
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = props?.urlParams?.airport;
    if (this.airportId) {
      this.getContactPersons(this.airportId);
    }
  }

  async setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getOfferDetail();
    }
    this.getAirTraceCourses();
    await this.getAirports();
    await this.getUsersList();
  }

  getValue(value) {
    return value ? value : "";
  }
  async getContactPersons(airportId) {
    let query = {
      airport: airportId,
      minimal: true,
    };
    let response = await getContactPersonsList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.persons?.map((person) => {
      return { label: person?.name, value: person?._id };
    });

    this.setState({
      contactPersons: options,
    });
  }
  async getAirports() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return {
        label: el?.name,
        value: el?._id,
        code_iata: el?.code_iata,
        code_oaci: el?.code_oaci,
      };
    });
    if (this.airportId) {
      let airportDetail = options.find((el) => el.value === this.airportId);
      this.setState({
        airportOptions: options,
        offerDetails: { ...this.state?.offerDetails, airport: airportDetail },
      });
    } else {
      this.setState({
        airportOptions: options,
      });
    }
  }
  async getDomainsList() {

    if (!this.state.offerDetails?.division) {
      this.setState({
        domainOptions: [],
      });
      return;
    }

    if (this.state.offerDetails?.division === "AERO") {
      this.setState({
        domainOptions: config?.categoriesOptions,
      });
      return;
    }

    let response = await getDomainList();
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.domains?.map((el) => {
      return {
        label: el?.name,
        value: el?.key,
      };
    });
    this.setState({
      domainOptions: options,
    });
  }

  async getOfferDetail() {
    this.setState({
      loading: true,
    });
    let response = await getOfferDetail(this.state.offer_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      await this.setState({
        loading: false,
      });
      return;
    }
    if (response && response.offer) {
      let offer = response.offer;
      this.getContactPersons(offer?.airport?._id);
      let currency = getOptionData(config?.currencyOptions, offer?.currency);
      await this.setState(
        {
          ...this.state,
          offerDetails: {
            ...this.state?.offerDetails,
            ...offer,
            airport: {
              ...offer?.airport,
              label: offer?.airport?.name,
              value: offer?.airport?._id,
            },
            resp: {
              label: offer?.resp?.name,
              value: offer?.resp?._id,
            },
            date: offer?.date ? moment(offer?.date) : null,
            validity: offer?.validity ? moment(offer?.validity) : null,
            award_date:offer?.award_date ?  moment(offer?.award_date) : null,
            contact_person: {
              label: offer?.contact_person?.name,
              value: offer?.contact_person?._id,
            },
            btee_sa_manager: {
              label: offer?.btee_sa_manager?.name,
              value: offer?.btee_sa_manager?._id,
            },
            currency,
          },
          domainOptions: offer?.division === "AERO" ? config?.categoriesOptions : [],
          loading: false,
        },
        () => {
          if (offer?.division === "AIRTRACE") {
            this.getDomainsList();
          }
        }
      );
    }
  }

  async saveOffer() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });
    let payload = {
      ...this.state.offerDetails,
      airport: this.state.offerDetails?.airport?.value,
      resp: this.state.offerDetails?.resp?.value,
      contact_person: this.state.offerDetails?.contact_person?.value,
      btee_sa_manager: this.state.offerDetails?.btee_sa_manager?.value,
      exp_air_manager: this.state.offerDetails?.exp_air_manager,
      currency: this.state.offerDetails?.currency?.value,
      course: this.state.offerDetails?.course?.value,
      date: this.state.offerDetails?.date && this.state.offerDetails?.date?.format("YYYY-MM-DD"),
      validity: this.state.offerDetails?.validity && this.state.offerDetails?.validity?.format("YYYY-MM-DD"),
      award_date: this.state.offerDetails?.award_date && this.state.offerDetails?.award_date?.format("YYYY-MM-DD"),
      award_amount: this.state.offerDetails?.award_amount && Number(this.state.offerDetails?.award_amount),
    };

    if (this.state.mode === "add") {
      let response = await addOffer(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }
      if (response && response.offer) {
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editOfferDetail(this.state.offer_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      if (response && response.offer) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("offer_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }
  goBack() {
    this.props.navigate(-1);
  }
  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }

  async validateField(field = null) {
    let error = { ...this.state?.formErrors };

    if (field == null || field == "airport") {
      if (
        !this.state.offerDetails?.airport?.value ||
        this.state.offerDetails?.airport?.value == ""
      ) {
        error["airport"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["airport"];
      }
    }

    if (field == null || field == "code_oaci") {
      if (
        !this.state?.offerDetails?.airport?.code_oaci ||
        this.state?.offerDetails?.airport?.code_oaci == ""
      ) {
        error["code_oaci"] = i18n.t("this_field_is_required");
      } else {
        delete error["code_oaci"];
      }
    }
    if (field == null || field == "resp") {
      if (
        !this.state?.offerDetails?.resp?.value ||
        this.state.offerDetails?.resp?.value == ""
      ) {
        error["resp"] = i18n.t("this_field_is_required");
      } else {
        delete error["resp"];
      }
    }
    if (field == null || field == "division") {
      if (
        !this.state?.offerDetails?.division ||
        this.state.offerDetails?.division == ""
      ) {
        error["division"] = i18n.t("this_field_is_required");
      } else {
        delete error["division"];
      }
    }
    if (field == null || field == "category") {
      if (
        !this.state?.offerDetails?.category ||
        this.state.offerDetails?.category == ""
      ) {
        error["category"] = i18n.t("this_field_is_required");
      } else {
        delete error["category"];
      }
    }
    if (field == null || field == "mandate_number") {
      if (
        !this.state?.offerDetails?.mandate_number ||
        this.state.offerDetails?.mandate_number == ""
      ) {
        error["mandate_number"] = i18n.t("this_field_is_required");
      } else if (!/^(\d+(\.\d*)?)?$/.test(this.state.offerDetails?.mandate_number) 
        || parseFloat(this.state.offerDetails?.mandate_number) < 0) {
          error["mandate_number"] = i18n.t("invalid_number");
      }else {
        delete error["mandate_number"];
      }
    }
    if (field == null || field == "currency") {
      if (
        !this.state?.offerDetails?.currency ||
        this.state.offerDetails?.currency == ""
      ) {
        error["currency"] = i18n.t("this_field_is_required");
      } else {
        delete error["currency"];
      }
    }
    if (field == null || field == "offer_amount") {
      if (
        !this.state?.offerDetails?.offer_amount ||
        this.state.offerDetails?.offer_amount == ""
      ) {
        error["offer_amount"] = i18n.t("this_field_is_required");
      }else if (!/^(\d+(\.\d*)?)?$/.test(this.state.offerDetails?.offer_amount) 
        || parseFloat(this.state.offerDetails?.offer_amount) < 0) {
          error["offer_amount"] = i18n.t("invalid_number");
      } else {
        delete error["offer_amount"];
      }
    }
    if (field == "award_amount") {
      if (/^(\d+(\.\d*)?)?$/.test(this.state.offerDetails?.award_amount) 
          && parseFloat(this.state.offerDetails?.award_amount) >= 0) {
        delete error["award_amount"];
      } else {
        error["award_amount"] = i18n.t("invalid_number");
      }
    }
    if (field == null || field == "document") {
      if (
        !this.state?.offerDetails?.document ||
        this.state.offerDetails?.document == ""
      ) {
        error["document"] = i18n.t("this_field_is_required");
      } else {
        delete error["document"];
      }
    }
    if (field == null || field == "status") {
      if (
        !this.state?.offerDetails?.status ||
        this.state.offerDetails?.status == ""
      ) {
        error["status"] = i18n.t("this_field_is_required");
      } else {
        delete error["status"];
      }
    }
    if (field == null || field == "date") {
      if (
        !this.state?.offerDetails?.date ||
        this.state.offerDetails?.date == null
      ) {
        error["date"] = i18n.t("this_field_is_required");
      } else {
        delete error["date"];
      }
    }
    if (field == null || field == "validity") {
      if (
        !this.state?.offerDetails?.validity ||
        this.state.offerDetails?.validity == null
      ) {
        error["validity"] = i18n.t("this_field_is_required");
      } else {
        delete error["validity"];
      }
    }
    if (field == null || field == "description") {
      if (
        !this.state?.offerDetails?.description ||
        this.state.offerDetails?.description == ""
      ) {
        error["description"] = i18n.t("this_field_is_required");
      } else {
        delete error["description"];
      }
    }
    if (field == null || field == "contact_person") {
      if (
        !this.state?.offerDetails?.contact_person?.value ||
        this.state.offerDetails?.contact_person?.value == ""
      ) {
        error["contact_person"] = i18n.t("this_field_is_required");
      } else {
        delete error["contact_person"];
      }
    }
    if (field == null || field == "btee_sa_manager") {
      if (
        !this.state?.offerDetails?.btee_sa_manager ||
        this.state.offerDetails?.btee_sa_manager == ""
      ) {
        error["btee_sa_manager"] = i18n.t("this_field_is_required");
      } else {
        delete error["btee_sa_manager"];
      }
    }
    this.setState({
      formErrors: error,
    });
    return error;
  }

  async getUsersList() {
    let query = {
      minimal: true,
      internal:true
    };

    let users = await getUsers(query);
    if (users.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: users?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(users?.error_code))
          : users?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let tempOptions = users.users?.map((user) => {
      return { label: user?.name, value: user?._id };
    });
    await this.setState({
      userOptions: tempOptions,
    });
  }

  async getAirTraceCourses() {
    let query = {
      minimal: true,
    };

    let response = await getCoursesList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return { label: el?.course_id, value: el?._id };
    });
    let tempCourse = getOptionData(options, this.state?.offerDetails?.course);
    this.setState({
      airTraceOffers: options,
      offerDetails: {
        ...this.state?.offerDetails,
        course: tempCourse,
      },
    });
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_offer")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("complete_dashboard")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            {this?.airportId && (
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                color="inherit"
                onClick={() => {
                  this.props.navigate("/airports/view");
                }}
              >
                <FlightTakeoffIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("airports")}
              </Link>
            )}
            {this?.airportId && (
              <Link
                underline="hover"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                color="inherit"
                onClick={() => {
                  this.props.navigate(
                    `/airports/airportDetails/${this.airportId}`
                  );
                }}
              >
                <FlightIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {this.state.offerDetails?.airport?.label}
              </Link>
            )}
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.airportId
                  ? this.props.navigate(
                      `/offers/view?airportId=${this.airportId}`
                    )
                  : this.props.navigate(`/offers/view`);
              }}
            >
              <LocalOfferOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("offers")}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {this.state.mode === "add"
                ? i18n.t("add")
                : this.state?.offerDetails?.mandate_number}
            </Typography>
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorOutlinedIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>

        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t("no_options")}
                  disabled={this.props?.urlParams?.airport ? true : false}
                  onOpen={async () => {
                    await this.getAirports();
                  }}
                  options={
                    !this.state.airportOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.airportOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        airport: {
                          ...this.state.offerDetails?.airport,
                          ...value
                        },
                        contact_person: {},
                      },
                    });
                    await this.validateField("airport");
                    if (value?.value) {
                      this.getContactPersons(value?.value);
                    }
                  }}
                  value={this.state.offerDetails?.airport?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("airport")}
                      error={this.state.formErrors?.airport ? true : false}
                      helperText={this.state.formErrors?.airport}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("code_OACI")}
                  variant="outlined"
                  disabled={true}
                  value={this.state?.offerDetails?.airport?.code_oaci || ""}
                  onChange={async (e) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        airport: {
                          ...this.state.offerDetails?.airport,
                          code_oaci: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("code_IATA")}
                  variant="outlined"
                  disabled={true}
                  value={this.state?.offerDetails?.airport?.code_iata || ""}
                  onChange={async (e) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        airport: {
                          ...this.state.offerDetails?.airport,
                          code_iata: e.target.value,
                        },
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  multiline
                  required
                  rows={2}
                  maxRows={4}
                  label={i18n.t("description")}
                  variant="outlined"
                  value={this.state?.offerDetails?.description || ""}
                  onChange={async (e) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        description: e.target.value,
                      },
                    });
                    await this.validateField("description");
                  }}
                  error={this.state.formErrors?.description ? true : false}
                  helperText={this.state.formErrors?.description}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  {/* <InputLabel required={true}>{i18n.t("resp")}</InputLabel> */}
                  <Autocomplete
                    disablePortal
                    noOptionsText={i18n.t('no_options')}
                    onOpen={async () => {
                      await this.getUsersList();
                    }}
                    options={
                      !this.state?.userOptions
                        ? [{ label: "Loading...", id: 0 }]
                        : this.state?.userOptions
                    }
                    onChange={async (option, value) => {
                      console.log("value", value);
                      await this.setStateAsync({
                        offerDetails: {
                          ...this.state?.offerDetails,
                          resp: value
                        },
                      });
                      await this.validateField("resp");
                    }}
                    value={this.state?.offerDetails?.resp?.label || ""}
                    renderInput={(params) => (
                      <TextField {...params} required label={i18n.t("resp")}
                      error={
                        this.state?.errors?.resp
                          ? true
                          : false
                      }
                      helperText={this.state?.errors?.resp} />
                    )}
                  />
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.resp}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel required={true} id="division">
                    {i18n.t("division")}
                  </InputLabel>
                  <Select
                    required
                    label={i18n.t("division")}
                    value={this.state?.offerDetails?.division || ""}
                    onChange={async (e) => {
                      await this.setStateAsync({
                        offerDetails: {
                          ...this.state.offerDetails,
                          division: e.target.value,
                        },
                      });
                      await this.validateField("division");
                      await this.getDomainsList();
                    }}
                    error={this.state.formErrors?.division ? true : false}
                  >
                    {divisionOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.division}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel required={true} id="domain">
                    {i18n.t("domain")}
                  </InputLabel>
                  <Select
                    required
                    disabled={!this.state?.offerDetails?.division}
                    label={i18n.t("domain")}
                    value={this.state?.offerDetails?.category || ""}
                    onOpen={async () => {
                      await this.getDomainsList();
                    }}
                    onChange={async (e) => {
                      await this.setState({
                        offerDetails: {
                          ...this.state.offerDetails,
                          category: e.target.value,
                        },
                      });
                      await this.validateField("category");
                    }}
                    error={this.state.formErrors?.category ? true : false}
                  >
                    {this.state?.domainOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {i18n.t(option?.value)}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.category}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("mandate_number")}
                  variant="outlined"
                  type="number"
                  value={this.state?.offerDetails?.mandate_number !== undefined 
                    ? this.state.offerDetails.mandate_number 
                    : ""}
                  inputProps={{ min: 0 }} 
                  onChange={async (e) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        mandate_number: e.target.value,
                      },
                    });
                    await this.validateField("mandate_number");
                  }}
                  error={this.state.formErrors?.mandate_number ? true : false}
                  helperText={this.state.formErrors?.mandate_number}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel required={true} id="document">
                    {i18n.t("document")}
                  </InputLabel>
                  <Select
                    required
                    label={i18n.t("document")}
                    value={this.state?.offerDetails?.document || ""}
                    onChange={async (e) => {
                      await this.setState({
                        offerDetails: {
                          ...this.state.offerDetails,
                          document: e.target.value,
                        },
                      });
                      await this.validateField("document");
                    }}
                    error={this.state.formErrors?.document ? true : false}
                  >
                    {/* <MenuItem value={null}>
                      <em>{i18n.t("none")}</em>
                    </MenuItem> */}
                    {documentOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.document}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel required={true} id="status">
                    {i18n.t("status")}
                  </InputLabel>
                  <Select
                    required
                    label={i18n.t("status")}
                    value={this.state?.offerDetails?.status || ""}
                    onChange={async (e) => {
                      await this.setState({
                        offerDetails: {
                          ...this.state.offerDetails,
                          status: e.target.value,
                        },
                      });
                      await this.validateField("status");
                    }}
                    error={this.state.formErrors?.status ? true : false}
                  >
                    {/* <MenuItem value={null}>
                      <em>{i18n.t("none")}</em>
                    </MenuItem> */}
                    {statusOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>
                          {option?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.status}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={4} mb={2}>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t("no_options")}
                  options={config?.currencyOptions}
                  onChange={async (option, value) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        currency: { label: value?.label, value: value?.value },
                      },
                    });
                    this.validateField("currency");
                  }}
                  value={this.state?.offerDetails?.currency?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      label={i18n.t("currency")}
                      error={this.state.formErrors?.currency ? true : false}
                      helperText={this.state.formErrors?.currency}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  required
                  type="number"
                  label={i18n.t("offer_amount")}
                  variant="outlined"
                  value={this.state?.offerDetails?.offer_amount !== undefined 
                    ? this.state.offerDetails.offer_amount 
                    : ""}
                  inputProps={{ min: 0 }} 
                  onChange={async (e) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        offer_amount: e.target.value,
                      },
                    });
                    this.validateField("offer_amount");
                  }}
                  error={this.state.formErrors?.offer_amount ? true : false}
                  helperText={this.state.formErrors?.offer_amount}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  type="number"
                  label={i18n.t("award_amount")}
                  variant="outlined"
                  value={this.state?.offerDetails?.award_amount !== undefined 
                    ? this.state.offerDetails.award_amount 
                    : ""}
                  onChange={async (e) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        award_amount: e.target.value,
                      },
                    });
                    this.validateField("award_amount");
                  }}
                  error={this.state.formErrors?.award_amount ? true : false}
                  helperText={this.state.formErrors?.award_amount}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t("no_options")}
                  onOpen={async () => {
                    await this.getAirTraceCourses();
                  }}
                  options={
                    !this.state.airTraceOffers
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.airTraceOffers
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        course: { label: value?.label, value: value?.value },
                      },
                    });
                  }}
                  value={this.state.offerDetails?.course?.label || ""}
                  renderInput={(params) => (
                    <TextField {...params} label={i18n.t("course")} />
                  )}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.date ? true : false,
                        helperText: this.state.formErrors?.date,
                        required: true,
                      },
                    }}
                    label={i18n.t("date_of_offer")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.offerDetails?.date || null}
                    onChange={async (e) => {
                      await this.setState({
                        offerDetails: {
                          ...this.state.offerDetails,
                          date: e,
                        },
                      });
                      await this.validateField("date");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.formErrors?.validity ? true : false,
                        helperText: this.state.formErrors?.validity,
                        required: true,
                      },
                    }}
                    label={i18n.t("validity_of_the_offer")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.offerDetails?.validity || null}
                    onChange={async (e) => {
                      await this.setState({
                        offerDetails: {
                          ...this.state.offerDetails,
                          validity: e,
                        },
                      });
                      await this.validateField("validity");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t("no_options")}
                  disabled={this.state?.offerDetails?.airport?.value ? false : true}
                  onOpen={async () => {
                    if (this.state?.offerDetails?.airport?.value)
                      await this.getContactPersons(this.state?.offerDetails?.airport?.value);
                  }}
                  options={
                    !this.state.contactPersons
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.contactPersons
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        contact_person: value
                      },
                    });
                    await this.validateField("contact_person");
                  }}
                  value={this.state.offerDetails?.contact_person?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={i18n.t("airport_contact_person")}
                      error={
                        this.state.formErrors?.contact_person ? true : false
                      }
                      helperText={this.state.formErrors?.contact_person}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t("no_options")}
                  onOpen={async () => {
                    await this.getUsersList();
                  }}
                  options={
                    !this.state.userOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state.userOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        btee_sa_manager: value
                      },
                    });
                    await this.validateField("btee_sa_manager");
                  }}
                  value={this.state.offerDetails?.btee_sa_manager?.label || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label={i18n.t("btee_sa_manager_of_the_offer")}
                      error={
                        this.state.formErrors?.btee_sa_manager ? true : false
                      }
                      helperText={this.state.formErrors?.btee_sa_manager}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  label={i18n.t("remarks")}
                  variant="outlined"
                  value={this.state?.offerDetails?.remarks || ""}
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={async (e) => {
                    await this.setState({
                      offerDetails: {
                        ...this.state.offerDetails,
                        remarks: e.target.value,
                      },
                    });
                  }}
                />
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: false,
                      },
                    }}
                    label={i18n.t("award_date")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.offerDetails?.award_date || null}
                    onChange={async (e) => {
                      await this.setState({
                        offerDetails: {
                          ...this.state.offerDetails,
                          award_date: e
                        },
                      });
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            {this.state?.offerDetails?.award_date && (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    disablePortal
                    noOptionsText={i18n.t("no_options")}
                    options={expAirManagerOptions}
                    onChange={async (option, value) => {
                      await this.setState({
                        offerDetails: {
                          ...this.state.offerDetails,
                          exp_air_manager: value?.value,
                        },
                      });
                    }}
                    value={
                      i18n.t(this.state.offerDetails?.exp_air_manager) || ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={i18n.t("exp_air_manager")}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sx={{ display: "flex" }}>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            checked={
                              this.state?.offerDetails
                                ?.exp_air_annual_assistance
                            }
                            label={i18n.t("exp_air_annual_assistance")}
                            onChange={async (e) => {
                              await this.setState({
                                offerDetails: {
                                  ...this.state.offerDetails,
                                  exp_air_annual_assistance: e?.target?.checked,
                                },
                              });
                            }}
                          />
                        }
                        label={i18n.t("exp_air_annual_assistance")}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            checked={
                              this.state?.offerDetails
                                ?.responsible_for_the_process
                            }
                            label={i18n.t("responsible_for_the_process")}
                            onChange={async (e) => {
                              await this.setState({
                                offerDetails: {
                                  ...this.state.offerDetails,
                                  responsible_for_the_process:
                                    e?.target?.checked,
                                },
                              });
                            }}
                          />
                        }
                        label={i18n.t("responsible_for_the_process")}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={4}>
                    <Stack spacing={1}>
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            checked={
                              this.state?.offerDetails
                                ?.co_responsible_for_the_process
                            }
                            label={i18n.t("co_responsible_for_the_process")}
                            onChange={async (e) => {
                              await this.setState({
                                offerDetails: {
                                  ...this.state.offerDetails,
                                  co_responsible_for_the_process:
                                    e?.target?.checked,
                                },
                              });
                            }}
                          />
                        }
                        label={i18n.t("co_responsible_for_the_process")}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveOffer();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_offer_or_mandate")
                  : i18n.t("update_offer_or_mandate")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();
  let urlParams = new URLSearchParams(location.search);
  urlParams = Object.fromEntries(urlParams.entries());

  return <AddEditOffer {...props} navigate={navigate} params={params} urlParams={urlParams} />;
}
