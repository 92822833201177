import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  FormHelperText,
  Container,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import StairsIcon from '@mui/icons-material/Stairs';
import BadgeIcon from "@mui/icons-material/Badge";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import i18n, { locale } from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import {
  addHandrail,
  getHandrailDetails,
  editHandrail,
} from "../../services/handrail.service";
import config from "../../config";
import { getAirportList } from "../../services/airport.service";
import { getContactPersonsList, getOfferList } from "../../services/offer.service";
import { getUsers } from "../../services/user.service";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import FlightIcon from '@mui/icons-material/Flight';
import moment from "moment";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

class AddEditHandrail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props?.params?.handrail_id ? "edit" : "add",
      handrail_id: props.params?.handrail_id,
      handrailDetails: {
        date: null,
        hour: null,
        btee_sa_employees: [],
        airport_employees: [],
      },
      btee_sa_employees_options: [],
      airport_employees_options: [],
      loading: false,
      tableLoader: false,
      errors: {},
      countriesList: [],
    };
    this.tradesOptions = config?.tradesOptions;
    this.airportId = props?.urlParams?.airport;
  }

  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve);
    });
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getHandrailData();
      this.getOffers();
    }
    this.getAirports();
    this.bteeInternalUsersData();
  }

  async getHandrailData() {
    this.setState({
      loading: true,
    });
    let response = await getHandrailDetails(this.state.handrail_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.handrail) {
      let handrailData = response.handrail;
      let temp_btee_sa_employees = handrailData?.btee_sa_employees?.map((btee_sa_employee) => {
        return { label: btee_sa_employee?.name, value: btee_sa_employee?._id }
      })
      let tempAirport_employees = handrailData?.airport_employees?.map((airport_employee) => {
        return { label: airport_employee?.name, value: airport_employee?._id }
      })
      let airport = { label: handrailData?.airport?.name, value: handrailData?.airport?._id }
      let offer_number = { label: handrailData?.offer_number?.mandate_number, value: handrailData?.offer_number?._id }
      let trades = { label: i18n.t(handrailData?.trades), value: handrailData?.trades }
      await this.setState({
        handrailDetails: {
          ...handrailData,
          btee_sa_employees: temp_btee_sa_employees,
          airport_employees: tempAirport_employees,
          airport,
          offer_number,
          trades,
          date: moment(handrailData?.date),
          hour: moment(handrailData?.hour, 'HH:mm')
        },
        loading: false,
      }, () => {
        this.getOffers();
        this.getAirportUsersData();
      });

    }
  }

  async saveHandrail() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });

    let temp_btee_sa_employees = this.state.handrailDetails?.btee_sa_employees?.map(data => data?.value)
    let temp_airport_employees = this.state.handrailDetails?.airport_employees?.map(data => data?.value)
    let payload = {
      ...this.state.handrailDetails,
      airport: this.state.handrailDetails?.airport?.value,
      offer_number: this.state.handrailDetails?.offer_number?.value,
      btee_sa_employees: temp_btee_sa_employees,
      airport_employees: temp_airport_employees,
      trades: this.state.handrailDetails?.trades?.value,
      date: this.state.handrailDetails?.date?.format('YYYY-MM-DD'),
      hour: this.state.handrailDetails?.hour?.format('HH:mm')
    };

    if (this.state.mode === "add") {
      let response = await addHandrail(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }
      if (response && response.handrail) {
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    } else {
      let response = await editHandrail(this.state.handrail_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
            ? i18n.t(convertMessageCodeToMessage(response?.error_code))
            : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      if (response && response.handrail) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("handrail_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }

  goBack() {
    this.props.navigate(-1);
  }

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != "") {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {
    let error = { ...this.state?.errors };

    if (field == null || field == "airport") {
      if (
        !this.state?.handrailDetails?.airport?.value ||
        this.state.handrailDetails?.airport?.value == ""
      ) {
        error["airport"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["airport"];
      }
    }

    if (field == null || field == "offer_number") {
      if (
        !this.state?.handrailDetails?.offer_number?.value ||
        this.state.handrailDetails?.offer_number?.value == ""
      ) {
        error["offer_number"] = i18n.t("this_field_is_required");
      } else {
        delete error["offer_number"];
      }
    }
    if (field == null || field == "date") {
      if (
        !this.state?.handrailDetails?.date ||
        this.state.handrailDetails?.date == ""
      ) {
        error["date"] = i18n.t("this_field_is_required");
      } else {
        delete error["date"];
      }
    }
    if (field == null || field == "hour") {
      if (
        !this.state?.handrailDetails?.hour ||
        this.state.handrailDetails?.hour == ""
      ) {
        error["hour"] = i18n.t("this_field_is_required");
      } else {
        delete error["hour"];
      }
    }
    if (field == null || field == "btee_sa_employees") {
      if (
        !this.state?.handrailDetails?.btee_sa_employees ||
        this.state.handrailDetails?.btee_sa_employees?.length == 0
      ) {
        error["btee_sa_employees"] = i18n.t("this_field_is_required");
      } else {
        delete error["btee_sa_employees"];
      }
    }
    if (field == null || field == "airport_employees") {
      if (
        !this.state?.handrailDetails?.airport_employees ||
        this.state.handrailDetails?.airport_employees?.length == 0
      ) {
        error["airport_employees"] = i18n.t("this_field_is_required");
      } else {
        delete error["airport_employees"];
      }
    }
    if (field == null || field == "importance") {
      if (
        !this.state?.handrailDetails?.importance ||
        this.state.handrailDetails?.importance == ""
      ) {
        error["importance"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["importance"];
      }
    }
    if (field == null || field == "trades") {
      if (
        !this.state?.handrailDetails?.trades?.value ||
        this.state.handrailDetails?.trades?.value == ""
      ) {
        error["trades"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["trades"];
      }
    }
    if (field == null || field == "information") {
      if (
        !this.state?.handrailDetails?.information ||
        this.state.handrailDetails?.information == ""
      ) {
        error["information"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["information"];
      }
    }
    if (this.state?.handrailDetails?.trades?.value === 'others' && field == null || field == "other_trade") {
      if (
        !this.state?.handrailDetails?.other_trade ||
        this.state.handrailDetails?.other_trade == ""
      ) {
        error["other_trade"] = i18n.t("this_field_is_required");
      } else {
        delete error?.["other_trade"];
      }
    }

    this.setState({
      errors: error,
    });

    return error;
  }

  async getAirports() {
    let query = {
      minimal: true,
    };

    let response = await getAirportList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.map((el) => {
      return {
        label: el?.name,
        value: el?._id,
        code_iata: el?.code_iata,
        code_oaci: el?.code_oaci,
      };
    });
    if (this.airportId) {
      let selectedAirport = options.find((el) => el?.value === this.airportId);
      await this.setStateAsync({
        airportOptions: options,
        handrailDetails: { ...this.state?.handrailDetails, airport: selectedAirport },
      });
      this.getOffers();
      this.getAirportUsersData();
    } else {
      this.setState({
        airportOptions: options,
      });
    }
  }
  async getOffers() {
    let query = {};
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    query["minimal"] = true;

    let offersData = await getOfferList(query);
    if (offersData.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: offersData?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(offersData?.error_code))
          : offersData?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = offersData?.offers?.map((el) => {
      return {
        label: el?.mandate_number,
        value: el?._id,
      };
    });
    this.setState({
      mandateNumberOptions: options,
    });
  }
  async bteeInternalUsersData() {
    let query = {
      internal: true,
      minimal: true
    };

    let users = await getUsers(query);
    if (users.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: users?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(users?.error_code))
          : users?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let tempOptions = users.users?.map((option) => {
      return { label: option?.name, value: option?._id };
    });
    await this.setState({
      btee_sa_employees_options: tempOptions,
    });
  }
  async getAirportUsersData() {
    let query = {};
    if (this.state.handrailDetails?.airport?.value) {
      query["airport"] = this.state.handrailDetails?.airport?.value;
      query['minimal'] = true
    }
    let response = await getContactPersonsList(query);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.persons?.map((person) => {
      return { label: person?.name, value: person?._id };
    });

    this.setState({
      airport_employees_options: options,
    });
  }

  render() {
    return (
      <Container maxWidth="xxl">
        <Helmet>
          <title> {i18n.t("add_handrail")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("handrail")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("home")}
            </Link>
            {this?.airportId &&
              <Link
                underline="hover"
                sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
                color="inherit"
                onClick={() => {
                  this.props.navigate("/airports/view");
                }}
              >
                <FlightTakeoffIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("airports")}
              </Link>
            }
            {this?.airportId && <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate(`/airports/airportDetails/${this.airportId}`);
              }}
            >
              <FlightIcon sx={{ mr: 0.5 }} fontSize="inherit" />

              {this.state.handrailDetails?.airport?.label}
            </Link>
            }
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/handrails/view");
              }}
            >
              <StairsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t("handrails")}
            </Link>
            {this.state.mode === "add" && <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <>
                <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("add")}
              </>
            </Typography>}
            {this.state.mode === "edit" && (
              <Typography
                sx={{ display: "flex", alignItems: "center" }}
                color="text.primary"
              >
                <BorderColorIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
              </Typography>
            )}
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={this.props?.urlParams?.airport ? true : false}
                  onOpen={async () => {
                    await this.getAirports();
                  }}
                  options={
                    !this.state?.airportOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state?.airportOptions
                  }
                  onChange={async (option, value) => {
                    await this.setStateAsync({
                      handrailDetails: {
                        ...this.state?.handrailDetails,
                        airport: value,
                        offer_number: {
                          label: '',
                          value: '',
                        },
                        airport_employees: []
                      },
                    });
                    this.airportId = value?.value;
                    await this.validateField("airport");
                    await this.getOffers();
                    await this.getAirportUsersData();
                  }}
                  value={this.state?.handrailDetails?.airport?.label || ""}
                  renderInput={(params) => (
                    <TextField {...params} required label={i18n.t("airport")}
                      error={
                        this.state?.errors?.airport
                          ? true
                          : false
                      }
                      helperText={this.state?.errors?.airport} />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  disabled={!this.airportId}
                  onOpen={async () => {
                    await this.getOffers();
                  }}
                  options={
                    !this.state?.mandateNumberOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this.state?.mandateNumberOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      handrailDetails: {
                        ...this.state?.handrailDetails,
                        offer_number: value
                      }
                    });
                    await this.validateField("offer_number");
                  }}
                  value={this.state?.handrailDetails?.offer_number || ""}
                  renderInput={(params) => (
                    <TextField {...params} required label={i18n.t("mandate_number")}
                      error={
                        this.state?.errors?.offer_number
                          ? true
                          : false
                      }
                      helperText={this.state?.errors?.offer_number} />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    slotProps={{
                      textField: {
                        error: this.state.errors?.date ? true : false,
                        helperText: this.state.errors?.date,
                        required: true
                      },
                    }}
                    label={i18n.t("date")}
                    format={i18n.t("OPTIONS.date_format")}
                    value={this.state?.handrailDetails?.date || null}
                    onChange={async (e) => {
                      await this.setState({
                        handrailDetails: {
                          ...this.state.handrailDetails,
                          date: e,
                        },
                      });
                      await this.validateField("date");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    slotProps={{
                      textField: {
                        error: this.state.errors?.hour ? true : false,
                        helperText: this.state.errors?.hour,
                        required: true
                      },
                    }}
                    label={i18n.t("hour")}
                    format={i18n.t("OPTIONS.time_format")}
                    value={this.state?.handrailDetails?.hour || null}
                    onChange={async (e) => {
                      await this.setState({
                        handrailDetails: {
                          ...this.state.handrailDetails,
                          hour: e
                        },
                      });
                      await this.validateField("hour");
                    }}
                  />
                </LocalizationProvider>
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  noOptionsText={i18n.t('no_options')}
                  multiple
                  id="tags-outlined"
                  filterSelectedOptions
                  limitTags={2}
                  onOpen={async () => {
                    await this.bteeInternalUsersData();
                  }}
                  options={this.state?.btee_sa_employees_options || []}
                  getOptionLabel={(option) => option.label}
                  value={this.state?.handrailDetails?.btee_sa_employees || []}
                  onChange={async (option, value) => {
                    await this.setState({
                      handrailDetails: {
                        ...this.state?.handrailDetails,
                        btee_sa_employees: [...value],
                      },
                    });
                    await this.validateField("btee_sa_employees");
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("btee_sa_employees")}
                      required
                      error={
                        this.state?.errors?.btee_sa_employees
                          ? true
                          : false
                      }
                      helperText={this.state?.errors?.btee_sa_employees}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  noOptionsText={i18n.t('no_options')}
                  disabled={!this.airportId}
                  multiple
                  limitTags={2}
                  id="tags-outlined"
                  onOpen={async () => {
                    await this.getAirportUsersData();
                  }}
                  options={this.state?.airport_employees_options || []}
                  getOptionLabel={(option) => option.label}
                  value={this.state?.handrailDetails?.airport_employees || []}
                  onChange={async (option, value) => {
                    await this.setState({
                      handrailDetails: {
                        ...this.state?.handrailDetails,
                        airport_employees: [...value],
                      },
                    });
                    await this.validateField("airport_employees");
                  }}
                  filterSelectedOptions
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={i18n.t("airport_employees")}
                      required
                      error={
                        this.state?.errors?.airport_employees
                          ? true
                          : false
                      }
                      helperText={this.state?.errors?.airport_employees}
                    />
                  )}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <FormControl variant="outlined">
                  <InputLabel required>{i18n.t("importance")}</InputLabel>
                  <Select
                    required
                    label={i18n.t("importance")}
                    value={this.state?.handrailDetails?.importance || ""}
                    onChange={async (e) => {
                      await this.setState({
                        handrailDetails: {
                          ...this.state.handrailDetails,
                          importance: e.target.value,
                        },
                      });
                      await this.validateField("importance");
                    }}
                    error={
                      this.state?.errors?.importance ? true : false
                    }
                  >
                    {config?.importanceOptions?.map((option) => {
                      return (
                        <MenuItem value={option?.value}>{option?.label}</MenuItem>
                      )
                    })}
                  </Select>
                  <FormHelperText style={{ color: "red" }}>
                    {this.state?.errors?.importance}
                  </FormHelperText>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack spacing={1}>
                <Autocomplete
                  disablePortal
                  noOptionsText={i18n.t('no_options')}
                  options={
                    !this?.tradesOptions
                      ? [{ label: "Loading...", id: 0 }]
                      : this?.tradesOptions
                  }
                  onChange={async (option, value) => {
                    await this.setState({
                      handrailDetails: {
                        ...this.state?.handrailDetails,
                        trades: {
                          label: value?.label,
                          value: value?.value,
                        },
                        other_trade: ""
                      },
                    });
                    await this.validateField("trades");
                  }}
                  value={this.state?.handrailDetails?.trades?.label || ""}
                  renderInput={(params) => (
                    <TextField {...params} required label={i18n.t("trades")}
                      error={
                        this.state?.errors?.trades
                          ? true
                          : false
                      }
                      helperText={this.state?.errors?.trades} />
                  )}
                />
              </Stack>
            </Grid>
            {this.state?.handrailDetails?.trades?.value === 'others' &&
              <Grid item xs={12} md={12}>
                <Stack spacing={1}>
                  <TextField
                    required
                    id="outlined-basic"
                    label={i18n.t("others")}
                    variant="outlined"
                    value={this.state?.handrailDetails?.other_trade || ""}
                    onChange={async (e) => {
                      await this.setState({
                        handrailDetails: {
                          ...this.state.handrailDetails,
                          other_trade: e.target.value,
                        },
                      });
                      await this.validateField("other_trade");
                    }}
                    error={this.state.errors?.other_trade ? true : false}
                    helperText={this.state.errors?.other_trade}
                  />
                </Stack>
              </Grid>}
            <Grid item xs={12}>
              <Stack spacing={1}>
                <TextField
                  id="outlined-basic"
                  required
                  label={i18n.t("information")}
                  variant="outlined"
                  value={this.state?.handrailDetails?.information || ""}
                  multiline
                  rows={2}
                  maxRows={4}
                  onChange={async (e) => {
                    await this.setState({
                      handrailDetails: {
                        ...this.state.handrailDetails,
                        information: e.target.value,
                      },
                    });
                    await this.validateField("information");
                  }}
                  error={
                    this.state?.errors?.information
                      ? true
                      : false
                  }
                  helperText={this.state?.errors?.information}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveHandrail();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                    ? i18n.t("add_handrail")
                    : i18n.t("update_handrail")}
              </Button>
            </LoadingButton>
          </Grid>
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  const location = useLocation();
  let urlParams = new URLSearchParams(location.search);
  urlParams = Object.fromEntries(urlParams.entries());

  return <AddEditHandrail {...props} navigate={navigate} params={params} urlParams={urlParams} />;
}
