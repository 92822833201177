import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
// @mui
import { styled, alpha } from "@mui/material/styles";
import {
  Box,
  Link,
  Drawer,
  Typography,
  Avatar,
  Stack,
  Grid,
  IconButton,
} from "@mui/material";
// mock
import account from "../../../_mock/account";
// hooks
import useResponsive from "../../../hooks/useResponsive";
// components
import Logo from "../../../components/logo";
import Scrollbar from "../../../components/scrollbar";
import NavSection from "../../../components/nav-section";
//
import navConfig from "./config";
//icons
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import config from "../../../config";
// ----------------------------------------------------------------------

const StyledAccount = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const isDesktop = useResponsive("up", "lg", "xl");
  const [width, setWidth] = useState(0);

  let username = localStorage.getItem("username");
  let fullName = localStorage.getItem("fullName");
  if (!fullName || fullName === "null" || fullName === "undefined") {
    fullName = "";
  }
  let role = localStorage.getItem("role");
  useEffect(() => {
    if (isDesktop) {
      if (!openNav) {
        setWidth(280);
      } else {
        setWidth(0);
      }
    } else if (!isDesktop && openNav) {
      setWidth(280);
    }
  }, [openNav]);
  useEffect(() => {
    if (openNav && !isDesktop) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
        backgroundColor: "#E6E6FA",
      }}
    >
      <Box sx={{ px: 2.5, py: 3 }}>
        <Grid container justifyContent="space-between" display="flex">
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Logo />
            <Typography
              variant="h6"
              sx={{
                color: "text.secondary",
                fontSize: "1rem !important",
                marginLeft: "5px",
              }}
            >
              {config?.APPLICATION_FULL_NAME}
            </Typography>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <IconButton color="primary" onClick={() => onCloseNav()}>
              <ArrowBackIosNewIcon sx={{ fontSize: "20px" }} />
            </IconButton>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline="none">
          <StyledAccount>
            <Avatar src={account.photoURL} alt="photoURL" />

            <Box sx={{ ml: 2 }}>
              <Typography
                variant="subtitle2"
                noWrap
                sx={{ color: "text.primary", maxWidth: "9rem" }}
              >
                {username}
              </Typography>

              <Typography
                variant="body2"
                noWrap
                sx={{ color: "text.secondary", maxWidth: "9rem" }}
              >
                {role}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />

      <Box sx={{ px: 2.5, pb: 3, mt: 10 }}>
        <Stack
          alignItems="center"
          spacing={3}
          sx={{ pt: 5, borderRadius: 2, position: "relative" }}
        >
          {/* <Box
            component="img"
            src="/assets/images/logo/massive_dynamics.png"
            sx={{ width: '60%', position: 'absolute', top: -30, cursor: 'pointer' }}
            onClick={() => window.open('https://massive-dynamics.in', '_blank')}
          /> */}

          <Box sx={{ textAlign: "center" }}>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              <span>JM Contactless</span>
            </Typography>
          </Box>

          {/* <Button href="https://material-ui.com/store/items/minimal-dashboard/" target="_blank" variant="contained">
            Upgrade to Pro
          </Button> */}
        </Stack>
      </Box>
    </Scrollbar>
  );
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: width },
      }}
    >
      {isDesktop ? (
        <Drawer
          open={!openNav}
          variant="persistent"
          onClose={onCloseNav}
          transitionDuration={{ appear: 300, enter: 300, exit: 300 }}
          ModalProps={{
            keepMounted: true,
            hideBackdrop: true,
          }}
          PaperProps={{
            sx: {
              bgcolor: "background.default",
              width: width,
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{zIndex:2000}}
          PaperProps={{
            sx: { width: width },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
