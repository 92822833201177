import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Stack,
  TextField,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import HomeIcon from "@mui/icons-material/Home";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import BadgeIcon from "@mui/icons-material/Badge";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import Link from "@mui/material/Link";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import i18n from "../../i18n";
import { convertMessageCodeToMessage } from "../../utils/messageCodeToMessage";
import {
  addUser,
  editUser,
  getUser,
} from "../../services/user.service";
import config from "../../config";
import Iconify from "../../components/iconify";


class AddEditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: props?.params?.user_id ? "edit" : "add",
      user_id: props.params?.user_id,
      user: {
      },
      showPassword:false,
      loading: false,
      tableLoader:false,
      formErrors: { roleFormErrors: {} ,errors:{}},
      role:[],

    };
  }

  async componentDidMount() {
    if (this.state.mode === "edit") {
      await this.getUserData();
    }
    let tempRoleOptions=config?.roleOptions?.filter(role=>role?.internal)
    this.setState({
        role:tempRoleOptions
    })
  }

  async getUserData() {
    this.setState({
      loading: true,
      tableLoader:true
    });
    let response = await getUser(this.state.user_id);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
        ? i18n.t(convertMessageCodeToMessage(response?.error_code))
        : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }

    if (response && response.user) {
      let userData = response.user;
      let tempRole = config?.roleOptions?.filter(role=>role?.value === userData?.role)?.[0];
      await this.setState({
        user: {
          ...userData,
          role:tempRole
        },
        loading: false,
        tableLoader:false
      });
    }
  }

  async saveUser() {
    if (!(await this.isFormValid())) {
      return;
    }
    this.setState({
      loading: true,
    });
    let payload = {
      ...this.state.user,
      role:this.state.user?.role?.value
    };

    if (this.state.mode === "add") {
      let response = await addUser(payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }
      if (response && response.user) {
        this.setState({
          loading: false,
        });
        this.props.navigate("/users/view");
      }
    } else {
      let response = await editUser(this.state.user_id, payload);
      if (response && response.error) {
        Swal.fire({
          icon: "error",
          title: i18n.t("error"),
          text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
          confirmButtonText: i18n.t("ok"),
          confirmButtonColor: config.primaryColor,
        });
        this.setState({
          loading: false,
        });
        return;
      }

      if (response && response.user) {
        // Swal toast
        Swal.fire({
          toast: true,
          position: "bottom-end",
          icon: "success",
          title: i18n.t("success"),
          text: i18n.t("user_details_updated_successfully"),
          showConfirmButton: false,
          timer: config.toastDelay,
          timerProgressBar: true,
        });
        this.setState({
          loading: false,
        });
        this.goBack();
      }
    }
  }

  goBack() {
    this.props.navigate(-1)
  }

  async isFormValid() {
    let error = await this.validateField();
    let is_valid = true;
    let keys = Object.keys(error);
    for (let i = 0; i < keys.length; i++) {
      if (error[keys[i]] != '') {
        is_valid = false;
        break;
      }
    }
    return is_valid;
  }
  async validateField(field = null) {

    let error = {...this.state?.formErrors?.errors};

    if (field == null || field == 'name') {
      if (!this.state?.user?.name || this.state.user?.name == '') {
        error['name'] = i18n.t('this_field_is_required');
      } else {
        delete error?.["name"];
      }
    }

    if (field == null || field == 'email') {
      if (!this.state?.user?.email || this.state.user?.email == '') {
        error['email'] = i18n.t('this_field_is_required');
      } else if(!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.state.user?.email)){
        error['email'] = i18n.t('invalid_email_format');
      }else {
        delete error['email'];
      }
    }
    if (field == null || field == 'phone_number') {
      if (!this.state?.user?.phone_number || this.state.user?.phone_number == '') {
        error['phone_number'] = i18n.t('this_field_is_required');
      } else {
        delete error['phone_number'];
      }
    }
     if (field == null || field == 'role') {
      if (!this.state?.user?.role || this.state.user?.role == '') {
        error['role'] = i18n.t('this_field_is_required');
      } else {
        delete error['role'];
      }
    }
    if (field == null || field == 'username') {
      if (!this.state?.user?.username || this.state.user?.username == '') {
        error['username'] = i18n.t('this_field_is_required');
      } else {
        delete error['username'];
      }
    }
    if (field == null || field == 'password') {
      if (!this.state?.user?.password || this.state.user?.password == '') {
        error['password'] = i18n.t('this_field_is_required');
      } else {
        delete error['password'];
      }
    }
    if (this.state.mode === 'add' && field == null || field == 'password') {
      if (!this.state?.user?.password || this.state.user?.password == '') {
        error['password'] = i18n.t('this_field_is_required');
      } else {
        delete error['password'];
      }
    }
    if (field == null || field == 'code') {
        if (!this.state?.user?.code || this.state.user?.code == '') {
          error['code'] = i18n.t('this_field_is_required');
        } else {
          delete error['code'];
        }
      }
      if (field == null || field == 'color') {
        if (!this.state?.user?.color || this.state.user?.color == '') {
          error['color'] = i18n.t('this_field_is_required');
        } else {
          delete error['color'];
        }
      }
    this.setState({
      formErrors:{...this.state.formErrors,errors:error}
    })

    return error;
  }

  render() {
    return (
      <Container maxWidth='xxl'>
        <Helmet>
          <title> {i18n.t("add_user")} | BTEE </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("user")}
        </Typography>
        <Stack direction="row" alignItems="center" mb={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/dashboard/view");
              }}
            >
              <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t('home')}
            </Link>
            <Link
              underline="hover"
              sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
              color="inherit"
              onClick={() => {
                this.props.navigate("/users/view");
              }}
            >
              <FlightTakeoffIcon sx={{ mr: 0.5 }} fontSize="inherit" />
              {i18n.t('users')}
            </Link>
            <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              
              {this.state.mode === "add"
                ? 
                <>
                <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("add")}
                </>
                : 
                <>
                <BadgeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {this.state?.user?.name}
                </>
                }
            </Typography>
            {this.state.mode === "edit" && <Typography
              sx={{ display: "flex", alignItems: "center" }}
              color="text.primary"
            >
              <BorderColorIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                {i18n.t("edit")}
            </Typography>}
          </Breadcrumbs>
        </Stack>
        <Typography variant="body2">
          <Grid container spacing={3} mb={2}>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("name")}
                  variant="outlined"
                  value={this.state?.user?.name || ""}
                  onChange={async (e) => {
                    await this.setState({
                      user: {
                        ...this.state.user,
                        name: e.target.value,
                      },
                    });
                    await this.validateField('name')
                  }}
                  error={
                    this.state.formErrors?.errors?.name
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.name}
                />
              </Stack>
            </Grid>
           
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("email")}
                  variant="outlined"
                  value={this.state?.user?.email || ""}
                  onChange={async (e) => {
                    await this.setState({
                      user: {
                        ...this.state.user,
                        email: e.target.value,
                      },
                    });
                    await this.validateField('email')
                  }}
                  error={
                    this.state.formErrors?.errors?.email
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.email}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("phone_number")}
                  variant="outlined"
                  value={this.state?.user?.phone_number || ""}
                  onChange={async (e) => {
                    // allow '+' and numbers only, '+' should be at the start and optional
                    let regex = /^[+]?[0-9]*$/;
                    if (e.target.value === '' || regex.test(e.target.value)) {
                      await this.setState({
                        user: {
                          ...this.state.user,
                          phone_number: e.target.value,
                        },
                      });
                    }
                    await this.validateField('phone_number')
                  }}
                  error={
                    this.state.formErrors?.errors?.phone_number
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.phone_number}
                />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
          <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  type="text"
                  id="outlined-basic"
                  label={i18n.t("username")}
                  variant="outlined"
                  value={this.state?.user?.username || ""}
                  onChange={async (e) => {
                    await this.setState({
                      user: {
                        ...this.state.user,
                        username: e.target.value,
                      },
                    });
                    await this.validateField('username')
                  }}
                  error={
                    this.state.formErrors?.errors?.username
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.username}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
              <TextField
                  name="password"
                  required
                  label={i18n.t('password')}
                  type={this.state.showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={() => { this.setState({ showPassword: !this.state.showPassword }) }} edge="end">
                          <Iconify icon={this.state.showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  onChange={async (e) => {
                    await this.setState({
                        user: {
                          ...this.state.user,
                          password: e.target.value,
                        },
                      });
                      await this.validateField('password')
                  }}
                  error={!!this.state?.formErrors?.errors?.password}
                  helperText={this.state?.formErrors?.errors?.password}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                 <Autocomplete
                      required
                      noOptionsText={i18n.t('no_options')}
                      disablePortal
                      options={
                        !this.state.role
                          ? [{ label: "Loading...", id: 0 }]
                          : this.state.role
                      }
                      onChange={async(option, value) => {
                        await this.setState({
                         user: {
                        ...this.state.user,
                        role:value,
                      },
                        });
                      await this.validateField('role')
                      }}
                      value={this.state?.user?.role?.label || ''}
                      renderOption={(props, option) => (
                        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                          {option.label} 
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField {...params} label={i18n.t("role")} 
                        error={
                            this.state.formErrors?.errors?.role
                              ? true
                              : false
                          }
                          helperText={this.state.formErrors?.errors?.role}/>
                      )}
                      
                    />
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={3} mb={2}>
           
           
            <Grid item xs={12} md={4}>
              <Stack spacing={1}>
                <TextField
                  required
                  id="outlined-basic"
                  label={i18n.t("code")}
                  variant="outlined"
                  value={this.state?.user?.code || ""}
                  onChange={async (e) => {
                    await this.setState({
                      user: {
                        ...this.state.user,
                        code: e.target.value,
                      },
                    });
                    await this.validateField('code')
                  }}
                  error={
                    this.state.formErrors?.errors?.code
                      ? true
                      : false
                  }
                  helperText={this.state.formErrors?.errors?.code}
                />
              </Stack>
            </Grid>
            <Grid item xs={12} md={4}>
              <Stack spacing={1} sx={{height:'100%'}}>
              <div className="color-picker" style={{borderColor:this.state.formErrors?.errors?.color ? 'red' : ''}}>
                <Grid container>
                <Typography style={{color:this.state.formErrors?.errors?.color ? 'red':""}} variant="subtitle2" sx={{ color: 'text.secondary' ,marginRight:'10px'}}>
                 {i18n.t('color')}*
                </Typography>
                {this?.state?.user?.color &&
                 <Grid item style={{backgroundColor:this.state.user.color,height:'20px',width:'20px'}}></Grid>}
                 </Grid>
                <Typography>
                <input 
                onChange={async (e) => {
                    await this.setState({
                      user: {
                        ...this.state.user,
                        color: e.target.value,
                      },
                    });
                    await this.validateField('color')
                  }}
                className="color-picker-input" type="color" />
                </Typography>
                </div>
                <FormHelperText style={{ color: "red" }}>
                    {this.state.formErrors?.errors?.color}
                  </FormHelperText>
              </Stack>
            </Grid>
          </Grid>
          <Grid item xs={4} style={{ textAlign: "center" }}>
            <LoadingButton>
              <Button
                disableElevation
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="primary"
                disabled={this.state.loading}
                onClick={async () => {
                  await this.saveUser();
                }}
              >
                {this.state.loading
                  ? i18n.t("saving")
                  : this.state.mode === "add"
                  ? i18n.t("add_user")
                  : i18n.t("update_user")}
              </Button>
            </LoadingButton>
          </Grid>
          
        </Typography>
        <Backdrop
          sx={{ color: "#e5e5e5", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={this.state?.loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <AddEditUser {...props} navigate={navigate} params={params} />;
}
