import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import {
  Container, Typography
} from "@mui/material";
import config from "../../config";
import i18n from "../../i18n";
import Trainees from "./Trainees/Trainees";
import Courses from "./Courses/Courses";
import AirTraceOffers from "./Offers/AirTraceOffers";
import Trainings from "./Trainings/Trainings";
class Airtrace extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab:props.params?.tabId || 'trainees',
      tabId: props.params?.tabId}
    
  }
  componentDidUpdate(prevProps) {
    if (this.state.tabId !== this.props.params.tabId) {
      this.setState({ tabId: this.props.params.tabId ,currentTab:this.props.params.tabId});
    }
  }


  render() {
    return (
    <Container maxWidth="xxl">
        <Helmet>
          <title>
            {" "}
            {i18n.t("airtrace")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Typography className="pageHeader" variant="h4" gutterBottom>
          {i18n.t("airtrace")}
        </Typography>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={this.state?.currentTab}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={(event, newValue)=>{
            this.setState({
                currentTab:newValue
            })
            this.props.navigate(`/airtrace/view/${newValue}`)
          }} aria-label="lab API tabs example">
            <Tab label={i18n.t('trainees')} value={'trainees'} />
            <Tab label={i18n.t('courses')} value={'courses'} />
            <Tab label={i18n.t('trainings')} value={'trainings'} />
            {/* <Tab label={i18n.t('offers')} value={3} /> */}
          </TabList>
        </Box>
        <TabPanel value={"trainees"} sx={{padding:'10px 0'}}><Trainees/></TabPanel>
        <TabPanel value={'courses'} sx={{padding:'10px 0'}}><Courses/></TabPanel>
        <TabPanel value={'trainings'} sx={{padding:'10px 0'}}><Trainings/></TabPanel>
        {/* <TabPanel value={3} sx={{padding:'10px 0'}}><AirTraceOffers/></TabPanel> */}
      </TabContext>
    </Box>
      </Container>
    )
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Airtrace {...props} navigate={navigate} params={params} />;
}
