import axios from 'axios'
import config from '../config';

import { getQueryStringFromObject, getRequestHeaders } from './common.service';

const getHandrailList = async (query=null) => {
    try {
        let queryString = '';
        if (query) {
            queryString = '?' + getQueryStringFromObject(query);
        }
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/handrails' + queryString, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}
const getHandrailDetails = async (handrailId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.get( config.SERVER_BASE_URL + '/handrails/' +  handrailId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const addHandrail = async (payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.post(config.SERVER_BASE_URL + '/handrails', payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

const editHandrail = async (handrailId, payload) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.patch( config.SERVER_BASE_URL + '/handrails/' + handrailId, payload, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}
const deleteHandrail = async (handrailId) => {
    try {
        let response;
        try {
            let headers = getRequestHeaders();
            response = await axios.delete( config.SERVER_BASE_URL + '/handrails/' +  handrailId, { headers: headers });
        } catch (error) {
            return error.response.data;
        }
        return response.data;
    } catch (error) {
        return { error: error.message };
    }
}

export { getHandrailList , getHandrailDetails , addHandrail, editHandrail , deleteHandrail };
