import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import Swal from "sweetalert2";

import {
  Stack,
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
  Input,
  InputAdornment,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Autocomplete,
  TextField,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { debounce } from "../../../utils/debounce";
import config from "../../../config";
import i18n from "../../../i18n";
import { convertMessageCodeToMessage } from "../../../utils/messageCodeToMessage";
import Iconify from "../../../components/iconify/Iconify";
import CoursesTable from "../../../SharedComponent/CoursesTable";
import {
  deleteCourse,
  editCourseDetail,
  exportCourses,
  getCoursesList,
} from "../../../services/course.service";
import {
  getDomainList,
  getDomainSubjectsList,
} from "../../../services/domain.service";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

const TABLE_HEAD = [
  {
    id: "course_id",
    label: i18n.t("course_id"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "domain",
    label: i18n.t("domain"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "subject",
    label: i18n.t("subject"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "type",
    label: i18n.t("type"),
    alignRight: false,
    sortable: true,
  },
  {
    id: "remarks",
    label: i18n.t("remarks"),
    alignRight: false,
    sortable: true,
  },
  { id: "" },
];
class Courses extends React.Component {
  constructor(props) {
    super(props);
    this.tableLimitOptions = config?.tableLimitOptions;
    this.state = {
      courses: [],
      course: {},
      open: null,
      page: 1,
      filtersOptions: [],
      search: "",
      limit: 100,
      skip: 0,
      total_courses: 0,
      expanded: false,
      tableLoader: false,
      pageLoader: false,
      filters: {},
      orderBy: { sort_key: "airport", sort_order: true },
      deleteCoursePopup: false,
    };
    this.useStyles = {
      sticky: {
        position: "sticky",
        left: "0",
        zIndex: "5",
      },
    };
    /**when navigated from airport details taking airport id from url */
    this.airportId = window.location.href.split("=")[1];
  }

  async componentDidMount() {
    this.getCourses();
    this.getDomainsList();
  }

  async getCourses(action) {
    this.setState({
      tableLoader: true,
    });

    let query = {
      limit: this.state.limit,
      skip: this.state.skip,
      sort_key: this.state?.orderBy?.sort_key,
      sort_order: this.state?.orderBy?.sort_order ? "ASC" : "DESC",
    };
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters[key]?.value) {
        query[key] = this.state?.filters[key]?.value;
      } else {
        delete query[key];
      }
    });
    if (this.airportId) {
      query["airport"] = this.airportId;
    }
    if (this.state.search) {
      query.search = this.state.search;
    }
    if (action === "filters") {
      query.skip = 0;
      await this.setState({
        skip: 0,
        page: 1,
      });
    }
    let courses = await getCoursesList(query);
    if (courses.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: courses?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(courses?.error_code))
          : courses?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        tableLoader: false,
      });
      return;
    }

    this.setState({
      tableLoader: false,
      courses: courses.airtrace_courses,
      total_courses: courses.count,
    });
  }
  async handleDeleteCourse(courseId) {
    this.setState({
      pageLoader: true,
    });
    let response = await deleteCourse(courseId);

    if (response && response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
      });
      return;
    }

    if (response) {
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : response?.message,
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
      });
      await this.getCourses();
      this.setState({
        pageLoader: false,
      });
    }
  }

  handleExpandClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  handleOpenMenu = (event) => {
    this.setState({ open: event.currentTarget });
  };

  handleCloseMenu = () => {
    this.setState({ open: null });
  };
  getCoursesDataOnchange = debounce(() => {
    this.getCourses("filters");
  }, 500);
  handleTableAction = async (action, data, rowData, key, rowIndex) => {
    if (action === "pagination") {
      await this.setState({
        skip: (data - 1) * this.state.limit,
        page: data,
      });
      this.getCourses();
    } else if (action === "limit") {
      await this.setState({
        ...this.state,
        limit: data,
      });
      this.getCourses();
    } else if (action === "popOverAction") {
      this.setState({
        course: rowData,
      });
      this.handleOpenMenu(data);
    } else if (action === "sort") {
      /**These keys are inside airport key from BE,
       * the sort key for these are like : airport.[keyName]
       */
      await this.setState({
        orderBy: {
          sort_key: data,
          sort_order: !this.state.orderBy?.sort_order,
        },
      });
      this.getCourses();
    } else if (action === "update") {
      if (rowData[key] === data) return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData };
      payload[key] = data;
      let tempData = this.state.courses;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        courses: tempData,
      });
      if (key === "domain") {
        this.getDomainSubjectsList(data);
      }
      this.updateCourseData(payload, payload?._id, backUpData);
    } else if (action === "updateCascade") {
      if (rowData[key] === data[key] && rowData["subject"] === data["subject"])
        return;
      let backUpData = { rowData, rowIndex };
      let payload = { ...rowData, ...data };
      let tempData = this.state.courses;
      tempData[rowIndex] = payload;
      this.setState({
        ...this.state,
        courses: tempData,
      });
      this.updateCourseData(payload, payload?._id, backUpData);
    } else if (action === "closeMenu") {
      this.handleCloseMenu();
    } else if (action === "deleteCourse") {
      this.handleCloseMenu();
      await this.setState({
        deleteCoursePopup: true,
      });
    } else if (action === "closeConfirmationPopUp") {
      this.setState({
        deleteCoursePopup: false,
      });
    } else if (action === "proceed") {
      this.setState({
        deleteCoursePopup: false,
      });
      this.handleDeleteCourse(this.state.course._id);
    }
  };

  async updateCourseData(payload, courseId, backUpData) {
    let response = await editCourseDetail(courseId, payload);
    if (response && response.error) {
      let tempData = this.state.courses;
      tempData[backUpData?.rowIndex] = backUpData?.rowData;
      this.setState({
        ...this.state,
        courses: tempData,
      });
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    } else {
      // Swal toast
      Swal.fire({
        toast: true,
        position: "bottom-end",
        icon: "success",
        title: i18n.t("success"),
        text: response?.message_code
          ? i18n.t(convertMessageCodeToMessage(response?.message_code))
          : "course_Details_updated_successfully",
        showConfirmButton: false,
        timer: config.toastDelay,
        timerProgressBar: true,
        customClass: {
          container: "my-swal",
        },
      });
    }
  }

  async getDomainsList() {
    let response = await getDomainList();
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.domains?.map((el) => {
      let subjectOptions = this.getDomainSubjectsList(el?.key);
      return {
        label: i18n.t(el?.key),
        value: el?.key,
      };
    });
    await this.setState({
      filtersOptions: {
        ...this.state.filtersOptions,
        domainOptions: options,
      },
      domainOptions: options,
    });
  }
  async getDomainSubjectsList(domain, action) {
    let response = await getDomainSubjectsList(domain);
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      return;
    }
    let options = response?.subjects?.map((el) => {
      return {
        label: i18n.t(el?.key),
        value: el?.key,
      };
    });
    if (action === "filter") {
      this.setState({
        filtersOptions: {
          ...this.state.filtersOptions,
          subjectOptions: options,
        },
      });
    } else {
      this.setState((prevState) => ({
        subjectOptions: { ...prevState?.subjectOptions, [domain]: options },
      }));
    }
  }

  async exportCoursesData() {
    this.setState({
      pageLoader: true,
    });
    let query = {};
    Object?.keys(this.state?.filters)?.map((key) => {
      if (this.state?.filters?.[key]?.value) {
        query[key] = this.state?.filters[key]?.value;
      } else {
        delete query[key];
      }
    });
    if (this.state.search) {
      query.search = this.state.search;
    }
    let response;
    if (Object.keys(query)?.length > 0) {
      response = await exportCourses(query);
    } else {
      response = await exportCourses();
    }
    if (response.error) {
      Swal.fire({
        icon: "error",
        title: i18n.t("error"),
        text: response?.error?.error_code
          ? i18n.t(convertMessageCodeToMessage(response?.error_code))
          : response?.error,
        confirmButtonText: i18n.t("ok"),
        confirmButtonColor: config.primaryColor,
      });
      this.setState({
        pageLoader: false,
      });
      return;
    } else {
      const newBlob = new Blob([response]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", `${i18n.t("courses")}.xlsx`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      this.setState({
        pageLoader: false,
      });
    }
  }

  render() {
    return (
      <Container maxWidth="xxl" sx={{ padding: "0!important" }}>
        <Helmet>
          <title>
            {" "}
            {i18n.t("course")} | {config.APPLICATION_NAME}{" "}
          </title>
        </Helmet>
        <Grid fullWidth>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={1}
          >
            <div style={{ width: "100%", marginRight: "10px" }}>
              <Accordion
                TransitionProps={{ unmountOnExit: true }}
                expanded={this.state.expanded}
                sx={{
                  background: "white",
                }}
              >
                <AccordionSummary
                  style={{ background: "white" }}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ margin: 0 }}
                >
                  <Input
                    autoFocus
                    fullWidth
                    disableUnderline
                    placeholder="Search…"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    startAdornment={
                      <InputAdornment position="start">
                        <Iconify
                          icon="eva:search-fill"
                          sx={{ color: "text.disabled", width: 20, height: 20 }}
                        />
                      </InputAdornment>
                    }
                    onChange={(e) => {
                      this.setState({
                        search: e?.target?.value,
                      });
                      this.getCoursesDataOnchange();
                    }}
                    sx={{ mr: 1, fontWeight: "fontWeightBold" }}
                  />
                  <IconButton
                    onClick={() => {
                      this.handleExpandClick();
                    }}
                  >
                    <FilterAltIcon />
                  </IconButton>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <Grid container spacing={3} mb={2}>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t("no_options")}
                            options={
                              !this.state?.filtersOptions?.domainOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.domainOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  domain: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.state?.filters?.domain?.value &&
                                this.getDomainSubjectsList(
                                  this.state?.filters?.domain?.value,
                                  "filter"
                                );
                              this.getCoursesDataOnchange();
                            }}
                            value={this.state?.filters?.domain?.label || ""}
                            renderInput={(params) => (
                              <TextField {...params} label={i18n.t("domain")} />
                            )}
                          />
                        </Stack>
                      </Grid>
                      <Grid item xs={4}>
                        <Stack spacing={1}>
                          <Autocomplete
                            disablePortal
                            noOptionsText={i18n.t("no_options")}
                            disabled={!this.state?.filters?.domain?.label}
                            options={
                              !this.state?.filtersOptions?.subjectOptions
                                ? [{ label: "Loading...", id: 0 }]
                                : this.state?.filtersOptions?.subjectOptions
                            }
                            onChange={async (option, value) => {
                              await this.setState({
                                filters: {
                                  ...this.state?.filters,
                                  subject: {
                                    label: value?.label,
                                    value: value?.value,
                                  },
                                },
                              });
                              this.getCoursesDataOnchange();
                            }}
                            value={this.state?.filters?.subject?.label || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={i18n.t("subject")}
                              />
                            )}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
            <div style={{ marginRight: "10px" }}>
              <Button
                variant="contained"
                startIcon={<SystemUpdateAltIcon />}
                className="BtnWidth"
                onClick={() => {
                  this.exportCoursesData();
                }}
              >
                {i18n.t("export_courses")}
              </Button>
            </div>
            <Button
              variant="contained"
              startIcon={<Iconify icon="eva:plus-fill" />}
              className="BtnWidth"
              onClick={() => {
                this.props.navigate("/airtrace/course/add");
              }}
            >
              {i18n.t("course")}
            </Button>
          </Stack>
        </Grid>

        <CoursesTable
          data={this.state}
          onAction={this.handleTableAction}
          tableHeaders={TABLE_HEAD}
        />

        <Backdrop
          sx={{
            color: "#e5e5e5",
            zIndex: (theme) => theme.zIndex.drawer + 1000,
          }}
          open={this.state?.pageLoader}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Container>
    );
  }
}

// Wrap and export
export default function (props) {
  const navigate = useNavigate();
  const params = useParams();

  return <Courses {...props} navigate={navigate} params={params} />;
}
